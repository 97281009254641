import React from 'react'
import { Group } from 'react-konva'
import { string, func, bool, number } from 'prop-types'
import { CircleNode } from './CircleNode'
import { RectNode } from './RectNode'
import { isCircleShape } from '../../Utilities/common'

export const NodeComponent = ({
  id,
  title,
  type,
  updateNodes,
  selectedId,
  connectionsHandler,
  isProcedureRunning,
  removeNodeHandler,
  editNodeHandler,
  x = 0,
  y = 0
}) => {
  const isSelected = selectedId === id

  const update = e => {
    updateNodes(id, e.target.x(), e.target.y())
  }

  return (
    <Group
      x={x}
      y={y}
      draggable={!isProcedureRunning}
      onDragMove={update}
      onClick={() => connectionsHandler(id)}>
      {isCircleShape(type)
        ? <CircleNode
            isSelected={isSelected}
            type={type} />
        : <RectNode
            id={id}
            removeNodeHandler={removeNodeHandler}
            editNodeHandler={editNodeHandler}
            isSelected={isSelected}
            title={title} />}
    </Group>
  )
}

NodeComponent.propTypes = {
  id: string,
  type: string,
  title: string,
  selectedId: string,
  x: number,
  y: number,
  updateNodes: func,
  connectionsHandler: func,
  removeNodeHandler: func,
  editNodeHandler: func,
  isProcedureRunning: bool
}
