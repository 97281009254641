import React from 'react'
import { Form, Icon } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import PropTypes from 'prop-types'
import testIds from '../../../../../public/testIds.json'

export const InitialValueComponent = props => {
  const { inputs } = labels.modalPattern
  switch (props.dataType) {
    case 'ActivityState':
      return (
        <Form.Group>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
              fluid
              id={testIds.initialValueComponent.activityStateDropDown}
              label={inputs.activityState.label}
              options={inputs.activityState.options}
              value={props.initValue}
              onChange={(event, { value }) => props.handleChangeInput('initValue', value)}
              placeholder={inputs.activityState.placeholder}/>
          </div>
        </Form.Group>
      )
    case 'Boolean':
      return (
        <Form.Group>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
              fluid
              label={inputs.booleanValue.label}
              id={testIds.initialValueComponent.activityStateDropDown}
              options={inputs.booleanValue.options}
              value={String(props.initValue)}
              onChange={(event, { value }) => props.handleChangeInput('initValue', value)}
              placeholder={inputs.booleanValue.placeholder}/>
          </div>
        </Form.Group>
      )
    case 'Float':
    case 'Integer':
      return (
        <Form.Group>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Input
              error={props.initValueError}
              label={props.dataType === 'Float' ? inputs.floatValue.label : inputs.integerValue.label}
              placeholder={props.dataType === 'Float'
                ? inputs.floatValue.placeholder : inputs.integerValue.placeholder}
              id={testIds.initialValueComponent[props.dataType === 'Float' ? 'floatValueInput' : 'integerValueInput']}
              value={props.initValue}
              onChange={(event, { value }) => props.handleChangeInput('initValue', value)}
              className='wizard__form-input'>
            </Form.Input>
          </div>
        </Form.Group>
      )
    case 'String':
      return (
        <Form.Group>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Input
              label={inputs.stringValue.label}
              id={testIds.initialValueComponent.stringValueInput}
              placeholder={inputs.stringValue.placeholder}
              type='text'
              value={props.initValue}
              onChange={(event, { value }) => props.handleChangeInput('initValue', value)}
              className='wizard__form-input'>
            </Form.Input>
          </div>
        </Form.Group>
      )
    case 'Map':
      return (
        <Form.Group>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
              fluid
              label={inputs.keyType.label}
              options={inputs.datatype.mapKeyOptions}
              id={testIds.initialValueComponent.keyTypeDropDown}
              onChange={(event, { value }) =>
                props.handleChangeInput('keyType', { value, error: !value.length })}
              placeholder={inputs.keyType.placeholder}
              error={props.keyType.error}
              value={props.keyType.value} />
          </div>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
              fluid
              id={testIds.initialValueComponent.valueTypeDropDown}
              label={inputs.valueType.label}
              options={inputs.datatype.mapAndListValueOptions}
              onChange={(event, { value }) =>
                props.handleChangeInput('valueType', { value, error: !value.length })}
              placeholder={inputs.valueType.placeholder}
              error={props.valueType.error}
              value={props.valueType.value} />
          </div>
        </Form.Group>
      )
    case 'List':
      return (
        <Form.Group>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
              fluid
              label={inputs.list_type.label}
              id={testIds.initialValueComponent.listTypeDropDown}
              options={inputs.datatype.mapAndListValueOptions}
              onChange={(event, { value }) => props.handleChangeInput('keyType', { value, error: !value.length })}
              placeholder={inputs.list_type.placeholder}
              error={props.keyType.error}
              value={props.keyType.value} />
          </div>
        </Form.Group>
      )
    case 'ConfirmationType':
      return (
        <Form.Group>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
              fluid
              id={testIds.initialValueComponent.confirmationTypeDropDown}
              label={inputs.confirmationType.label}
              options={inputs.confirmationType.options}
              value={props.initValue}
              onChange={(event, { value }) => props.handleChangeInput('initValue', value)}
              placeholder={inputs.confirmationType.placeholder}/>
          </div>
        </Form.Group>
      )
    default:
      return null
  }
}

InitialValueComponent.propTypes = {
  // props from parent
    dataType: PropTypes.string,
    initValue: PropTypes.any,
    handleChangeInput: PropTypes.func,
    keyType: PropTypes.string,
    valueType: PropTypes.string,
    typeList: PropTypes.array,
    initValueError: PropTypes.bool
}

export default InitialValueComponent
