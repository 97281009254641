export const dndTypes = {
  DIAGRAM: 'diagram'
}

export const allActionTypes = [
  'Assignment',
  'ProcedureCall',
  'ListRemove',
  'ListClear',
  'ListAppend',
  'ListInsert',
  'ListConcatenate',
  'MapUnion',
  'MapRemove',
  'MapClear',
  'MapPut',
  'BitSet',
  'BitShiftLeft',
  'BitShiftRight',
  'BitAppend',
  'BitReplace',
  'AddDataListener',
  'AddPackageListener',
  'InvokeActivity',
  'InvokeActivityAndWait',
  'TerminateActivity',
  'GetActivityState',
  'Connect',
  'Disconnect',
  'Wait',
  'AddEventListener',
  'WriteLog',
  'ChoiceDialog',
  'InputDialog',
  'ConfirmDialog',
  'ServerCommand'
]

export const allElementTypes = [
  'Action',
  'Assignment',
  'ProcedureCall',
  'Loop',
  'ForLoop',
  'ForEachLoop',
  'WhileLoop',
  'Branch',
  'If',
  'ElseIf',
  'Else',
  'Group',
  'GroupElement',
  'Parallel',
  'Lane',
  'MutexGroup',
  'Lane',
  'Handler',
  'Try',
  'CatchingFlow',
  'Finally',
  'ListRemove',
  'ListClear',
  'ListAppend',
  'ListInsert',
  'ListConcatenate',
  'MapUnion',
  'MapRemove',
  'MapClear',
  'MapPut',
  'BitSet',
  'BitShiftLeft',
  'BitShiftRight',
  'BitAppend',
  'BitReplace',
  'Break',
  'Continue',
  'Return',
  'TerminateLanes',
  'Throw',
  'AddDataListener',
  'AddPackageListener',
  'InvokeActivity',
  'InvokeActivityAndWait',
  'TerminateActivity',
  'GetActivityState',
  'Connect',
  'Disconnect',
  'Wait',
  'AddEventListener',
  'WriteLog',
  'ChoiceDialog',
  'InputDialog',
  'ConfirmDialog',
  'ServerCommand'
]

export const logElements = [
  'WriteLog',
  'ChoiceDialog',
  'InputDialog',
  'ConfirmDialog'
]

export const ssmExtentionList = [
  'AddDataListener',
  'AddPackageListener',
  'InvokeActivity',
  'InvokeActivityAndWait',
  'TerminateActivity',
  'GetActivityState',
  'Connect',
  'Disconnect',
  'Wait',
  'AddEventListener',
  'ServerCommand'
]

export const bitFieldModifierList = [
  'BitSet',
  'BitShiftLeft',
  'BitShiftRight',
  'BitAppend',
  'BitReplace'
]

export const listModifierList = [
  'ListRemove',
  'ListClear',
  'ListAppend',
  'ListInsert',
  'ListConcatenate'
]

export const mapModifierList = [
  'MapUnion',
  'MapRemove',
  'MapClear',
  'MapPut'
]

export const loopsList = [
  'ForLoop',
  'ForEachLoop',
  'WhileLoop'
]

export const conditionnalsList = [
  'If',
  'ElseIf',
  'Else'
]

export const exceptionValueOfList = [
  'ExceptionValue',
  'GetReportingDataEngineeringValue',
  'GetReportingDataRawValue'
]

export const endedList = [
  'End',
  'Break',
  'Continue',
  'Return',
  'TerminateLanes',
  'Throw'
]

export const typesWithAbilityToAddMultipleElements = [
  'GroupElement',
  'Lane',
  'Try',
  'CatchingFlow',
  'Finally',
  'ForLoop',
  'ForEachLoop',
  'WhileLoop',
  'If',
  'ElseIf',
  'Else'
]

export const elementTypesWithVariableList = [
  'Assignment',
  'ListRemove',
  'ListClear',
  'ListAppend',
  'ListInsert',
  'ListConcatenate',
  'InvokeActivity',
  'InvokeActivityAndWait',
  'AddDataListener',
  'MapUnion',
  'MapRemove',
  'MapClear',
  'MapPut',
  'BitSet',
  'BitShiftLeft',
  'BitShiftRight',
  'BitAppend',
  'BitReplace',
  'AddPackageListener',
  'GetActivityState',
  'Connect',
  'Disconnect',
  'AddEventListener',
  'ServerCommand'
]

export const flowElementsList = [
  'Assignment',
  'ProcedureCall',
  'ListRemove',
  'ListClear',
  'ListAppend',
  'ListInsert',
  'ListConcatenate',
  'MapUnion',
  'MapRemove',
  'MapClear',
  'MapPut',
  'BitSet',
  'BitShiftLeft',
  'BitShiftRight',
  'BitAppend',
  'BitReplace',
  'TerminateLanes',
  'Throw',
  'GroupElement',
  'Lane',
  'Try',
  'CatchingFlow',
  'Finally',
  'ForLoop',
  'ForEachLoop',
  'WhileLoop',
  'If',
  'ElseIf',
  'Else',
  'Break',
  'Continue',
  'Return',
  'AddDataListener',
  'AddPackageListener',
  'InvokeActivity',
  'InvokeActivityAndWait',
  'TerminateActivity',
  'GetActivityState',
  'Connect',
  'Disconnect',
  'Wait',
  'AddEventListener',
  'ServerCommand'
]

export const inheritsFromFlowList = [
  'GroupElement',
  'Lane',
  'Try',
  'CatchingFlow',
  'Finally',
  'ForLoop',
  'ForEachLoop',
  'WhileLoop',
  'MutexGroup',
  'If',
  'ElseIf',
  'Else'
]

export const isNodesList = [
  'Action',
  'node',
  'Group',
  'Parallel',
  'Handler',
  'Loop',
  'MutexGroup',
  'Branch'
]

export const isBreakPointList = [
  'Action',
  'Branch',
  'Group',
  'Handler',
  'Loop',
  'MutexGroup',
  'Parallel',
  'Break',
  'Throw',
  'Return',
  'Continue',
  'TerminateLanes'
]
