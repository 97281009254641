import React from 'react'
import PropTypes from 'prop-types'
import * as labels from '../../../../../public/labels.json'
import { isEmpty } from 'lodash'
import { Table } from 'semantic-ui-react'

const renderTableRows = data => {
  return data.map((el, index) => {
    const searchingIndex = el.indexOf(':')
    const dividedStringArr = []
    if (searchingIndex) {
      dividedStringArr[0] = el.slice(0, searchingIndex)
      dividedStringArr[1] = el.slice(searchingIndex + 1, el.length).trim()
    }
    return <Table.Row key={index}>
      <Table.Cell style={{ display: 'flex' }}>
        {dividedStringArr[0] || ''}
      </Table.Cell>
      <Table.Cell style={{ whiteSpace: 'pre-wrap' }}>
        {dividedStringArr[1] || ''}
      </Table.Cell>
    </Table.Row>
  })
}

const VariableWatch = props => (
  <div>
    {typeof props.params.projectId !== 'undefined' &&
      typeof props.params.otxFileId !== 'undefined' &&
      typeof props.params.procedureId !== 'undefined' &&
      props.driver !== '' &&
      props.ssmId !== -1
    ? <div>{!isEmpty(props.variableWatchItem)
      ? <Table compact='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width='1'>{labels.editorConsoleComponent.name}</Table.HeaderCell>
            <Table.HeaderCell width='10'>{labels.editorConsoleComponent.value}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderTableRows(props.variableWatchItem)}
        </Table.Body>
      </Table>
      : props.isProcedureRunning ? 'Procedure is running...' : 'Run procedure'}
    </div>
    : <div>
      {typeof props.params.projectId === 'undefined' && <div>{labels.error_list.not_defined.project_id}</div>}
      {typeof props.params.otxFileId === 'undefined' && <div>{labels.error_list.not_defined.otx_file_id}</div>}
      {typeof props.params.procedureId === 'undefined' && <div>{labels.error_list.not_defined.procedure_id}</div>}
      {props.ssmId === -1 && <div>{labels.error_list.not_defined.ssm_id}</div>}
      {props.driver === '' && <div>{labels.error_list.not_defined.driver}</div>}
      {labels.error_list.run_procedure}
    </div>}
  </div>
)

export default VariableWatch

VariableWatch.propTypes = {
  params: PropTypes.object,
  ssmId: PropTypes.number,
  variableWatchItem: PropTypes.string,
  driver: PropTypes.string,
  isProcedureRunning: PropTypes.bool
}
