import HandlerWizard from './HandlerWizard'
import { connect } from 'react-redux'
import { getVariableList, openAddVariable } from '../../../../store/thunk/reducerList/diagramElement'

const mapDispatchToProps = {
  getVariableList,
  openAddVariable
}

const mapStateToProps = state => ({
  variableList: state.diagramElement.variableList,
  newVariable: state.outlineTree.newVariable
})

export default connect(mapStateToProps, mapDispatchToProps)(HandlerWizard)
