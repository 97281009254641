import { connect } from 'react-redux'
import GroupParallelMutexGroupWizard from './GroupParallelMutexGroupWizard'
import { getValidities } from '../../../../store/thunk/reducerList/parameters'

const mapDispatchToProps = {
    getValidities
}

const mapStateToProps = state => ({
    validities: state.parameters.validities
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupParallelMutexGroupWizard)
