import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragSource } from 'react-dnd'
import { dndTypes } from '../../../../constants/ItemTypes'
import { idChanger } from '../../../../constants/common'
import { getPopupHint, shouldRenderDndPopup } from '../../../../constants/popupHint'
import './SingleElementListComponent.scss'
import { Popup } from 'semantic-ui-react'

const diagramSource = {
  beginDrag (props, monitor, component) {
    const item = {
      id: `${idChanger()}`,
      key: `${idChanger()}`,
      name: props.item.name,
      kind: 'tree_list',
      type: props.item.type,
      children: []
    }
   return { item }
  }
}

class SingleElementListComponent extends Component {
  static propTypes = {
    item: PropTypes.object,
    connectDragSource: PropTypes.func,
    isDragging: PropTypes.bool,
    colorAndIcon: PropTypes.object,
    isCollored: PropTypes.bool
  }

  checkIfSupported (type) {
    return type !== 'BitSet' &&
        type !== 'BitShiftLeft' &&
        type !== 'BitShiftRight' &&
        type !== 'BitAppend' &&
        type !== 'BitReplace'
  }

  render () {
    const {
      isDragging,
      connectDragSource,
      colorAndIcon,
      isCollored,
      item
    } = this.props
    const opacity = isDragging ? 0.3 : 1
    const shouldPopupRender = shouldRenderDndPopup(item.type)
    let popupHint = ''
    if (shouldPopupRender) {
      popupHint = getPopupHint(item.type)
    }
    const actionRealizationModifier =
      <div
        className='action-realization-modifier'
        style={{
          backgroundColor: isCollored ? colorAndIcon.headerColor : colorAndIcon.textOnDark,
          color: isCollored ? colorAndIcon.textOnDark : colorAndIcon.textOnLight,
          cursor: 'move',
          opacity
        }}>
        <span
          className={colorAndIcon.icon}
          style={{
            color: isCollored ? colorAndIcon.textOnDark : colorAndIcon.textOnLight,
            marginRight: 5
          }}/>
        {item.name}
      </div>
    return (
      this.checkIfSupported(item.type)
      ? connectDragSource(
        <div id={`singleElementListComponent_${item.type}`}>
          {shouldPopupRender ? <Popup
            position='left center'
            content={popupHint}
            trigger={actionRealizationModifier} />
            : <div>
              { actionRealizationModifier }
            </div> }
        </div>
        )
        : <Popup
            content={`Element ${item.name} is not supported yet.`}
            position='left center'
            trigger={
              <div
              className='action-realization-modifier'
              style={{
                backgroundColor: 'gray',
                color: isCollored ? colorAndIcon.textOnDark : colorAndIcon.textOnLight,
                cursor: 'pointer',
                opacity: 0.5
              }}>
                <span
                  className={colorAndIcon.icon}
                  style={{
                    color: isCollored ? colorAndIcon.textOnDark : colorAndIcon.textOnLight,
                    marginRight: 5
                  }}/>
                {item.name}
              </div>} />
    )
  }
}

export default DragSource(dndTypes.DIAGRAM, diagramSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))(SingleElementListComponent)
