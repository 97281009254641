import { connect } from 'react-redux'
import MainViewComponent from './MainViewComponent.js'
import { getAttributes, getXmlPreview } from '../../store/thunk/reducerList/diagramAttributes'
import {
  getDiagramList,
  closeRemoveModal,
  deleteDiagramElement,
  resetHeaderData,
  closeEditModal,
  updateDiagramElement,
  createDiagramElement,
  updateDiagramList,
  updateDiagramAfterDeleteElement,
  openEditModal
} from '../../store/thunk/reducerList/diagramElement'
import { loaderControl } from '../../store/thunk/reducerList/loaderControl'
import { getPseudoCodeList, updatePseudoCodeList } from '../../store/thunk/reducerList/pseudoCode'
import {
  removeDeclaration,
  removeParameter,
  removeLocalDeclaration,
  deleteValidity,
  removeSignature,
  removeProcedure,
  deleteImport,
  deleteThrow
} from '../../store/thunk/reducerList/outlineTree'
import { removeOtxOrPackage } from '../../store/thunk/reducerList/otxFile'
import { updateScroll } from '../../store/thunk/reducerList/procedure'
import { setSelectedContextViewId } from '../../store/thunk/reducerList/selectElement'
import { getStateDiagram } from '../../store/thunk/reducerList/stateDiagram'

const mapDispatchToProps = {
    getDiagramList,
    getAttributes,
    getXmlPreview,
    loaderControl,
    getPseudoCodeList,
    closeRemoveModal,
    deleteDiagramElement,
    removeDeclaration,
    removeParameter,
    removeLocalDeclaration,
    deleteValidity,
    removeSignature,
    removeProcedure,
    deleteImport,
    deleteThrow,
    resetHeaderData,
    removeOtxOrPackage,
    closeEditModal,
    updateDiagramElement,
    createDiagramElement,
    updateDiagramList,
    openEditModal,
    updateScroll,
    setSelectedContextViewId,
    updateDiagramAfterDeleteElement,
    updatePseudoCodeList,
    getStateDiagram
}

const mapStateToProps = state => ({
    headerData: state.diagramElement.headerData,
    updateDiagramListTime: state.diagramElement.updateDiagramListTime,
    modalElement: state.diagramElement.modalElement,
    isOpenRemoveModal: state.diagramElement.isOpenRemoveModal,
    isActiveLoader: state.loaderControl.isActiveLoader,
    isOpenEditModal: state.diagramElement.isOpenEditModal,
    diagramList: state.diagramElement.diagramList,
    updatePseudoCodeListTime: state.pseudoCode.updatePseudoCodeListTime,
    selectedContextViewId: state.selectElement.selectedContextViewId
})

export default connect(mapStateToProps, mapDispatchToProps)(MainViewComponent)
