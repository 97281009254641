import { isEmpty, isEqual, cloneDeep } from 'lodash'
import { inheritsFromFlow, isSsmExtention } from '../constants/common'
import { summaryLineCreatorHandler } from '../constants/summaryLineCreator'
import * as labels from '../../public/labels.json'
import moment from 'moment'
export const protocol = window.location.protocol
export const port = window.location.port
export let origin = window.location.hostname

if (origin === 'localhost') {
  origin = 'dev.itti.com.pl:8019'
} else if (origin === 'dev.itti.com.pl') {
  origin = 'dev.itti.com.pl:' + port
}

export const getHeaders = () => {
  const token = localStorage.getItem('atenatoken')
  const globalHeaders = { headers: { 'x-auth-token': token } }
  return globalHeaders
}

export const getHeadersDocuments = () => {
  const token = localStorage.getItem('atenatoken')
  const globalHeaders = {
    headers: {
      'x-auth-token': token,
      'content-Type': 'multipart/form-data; '
    }
  }
  return globalHeaders
}

export const getHeadersFileDownload = () => {
  const token = localStorage.getItem('atenatoken')
  const globalHeaders = { headers: { 'x-auth-token': token }, responseType: 'arraybuffer' }
  return globalHeaders
}

export const getDefaultValueFromType = (type = '') => {
  switch (type) {
    case 'Real':
    case 'SignedInteger':
    case 'SignedIntegerLiteral':
    case 'SignedIntegerVariable':
    case 'SignedIntegerValue':
    case 'Integer':
    case 'IntegerLiteral':
    case 'IntegerVariable':
    case 'IntegerValue':
    case 'Float':
    case 'FloatLiteral':
    case 'FloatVariable':
    case 'FloatValue':
      return 0
    case 'Boolean':
    case 'BooleanLiteral':
    case 'BooleanVariable':
    case 'BooleanValue':
      return true
    case 'ActivityState':
      return labels.modalPattern.inputs.activityState.options[0].value
    default:
      return ''
  }
}

export const nameRegex = RegExp('^_*[a-zA-Z][a-zA-Z0-9_]*$')

export const errorHandle = (error, request) => {
  if (error.response && error.response.status && error.response.status === 401 && request === 'login') {
    clearLocalStorage()
  } else if (error.response && error.response.status && error.response.status === 401) {
    clearLocalStorage()
    window.location.replace(window.location.origin)
    return null
  } else if (error.message === 'Network Error' && localStorage.getItem('online')) {
    localStorage.removeItem('online')
  }
}

export const clearLocalStorage = () => {
  const itemsToRemoveList = [
    'atenatoken',
    'isTesterRole',
    'user',
    'pseudocodeType',
    'projectTabDataCollapsedElements',
    'outlineDataCollapsedElements',
    'devicesDataTabCollapsedElements',
    'executionsDataTabCollapsedElements'
  ]
  itemsToRemoveList.forEach(item => localStorage.removeItem(item))
}

export const mapJsonPositionList = (objectList, parentElementId, level = 0, parentPosition = null, deepness = '') => {
  for (let i = 0; i < objectList.length; i++) {
    objectList[i] = Object.assign(objectList[i], {
      position: i,
      id: objectList[i].otx_id || `${(new Date()).valueOf() + Math.random()}`,
      parentId: parentElementId,
      key: objectList[i].otx_id || `${(new Date()).valueOf() + Math.random()}`,
      kind: 'diagram_list',
      isSelect: false,
      elementLength: objectList.length,
      level,
      parentPosition,
      summaryLine: summaryLineCreatorHandler(objectList[i]),
      deepness: `${deepness}_${level}-${i}`
    })
    if (objectList[i].children.length > 0) {
      mapJsonPositionList(
        objectList[i].children,
        objectList[i].key,
        level + 1,
        objectList[i].level === 0 ? objectList[i].position : parentPosition,
        objectList[i].deepness)
    }
  }
  return objectList
}

export const mapPseudoCode = data => {
    let procedureId = ''
    let lastParentFlowElement = {}
    data.map((item, index) => {
      item = Object.assign(item, {
        index,
        procedureId
      })
      if (!isEmpty(lastParentFlowElement) && item.indent > 2 && lastParentFlowElement.indent < item.indent) {
        item = Object.assign(item, {
          parentId: lastParentFlowElement.id
        })
      }
      item.type === 'Procedure' && (procedureId = item.id)
      item.type !== 'UNDEFINED' && item.type !== 'Procedure' && (lastParentFlowElement = item)
    })
    return data
}

export const loop = (data, preKey = '0', prePath = '', isProcedureElement = false,
isTypeAdded = false, isVisibilityAdded = false, elementId = '', isNameOnlyLabel = false,
isDocument = false, isTypeOnlyLabel = false, isSignature = false, lastParentFlowElement = null,
preLocalisation = [], preLevel = 2, collapsedElementsArray = [], prePackageName = '') => {
  data.forEach((item, index) => {
    const localisation = preLocalisation
    let level = preLevel
    const key = `${preKey}-${index}`
    let path = `${prePath}`
    path += `[${index}]${item.children && '.children'}`
    let parentFlowElement = null
    let packageName = prePackageName
    item.key = key
    if (item.itemType === 'PACKAGE') {
      key.length === 3
      ? packageName = item.name
      : packageName += `.${item.name}`
      item.packageName = packageName
    }
    if (item.name && item.name.includes('.otx') && item.itemType === 'FILE') {
      item.packageName = packageName
    }
    if (item.id) {
      item.treeId = typeof item.id === 'number' ? item.id.toString() : item.id
      item.id = typeof item.id === 'number' ? item.id.toString() : item.id
    } else if (item.otx_id) {
      item.id = typeof item.otx_id === 'number' ? item.otx_id.toString() : item.otx_id
      item.treeId = typeof item.otx_id === 'number' ? item.otx_id.toString() : item.otx_id
    } else {
      item.id = typeof key === 'number' ? key.toString() : key
      item.treeId = typeof key === 'number' ? key.toString() : key
    }
    if (item.childItems) {
      item.children = item.childItems
    }
    item.state = {
      expanded: isEmpty(collapsedElementsArray) || !collapsedElementsArray.some(el => el === item.id)
    }
    elementId !== '' && (isSignature ? item.signatureId = elementId : item.procedureId = elementId)
    if (isProcedureElement) {
      item.lastParentFlowElement = lastParentFlowElement
      item.otx_id &&
      (item.isProcedureElement = true)
      item.type && inheritsFromFlow(item.type) && item.otx_id
      ? (parentFlowElement = item.otx_id)
      : (parentFlowElement = lastParentFlowElement)
      if (level === localisation.length) {
        localisation.push({
          parentFlowElement,
          type: item.type,
          id: item.otx_id,
          name: item.otx_name ? `${item.otx_name}(${item.type})` : item.type
        })
      } else if (localisation.length > level) {
        localisation.splice(level - localisation.length, localisation.length)
        localisation.push({
          parentFlowElement,
          type: item.type,
          id: item.otx_id,
          name: item.otx_name ? `${item.otx_name}(${item.type})` : item.type
        })
      }
      item.localisation = cloneDeep(localisation)
    }
    if (!item.name && item.type) {
      if (((item.type === 'Branch' || item.type === 'Handler') &&
        item.children.length > 1 &&
        (item.children[0].type === 'If' || item.children[0].type === 'Try'))) {
          (item.children[0].hasSiblings = true)
        }
      if (isTypeAdded) {
        item.name = item.otx_name + ': ' + (item.dataType ? item.dataType.type : '')
      } else if (isVisibilityAdded) {
        item.name = item.otx_name + ': ' + item.visibility
      } else if (isNameOnlyLabel) {
        item.name = item.otx_name
      } else if (isDocument) {
        item.name = `${item.prefix}: ${item.document}`
      } else if (isTypeOnlyLabel) {
        item.name = item.type || ''
      } else {
        item.name = item.type.toUpperCase() + ' ' + (item.otx_name || '')
      }
    }
    if (item.name && isSsmExtention(item.type)) {
      item.name = item.type.toUpperCase() + ': ' + (item.name || '')
    }
    if (!item.name && item.document) { item.name = item.document }
    if (item.otxName && item.generated) {
      item.name = `${moment(item.generated).format('MM-DD-YYYY, hh:mm:ss')}_${item.otxName}`
      item.itemType = 'report'
    }
    if (item.children && item.children.length) {
      level += 1
      return loop(item.children, key, path, isProcedureElement,
        isTypeAdded, isVisibilityAdded, elementId, false,
        false, false, false, parentFlowElement,
        localisation, level, collapsedElementsArray, packageName)
    } else if (item.childItems && item.childItems.length) {
      return loop(item.childItems, key, path, isProcedureElement)
    } else { item.children = [] }
  })
}

export const mapJsonToDisplayList = (arr, isValidity = false) => {
  const tempArr = []
  for (let i = 0; i < arr.length; i++) {
    tempArr.push({ text: arr[i].otx_name, value: isValidity ? arr[i].otx_name : arr[i].otx_id, key: arr[i].otx_id })
  }
  return tempArr
}

export const executionsTabDataCreator = executions => {
  const collapsedElementsArray = JSON.parse(localStorage.getItem('executionsDataTabCollapsedElements')) || []
  const newExecutionsList = []
  let key = 0
  let folderName = ''
  executions.forEach((item, index) => {
    if (item.otxName && !isEqual(folderName, item.otxName)) {
      !isEmpty(folderName) && (key += 1)
      folderName = item.otxName
      newExecutionsList.push({
        key: key.toString(),
        id: key.toString(),
        itemType: 'Execution',
        name: item.otxName.replace('.otx', ''),
        state: {
          expanded: !collapsedElementsArray.some(el => el === key.toString())
        },
        children: []
      })
    }
    const tempObj = Object.assign(item, {
      key: `${key}-${index}`,
      children: [],
      state: { expanded: true },
      name: `${moment(item.generated).format('MM-DD-YYYY, hh:mm:ss')}_${item.otxName}`,
      itemType: 'report'
    })
    newExecutionsList[key].children.push(tempObj)
  })
  return newExecutionsList
}

export const search = (array, arrayNext) => {
  if (array.length !== 0 && arrayNext && arrayNext.length !== 0) {
    array.map(arrayObj => {
      arrayNext.map(arrayNextObj => {
        if (arrayObj.id === arrayNextObj.id) {
          arrayNextObj.state.expanded = arrayObj.state.expanded
        } else if (arrayObj.id === arrayNextObj.id && arrayObj.children) {
          search(arrayObj.children, arrayNextObj.children)
        }
      })
    })
  }
  return arrayNext
}

export const findNested = (arr, id) => {
  let node = {}
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id !== id && arr[i].children && arr[i].children.length !== 0) {
      const nextNode = findNested(arr[i].children, id)
      if (!isEmpty(nextNode)) {
        node = nextNode
      }
    } else if (arr[i].id === id) {
      node = arr[i]
      break
    }
  }
  if (!isEmpty(node)) {
    return node
  }
}
