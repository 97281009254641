import React from 'react'
import PropTypes from 'prop-types'

const ClearSelect = props => (
  <div
    onClick={props.handleClick}
    className='input-clear-cross'>
    <div className='cross-bars-container'>
      <div className='cross-bar-1'></div>
      <div className='cross-bar-2'></div>
    </div>
  </div>
)

ClearSelect.propTypes = {
  handleClick: PropTypes.func
}

export default ClearSelect
