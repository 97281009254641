import axios from 'axios'
import { origin, protocol, getHeaders, errorHandle } from '../../../store/addons'
import { GET_STATE_DIAGRAM } from '../actionTypeList'
import { loaderControl } from './loaderControl'
import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
import { returnInitValueHandler } from '../../../constants/initValues'
import { summaryLineHandler } from '../../../constants/summaryLineCreator'
import isEmpty from 'lodash/isEmpty'

export const getStateDiagramAction = data => {
  return {
    type: GET_STATE_DIAGRAM,
    data
  }
}

export const getStateDiagram = (projectId, otxFileId) => {
  return dispatch => {
    dispatch(loaderControl(true))
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/otx/${otxFileId}/state-diagram`, getHeaders())
       .then(response => {
          const connections = []
          response.data.connections.forEach(connection =>
            connections.push({
              ...connection,
              summaryLine: !isEmpty(connection.conditions)
                ? summaryLineHandler(connection.conditions[0]).toString()
                : ''
            }))
         const newNodesDataState = { ...response.data, connections }
         dispatch(getStateDiagramAction(newNodesDataState))
         dispatch(loaderControl())
         resolve()
       })
       .catch(error => {
         const errorMessage = parseError(error)
         dispatch(Notifications.error(errorMessage))
         errorHandle(error)
         dispatch(loaderControl())
         reject(error)
       })
    })
  }
}

export const postStateDiagram = (projectId, otxFileId, body) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/otx/${otxFileId}/state-diagram`, body,
      getHeaders())
      .then(() => {
        dispatch(getStateDiagram(projectId, otxFileId))
        resolve()
      })
       .catch(error => {
         const errorMessage = parseError(error)
         dispatch(Notifications.error(errorMessage))
         errorHandle(error)
         dispatch(loaderControl())
         reject(error)
       })
    })
  }
}

export const actions = {
  getStateDiagram,
  postStateDiagram
}

const ACTION_HANDLERS = {
  [GET_STATE_DIAGRAM]: (state, action) => {
    return {
      ...state,
      nodesDataState: action.data
    }
  }
}

const initialState = {
  nodesDataState: returnInitValueHandler('initNodesDataState')
}

export default function stateDiagramReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
