import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Popup } from 'semantic-ui-react'
import { nameRegex } from '../../../../store/addons'
import testIds from '../../../../../public/testIds.json'
import { hints, modalPattern, buttons } from '../../../../../public/labels.json'

export default class Import extends Component {
  constructor (props) {
    super()
    this.state = typeof props.editItem.document === 'undefined'
    ? {
      otx: '',
      otxError: false,
      prefix: '',
      prefixError: false
    } : {
      otx: props.editItem.document,
      otxError: false,
      prefix: props.editItem.prefix,
      prefixError: false
    }
  }

  static propTypes = {
    saveModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    editItem: PropTypes.object.isRequired,
    otxFileList: PropTypes.array.isRequired
  }

  handleChangeInput = (stateName, value) => {
    this.setState({
      [stateName]: value
    }, () => this.validateBlur(stateName))
  }

  validateBlur = stateName => {
    this.setState({
      [`${stateName}Error`]: stateName === 'prefix'
        ? !nameRegex.test(this.state[stateName])
        : this.state[stateName] === ''
    })
  }

  saveData = () => {
    this.validateBlur('otx')
    this.validateBlur('prefix')

    if (nameRegex.test(this.state.prefix) && this.state.otx !== '') {
      this.props.saveModal(
        'Import',
        {
          document: this.state.otx,
          prefix: this.state.prefix,
          type: 'Import',
          package: this.props.otxFileList
            .filter(file => file.value === this.state.otx)[0].package
        },
        this.props.editItem.document)
    }
  }

  render () {
    return (
      <section className='wizard__form-container'>
        <Form className='wizard__form'>
          <Form.Group>
            <section className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={testIds.import.otxDocumentDropDown}
                label={modalPattern.inputs.otx_document.label}
                placeholder={modalPattern.inputs.otx_document.placeholder}
                options={this.props.otxFileList}
                value={this.state.otx}
                error={this.state.otxError}
                onChange={(event, { value }) => this.handleChangeInput('otx', value)}
                onBlur={this.validateBlur.bind(this, 'otx')} />
            </section>
          </Form.Group>
          <Form.Group>
            <section className='wizard__form-info'>
              <Popup
                position='top center'
                content={hints.import.prefix}
                trigger={<Icon name='info' />} />
              <Form.Input
                id={testIds.import.prefixInput}
                label={modalPattern.inputs.prefix.label}
                placeholder={modalPattern.inputs.prefix.placeholder}
                type='text'
                value={this.state.prefix}
                onChange={event => this.handleChangeInput('prefix', event.target.value)}
                onBlur={this.validateBlur.bind(this, 'prefix')}
                error={this.state.prefixError}
                className='wizard__form-input'>
              </Form.Input>
            </section>
          </Form.Group>
        </Form>
        <div className='wizard__footer'>
          <div>
            <Button
              id={testIds.import.cancelButton}
              onClick={this.props.closeModal}
              content={buttons.cancel} />
          </div>
          <div>
            <Button
              id={testIds.import.saveButton}
              onClick={this.saveData}
              content={buttons.save}
              positive />
          </div>
        </div>
      </section>
    )
  }
}
