import { connect } from 'react-redux'
import ActionWizard from './ActionWizard'
import {
  getVariableList,
  readDiagramElement,
  readDiagramElementAction,
  getProceduresParametersList,
  openAddVariable
} from '../../../../store/thunk/reducerList/diagramElement'
import { setSsmElementListByType, resetSsmElementListByType } from '../../../../store/thunk/reducerList/otxFile'
import { getValidities } from '../../../../store/thunk/reducerList/parameters'
import { setAddedVariable } from '../../../../store/thunk/reducerList/outlineTree'
import { loaderControl } from '../../../../store/thunk/reducerList/loaderControl'

const mapDispatchToProps = {
    getVariableList,
    getProceduresParametersList,
    openAddVariable,
    getValidities,
    setSsmElementListByType,
    readDiagramElement,
    readDiagramElementAction,
    setAddedVariable,
    loaderControl,
    resetSsmElementListByType
}

const mapStateToProps = state => ({
    diagramElement: state.diagramElement.diagramElement,
    variableList: state.diagramElement.variableList,
    optionsVariableList: state.diagramElement.variableListWithTypes,
    proceduresWithParametersList: state.diagramElement.proceduresWithParametersList,
    activityList: state.otxFile.activityList,
    ssmId: state.selectElement.ssmId,
    validities: state.parameters.validities,
    ssmElementList: state.otxFile.ssmElementList,
    newVariable: state.outlineTree.newVariable,
    isOpenAddVar: state.diagramElement.isOpenAddVar
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionWizard)
