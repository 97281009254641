import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './AddNewFileModal.scss'
import { Icon, Modal } from 'semantic-ui-react'
import labels from '../../../public/labels.json'
import NewProcedureComponent from './components/NewProcedureComponent'
import NewSignatureComponent from './components/NewSignatureComponent'
import NewValidityComponent from './components/NewValidityComponent'
import NewDeclarationComponent from './components/NewDeclarationComponent'
import NewLocalDeclarationComponent from './components/NewLocalDeclarationComponent'
import Import from './components/Import/Import'
import Throw from './components/Throw/Throw'
import NewParameterComponent from './components/NewParameterComponent'
import _ from 'lodash'
import { nameRegex } from '../../store/addons'

class AddNewFileModal extends Component {
  state = {
    headerData: {
      title: '',
      titleError: false,
      description: '',
      icon: ''
    }
  }

  static propTypes = {
    // props from parent
    closeModal: PropTypes.func,
    isModalOpened: PropTypes.bool,
    saveModal: PropTypes.func,
    fileType: PropTypes.string,
    editItem: PropTypes.object,
    // props from reducers
    otxFileList: PropTypes.array,
    params: PropTypes.object
  }

  componentDidMount () {
    this.headerHandler(this.props.fileType, _.isEmpty(this.props.editItem))
  }

  changeHandler = (stateName, value) => {
    this.setState({ [stateName]: value })
  }

  validateBlur = (stateName, validationName) => {
    const error = `${stateName}Error`
    const change = { ...this.state }
    change[error] = stateName === 'title' ? !nameRegex.test(this.state[stateName])
      : this.state[stateName] === '' || this.state[stateName] === null
    this.setState(change)
    if (validationName === 'formValidation') return change[error]
  }

  renderContent = fileType => {
    switch (fileType) {
      case 'postNewProcedure':
      case 'Procedure':
        return <NewProcedureComponent
                  params={this.props.params}
                  fileType={this.props.fileType}
                  editItem={fileType === 'Procedure' ? this.props.editItem : {}}
                  saveModal={this.props.saveModal}
                  closeModal={this.props.closeModal} />
      case 'postNewSignature':
      case 'Signature':
        return <NewSignatureComponent
                  fileType={this.props.fileType}
                  editItem={this.props.editItem}
                  saveModal={this.props.saveModal}
                  closeModal={this.props.closeModal} />
      case 'postNewValidity':
      case 'Validity':
          return <NewValidityComponent
                    params={this.props.params}
                    editItem={this.props.editItem}
                    fileType={this.props.fileType}
                    saveModal={this.props.saveModal}
                    closeModal={this.props.closeModal} />
      case 'GlobalConstantDeclaration':
      case 'DocumentVariableDeclaration':
      case 'ContextVariableDeclaration':
          return <NewDeclarationComponent
                    editItem={this.props.editItem}
                    fileType={this.props.fileType}
                    saveModal={this.props.saveModal}
                    closeModal={this.props.closeModal} />
      case 'InOutParameter':
      case 'OutParameter':
      case 'InParameter':
          return <NewParameterComponent
                    editItem={this.props.editItem}
                    fileType={this.props.fileType}
                    saveModal={this.props.saveModal}
                    closeModal={this.props.closeModal} />
      case 'ConstantDeclaration':
      case 'VariableDeclaration':
        return <NewLocalDeclarationComponent
                  editItem={this.props.editItem}
                  fileType={this.props.fileType}
                  saveModal={this.props.saveModal}
                  closeModal={this.props.closeModal} />
      case 'AddImport':
      case 'Import':
        return <Import
                  editItem={this.props.editItem}
                  saveModal={this.props.saveModal}
                  otxFileList={this.props.otxFileList}
                  closeModal={this.props.closeModal} />
      case 'AddThrow':
      case 'Throw':
        return <Throw
                  editItem={this.props.editItem}
                  saveModal={this.props.saveModal}
                  closeModal={this.props.closeModal} />
    }
  }

  // isPostMode --> true if we wre in Add mode, false in edition mode
  headerHandler = (type = '', isPostMode = false) => {
    switch (type) {
      case 'postNewProcedure':
      case 'Procedure':
        this.setState(
          Object.assign(this.state.headerData,
            isPostMode ? {
              title: labels.modalPattern.newProcedure.title,
              description: labels.modalPattern.newProcedure.description,
              icon: `icon-${labels.modalPattern.newProcedure.icon}`
            } : {
              title: labels.modalPattern.edit_procedure.title,
              description: labels.modalPattern.edit_procedure.description,
              icon: `icon-${labels.modalPattern.edit_procedure.icon}`
            }
          )
        )
        break
      case 'Signature':
      case 'postNewSignature':
        this.setState(
          Object.assign(this.state.headerData,
            isPostMode ? {
              title: labels.modalPattern.newSignaure.title,
              description: labels.modalPattern.newSignaure.description,
              icon: `icon-${labels.modalPattern.newSignaure.icon}`
            } : {
              title: labels.modalPattern.edit_signaure.title,
              description: labels.modalPattern.edit_signaure.description,
              icon: `icon-${labels.modalPattern.edit_signaure.icon}`
            }
          )
        )
        break
      case 'postNewValidity':
      case 'Validity':
        this.setState(
          Object.assign(this.state.headerData,
            isPostMode ? {
              title: labels.modalPattern.newValidity.title,
              description: labels.modalPattern.newValidity.description,
              icon: `icon-${labels.modalPattern.newValidity.icon}`
            } : {
              title: labels.modalPattern.edit_validity.title,
              description: labels.modalPattern.edit_validity.description,
              icon: `icon-${labels.modalPattern.edit_validity.icon}`
            }
          )
        )
        break
      case 'GlobalConstantDeclaration':
          this.setState(
            Object.assign(this.state.headerData,
              isPostMode ? {
                title: labels.modalPattern.newGlobalConstant.title,
                description: labels.modalPattern.newGlobalConstant.description,
                icon: `icon-${labels.modalPattern.newGlobalConstant.icon}`
              } : {
                title: labels.modalPattern.edit_global_constant.title,
                description: labels.modalPattern.edit_global_constant.description,
                icon: `icon-${labels.modalPattern.edit_global_constant.icon}`
              }
            )
          )
          break
      case 'DocumentVariableDeclaration':
          this.setState(
            Object.assign(this.state.headerData,
              isPostMode ? {
                title: labels.modalPattern.newDocumentVariable.title,
                description: labels.modalPattern.newDocumentVariable.description,
                icon: `icon-${labels.modalPattern.newDocumentVariable.icon}`
              } : {
                title: labels.modalPattern.edit_document_variable.title,
                description: labels.modalPattern.edit_document_variable.description,
                icon: `icon-${labels.modalPattern.edit_document_variable.icon}`
              }
            )
          )
          break
      case 'ContextVariableDeclaration':
          this.setState(
            Object.assign(this.state.headerData,
              isPostMode ? {
                title: labels.modalPattern.newContextVariable.title,
                description: labels.modalPattern.newContextVariable.description,
                icon: `icon-${labels.modalPattern.newContextVariable.icon}`
              } : {
                title: labels.modalPattern.edit_context_variable.title,
                description: labels.modalPattern.edit_context_variable.description,
                icon: `icon-${labels.modalPattern.edit_context_variable.icon}`
              }
            )
          )
          break
      case 'InOutParameter':
      case 'OutParameter':
      case 'InParameter':
          this.setState(
            Object.assign(this.state.headerData,
              isPostMode ? {
                title: labels.modalPattern.newParameterDeclaration.title,
                description: labels.modalPattern.newParameterDeclaration
                  .description[type === 'InParameter' ? 0 : type === 'InOutParameter' ? 1 : 2],
                icon: `icon-${labels.modalPattern.newParameterDeclaration.icon}`
              } : {
                title: labels.modalPattern.newParameterDeclaration.title_edit,
                description: labels.modalPattern.newParameterDeclaration
                  .description[type === 'InParameter' ? 3 : type === 'InOutParameter' ? 4 : 5],
                icon: `icon-${labels.modalPattern.newParameterDeclaration.icon}`
              }
            )
          )
          break
      case 'VariableDeclaration':
        this.setState(
          Object.assign(this.state.headerData,
            isPostMode ? {
              title: labels.modalPattern.newVariable.title,
              description: labels.modalPattern.newVariable.description,
              icon: `icon-${labels.modalPattern.newVariable.icon}`
            } : {
              title: labels.modalPattern.edit_variable.title,
              description: labels.modalPattern.edit_variable.description,
              icon: `icon-${labels.modalPattern.edit_variable.icon}`
            }
          )
        )
        break
      case 'AddImport':
      case 'Import':
        this.setState(
          Object.assign(this.state.headerData,
            isPostMode ? {
              title: labels.modalPattern.import.add.title,
              description: labels.modalPattern.import.add.description,
              icon: `icon-${labels.modalPattern.import.add.icon}`
            } : {
              title: labels.modalPattern.import.edit.title,
              description: labels.modalPattern.import.edit.description,
              icon: `icon-${labels.modalPattern.import.edit.icon}`
            }
          )
        )
        break
      case 'AddThrow':
      case 'Throw':
        this.setState(
          Object.assign(this.state.headerData,
            isPostMode ? {
              title: labels.modalPattern.throw.add.title,
              description: labels.modalPattern.throw.add.description,
              icon: `icon-${labels.modalPattern.throw.add.icon}`
            } : {
              title: labels.modalPattern.throw.edit.title,
              description: labels.modalPattern.throw.edit.description,
              icon: `icon-${labels.modalPattern.throw.edit.icon}`
            }
          )
        )
          break
      case 'ConstantDeclaration':
        this.setState(
          Object.assign(this.state.headerData,
            isPostMode ? {
              title: labels.modalPattern.newConstant.title,
              description: labels.modalPattern.newConstant.description,
              icon: `icon-${labels.modalPattern.newConstant.icon}`
            } : {
              title: labels.modalPattern.edit_constant.title,
              description: labels.modalPattern.edit_constant.description,
              icon: `icon-${labels.modalPattern.edit_constant.icon}`
            }
          )
        )
        break
    }
  }

  render () {
    const { headerData } = this.state
    const {
      isModalOpened,
      closeModal,
      fileType
    } = this.props
    return (
      <Modal
        closeOnDimmerClick={false}
        open={isModalOpened}
        onClose={closeModal}
        className='wizard__container'
        centered>
        <div className='wizard__header'>
          <div className='wizard__header-content'>
            <div className='wizard__header-logo'>
              <Icon name={headerData.icon} />
            </div>
            <div className='wizard__header-text'>
              <h2>{headerData.title}</h2>
              <p>{headerData.description}</p>
            </div>
          </div>
        </div>
        {this.renderContent(fileType)}
      </Modal>
    )
  }
}

export default AddNewFileModal
