import React from 'react'
import { array } from 'prop-types'
import { fontColorSetter } from '../../constants/common'

const ValidationLogs = ({ logs }) => (
  logs.map((log, index) =>
    <div
      key={index}
      style={{ paddingBottom: 5, display: 'flex' }}
      className={fontColorSetter(log)}>
      {logs.length > 1 && <div>{index + 1}. </div>}
      <div>{log}</div>
    </div>
  )
)

ValidationLogs.propTypes = {
  logs: array
}

export default ValidationLogs
