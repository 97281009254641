import React, { Component } from 'react'
import NewProjectComponent from '../../../NewProjectComponent'
import ModalComponent from '../../../ModalComponent/ModalComponent'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import './PickProjectComponent.scss'
import { browserHistory } from 'react-router'
import { Table, Button, Input, Icon, TableCell, Popup } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import testIds from '../../../../../public/testIds.json'
import isEqual from 'lodash/isEqual'

class PickProjectComponent extends Component {
  constructor () {
    super()
    this.state = {
      projectId: null,
      projects: [],
      searchProject: '',
      isNewProjectOpen: false,
      isModalOpen: false,
      modalOpenTime: 0,
      newProjectModalMode: '',
      editProject: {}
    }
  }

  static propTypes = {
    projects: PropTypes.array,
    getProjectList: PropTypes.func,
    project: PropTypes.object,
    addProject: PropTypes.func,
    updateProject: PropTypes.func,
    deleteProject: PropTypes.func,
    responseTime: PropTypes.number,
    clearProjectListTime: PropTypes.func,
    loaderControl: PropTypes.func,
    isTesterRole: PropTypes.bool
  }

  componentDidMount () {
    const { getProjectList, projects, loaderControl } = this.props
    loaderControl(true)
    getProjectList()
    this.setState({ projects })
  }

  componentDidUpdate (prevProps) {
    const { projects } = this.props
    const { searchProject } = this.state
    if (this.props.responseTime > this.state.modalOpenTime) {
        this.props.clearProjectListTime()
        this.props.getProjectList()
        this.setState({
          isNewProjectOpen: false,
          isModalOpen: false
        })
      }
    if (!isEqual(projects, prevProps.projects)) {
      this.setState({
        projects: searchProject === '' ? projects : this.filterProjects(searchProject, projects)
      })
    }
  }

  handleRowClick = id => {
    browserHistory.push(`/project/${id}`)
  }

  handleSearch = (name, value) => {
    const projects = value !== '' ? this.filterProjects(value, this.props.projects) : this.props.projects
    this.setState({ [name]: value, projects })
  }

  filterProjects = (inputVal, projects) =>
  projects.filter(el => el.name.toLowerCase().search(inputVal.toLowerCase()) !== -1)

  openNewProject = () => {
    this.setState({
      isNewProjectOpen: true,
      modalOpenTime: Date.now(),
      newProjectModalMode: 'addProject'
    })
  }

  closeNewProject = () => {
    this.setState({
      isNewProjectOpen: false
    })
  }

  openEditModal = (editProject, e) => {
    e.stopPropagation()
    this.setState({
      editProject,
      isNewProjectOpen: true,
      modalOpenTime: Date.now(),
      newProjectModalMode: 'editProject'
    })
  }

  deleteProject = () => {
    this.props.loaderControl(true)
    this.props.deleteProject(this.state.projectId)
    .then(() => this.setState({ projectId: null }))
  }

  openDeleteModal = (projectId, e) => {
    e.stopPropagation()
    this.setState({
      isModalOpen: true,
      modalOpenTime: Date.now(),
      projectId
    })
  }

  closeDeleteModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  saveModal = itemToAdd => {
    this.props.loaderControl(true)
    this.props.addProject(itemToAdd)
  }

  handleRating = (project, e) => {
    e.stopPropagation()
    const { updateProject, loaderControl } = this.props
    const editProject = { ...project, favourite: !project.favourite }
    loaderControl(true)
    updateProject(editProject)
  }

  updateProjectNameAndDescription = (name, description) => {
    const { updateProject, loaderControl } = this.props
    const editProject = { ...this.state.editProject, name, description }
    loaderControl(true)
    updateProject(editProject)
  }

  render () {
    const {
      projects,
      searchProject,
      isNewProjectOpen,
      isModalOpen
    } = this.state
    const { isTesterRole } = this.props
    const {
      pickProjectComponent,
      buttons,
      modalPattern
    } = labels
    return (
      <div className='pickproject'>
        <div>
          {!isTesterRole &&
          <Button
            id={testIds.pickProjectComponent.newProjectButton}
            compact
            className='pickproject__new'
            content='New Project'
            onClick={this.openNewProject} />}
          <div className='pickproject__header'>
            <h2>{pickProjectComponent.headerTitle}</h2>
            <h5>{pickProjectComponent.headerDescription}</h5>
          </div>
          <div className='pickproject__search'>
            <Input
              fluid
              id={testIds.pickProjectComponent.userNameInput}
              data-test={testIds.pickProjectComponent.userNameInput}
              icon='search'
              placeholder={pickProjectComponent.searchBarPlaceholder}
              value={searchProject}
              onChange={(e, { value }) => this.handleSearch('searchProject', value)} />
          </div>
        </div>
        <div className='pickproject__table'>
          <Table celled selectable id={testIds.pickProjectComponent.projectsTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>{pickProjectComponent.tableName}</Table.HeaderCell>
                <Table.HeaderCell width={4}>{pickProjectComponent.tableDescription}</Table.HeaderCell>
                <Table.HeaderCell width={3}>{pickProjectComponent.tableLastModification}</Table.HeaderCell>
                <Table.HeaderCell width={2}>{pickProjectComponent.tableOwner}</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center'>{pickProjectComponent.tableFavourites}</Table.HeaderCell>
                {!isTesterRole &&
                <Table.HeaderCell width={1} />}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {projects && projects.length ? projects.sort((a, b) =>
                b.favourite - a.favourite).map((project, index) => (
                  <Table.Row onClick={() => this.handleRowClick(project.id)} key={index}>
                    <TableCell width={2}>{project.name}</TableCell>
                    <TableCell width={4}>{!project.description ? 'Not defined' : project.description}</TableCell>
                    <TableCell width={3}>
                      {!project.lastModificationDate ? 'Not defined'
                      : <Moment format='YYYY-MM-DD HH:mm'>{project.lastModificationDate}</Moment>}
                    </TableCell>
                    <TableCell width={2}>{project.createdBy.login}</TableCell>
                    <TableCell width={1} textAlign='center'>
                      <Icon
                        name='star'
                        color={ project.favourite ? 'yellow' : 'grey' }
                        className='pickproject__table-favourite'
                        onClick={e => this.handleRating(project, e)} />
                    </TableCell>
                    {!isTesterRole &&
                    <TableCell width={1}>
                      <div className='pickproject__table-actions'>
                        <Popup
                          trigger={
                            <Button
                              compact
                              id={`${testIds.pickProjectComponent.editButton}_${index}`}
                              onClick={e => this.openEditModal(project, e) }
                              style={{ marginRight: 10 }}
                              icon>
                              <Icon name='edit' />
                            </Button>}
                          content={buttons.edit}
                          position='top center'
                          size='tiny' />
                        <Popup
                          trigger={
                            <Button
                              compact
                              id={`${testIds.pickProjectComponent.deleteButton}_${index}`}
                              onClick={e => this.openDeleteModal(project.id, e)
                              }
                              style={{ marginRight: 0 }}
                              negative
                              icon>
                              <Icon name='trash alternate' />
                            </Button>}
                          content={buttons.delete}
                          position='top center'
                          size='tiny' />
                      </div>
                    </TableCell>}
                  </Table.Row>
            )) : null}
            </Table.Body>
          </Table>
        </div>
        {isModalOpen &&
        <ModalComponent
          isOpen={isModalOpen}
          modalType={'delete'}
          modalTitle={modalPattern.deleteProject.title}
          modalText={modalPattern.deleteProject.description}
          deleteElement={this.deleteProject}
          closeModal={this.closeDeleteModal}
          saveModal={this.saveModal} />}
        {isNewProjectOpen &&
        <NewProjectComponent
          isNewProjectOpen={isNewProjectOpen}
          closeNewProject={this.closeNewProject}
          saveModal={this.saveModal}
          modalType={this.state.newProjectModalMode}
          editProject={this.state.editProject}
          onSaveEditedProject={this.updateProjectNameAndDescription} />}
      </div>
    )
  }
}

export default PickProjectComponent
