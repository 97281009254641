import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Popup } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import { nameRegex } from '../../../../store/addons'
import ClearSelect from '../../../Utilities/ClearSelect'
import testIds from '../../../../../public/testIds.json'

export default class GroupParallelMutexGroupWizard extends Component {
  constructor (props) {
    super()
    this.state = {
      basicName: (props.modalItemToAdd && props.modalItemToAdd.otx_name) ? props.modalItemToAdd.otx_name : '',
      basicSpecification: (props.modalItemToAdd && props.modalItemToAdd.otx_specification)
      ? props.modalItemToAdd.otx_specification : '',
      validFor: (props.modalItemToAdd.type === 'GroupElement' &&
      props.modalItemToAdd.validFor !== undefined) ? props.modalItemToAdd.validFor : '',
      itemToAdd: {},
      basicNameError: false
    }
  }

  static propTypes = {
    modalItemToAdd: PropTypes.object,
    saveModal: PropTypes.func,
    closeModal: PropTypes.func,
    item: PropTypes.object,
    onTabChange: PropTypes.func,
    getValidities: PropTypes.func,
    validities: PropTypes.array,
    isFirstTabActive: PropTypes.bool,
    params: PropTypes.object
  }

  componentDidMount () {
    const { params } = this.props
    this.props.getValidities(params.projectId, params.otxFileId)
  }

  handleChangeInput = (stateName, e) => {
    const change = { ...this.state }
    change[stateName] = e.target.value
    this.setState(change)
    this.setState(change, () => this.validateBlur(stateName))
  }

  handleChangeDropDown = (stateName, value) => {
    const change = {}
    change[stateName] = value
    this.setState(change)
  }

  validateBlur = (stateName, validationName) => {
    const error = `${stateName}Error`
    const change = { ...this.state }
    change[error] = stateName === 'basicName' || stateName === 'technicalName' ? !nameRegex.test(this.state[stateName])
      : this.state[stateName] === '' || this.state[stateName] === null
    this.setState(change)
    if (validationName === 'formValidation') return change[error]
  }

  isFormValid = itemType => {
    if (itemType === 'Lane') {
        return true
    } else if (itemType === 'Group' ||
              itemType === 'Parallel' ||
              itemType === 'MutexGroup') {
        return !this.validateBlur('basicName', 'formValidation')
    } else {
        return !this.validateBlur('technicalName', 'formValidation')
    }
  }

  saveModal = e => {
    e.preventDefault()
    const { saveModal, modalItemToAdd } = this.props
    if (this.isFormValid(modalItemToAdd.type) &&
    (modalItemToAdd.children[0] ? this.isFormValid(modalItemToAdd.children[0].type) : true)) {
      const {
        basicName,
        basicSpecification,
        validFor
      } = this.state
        modalItemToAdd.otx_name = basicName
        modalItemToAdd.otx_specification = basicSpecification
      if (modalItemToAdd.type === 'GroupElement') {
        modalItemToAdd.validFor = validFor
      }
      if (modalItemToAdd.children[0] && modalItemToAdd.children[0].type === 'GroupElement') {
        modalItemToAdd.children[0].validFor = validFor
      }
      saveModal(modalItemToAdd)
    }
  }

  renderButtons = () => {
    const { buttons } = labels
    if (this.props.modalItemToAdd.isEdit || !this.props.modalItemToAdd.children.length) {
      return (
        <div>
          <Button
            id={testIds.wizardInputs.finishButton}
            onClick={e => this.saveModal(e)}
            content={buttons.finish}
            positive />
        </div>
      )
    } else {
      return (
        <div>
          {this.props.isFirstTabActive
          ? <Button
              id={testIds.wizardInputs.nextButton}
              disabled={this.state.basicNameError}
              onClick={() => !this.validateBlur('basicName', 'formValidation') && this.props.onTabChange(false, true)}
              content={buttons.next} />
          : <Button
              id={testIds.wizardInputs.previousButton}
              onClick={() => this.props.onTabChange(true, false)}
              content={buttons.previous} />}
          {(!this.props.isFirstTabActive || !this.props.modalItemToAdd.children.length) &&
          <Button
            id={testIds.wizardInputs.finishButton}
            onClick={e => this.saveModal(e)}
            content={buttons.finish}
            positive />}
        </div>)
    }
  }

render () {
  const {
    basicName,
    basicSpecification,
    validFor,
    basicNameError
  } = this.state
  const {
    closeModal,
    item,
    validities
  } = this.props
  const { inputs } = labels.modalPattern
  const { buttons, modalPattern, hints } = labels
    if (item.type === 'Group' ||
        item.type === 'Parallel' ||
        item.type === 'MutexGroup') {
     return (
       <React.Fragment>
         <div className='wizard__form-container'>
           <Form.Group className='wizard__form'>
             <Form.Group>
               <div className='wizard__form-info'>
                 <Popup
                  position='top center'
                  content={hints.diagramModal.basicName}
                  trigger={<Icon name='info' />} />
                 <Form.Input
                  id={testIds.wizardInputs.nameInput}
                  error={basicNameError}
                  onBlur={() => this.validateBlur('basicName')}
                  label={inputs.name.label}
                  placeholder={inputs.name.placeholder}
                  value={basicName}
                  type='text'
                  onChange={event => this.handleChangeInput('basicName', event)}
                  className='wizard__form-input' />
               </div>
             </Form.Group>
             <Form.Group>
               <div className='wizard__form-info'>
                 <Icon name='info' className='info-hidden' />
                 <Form.TextArea
                  id={testIds.wizardInputs.descriptionTextArea}
                  label={inputs.specification.label}
                  onChange={event => this.handleChangeInput('basicSpecification', event)}
                  placeholder={inputs.specification.placeholder}
                  value={basicSpecification}
                  className='wizard__form-input' />
               </div>
             </Form.Group>
           </Form.Group>
         </div>
         <div className='wizard__footer'>
           <div>
             <Button
                id={testIds.wizardInputs.cancelButton}
                onClick={closeModal}
                content={buttons.cancel} />
           </div>
           {this.renderButtons()}
         </div>
       </React.Fragment>
     )
    } else if (item.type === 'GroupElement') {
      return (
        <React.Fragment>
          <div className='wizard__form-container'>
            <Form.Group className='wizard__form'>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Icon name='info' className='info-hidden' />
                  <Form.Select
                    id={testIds.wizardInputs.validForDropDown}
                    onBlur={() => this.validateBlur('validFor', 'formValidation')}
                    error={this.state.validForError}
                    fluid
                    className='wizard__form-input'
                    label={inputs.validFor.label}
                    options={validities}
                    onChange={(e, { value }) => this.handleChangeDropDown('validFor', value)}
                    placeholder={inputs.validFor.placeholder}
                    value={validFor} />
                  {validFor !== '' && <ClearSelect handleClick={() => this.handleChangeDropDown('validFor', '')} />}
                </div>
              </Form.Group>
            </Form.Group>
          </div>
          <div className='wizard__footer'>
            <div>
              <Button
                id={testIds.wizardInputs.cancelButton}
                onClick={closeModal}
                content={buttons.cancel} />
            </div>
            {this.renderButtons()}
          </div>
        </React.Fragment>
    )
  } else if (item.type === 'Lane') {
      return (
        <React.Fragment>
          <p className='modalcomponent__body-warning'>
            {modalPattern.parallel.modalWarning}
          </p>
          <div className='wizard__footer'>
            <div>
              <Button
                id={testIds.wizardInputs.cancelButton}
                onClick={closeModal}
                content={buttons.cancel} />
            </div>
            {this.renderButtons()}
          </div>
        </React.Fragment>
    )
  }
}
}
