import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ElementsListComponent.scss'
import { elementsList, onlyWrapperElementsList } from '../../../../constants/treeLists'
import SingleElementListComponent from '../SingleElementListComponent'
import { Tree } from 'antd'
import { Popup } from 'semantic-ui-react'
import { colorsAndIconsSetter } from '../../../../constants/common'
import { getPopupHint, shouldRenderDndPopup } from '../../../../constants/popupHint'

const { TreeNode } = Tree

class ElementsListComponent extends Component {
  constructor () {
    super()
    this.state = {
      newTreeData: [],
      expandedKeys: [],
      autoExpandParent: true
    }
  }

  static propTypes = {
    addToEmptyElement: PropTypes.func,
    isTesterRole: PropTypes.bool
  }

  componentDidMount () {
    this.generateData()
  }

  generateData = () => {
    const newTreeData = [...elementsList]
    const expandedKeys = []
    const loop = (data, preKey = '0', prePath = '') => {
      data.forEach((item, index, arr) => {
        const key = `${preKey}-${index}`
        key.length === 3 && expandedKeys.push(key)
        let path = `${prePath}`
          path += `[${index}]${item.children && '.children'}`
          item.key = key
          item.id = key
         if (item.children) {
          return loop(item.children, key, path)
        }
      })
    }
    loop(newTreeData)
    this.setState({
      newTreeData,
      expandedKeys,
      startExpandedKeys: expandedKeys
    })
  }

renderTree = data => data.map((item, i) => {
  const colorAndIcon = colorsAndIconsSetter(item)
  if (item.children) {
    if (onlyWrapperElementsList.includes(item.type)) {
      const shouldPopupRender = shouldRenderDndPopup(item.type)
      let popupHint = ''
      if (shouldPopupRender) {
        popupHint = getPopupHint(item.type)
      }
      const title =
        <div
          id={`singleElementListComponent_${item.type}`}
          className='action-realization-modifier'
          style={{
            backgroundColor: item.type === 'End'
              ? colorAndIcon.headerColor : colorAndIcon.textOnDark,
            color: item.type === 'End'
              ? colorAndIcon.textOnDark : colorAndIcon.headerColor
          }}>
          {item.type === 'End' && <span className={colorAndIcon.icon} />}
          <div className='action-realization-ml'>{item.name}</div>
        </div>
      return <TreeNode
          key={item.key}
          title={shouldPopupRender ? <Popup
            position='left center'
            content={popupHint}
            trigger={title} />
            : <div>
              { title }
            </div>
          }>
        {this.renderTree(item.children)}
      </TreeNode>
    }
    return (
      <TreeNode
        key={item.key}
        title={ <SingleElementListComponent isCollored={true} item={item} colorAndIcon={colorAndIcon} /> }>
        {this.renderTree(item.children)}
      </TreeNode>
    )
  }
  return <TreeNode
            key={item.key}
            title={ <SingleElementListComponent isCollored={false} item={item} colorAndIcon={colorAndIcon} /> } />
})

onExpand = (expandedKeys, e) => {
  this.setState({
    expandedKeys,
    autoExpandParent: false
  })
}

  render () {
    const { autoExpandParent, expandedKeys, newTreeData } = this.state
    const { isTesterRole } = this.props
    return (
      <div className='elements-list-container'>
        {!isTesterRole && <div className='add-scroll max-size'>
          <Tree
            onExpand={this.onExpand}
            autoExpandParent={autoExpandParent}
            expandedKeys={expandedKeys}
          >
            {this.renderTree(newTreeData)}
          </Tree>
        </div>}
      </div>
    )
  }
}
export default ElementsListComponent
