import React from 'react'
import PropTypes from 'prop-types'
import * as labels from '../../../../../public/labels.json'

const SutStatusComponent = props => (
  <div>
    {typeof props.params.projectId !== 'undefined' &&
      typeof props.params.otxFileId !== 'undefined' &&
      typeof props.params.procedureId !== 'undefined' &&
      props.driver !== '' &&
      props.ssmId !== -1
    ? <div>{props.sutStatusItem && props.sutStatusItem !== ''
      ? props.sutStatusItem : props.isProcedureRunning ? 'Procedure is running...' : 'Run procedure'}
    </div>
    : <div>
      {typeof props.params.projectId === 'undefined' && <div>{labels.error_list.not_defined.project_id}</div>}
      {typeof props.params.otxFileId === 'undefined' && <div>{labels.error_list.not_defined.otx_file_id}</div>}
      {typeof props.params.procedureId === 'undefined' && <div>{labels.error_list.not_defined.procedure_id}</div>}
      {props.ssmId === -1 && <div>{labels.error_list.not_defined.ssm_id}</div>}
      {props.driver === '' && <div>{labels.error_list.not_defined.driver}</div>}
      {labels.error_list.run_procedure}
    </div>}
  </div>
)

export default SutStatusComponent

SutStatusComponent.propTypes = {
  params: PropTypes.object,
  ssmId: PropTypes.number,
  sutStatusItem: PropTypes.string,
  driver: PropTypes.string,
  isProcedureRunning: PropTypes.bool
}
