import { connect } from 'react-redux'
import VariableWatch from './VariableWatch'

const mapDispatchToProps = {}

const mapStateToProps = state => ({
  ssmId: state.selectElement.ssmId,
  driver: state.driver.driver,
  isProcedureRunning: state.procedure.isProcedureRunning,
  variableWatchItem: state.driver.variableWatchItem
})

export default connect(mapStateToProps, mapDispatchToProps)(VariableWatch)
