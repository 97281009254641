import { SET_UNIVERSAL_MODAL_OBJECT } from '../actionTypeList'
import labels from '../../../../public/labels.json'
import isEmpty from 'lodash/isEmpty'

export const setUniversalModalObjectAction = data => {
  return {
    type: SET_UNIVERSAL_MODAL_OBJECT,
    data
  }
}

export const setUniversalModalObject = type => dispatch => {
  const tempObj = labels.universalModal[type] || {}
  if (!isEmpty(tempObj)) {
    dispatch(setUniversalModalObjectAction(tempObj))
  }
}

export const clearUniversalModalObject = () => dispatch => {
  dispatch(setUniversalModalObjectAction({}))
}

export const actions = { setUniversalModalObject, clearUniversalModalObject }

const ACTION_HANDLERS = {
  [SET_UNIVERSAL_MODAL_OBJECT]: (state, action) => {
    return {
      ...state,
      universalModalObject: action.data
    }
  }
}

const initialState = {
  universalModalObject: {}
}

export default function universalModalReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
