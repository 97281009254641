import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Checkbox, Accordion, Popup, Label } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import { actionStructureCreator } from '../../../../constants/itemStructureCreator'
import {
  isListModifier,
  isMapModifier,
  isBitFieldModifier,
  isLogElement
} from '../../../../constants/summaryLineCreator'
import { shouldGetVariableList } from '../../../../constants/common'
import { nameRegex } from '../../../../store/addons'
import { isValidPositiveInteger } from '../../../../utilites/validation/numbers'
import { isValidString } from '../../../../utilites/validation/strings'
import { checkIsValidListHandler } from '../../../../utilites/validation/validationHandlers'
import VariableEditor from '../../../VariableEditor/VariableEditor'
import ClearSelect from '../../../Utilities/ClearSelect'
import ListModifiers from './components/ListModifiers/ListModifiers'
import MapModifiers from './components/MapModifiers/MapModifiers'
import BitFieldModifiers from '../BitFieldModifiers/BitFieldModifiers'
import TermEditorComponent from '../../../TermEditor/TermEditorComponent'
import { isEqual, cloneDeep, isEmpty } from 'lodash'
import testIds from '../../../../../public/testIds.json'
import LogElements from './components/LogElements/LogElements'
import { severityLevelOptions } from '../../../../constants/optionsLists'
import VirtualizedDropdownComponent from '../../../VirtualizedDropdownComponent/VirtualizedDropdownComponent'
import {
  returnInitTermHandler,
  termEditorHandler,
  termCreatorHandler
} from '../../../../utilites/terms/terms'

export default class ActionWizard extends Component {
  constructor (props) {
    super()
    this.state = {
      assignedValue: null,
      variableList: [],
      inParameterList: [],
      inOutParameterList: [],
      outParameterList: [],
      inParameterListValid: true,
      outParameterListValid: true,
      inOutParameterListValid: true,
      basicName: (props.modalItemToAdd && props.modalItemToAdd.otx_name) ? props.modalItemToAdd.otx_name : '',
      basicSpecification: (props.modalItemToAdd && props.modalItemToAdd.otx_specification)
      ? props.modalItemToAdd.otx_specification : '',
      validFor: (props.modalItemToAdd && props.modalItemToAdd.validity) ? props.modalItemToAdd.validity : '',
      modifiedVar: props.modalItemToAdd.content !== undefined && props.modalItemToAdd.content[0]
      ? props.modalItemToAdd.content[0].name : '',
      calledProcedure: '',
      throwAmbiguousCallException: false,
      itemToAdd: {},
      activeIndex: 0,
      modifiedList: isListModifier(props.modalItemToAdd.type) && props.modalItemToAdd.list !== undefined
      ? props.modalItemToAdd.list.name : '',
      modifiedMap: isMapModifier(props.modalItemToAdd.type) && props.modalItemToAdd.map !== undefined
      ? props.modalItemToAdd.map.name : '',
      modifiedByteField: isBitFieldModifier(props.modalItemToAdd.type) &&
      props.modalItemToAdd.byteField !== undefined
      ? props.modalItemToAdd.modifiedByteField : '',
      printToLog: (props.modalItemToAdd.type === 'AddPackageListener' ||
      props.modalItemToAdd.type === 'AddEventListener')
      ? props.modalItemToAdd.printToLog || false : false,
      mappedSsmReportingData: (props.modalItemToAdd.type === 'AddEventListener' && props.modalItemToAdd.mapping)
      ? props.modalItemToAdd.mapping.ssmName : '',
      mappedOtxVar: (props.modalItemToAdd.type === 'AddEventListener' && props.modalItemToAdd.mapping)
      ? props.modalItemToAdd.mapping.variableName : '',
      result: ((props.modalItemToAdd.type === 'Connect' ||
      props.modalItemToAdd.type === 'ChoiceDialog' ||
      props.modalItemToAdd.type === 'InputDialog' ||
      props.modalItemToAdd.type === 'ConfirmDialog' ||
      props.modalItemToAdd.type === 'AddPackageListener' ||
      props.modalItemToAdd.type === 'ServerCommand') && props.modalItemToAdd.result)
      ? props.modalItemToAdd.result.name : '',
      resultBool: (props.modalItemToAdd.type === 'Disconnect' && props.modalItemToAdd.result)
      ? props.modalItemToAdd.result.name : null,
      ssmActivityName: ((props.modalItemToAdd.type ===
      'TerminateActivity' || props.modalItemToAdd.type === 'GetActivityState') &&
      props.modalItemToAdd.otx_id !== undefined)
      ? props.modalItemToAdd.name : '',
      ssmActivityStateVar: (props.modalItemToAdd.type === 'GetActivityState' && props.modalItemToAdd.activityState)
      ? props.modalItemToAdd.activityState.name : '',
      basicNameError: false,
      modifiedVarError: false,
      calledProcedureError: false,
      modifiedListError: false,
      modifiedMapError: false,
      modifiedByteFieldError: false,
      mappedSsmReportingDataError: false,
      resultError: false,
      ssmActivityNameError: false,
      parameters: {},
      ssmIdError: false,
      projectIdError: false,
      ssmActivityStateVarError: false,
      isSaveClicked: false,
      editedOtxVarIndex: null,
      isReportingDataFormValid: false,
      severityLevel: props.modalItemToAdd.severityLevelTerm && props.modalItemToAdd.severityLevelTerm.value
          ? props.modalItemToAdd.severityLevelTerm.value
          : severityLevelOptions[2].value,
      ssmReportingDataList: [
        {
          reportingDataValue: '',
          otxVariableValue: '',
          otxVariableList: []
        }
      ],
      editorTermArray: [],
      editorTermArrayOptinal: [],
      actionPatternParameter: returnInitTermHandler('String'),
      messageTerm: returnInitTermHandler('String'),
      messageOptionalTerm: returnInitTermHandler('String', true),
      restrictionTerm: returnInitTermHandler('String', true),
      titleTerm: returnInitTermHandler('String', true),
      optionsTerm: returnInitTermHandler('List'),
      defaultTerm: returnInitTermHandler('Integer', true),
      initialValueTerm: returnInitTermHandler('String', true),
      instructionTerm: returnInitTermHandler('String', true),
      indexTerm: returnInitTermHandler('Integer'),
      countTerm: returnInitTermHandler('Integer'),
      timeoutTerm: returnInitTermHandler('Integer'),
      commandTerm: returnInitTermHandler('String', true),
      messageType: props.modalItemToAdd.type === 'ConfirmDialog' && props.modalItemToAdd.messageType
      ? props.modalItemToAdd.messageType.value : '',
      isDangerousActivity: false,
      exitCode: props.modalItemToAdd.exitCode && props.modalItemToAdd.exitCode.name
        ? props.modalItemToAdd.exitCode.name : ''
    }
  }

  static propTypes = {
    modalItemToAdd: PropTypes.object,
    saveModal: PropTypes.func,
    closeModal: PropTypes.func,
    item: PropTypes.object,
    onTabChange: PropTypes.func,
    isFirstTabActive: PropTypes.bool,
    // Reducer
    getVariableList: PropTypes.func,
    variableList: PropTypes.array,
    optionsVariableList: PropTypes.array,
    getProceduresParametersList: PropTypes.func,
    proceduresWithParametersList: PropTypes.array,
    ssmElementList: PropTypes.array,
    openAddVariable: PropTypes.func,
    ssmId: PropTypes.number,
    getValidities: PropTypes.func,
    validities: PropTypes.array,
    setSsmElementListByType: PropTypes.func,
    readDiagramElement: PropTypes.func,
    diagramElement: PropTypes.object,
    readDiagramElementAction: PropTypes.func,
    newVariable: PropTypes.any,
    setAddedVariable: PropTypes.func,
    isOpenAddVar: PropTypes.bool,
    params: PropTypes.obejct,
    loaderControl: PropTypes.func,
    resetSsmElementListByType: PropTypes.func
  }

  componentDidMount () {
    const { item, params } = this.props
    this.props.getVariableList(params.projectId, params.otxFileId, params.procedureId)
    this.createTermEditorData()
    this.props.getValidities(params.projectId, params.otxFileId)
    // *** ADD_DATA_LISTENER ***
    if (item.type === 'AddDataListener' && item.mappings) {
      this.setState({
        printToLog: item.printToLog,
        ssmReportingDataList: this.createSsmReportingDataList()
      })
    }

    if (item.type === 'InvokeActivity' || item.type === 'InvokeActivityAndWait' ||
    item.type === 'TerminateActivity' ||
    item.type === 'AddDataListener' ||
    item.type === 'AddEventListener' ||
    item.type === 'GetActivityState' ||
    (item.children.length && (item.children[0].type === 'InvokeActivity' ||
    item.children[0].type === 'InvokeActivityAndWait' ||
    item.children[0].type === 'TerminateActivity' ||
    item.children[0].type === 'AddEventListener' ||
    item.children[0].type === 'GetActivityState' ||
    item.children[0].type === 'AddDataListener'))) this.errorHandler()

    if (shouldGetVariableList(item)) {
      if (item.arguments &&
        (item.type === 'InvokeActivity' ||
        item.type === 'InvokeActivityAndWait')) {
        this.props.readDiagramElement(params.projectId, params.otxFileId, params.procedureId,
          item.otx_id)
        const ssmActivityNameCopy = item.name ? item.name.replace(`${item.type.toUpperCase()}: `, '') : ''
        this.setState({
          ssmActivityStateVar: item.resultState && item.resultState.name
          ? item.resultState.name : '',
          ssmActivityName: ssmActivityNameCopy
        })
      }
    } else if (item.type === 'ProcedureCall' ||
               (item.type === 'Action' &&
                item.children[0] &&
                item.children[0].type === 'ProcedureCall')) {
        item && this.setState({
        throwAmbiguousCallException: item.isExceptionThrown || false,
        calledProcedure: item.calledProcedureName || ''
      })
      this.props.getProceduresParametersList(params.projectId, params.otxFileId)
      .then(() => item &&
        item.isEdit &&
        !isEmpty(this.props.proceduresWithParametersList) &&
        this.updateParametersLists(item.calledProcedureName))
    }
  }

  componentDidUpdate (nextProps, prevState) {
    const { newVariable, variableList, item } = this.props
    if (
      variableList.length > 0 &&
      variableList.length !== nextProps.variableList.length &&
      newVariable !== null &&
      newVariable.dataType.type === 'SsmReportingData' &&
      this.state.editedOtxVarIndex !== null
      ) {
        const ssmReportingDataList = cloneDeep(this.state.ssmReportingDataList)
        ssmReportingDataList[this.state.editedOtxVarIndex].otxVariableList = this.props.variableList.filter(element => {
          if (element.obj.dataType !== null) return element.obj.dataType.type === 'SsmReportingData'
        })
        ssmReportingDataList[this.state.editedOtxVarIndex].otxVariableValue = newVariable.otx_name
        this.setState({
          ssmReportingDataList,
          editedOtxVarIndex: null
        })
      }
      if (this.props.item.type === 'AddDataListener' &&
          this.props.item.mappings &&
          !isEqual(variableList, nextProps.variableList)) {
          this.setState({
            ssmReportingDataList: this.createSsmReportingDataList()
          })
      }
    if ((item.type === 'InvokeActivity' || item.type === 'InvokeActivityAndWait') &&
        !isEmpty(this.props.ssmElementList) &&
        this.props.ssmElementList !== nextProps.ssmElementList &&
        this.state.ssmActivityName !== '') {
        const searchingElement = this.props.ssmElementList
        .filter(element => element.value === this.state.ssmActivityName)[0]
        const change = {}
        change.isDangerousActivity = searchingElement.activity.dangerous
        change.editorTermArrayOptinal = searchingElement
          .activity.activityArgs.map(element => {
            const elementType = this.ssmActivityTypeSetter(element.actArgDataType)
            const initTermObj = { ...returnInitTermHandler(elementType, true), name: element.name }
            const searchingItem = item.arguments.find(el => el.ssmName === element.name)
            const term = searchingItem &&
              searchingItem.term &&
              searchingItem.term !== null &&
              searchingItem.term.type !== 'NULL_OBJECT'
              ? searchingItem.term
              : null
            if (term !== null) {
              const returningObj = {
                ...termCreatorHandler(term),
                name: element.name
              }
              return returningObj
            } else {
              return initTermObj
            }
          })
          this.setState(change)
    }
    if (!isEqual(nextProps.newVariable, newVariable)) {
      const { modalItemToAdd } = this.props
      const itemType = modalItemToAdd.type === 'Action' && !isEmpty(modalItemToAdd.children)
      ? modalItemToAdd.children[0].type : modalItemToAdd.type
      this.asignNewVariableHandler(itemType, newVariable)
    }
  }

  componentWillUnmount () {
    if (!isEmpty(this.props.ssmElementList)) {
      this.props.resetSsmElementListByType()
    }
  }

  asignNewVariableHandler (elType, newVariable) {
    if (newVariable.dataType && newVariable.dataType.type) {
      const { type } = newVariable.dataType
      const change = {}
      let stateName = ''
      switch (elType) {
        case 'AddPackageListener':
          if (type === 'Map') {
            stateName = 'result'
            change[stateName] = newVariable.otx_name
            change[`${stateName}Error`] = false
          }
        break
        case 'GetActivityState':
          if (type === 'ActivityState') {
            stateName = 'ssmActivityStateVar'
            change[stateName] = newVariable.otx_name
            change[`${stateName}Error`] = false
          }
        break
        case 'AddEventListener':
          if (type === 'SsmEventData') {
            stateName = 'mappedOtxVar'
            change[stateName] = newVariable.otx_name
            change[`${stateName}Error`] = false
          }
        break
        case 'Connect':
          if (type === 'Connection') {
            stateName = 'result'
            change[stateName] = newVariable.otx_name
          }
        break
        case 'Disconnect':
          if (type === 'Boolean') {
            stateName = 'resultBool'
            change[stateName] = newVariable.otx_name
          }
        break
        case 'Assignment':
          if (!type.includes('Exception') ||
              type === 'UserException') {
            stateName = 'modifiedVar'
            change[stateName] = newVariable.otx_name
            change[`${stateName}Error`] = false
            change.assignedValue = {
              children: [],
              name: newVariable.otx_name,
              type: `${type}Variable`
            }
            const termType = type.includes('Exception') ? 'Exception' : type
            const isValid =
              newVariable.dataType && newVariable.dataType.value !== undefined && newVariable.dataType.value !== null
            const newTerm = isValid
              ? termEditorHandler(
                returnInitTermHandler(termType),
                'actionPatternParameter',
                newVariable.dataType.value,
                'value')
              : returnInitTermHandler(termType)
            change.actionPatternParameter = newTerm
          }
        break
      }
      if (!isEmpty(change)) {
        this.setState(change)
      }
    }
  }

  createSsmReportingDataList = () => this.props.item.mappings.map(element => ({
      reportingDataValue: element.ssmName,
      otxVariableValue: element.variableName,
      otxVariableList: this.props.variableList.filter(element =>
        element.obj.dataType !== null && element.obj.dataType.type === 'SsmReportingData')
    })
  )

  updateParametersLists (calledProcedure) {
    const { item } = this.props
    const change = {}
    change.inParameterList = []
    change.inOutParameterList = []
    change.outParameterList = []
    this.props.proceduresWithParametersList
      .filter(element => element.value === calledProcedure)[0]
      .parameters.forEach(parameter => {
        if (parameter.type === 'InParameter') {
          const type = parameter.dataType.type
          const inParam = item.inArguments &&
            item.inArguments.find(el => el.param === parameter.otx_name)
          let newTerm = {}
          if (inParam && inParam.term) {
            newTerm = termCreatorHandler(inParam.term, type)
          } else {
            newTerm = returnInitTermHandler(type)
          }
          newTerm.name = parameter.otx_name
          change.inParameterList.push(newTerm)
        } else if (parameter.type === 'InOutParameter') {
          const inOutParam = item.inOutArguments &&
          item.inOutArguments.find(el => el.param === parameter.otx_name)
          change.inOutParameterList.push({
            name: parameter.otx_name,
            type: parameter.dataType.type,
            valueOfVariable: inOutParam && inOutParam.variable && inOutParam.variable.name
            ? inOutParam.variable.name : '',
            isValid: isValidString(inOutParam && inOutParam.variable && inOutParam.variable.name
              ? inOutParam.variable.name : '')
          })
        } else if (parameter.type === 'OutParameter') {
          const outParam = item.outArguments &&
          item.outArguments.find(el => el.param === parameter.otx_name)
          change.outParameterList.push({
            name: parameter.otx_name,
            type: parameter.dataType.type,
            valueOfVariable: outParam && outParam.variable && outParam.variable.name
            ? outParam.variable.name : '',
            isValid: isValidString(outParam && outParam.variable && outParam.variable.name
              ? outParam.variable.name : '')
          })
        }
      })
      change.inParameterListValid = this.validateInParameters(change.inParameterList)
      change.outParameterListValid = this.validateInOutParameters(change.outParameterList)
      change.inOutParameterListValid = this.validateInOutParameters(change.inOutParameterList)
      this.setState(change)
  }

  createTermEditorData () {
    const { item } = this.props
    const change = {}
    switch (item.type) {
      case 'Assignment':
        if (item.content && item.content[0] && item.content[1]) {
          const type = item.content[0].type.replace('Variable', '')
          change.assignedValue = item.content[0]
          change.actionPatternParameter = termCreatorHandler(item.content[1], type)
        }
      break
      case 'WriteLog':
        if (item.message && item.message.type) {
          change.messageTerm = termCreatorHandler(item.message)
        }
      break
      case 'ChoiceDialog':
      case 'ConfirmDialog':
      case 'InputDialog':
        if (item.message && item.message.type) {
          change.messageOptionalTerm = termCreatorHandler(item.message)
        }

        if (item.title && item.title.type) {
          change.titleTerm = termCreatorHandler(item.title)
        }

        if (item.type === 'ChoiceDialog') {
          if (item.options && item.options.type) {
            change.optionsTerm = termCreatorHandler(item.options)
          }

          if (item.default && item.default.type) {
            change.defaultTerm = termCreatorHandler(item.default)
          }
        }

        if (item.type === 'InputDialog') {
          if (item.initialValue && item.initialValue.type) {
            change.initialValueTerm = termCreatorHandler(item.initialValue)
          }

          if (item.instruction && item.instruction.type) {
            change.instructionTerm = termCreatorHandler(item.instruction)
          }

          if (item.restriction && item.restriction.type) {
            change.restrictionTerm = termCreatorHandler(item.restriction)
          }
        }
      break
      case 'ListRemove':
      case 'ListInsert':
        if (item.index && item.index.type) {
          change.indexTerm = termCreatorHandler(item.index)
        }
        if (item.count && item.count.type) {
          change.countTerm = termCreatorHandler(item.count)
        }
      break
      case 'Wait':
        if (item.timeout && item.timeout.type) {
          change.timeoutTerm = termCreatorHandler(item.timeout)
        }
      break
      case 'ServerCommand':
        if (item.command && item.command.type) {
          change.commandTerm = termCreatorHandler(item.command)
        }
      break
    }
    !isEmpty(change) && this.setState(change)
  }

  errorHandler = () => {
    const { params, item } = this.props
    if (typeof params.projectId !== 'undefined' && this.props.ssmId !== -1) {
      const itemType = item.children.length ? item.children[0].type : item.type
      this.props.loaderControl(true)
      this.props.setSsmElementListByType(params.projectId, this.props.ssmId, this.handleElementListType(itemType))
    } else {
      this.setState({
        projectIdError: typeof params.projectId === 'undefined',
        ssmIdError: this.props.ssmId === -1
      })
    }
  }

  handleElementListType = itemType => {
    if (itemType === 'AddDataListener') {
      return 'reporting data'
    } else if (itemType === 'AddEventListener') {
      return 'event'
    } else {
      return 'activity'
    }
  }

  handleChangeInput = (stateName, event) => {
    this.setState({
      [stateName]: event.target.value
    }, () => {
      if (stateName === 'timeout' || stateName === 'basicName' || stateName === 'technicalName' ||
        stateName === 'startingPosition' || stateName === 'ssmActivityStateVar') {
        this.validateBlur(stateName)
      }
    })
  }

  validateBlur = (stateName, validationName) => {
    const error = stateName === 'basicName' || stateName === 'technicalName'
    ? !nameRegex.test(this.state[stateName])
    : stateName === 'timeout'
    ? !isValidPositiveInteger(this.state[stateName])
    : !this.state[stateName]
    this.setState({ [`${stateName}Error`]: error })
    if (validationName === 'formValidation') return error
  }

  ssmActivityTypeSetter = type => {
    switch (type) {
      case 'UnsignedInteger':
      case 'SignedInteger':
        return 'Integer'
      case 'Real':
        return 'Float'
      default:
        return type
    }
  }

  handleChangeDropDown = (stateName, value) => {
    const change = {}
    if (stateName === 'ssmActivityName') {
      const searchingElement = this.props.ssmElementList
      .filter(element => element.value === value)[0]

      change.ssmActivityStateVar = ''
      change.isDangerousActivity = searchingElement.activity.dangerous
      change.editorTermArrayOptinal = searchingElement
      .activity.activityArgs.map(element => {
        const elementType = this.ssmActivityTypeSetter(element.actArgDataType)
        const initTermObj = { ...returnInitTermHandler(elementType, true), name: element.name }
        return initTermObj
      })
    } else if (stateName === 'calledProcedure') {
      this.updateParametersLists(value)
    } else if (stateName === 'modifiedVar') {
      const element = this.props.optionsVariableList.filter(element => element.value === value)[0].object.obj
      const type = element.dataType && element.dataType.type ? element.dataType.type : 'String'
      const termType = type.includes('Exception') ? 'Exception' : type
      const isValid = element.dataType && element.dataType.value !== undefined && element.dataType.value !== null
      const newTerm = isValid
        ? termEditorHandler(
          returnInitTermHandler(termType),
          'actionPatternParameter',
          element.dataType.value,
          'value')
        : returnInitTermHandler(termType)

      change.actionPatternParameter = newTerm
      change.assignedValue = {
        children: [],
        name: element.otx_name,
        type: element.dataType.type + 'Variable'
      }
      change[`${stateName}Error`] = false
    }
    change[stateName] = value
    this.setState(change)
  }

  toggleCheckbox = stateName => {
    this.setState({
      [stateName]: !this.state[stateName]
    })
  }

  handleClick = (event, props) => {
    this.setState({
      activeIndex: this.state.activeIndex === props.index ? -1 : props.index
    })
  }

  isFormValid = itemType => {
    if (itemType === 'Connect' ||
        itemType === 'Disconnect') {
      return true
    } else if (itemType === 'ServerCommand') {
      const { commandTerm } = this.state
      if (commandTerm.term.valueOf === '') {
        const tempCommandTerm = {
          ...commandTerm,
          isValid: false
        }
        this.setState({ commandTerm: tempCommandTerm })
        return false
      } else {
        return true
      }
    } else if (itemType === 'Action') {
      return !this.validateBlur('basicName', 'formValidation')
    } else if (itemType === 'Assignment') {
        return !this.validateBlur('modifiedVar', 'formValidation') &&
                this.state.actionPatternParameter.isValid
    } else if (itemType === 'ProcedureCall') {
        return (!this.validateBlur('calledProcedure', 'formValidation') &&
                this.state.inParameterListValid &&
                this.state.outParameterListValid &&
                this.state.inOutParameterListValid)
    } else if (isListModifier(itemType)) {
        let isValid = false
        switch (itemType) {
          case 'ListAppend':
            isValid = checkIsValidListHandler(this.state.editorTermArray)
          break
          case 'ListRemove':
            isValid = this.state.indexTerm.isValid && this.state.countTerm.isValid
          break
          case 'ListInsert':
            isValid = this.state.indexTerm.isValid && checkIsValidListHandler(this.state.editorTermArray)
          break
          case 'ListConcatenate':
            isValid = checkIsValidListHandler(this.state.editorTermArray)
          break
          case 'ListClear':
            isValid = true
          break
        }
        return !this.validateBlur('modifiedList', 'formValidation') && isValid
    } else if (isMapModifier(itemType)) {
        let isValid = false
        switch (itemType) {
          case 'MapUnion':
          case 'MapPut':
          case 'MapRemove':
            isValid = checkIsValidListHandler(this.state.editorTermArray)
            break
          default:
            isValid = true
            break
        }
        return !this.validateBlur('modifiedMap', 'formValidation') && isValid
    } else if (isBitFieldModifier(itemType)) {
        return (!this.validateBlur('modifiedByteField', 'formValidation'))
    } else if (itemType === 'AddDataListener') {
        return this.isReportingDataListValid(this.state.ssmReportingDataList)
    } else if (itemType === 'AddPackageListener') {
        return !this.validateBlur('result', 'formValidation')
    } else if (itemType === 'GetActivityState') {
        return !this.validateBlur('ssmActivityName', 'formValidation') &&
               !this.validateBlur('ssmActivityStateVar', 'formValidation')
    } else if (itemType === 'TerminateActivity') {
        return !this.validateBlur('ssmActivityName', 'formValidation')
    } else if (itemType === 'InvokeActivity' ||
              itemType === 'InvokeActivityAndWait') {
        return !this.validateBlur('ssmActivityName', 'formValidation')
        ? this.checkSmmArguments()
        : (this.state.ssmActivityName === 'stop' || this.state.ssmActivityName === 'reset')
    } else if (itemType === 'Wait') {
        return this.state.timeoutTerm.isValid
    } else if (itemType === 'AddEventListener') {
        return (!this.validateBlur('mappedSsmReportingData', 'formValidation') &&
                !this.validateBlur('mappedOtxVar', 'formValidation'))
    } else if (itemType === 'WriteLog') {
      return this.state.messageTerm.isValid
    } else if (itemType === 'ChoiceDialog') {
      return !this.validateBlur('result', 'formValidation') &&
      this.state.optionsTerm.isValid &&
      this.state.defaultTerm.isValid &&
      this.state.titleTerm.isValid &&
      this.state.messageOptionalTerm.isValid
    } else if (itemType === 'InputDialog') {
      return !this.validateBlur('result', 'formValidation') &&
      this.state.messageOptionalTerm.isValid
    } else if (itemType === 'ConfirmDialog') {
      return this.state.titleTerm.isValid &&
      this.state.messageOptionalTerm.isValid
    } else {
      return false
    }
  }

  checkSmmArguments = () => {
    const { editorTermArrayOptinal } = this.state
    return isEmpty(editorTermArrayOptinal.filter(el => !el.isValid))
  }

  validateInParameters = parameters => {
    let areParamsValid = true
    if (parameters.length) {
      parameters.map(param => {
        areParamsValid = areParamsValid && param.isValid
      })
    }
    return areParamsValid
  }

  validateInOutParameters = parameters => {
    let areParamsValid = true
    if (parameters.length) {
      parameters.map(param => {
        const isParamValid = isValidString(param.valueOfVariable)
        areParamsValid = areParamsValid && isParamValid
      })
    }
    return areParamsValid
  }

  addReportingData = () => {
    const ssmReportingDataList = [...this.state.ssmReportingDataList]
    ssmReportingDataList.push({
      reportingDataValue: '',
      otxVariableValue: '',
      otxVariableList: []
    })
    const isReportingDataFormValid = this.isReportingDataListValid(ssmReportingDataList)
    this.setState({
      ssmReportingDataList,
      isReportingDataFormValid
    })
  }

  removeReportingData = (index = 0) => {
    const ssmReportingDataList = [...this.state.ssmReportingDataList]
    ssmReportingDataList.splice(index, 1)
    const isReportingDataFormValid = this.isReportingDataListValid(ssmReportingDataList)
    this.setState({
      ssmReportingDataList,
      isReportingDataFormValid
    })
  }

  saveModal = () => {
    this.setState({ isSaveClicked: true })
    const { modalItemToAdd, saveModal } = this.props
    if (this.isFormValid(modalItemToAdd.type) &&
     (modalItemToAdd.children[0] ? this.isFormValid(modalItemToAdd.children[0].type) : true)) {
      const modifiedModalItemToAdd = actionStructureCreator(cloneDeep(modalItemToAdd), this.state)
      saveModal(modifiedModalItemToAdd)
    }
  }

  handleChangeDropDownReportingData = (stateName, value, index) => {
    const change = cloneDeep(this.state.ssmReportingDataList)
    if (stateName === 'reportingDataValue') {
      change[index].otxVariableValue = ''
      change[index].otxVariableList =
    this.props.variableList.filter(element => {
      if (element.obj.dataType !== null) return element.obj.dataType.type === 'SsmReportingData'
    })
    }
    change[index][stateName] = value
    const isReportingDataFormValid = this.isReportingDataListValid(change)
    this.setState({
      ssmReportingDataList: change,
      isReportingDataFormValid
    })
  }

  isReportingDataListValid = list => {
    let isValid = true
    list.map(el => (isValid = isValid && !!el.reportingDataValue && !!el.otxVariableValue))
    return isValid
  }

  handleAddVariable = index => {
    this.props.openAddVariable(true)
    this.setState({
      editedOtxVarIndex: index
    })
  }

  renderReportingData = () => {
    const { hints } = labels
    const reportingDataList = this.state.ssmReportingDataList.map((element, index, array) => {
      return (
        <Form.Group className='wizard__form-col4' key={index} data-test='mappingToBeSetRow'>
          <div className='wizard__form-info'>
            <VirtualizedDropdownComponent
              value={element.reportingDataValue}
              propsOptions={this.props.ssmElementList}
              handleChange={option =>
                this.handleChangeDropDownReportingData('reportingDataValue', option.value || '', index)}
              index={index}
              label={labels.modalPattern.inputs.mappedSsmReportingData.label}
              propsPlaceholder={labels.modalPattern.inputs.mappedSsmReportingData.placeholder}
              error={!element.reportingDataValue && this.state.isSaveClicked}
              id={`${testIds.actionWizard.mappedSsmReportingDataDropDown}_${index}`}
            />
          </div>
          <div className='wizard__form-info'>
            <Popup
              position='top center'
              content={hints.diagramModal.addDataListener.mappedOtxVariable}
              trigger={<Icon name='info' />} />
            <Form.Select
              fluid
              id={`${testIds.actionWizard.mappedOtxVariableDropDown}_${index}`}
              error={!element.otxVariableValue && this.state.isSaveClicked}
              label={labels.modalPattern.inputs.mappedOtxVariable.label}
              options={element.otxVariableList}
              onChange={(e, { value }) => this.handleChangeDropDownReportingData('otxVariableValue', value, index)}
              placeholder={labels.modalPattern.inputs.mappedOtxVariable.placeholder}
              value={element.otxVariableValue} />
          </div>
          <Button
            id={`${testIds.actionWizard.addVariableButton}_${index}`}
            content={labels.buttons.addVariable}
            onClick={() => this.handleAddVariable(index)} />
          <Button
            id={`${testIds.actionWizard.removeButton}_${index}`}
            disabled={array.length === 1}
            content={labels.buttons.remove}
            negative onClick={() => this.removeReportingData(index)} />
        </Form.Group>
      )
    })
    return reportingDataList
  }

  listElementChangeHandler = (propertyName = '', value, index = -1, parameterName = '') => {
    if (this.props.item.type === 'Assignment') {
      this.setState({
        assignedValue: {
          ...this.state.assignedValue,
          [propertyName]: value
        }
      })
    } else {
      const change = [...this.state[parameterName]]
      const isValid = isValidString(value.valueOfVariable)
      change[index] = {
        ...change[index],
        [propertyName]: value
      }
      change[index].isValid = isValid
      const isValidParameterList = this.validateInOutParameters(change)
      this.setState({
        [parameterName]: change,
        [parameterName + 'Valid']: isValidParameterList
      })
    }
  }

  setOptionsList = optionType =>
  this.props.optionsVariableList.filter(option => option.type === optionType)

  renderAcordion = () => {
    if (this.state.inParameterList.length ||
        this.state.inOutParameterList.length ||
        this.state.outParameterList.length) {
        let inParameterError = false
        let outParameterError = false
        let inOutParameterError = false
        if (this.state.isSaveClicked) {
          inParameterError = !this.state.inParameterListValid
          outParameterError = !this.state.outParameterListValid
          inOutParameterError = !this.state.inOutParameterListValid
        }
      return (
        <Accordion style={{ marginBottom: 20 }}>
          {this.state.inParameterList.length !== 0 &&
            <Accordion.Title
              style={inParameterError ? { color: '#9F3A38' } : null}
              active={this.state.activeIndex === 0}
              index={0}
              onClick={this.handleClick}>
              <Icon name='dropdown' />
              {labels.modalPattern.collapseFields.inParameters}
            </Accordion.Title>
          }
          {this.state.inParameterList.length !== 0 && this.state.inParameterList.map((element, index) => {
            return (
              <Accordion.Content active={this.state.activeIndex === 0} key={element.otx_id}>
                <TermEditorComponent
                  changeHandler={this.termEditorFieldHandler}
                  element={element}
                  stateName='inParameterList'
                  item={this.props.item}
                  index={index}
                  isSaveClicked={this.state.isSaveClicked}
                  variableList={this.props.variableList.filter(variable =>
                    variable.obj.dataType
                    ? variable.obj.dataType.type === element.type : false)}
                />
              </Accordion.Content>)
          })}
          {this.state.inOutParameterList.length !== 0 &&
            <Accordion.Title
              style={inOutParameterError ? { color: '#9F3A38' } : null}
              active={this.state.activeIndex === 1}
              index={1}
              onClick={this.handleClick}>
              <Icon name='dropdown' />
              {labels.modalPattern.collapseFields.inOutParameters}
            </Accordion.Title>
          }
          {this.state.inOutParameterList.length !== 0 && this.state.inOutParameterList.map((element, index) => {
            return (
              <Accordion.Content active={this.state.activeIndex === 1} key={element.otx_id}>
                <VariableEditor
                  changeHandler={(propertyName = '', value) =>
                    this.listElementChangeHandler(propertyName, value, index, 'inOutParameterList')}
                  element={element}
                  index={index}
                  hintText=''
                  isSaveClicked={this.state.isSaveClicked}
                  variableList={this.props.variableList.filter(item => (item.obj.dataType &&
                    (item.obj.dataType.type === element.type)))}
                />
              </Accordion.Content>)
          })}
          {this.state.outParameterList.length !== 0 &&
            <Accordion.Title
              style={outParameterError ? { color: '#9F3A38' } : null}
              active={this.state.activeIndex === 2}
              index={2}
              onClick={this.handleClick}>
              <Icon name='dropdown' />
              {labels.modalPattern.collapseFields.outParameters}
            </Accordion.Title>
          }
          {this.state.outParameterList.length !== 0 && this.state.outParameterList.map((element, index) => {
            return (
              <Accordion.Content active={this.state.activeIndex === 2} key={element.otx_id}>
                <VariableEditor
                  changeHandler={(propertyName = '', value) =>
                    this.listElementChangeHandler(propertyName, value, index, 'outParameterList')}
                  element={element}
                  index={index}
                  hintText={labels.hints.diagramModal.procedureCall.outParameter}
                  isSaveClicked={this.state.isSaveClicked}
                  variableList={this.props.variableList.filter(item => (item.obj.dataType &&
                    (item.obj.dataType.type === element.type)))}
                />
              </Accordion.Content>)
          })}
        </Accordion>
      )
    }
  }

  renderButtons = () => {
    const { buttons } = labels
    if (this.props.modalItemToAdd.isEdit || !this.props.modalItemToAdd.children.length) {
      return (
        <div>
          <Button
            id={testIds.actionWizard.finishButton}
            onClick={this.saveModal}
            content={buttons.finish}
            positive />
        </div>
      )
    } else {
      return (
        <div>
          {this.props.isFirstTabActive
          ? <Button
              id={testIds.actionWizard.nextButton}
              disabled={this.state.basicNameError}
              positive={!this.state.basicNameError && nameRegex.test(this.state.basicName)}
              onClick={() => !this.validateBlur('basicName', 'formValidation') && this.props.onTabChange(false, true)}
              content={buttons.next} />
          : <Button
              id={testIds.actionWizard.previousButton}
              onClick={() => this.props.onTabChange(true, false)}
              content={buttons.previous} />}
          {(!this.props.isFirstTabActive || !this.props.modalItemToAdd.children.length) &&
          <Button
            onClick={this.saveModal}
            id={testIds.actionWizard.finishButton}
            content={buttons.finish}
            positive />}
        </div>)
    }
  }

  termEditorFieldHandler = (stateName, value, propertyName, index) => {
    if (Array.isArray(this.state[stateName]) && typeof index === 'number') {
      const newTerm = termEditorHandler(this.state[stateName][index], stateName, value, propertyName)
      const newListOfTerms = [...this.state[stateName]]
      newListOfTerms[index] = newTerm
      if (stateName === 'inParameterList') {
        const areParamsValid = this.validateInParameters(newListOfTerms)
        this.setState({
          [stateName]: newListOfTerms,
          [stateName + 'Valid']: areParamsValid
        })
      } else {
        this.setState({
          [stateName]: newListOfTerms
        })
      }
    } else {
      const newTerm = termEditorHandler(this.state[stateName], stateName, value, propertyName)
      this.setState({ [stateName]: newTerm })
    }
  }

  render () {
    const {
      basicName,
      basicSpecification,
      validFor,
      modifiedVar,
      calledProcedure,
      throwAmbiguousCallException,
      modifiedList,
      modifiedListError,
      modifiedByteField,
      printToLog,
      mappedSsmReportingData,
      mappedOtxVar,
      result,
      ssmActivityName,
      ssmActivityStateVar,
      basicNameError,
      modifiedVarError,
      calledProcedureError,
      modifiedMap,
      modifiedMapError,
      modifiedByteFieldError,
      mappedSsmReportingDataError,
      mappedOtxVarError,
      resultError,
      ssmActivityNameError,
      resultBool,
      isDangerousActivity,
      exitCode
    } = this.state
    const {
      closeModal,
      item,
      optionsVariableList,
      params,
      validities
    } = this.props
    const { inputs, checkboxes } = labels.modalPattern
    const { buttons, hints } = labels
     return (
       <React.Fragment>
         <div className='wizard__form-container'>
           <Form.Group className='wizard__form'>
             {item.type === 'Action' && <React.Fragment>
               <Form.Group>
                 <div className='wizard__form-info'>
                   <Popup
                    position='top center'
                    content={hints.diagramModal.basicName}
                    trigger={<Icon name='info' />} />
                   <Form.Input
                      id={testIds.actionWizard.nameInput}
                      error={basicNameError}
                      onBlur={() => this.validateBlur('basicName')}
                      label={inputs.name.label}
                      placeholder={inputs.name.placeholder}
                      value={basicName}
                      type='text'
                      onChange={event => this.handleChangeInput('basicName', event)}
                      className='wizard__form-input' />
                 </div>
               </Form.Group>
               <Form.Group>
                 <div className='wizard__form-info'>
                   <Popup
                      trigger={<Icon name='info' className='info-hidden' />}
                      position='bottom right'
                      content='' />
                   <Form.TextArea
                      label={inputs.specification.label}
                      id={testIds.actionWizard.descriptionTextArea}
                      onChange={event => this.handleChangeInput('basicSpecification', event)}
                      placeholder={inputs.specification.placeholder}
                      value={basicSpecification}
                      className='wizard__form-input' />
                 </div>
               </Form.Group>
             </React.Fragment>}
             {item.type === 'Assignment' && <React.Fragment>
               <Form.Group>
                 <div className='wizard__form-info'>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                    fluid
                    label={inputs.validFor.label}
                    id={testIds.actionWizard.validForDropDown}
                    options={validities}
                    onChange={(e, { value }) => this.handleChangeDropDown('validFor', value)}
                    placeholder={inputs.validFor.placeholder}
                    value={validFor} />
                   {validFor !== '' && <ClearSelect handleClick={() => this.handleChangeDropDown('validFor', '')} />}
                 </div>
               </Form.Group>
               <Form.Group className='wizard__form-col2'>
                 <div className='wizard__form-info'>
                   <Popup
                     position='top center'
                     content={hints.diagramModal.assignment.modifiedVariable}
                     trigger={<Icon name='info' className='info' />} />
                   <Form.Select
                    onBlur={() => this.validateBlur('modifiedVar')}
                    error={modifiedVarError}
                    fluid
                    id={testIds.actionWizard.modifiedVariableDropDown}
                    label={inputs.modifiedVariable.label}
                    options={optionsVariableList.filter(el =>
                      el.object &&
                      el.object.obj &&
                      el.object.obj.dataType &&
                      (!el.object.obj.dataType.type.includes('Exception') ||
                      el.object.obj.dataType.type === 'UserException'))}
                    onChange={(e, { value }) => this.handleChangeDropDown('modifiedVar', value)}
                    placeholder={inputs.modifiedVariable.placeholder}
                    value={modifiedVar} />
                 </div>
                 <Button
                  id={testIds.actionWizard.addVariableButton}
                  content={buttons.addVariable}
                  onClick={() => this.props.openAddVariable(true)} />
               </Form.Group>
               {this.state.assignedValue &&
               <TermEditorComponent
                 changeHandler={this.termEditorFieldHandler}
                 element={this.state.actionPatternParameter}
                 stateName='actionPatternParameter'
                 item={item}
                 index={null}
                 isSaveClicked={this.state.isSaveClicked}
                 variableList={this.props.variableList.filter(variable =>
                  variable.obj.dataType
                  ? variable.obj.dataType.type ===
                  (this.state.actionPatternParameter.type === 'Exception'
                    ? 'UserException'
                    : this.state.actionPatternParameter.type)
                  : false)} />}
               </React.Fragment>}
             {item.type === 'ProcedureCall' && <React.Fragment>
               <Form.Group>
                 <div className='wizard__form-info'>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                      id={testIds.actionWizard.validForDropDown}
                      fluid
                      label={inputs.validFor.label}
                      options={validities}
                      onChange={(e, { value }) => this.handleChangeDropDown('validFor', value)}
                      placeholder={inputs.validFor.placeholder}
                      value={validFor} />
                   {validFor !== '' && <ClearSelect handleClick={() => this.handleChangeDropDown('validFor', '')} />}
                 </div>
               </Form.Group>
               <Form.Group className='wizard__form-col2'>
                 <div className='wizard__form-info'>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                      id={testIds.actionWizard.calledProcedureDropDown}
                      error={calledProcedureError}
                      onBlur={() => this.validateBlur('calledProcedure')}
                      fluid
                      label={inputs.calledProcedure.label}
                      options={this.props.proceduresWithParametersList}
                      onChange={(e, { value }) => this.handleChangeDropDown('calledProcedure', value)}
                      placeholder={inputs.calledProcedure.placeholder}
                      value={calledProcedure} />
                 </div>
                 <Button
                  id={testIds.actionWizard.addVariableButton}
                  content={buttons.addVariable}
                  onClick={() => this.props.openAddVariable(true)} />
               </Form.Group>
               <Checkbox className='wizard__form-checkbox'
                  id={testIds.actionWizard.throwAmbiguousCallExceptionCheckBox}
                  label={checkboxes.throwAmbiguousCallException}
                  checked={throwAmbiguousCallException}
                  onChange={() => this.toggleCheckbox('throwAmbiguousCallException')} />
               {this.renderAcordion()}
               </React.Fragment>}
             {isListModifier(item.type) && <ListModifiers
                onSelect={this.handleChangeDropDown}
                onInput={this.handleChangeInput}
                onValidate={this.validateBlur}
                item={item}
                validFor={validFor}
                optionsVariableList={this.setOptionsList('List')}
                isSaveClicked={this.state.isSaveClicked}
                validities={validities}
                openAddVariable={this.props.openAddVariable}
                editorTermArrayCreator={editorTermArray => this.setState({ editorTermArray })}
                changeHandler={this.termEditorFieldHandler}
                indexTerm={this.state.indexTerm}
                countTerm={this.state.countTerm}
                modifiedList={modifiedList}
                variableList={this.props.variableList}
                modifiedListError={modifiedListError} />}
             {isMapModifier(item.type) && <MapModifiers
                params={params}
                item={item}
                onSelect={this.handleChangeDropDown}
                onValidate={this.validateBlur}
                isSaveClicked={this.state.isSaveClicked}
                openAddVariable={this.props.openAddVariable}
                validFor={validFor}
                validities={validities}
                optionsVariableList={this.setOptionsList('Map')}
                variableList={this.props.variableList}
                modifiedMap={modifiedMap}
                editorTermArrayCreator={editorTermArray =>
                  this.setState({ editorTermArray })}
                modifiedMapError={modifiedMapError} />}
             {isBitFieldModifier(item.type) && <BitFieldModifiers
                item={item}
                onSelect={this.handleChangeDropDown}
                onValidate={this.validateBlur}
                openAddVariable={this.props.openAddVariable}
                validFor={validFor}
                validities={validities}
                optionsVariableList={this.setOptionsList('ByteField')}
                variableList={this.props.variableList}
                modifiedByteField={modifiedByteField}
                editorTermArrayCreator={editorTermArray => this.setState({ editorTermArray })}
                modifiedByteFieldError={modifiedByteFieldError} />}
             {isLogElement(item.type) && <LogElements
              onSelect={this.handleChangeDropDown}
              validFor={validFor}
              validities={validities}
              severityLevelOptions={severityLevelOptions}
              severityLevel={this.state.severityLevel}
              termEditorFieldHandler={this.termEditorFieldHandler}
              messageTerm={this.state.messageTerm}
              messageOptionalTerm={this.state.messageOptionalTerm}
              restrictionTerm={this.state.restrictionTerm}
              optionsTerm={this.state.optionsTerm}
              titleTerm={this.state.titleTerm}
              defaultTerm={this.state.defaultTerm}
              variableList={this.props.variableList}
              isSaveClicked={this.state.isSaveClicked}
              resultError={this.state.resultError}
              result={this.state.result}
              validateBlur={this.validateBlur}
              initialValueTerm={this.state.initialValueTerm}
              instructionTerm={this.state.instructionTerm}
              openAddVariable={this.props.openAddVariable}
              messageType={this.state.messageType}
              item={item} />}
             {(item.type === 'AddDataListener' ||
              item.type === 'AddPackageListener' ||
              item.type === 'AddEventListener') && <React.Fragment>
                <Form.Group>
                  <div className='wizard__form-info'>
                    <Icon name='info' className='info-hidden' />
                    <Form.Select
                      fluid
                      id={testIds.actionWizard.validForDropDown}
                      label={inputs.validFor.label}
                      options={validities}
                      onChange={(e, { value }) => this.handleChangeDropDown('validFor', value)}
                      placeholder={inputs.validFor.placeholder}
                      value={validFor} />
                    {validFor !== '' && <ClearSelect handleClick={() => this.handleChangeDropDown('validFor', '')} />}
                  </div>
                </Form.Group>
                <Checkbox className='wizard__form-checkbox'
                  id={testIds.actionWizard.printToLogCheckBox}
                  label={checkboxes.printToLog}
                  checked={printToLog}
                  onChange={() => this.toggleCheckbox('printToLog')} />
              </React.Fragment>}
             {item.type === 'AddDataListener' && !this.state.projectIdError && !this.state.ssmIdError &&
             <React.Fragment>
               <div className='wizard__form-divider'>
                 <h3>{inputs.ssmMappingToBeSet.label}</h3>
                 <span></span>
               </div>
               {this.renderReportingData()}
               <div style={{ marginTop: 10 }}>
                 <Button
                  id={testIds.actionWizard.addButton}
                  content={labels.buttons.add}
                  positive
                  onClick={this.addReportingData}/>
               </div>
             </React.Fragment>}
             {item.type === 'AddDataListener' &&
             <React.Fragment>
               <Form.Group className='wizard__form-info'>
                 {this.state.projectIdError && <div>{labels.error_list.not_defined.project_id}</div>}
                 {this.state.ssmIdError && <div>{labels.error_list.not_defined.ssm_id}</div>}
               </Form.Group>
             </React.Fragment>}
             {item.type === 'AddPackageListener' && <React.Fragment>
               <Form.Group className='wizard__form-col2'>
                 <div className='wizard__form-info'>
                   <Popup
                    position='top center'
                    content={hints.diagramModal.addPackageListener.result}
                    trigger={<Icon name='info' className='info' />} />
                   <Form.Select
                      fluid
                      error={resultError}
                      id={testIds.actionWizard.resultDropDown}
                      onBlur={() => this.validateBlur('result')}
                      label={inputs.result.label[1]}
                      options={this.setOptionsList('Map')}
                      onChange={(e, { value }) => this.handleChangeDropDown('result', value)}
                      placeholder={inputs.result.placeholder[0]}
                      value={result} />
                 </div>
                 <Button
                  id={testIds.actionWizard.addVariableButton}
                  content={buttons.addVariable}
                  onClick={() => this.props.openAddVariable(true)} />
               </Form.Group>
             </React.Fragment>}
             {(item.type === 'InvokeActivity' ||
              item.type === 'InvokeActivityAndWait') && !this.state.projectIdError && !this.state.ssmIdError &&
              <React.Fragment>
                <Form.Group>
                  <div className='wizard__form-info'>
                    <Icon name='info' className='info-hidden' />
                    <Form.Select
                      fluid
                      id={testIds.actionWizard.validForDropDown}
                      label={inputs.validFor.label}
                      options={validities}
                      onChange={(e, { value }) => this.handleChangeDropDown('validFor', value)}
                      placeholder={inputs.validFor.placeholder}
                      value={validFor} />
                    {validFor !== '' && <ClearSelect handleClick={() => this.handleChangeDropDown('validFor', '')} />}
                  </div>
                </Form.Group>
                <Form.Group className='wizard__form-info'>
                  <div>
                    <Popup
                      position='top center'
                      content={hints.diagramModal.invokeActivity.ssmActivityName}
                      trigger={<Icon name='info' className='info' />} />
                    <Form.Select
                      fluid
                      search
                      id={testIds.actionWizard.ssmActivityNameDropDown}
                      error={ssmActivityNameError}
                      onBlur={() => this.validateBlur('ssmActivityName')}
                      label={inputs.ssmActivityName.label}
                      options={this.props.ssmElementList}
                      onChange={(e, { value }) => this.handleChangeDropDown('ssmActivityName', value)}
                      placeholder={inputs.ssmActivityName.placeholder}
                      value={ssmActivityName} />
                    {isDangerousActivity && <Label color='red' pointing>{inputs.ssmActivityName.info}</Label>}
                  </div>
                </Form.Group>
                <Form.Group className='wizard__form-col2'>
                  <div className='wizard__form-info'>
                    <Popup
                      position='top center'
                      content={hints.diagramModal.invokeActivity.ssmActivityStateVariable}
                      trigger={<Icon name='info' className='info' />} />
                    <Form.Select
                      fluid
                      id={testIds.actionWizard.ssmActivityStateVariableDropDown}
                      label={inputs.ssmActivityStateVariable.label}
                      options={this.setOptionsList('ActivityState')}
                      onChange={(e, { value }) => this.handleChangeDropDown('ssmActivityStateVar', value)}
                      placeholder={inputs.ssmActivityStateVariable.placeholder}
                      value={ssmActivityStateVar} />
                  </div>
                  <Button
                    id={testIds.actionWizard.addVariableButton}
                    content={buttons.addVariable}
                    onClick={() => this.props.openAddVariable(true)} />
                </Form.Group>
                <div className='wizard__form-divider'>
                  <h3>{inputs.ssmArgumentsToBeSet.label}</h3>
                  <span></span>
                </div>
                {!isEmpty(ssmActivityName) &&
                !isEmpty(this.state.editorTermArrayOptinal) &&
                this.state.editorTermArrayOptinal.map((element, index) => {
                  return (
                    <Accordion.Content key={index}>
                      <TermEditorComponent
                        changeHandler={this.termEditorFieldHandler}
                        element={element}
                        stateName='editorTermArrayOptinal'
                        item={item}
                        index={index}
                        isSaveClicked={this.state.isSaveClicked}
                        variableList={this.props.variableList.filter(variable =>
                          variable.obj.dataType
                          ? variable.obj.dataType.type === element.type : false)} />
                    </Accordion.Content>)
                })}
              </React.Fragment>}
             {(item.type === 'TerminateActivity' ||
             item.type === 'GetActivityState') && !this.state.projectIdError && !this.state.ssmIdError &&
             <React.Fragment>
               <Form.Group>
                 <div className='wizard__form-info'>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                      id={testIds.actionWizard.validForDropDown}
                      fluid
                      label={inputs.validFor.label}
                      options={validities}
                      onChange={(e, { value }) => this.handleChangeDropDown('validFor', value)}
                      placeholder={inputs.validFor.placeholder}
                      value={validFor} />
                   {validFor !== '' && <ClearSelect handleClick={() => this.handleChangeDropDown('validFor', '')} />}
                 </div>
               </Form.Group>
               <Form.Group className='wizard__form-info'>
                 <div>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                      fluid
                      search
                      id={testIds.actionWizard.ssmActivityNameDropDown}
                      error={ssmActivityNameError}
                      onBlur={() => this.validateBlur('ssmActivityName')}
                      label={inputs.ssmActivityName.label}
                      options={this.props.ssmElementList}
                      onChange={(e, { value }) => this.handleChangeDropDown('ssmActivityName', value)}
                      placeholder={inputs.ssmActivityName.placeholder}
                      value={ssmActivityName}
                      />
                 </div>
               </Form.Group>
             </React.Fragment>}
             {item.type === 'GetActivityState' &&
             !this.state.projectIdError && !this.state.ssmIdError && <React.Fragment>
               <Form.Group className='wizard__form-col2'>
                 <div className='wizard__form-info'>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                      fluid
                      id={testIds.actionWizard.ssmActivityStateVariableDropDown}
                      label={inputs.ssmActivityStateVariable.labelRequired}
                      options={this.setOptionsList('ActivityState')}
                      onChange={(e, { value }) => this.handleChangeDropDown('ssmActivityStateVar', value)}
                      onBlur={() => this.validateBlur('ssmActivityStateVar')}
                      placeholder={inputs.ssmActivityStateVariable.placeholder}
                      value={ssmActivityStateVar}
                      error={this.state.ssmActivityStateVarError} />
                 </div>
                 <Button
                  id={testIds.actionWizard.addVariableButton}
                  content={buttons.addVariable}
                  onClick={() => this.props.openAddVariable(true)} />
               </Form.Group>
             </React.Fragment>}
             {item.type === 'Connect' && <React.Fragment>
               <Form.Group>
                 <div className='wizard__form-info'>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                    fluid
                    id={testIds.actionWizard.validForDropDown}
                    label={inputs.validFor.label}
                    options={validities}
                    onChange={(e, { value }) => this.handleChangeDropDown('validFor', value)}
                    placeholder={inputs.validFor.placeholder}
                    value={validFor} />
                   {validFor !== '' && <ClearSelect handleClick={() => this.handleChangeDropDown('validFor', '')} />}
                 </div>
               </Form.Group>
               <Form.Group className='wizard__form-col2'>
                 <div className='wizard__form-info'>
                   <Popup
                    position='top center'
                    content={hints.diagramModal.connect.result}
                    trigger={<Icon name='info' className='info' />} />
                   <Form.Select
                      fluid
                      id={testIds.actionWizard.resultDropDown}
                      label={inputs.result.label[0]}
                      options={this.setOptionsList('Connection')}
                      onChange={(e, { value }) => this.handleChangeDropDown('result', value)}
                      placeholder={inputs.result.placeholder[1]}
                      value={result} />
                 </div>
                 <Button
                  id={testIds.actionWizard.addVariableButton}
                  content={buttons.addVariable}
                  onClick={() => this.props.openAddVariable(true)} />
               </Form.Group>
             </React.Fragment>}
             {item.type === 'Disconnect' && <React.Fragment>
               <Form.Group>
                 <div className='wizard__form-info'>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                      fluid
                      id={testIds.actionWizard.validForDropDown}
                      label={inputs.validFor.label}
                      options={validities}
                      onChange={(e, { value }) => this.handleChangeDropDown('validFor', value)}
                      placeholder={inputs.validFor.placeholder}
                      value={validFor} />
                   {validFor !== '' && <ClearSelect handleClick={() => this.handleChangeDropDown('validFor', '')} />}
                 </div>
               </Form.Group>
               <Form.Group className='wizard__form-col2'>
                 <div className='wizard__form-info'>
                   <Popup
                      position='top center'
                      content={hints.diagramModal.disconnect.result}
                      trigger={<Icon name='info' className='info' />} />
                   <Form.Select
                      fluid
                      id={testIds.actionWizard.resultDropDown}
                      label={inputs.result.label[0]}
                      options={this.setOptionsList('Boolean')}
                      onChange={(e, { value }) => this.handleChangeDropDown('resultBool', value)}
                      placeholder={inputs.result.placeholder[2]}
                      value={resultBool} />
                 </div>
                 <Button
                  content={buttons.addVariable}
                  onClick={() => this.props.openAddVariable(true)} />
               </Form.Group>
             </React.Fragment>}
             {(item.type === 'InvokeActivity' ||
              item.type === 'InvokeActivityAndWait' ||
              item.type === 'TerminateActivity' ||
              item.type === 'GetActivityState') &&
              <React.Fragment>
                <Form.Group className='wizard__form-info'>
                  {this.state.projectIdError && <div>{labels.error_list.not_defined.project_id}</div>}
                  {this.state.ssmIdError && <div>{labels.error_list.not_defined.ssm_id}</div>}
                </Form.Group>
              </React.Fragment>}
             {item.type === 'Wait' && <React.Fragment>
               <Form.Group>
                 <div className='wizard__form-info'>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                      fluid
                      id={testIds.actionWizard.validForDropDown}
                      label={inputs.validFor.label}
                      options={validities}
                      onChange={(e, { value }) => this.handleChangeDropDown('validFor', value)}
                      placeholder={inputs.validFor.placeholder}
                      value={validFor} />
                   {validFor !== '' && <ClearSelect handleClick={() => this.handleChangeDropDown('validFor', '')} />}
                 </div>
               </Form.Group>
               <TermEditorComponent
                changeHandler={this.termEditorFieldHandler}
                isSaveClicked={this.state.isSaveClicked}
                element={this.state.timeoutTerm}
                stateName='timeoutTerm'
                item={item}
                index={null}
                labelsProp={inputs.timeout}
                variableList={this.props.variableList.filter(variable =>
                  variable.obj.dataType
                  ? variable.obj.dataType.type === this.state.timeoutTerm.type
                  : false)} />
             </React.Fragment>}
             {item.type === 'AddEventListener' && <React.Fragment>
               <Form.Group className='wizard__form-info'>
                 <div>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                      id={testIds.actionWizard.mappedSsmEventDataDropDown}
                      onBlur={() => this.validateBlur('mappedSsmReportingData')}
                      error={mappedSsmReportingDataError}
                      fluid
                      label={inputs.mappedSsmEventData.label}
                      options={this.props.ssmElementList}
                      onChange={(e, { value }) => this.handleChangeDropDown('mappedSsmReportingData', value)}
                      placeholder={inputs.mappedSsmEventData.placeholder}
                      value={mappedSsmReportingData} />
                 </div>
               </Form.Group>
               <Form.Group className='wizard__form-col2'>
                 <div className='wizard__form-info'>
                   <Icon name='info' className='info-hidden' />
                   <Form.Select
                      id={testIds.actionWizard.mappedOtxVariableDropDown}
                      onBlur={() => this.validateBlur('mappedOtxVar')}
                      error={mappedOtxVarError}
                      fluid
                      label={inputs.mappedOtxVariable.label}
                      options={this.setOptionsList('SsmEventData')}
                      onChange={(e, { value }) => this.handleChangeDropDown('mappedOtxVar', value)}
                      placeholder={inputs.mappedOtxVariable.placeholder}
                      value={mappedOtxVar} />
                 </div>
                 <Button
                  id={testIds.actionWizard.addVariableButton}
                  content={buttons.addVariable}
                  onClick={() => this.props.openAddVariable(true)} />
               </Form.Group>
             </React.Fragment>}
             {item.type === 'ServerCommand' && <React.Fragment>
               <div className='wizard__form-info'>
                 <Icon name='info' className='info-hidden' />
                 <Form.Select
                   fluid
                   label={inputs.validFor.label}
                   id={testIds.actionWizard.validForDropDown}
                   options={validities}
                   onChange={(e, { value }) => this.handleChangeDropDown('validFor', value)}
                   placeholder={inputs.validFor.placeholder}
                   value={validFor} />
                 {validFor !== '' && <ClearSelect handleClick={() => this.handleChangeDropDown('validFor', '')} />}
               </div>
               <div className='wizard__form-info'>
                 <Form.Select
                   fluid
                   id={testIds.actionWizard.resultDropDown}
                   label={inputs.result.label[0]}
                   options={this.setOptionsList('String')}
                   onChange={(e, { value }) => this.handleChangeDropDown('result', value)}
                   placeholder={inputs.result.placeholder[3]}
                   value={result} />
               </div>
               <div className='wizard__form-info'>
                 <Form.Select
                   fluid
                   id={testIds.actionWizard.exitCodeDropDown}
                   label={inputs.exitCode.label}
                   options={this.setOptionsList('Integer')}
                   onChange={(e, { value }) => this.handleChangeDropDown('exitCode', value)}
                   placeholder={inputs.exitCode.placeholder}
                   value={exitCode} />
               </div>
               <div style={{ position: 'relative' }}>
                 <TermEditorComponent
                  changeHandler={this.termEditorFieldHandler}
                  isSaveClicked={this.state.isSaveClicked}
                  element={this.state.commandTerm}
                  stateName='commandTerm'
                  item={item}
                  index={null}
                  fieldType='textArea'
                  labelsProp={inputs.command}
                  variableList={this.props.variableList.filter(variable =>
                    variable.obj.dataType
                    ? variable.obj.dataType.type === this.state.commandTerm.type
                    : false)} />
                 <Button
                  style={{ position: 'absolute', top: 31, right: 0 }}
                  id={testIds.actionWizard.addVariableButton}
                  content={buttons.addVariable}
                  onClick={() => this.props.openAddVariable(true)} />
               </div>
             </React.Fragment>}
           </Form.Group>
         </div>
         <div className='wizard__footer'>
           <div>
             <Button
                id={testIds.actionWizard.cancelButton}
                type='button'
                onClick={closeModal}
                content={buttons.cancel} />
           </div>
           {this.renderButtons()}
         </div>
       </React.Fragment>
      )
  }
}
