import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
// ------------------------------------
// Constants
// ------------------------------------
import axios from 'axios'
import { origin, protocol, getHeaders, errorHandle } from '../../addons'
import { getOutlineTree, getOtxFileModalTimeAction } from './otxFile'
import { openRemoveModalAction, getVariableList, getDiagramList } from './diagramElement'
import { loaderControl } from './loaderControl'
import { SET_ADDED_VARIABLE } from '../actionTypeList'
import { updatePseudoCodeList } from './pseudoCode'
// ------------------------------------
// Actions
// ------------------------------------

export const setAddedVariableAction = (data = null) => {
  return {
    type: SET_ADDED_VARIABLE,
    data
  }
}

export const setAddedVariable = (value = null) => {
  return dispatch => {
    dispatch(setAddedVariableAction(value))
  }
}

export const addProcedure = (projectId = '', otxFileId = -1, data) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/procedures`, data,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const editProcedure = (projectId = '', otxFileId = -1, data) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/procedures/${data.otx_id}`, data,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(getDiagramList(projectId, otxFileId, data.otx_id, null, true, false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const removeProcedure = (projectId = '', otxFileId = -1, procedureId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/procedures/${procedureId}`,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(openRemoveModalAction(false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const addSignature = (projectId = '', otxFileId = -1, data) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/signatures`, data,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const addValidity = (projectId = '', otxFileId = -1, data) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/validities`, data,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const putValidity = (projectId = '', otxFileId = -1, data) => {
  const validityId = data.otx_id
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/validities/${validityId}`, data,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const deleteValidity = (projectId = '', otxFileId = -1, validityId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/validities/${validityId}`,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(openRemoveModalAction(false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const addDeclaration = (projectId = '', otxFileId = -1, data) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/declarations`, data,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const addParameter = (projectId = -1, otxFileId = -1,
  elementId = '', data, shouldHeaderUpdate = false, isSignature = false) => {
  const elementKind = isSignature ? 'signatures' : 'procedures'
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/items/` +
      `${otxFileId}/${elementKind}/${elementId}/parameters`, data, getHeaders())
      .then(() => {
        dispatch(getOutlineTree(projectId, otxFileId))
        shouldHeaderUpdate && !isSignature &&
        dispatch(getDiagramList(projectId, otxFileId, elementId, null, true, false))
        dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
        dispatch(updatePseudoCodeList())
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const addLocalDeclaration = (projectId = -1, otxFileId = -1, procedureId = '', data, addFromEl) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/items/` +
      `${otxFileId}/procedures/${procedureId}/declarations`, data, getHeaders())
      .then(() => {
        dispatch(getOutlineTree(projectId, otxFileId))
        dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
        dispatch(getVariableList(projectId, otxFileId, procedureId))
        addFromEl && dispatch(setAddedVariableAction(data))
        dispatch(updatePseudoCodeList())
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const editDeclaration = (projectId = '', otxFileId = -1, elId, data) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/declarations/${elId}`, data,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const editParameter = (projectId = -1, otxFileId = -1, parentId = '',
  elId, data, shouldHeaderUpdate = false, isSignature = false) => {
  const elementKind = isSignature ? 'signatures' : 'procedures'
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${projectId}/items/` +
      `${otxFileId}/${elementKind}/${parentId}/parameters/${elId}`, data, getHeaders())
      .then(() => {
        dispatch(getOutlineTree(projectId, otxFileId))
        shouldHeaderUpdate && !isSignature &&
        dispatch(getDiagramList(projectId, otxFileId, parentId, null, true, false))
        dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
        dispatch(updatePseudoCodeList())
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const editLocalDeclaration = (projectId = -1, otxFileId = -1, procedureId = '', elId, data) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${projectId}/items/` +
      `${otxFileId}/procedures/${procedureId}/declarations/${elId}`, data, getHeaders())
      .then(() => {
        dispatch(getOutlineTree(projectId, otxFileId))
        dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
        dispatch(updatePseudoCodeList())
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const removeDeclaration = (projectId = '', otxFileId = -1, elId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/declarations/${elId}`,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(openRemoveModalAction(false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const removeParameter = (projectId = '', otxFileId = -1, element,
  shouldHeaderUpdate = false, isSignature = false) => {
  const elementId = element.otx_id
  let parentId = ''
  let elementKind = ''
  if (element.procedureId) {
    elementKind = 'procedures'
    parentId = element.procedureId
 } else {
    elementKind = 'signatures'
    parentId = element.signatureId
 }
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/` +
      `${projectId}/items/${otxFileId}/${elementKind}/${parentId}/parameters/${elementId}`,
      getHeaders())
        .then(() => {
          shouldHeaderUpdate && !isSignature &&
          dispatch(getDiagramList(projectId, otxFileId, parentId, null, true, false))
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(openRemoveModalAction(false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const addThrow = (projectId = -1, otxFileId = -1, elementId = -1, data = {},
  shouldHeaderUpdate = false, isSignature = false) => {
  const elementKind = isSignature ? 'signatures' : 'procedures'
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}` +
        `/items/${otxFileId}/${elementKind}/${elementId}/throws/`,
        data, getHeaders())
        .then(() => {
          shouldHeaderUpdate && !isSignature &&
          dispatch(getDiagramList(projectId, otxFileId, elementId, null, true, false))
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(openRemoveModalAction(false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const editThrow = (projectId = -1, otxFileId = -1, procedureId = -1,
  throwType = '', data = {}, shouldHeaderUpdate = false, isSignature = false) => {
  const elementKind = isSignature ? 'signatures' : 'procedures'
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${projectId}` +
        `/items/${otxFileId}/${elementKind}/${procedureId}/throws/${throwType}`,
        data, getHeaders())
      .then(() => {
        shouldHeaderUpdate && !isSignature &&
        dispatch(getDiagramList(projectId, otxFileId, procedureId, null, true, false))
        dispatch(getOutlineTree(projectId, otxFileId))
        dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
        dispatch(updatePseudoCodeList())
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const deleteThrow = (projectId = -1, otxFileId = -1, element,
  shouldHeaderUpdate = false, isSignature = false) => {
  const elementId = element.type
  let parentId = ''
  let elementKind = ''
  if (element.procedureId) {
    elementKind = 'procedures'
    parentId = element.procedureId
 } else {
    elementKind = 'signatures'
    parentId = element.signatureId
 }
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/${projectId}` +
        `/items/${otxFileId}/${elementKind}/${parentId}/throws/${elementId}`,
        getHeaders())
        .then(() => {
          shouldHeaderUpdate && !isSignature &&
          dispatch(getDiagramList(projectId, otxFileId, parentId, null, true, false))
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(openRemoveModalAction(false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const addImport = (projectId = -1, otxFileId = -1, data = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/imports/`,
        data, getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(openRemoveModalAction(false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const editImport = (projectId = -1, otxFileId = -1, importName = '', data = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/imports/${importName}`,
        data, getHeaders())
      .then(() => {
        dispatch(getOutlineTree(projectId, otxFileId))
        dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
        dispatch(updatePseudoCodeList())
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const deleteImport = (projectId = -1, otxFileId = -1, importName = '') => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/imports/${importName}`,
        getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(openRemoveModalAction(false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const removeSignature = (projectId = '', otxFileId = -1, elId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/signatures/${elId}`,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(openRemoveModalAction(false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const editSignature = (projectId = -1, otxFileId = -1, elId, data) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/signatures/${elId}`,
       data, getHeaders())
      .then(() => {
        dispatch(getOutlineTree(projectId, otxFileId))
        dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
        dispatch(updatePseudoCodeList())
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const removeLocalDeclaration = (projectId = '', otxFileId = -1, procedureId, elId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/` +
      `${projectId}/items/${otxFileId}/procedures/${procedureId}/declarations/${elId}`,
      getHeaders())
        .then(() => {
          dispatch(getOutlineTree(projectId, otxFileId))
          dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
          dispatch(openRemoveModalAction(false))
          dispatch(updatePseudoCodeList())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const actions = {
  addProcedure,
  addSignature,
  addValidity,
  putValidity,
  deleteValidity,
  addDeclaration,
  addLocalDeclaration,
  editDeclaration,
  removeDeclaration,
  removeParameter,
  addParameter,
  editParameter,
  editLocalDeclaration,
  removeLocalDeclaration,
  removeProcedure,
  editProcedure,
  addImport,
  editImport,
  deleteImport
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_ADDED_VARIABLE]: (state, action) => {
    return {
      ...state,
      newVariable: action.data
    }
  }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  newVariable: null
}

export default function outlineTreeReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
