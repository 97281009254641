import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import { func } from 'prop-types'

export const ToggleButtonsComponent = ({ changePseudocodeTypeHandler }) => {
  const [pseudocodeType, setPseudocodeType] = useState(localStorage.getItem('pseudocodeType') || 'JAVA')
  const onClickHandler = value => {
    if (pseudocodeType !== value) {
      localStorage.setItem('pseudocodeType', value)
      setPseudocodeType(value)
      changePseudocodeTypeHandler()
    }
  }
  const { buttons } = labels.pseudocode
  return (
    <Button.Group className='pseudocode__container-buttons'>
      {Object.entries(buttons).map(([key, value]) =>
        <Button
          compact
          id={`toggleButtonsComponent_${key}`}
          style={{ fontSize: '0.7rem' }}
          key={key}
          onClick={() => onClickHandler(value)}
          positive={pseudocodeType === value}
          content={value} />
        )}
    </Button.Group>
  )
}

ToggleButtonsComponent.propTypes = {
  changePseudocodeTypeHandler: func
}

export default ToggleButtonsComponent
