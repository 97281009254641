import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './VirtualizedTreeComponent.scss'
import Tree, { renderers, FilteringContainer } from 'react-virtualized-tree'
import { search, findNested } from '../../../../store/addons'
import {
  isGlobalDeclaration,
  isLocalDeclaration,
  showContextMenu,
  inheritsFromFlow,
  isParameter,
  isThrows,
  iconSetter
} from '../../../../constants/common'
import { MemoizedContextMenu } from '../../../ContextMenuComponent/ContextMenu'
import { contextMenu } from 'react-contexify'
import { browserHistory } from 'react-router'
import { isEqual, isEmpty } from 'lodash'
import { returnInitValueHandler } from '../../../../constants/initValues'

const { Expandable } = renderers

const SELECT = 3

class ExtendedFilteringContainer extends FilteringContainer {
  constructor (props) {
    super(props)
    this.setFilterTerm = props.debouncer(this.setFilterTerm, 300)
  }

  setFilterTerm () {
    this.state.filterText.length >= 3
    ? this.setState(ps => ({ filterTerm: ps.filterText })) : this.setState(({ filterTerm: '' }))
  }

  handleFilterTextChange = e => {
    const filterText = e.target.value
    this.setState({ filterText })
    this.setFilterTerm()
  }
}

class VirtualizedTreeComponent extends Component {
  constructor () {
    super()
    this.state = {
      nodes: [],
      selectedItemId: null,
      treeData: null,
      nodeObject: {}
    }
  }

  static propTypes = {
    // props from parent
    treeData: PropTypes.array,
    position: PropTypes.string,
    getPackageName: PropTypes.func,
    menuContextActionHandler: PropTypes.func,
    params: PropTypes.object,
    selectedTabId: PropTypes.any,
    setSelectedIdTopTree: PropTypes.func,
    activeTopTabIndex: PropTypes.number,
    isTesterRole: PropTypes.bool,
    // props from redux
    getAttributes: PropTypes.func,
    getXmlPreview: PropTypes.func,
    headerData: PropTypes.object,
    loaderControl: PropTypes.func,
    getSsmList: PropTypes.func,
    resetSsmList: PropTypes.func,
    getOutlineTreeAction: PropTypes.func,
    clearDiagramData: PropTypes.func,
    getXmlPreviewAction: PropTypes.func,
    reachedBreakPoint: PropTypes.string,
    getReportInfo: PropTypes.func,
    activeConsoleIndex: PropTypes.number,
    setActiveConsoleIndex: PropTypes.func,
    updateScroll: PropTypes.func,
    setReportAttributes: PropTypes.func,
    updateTreeData: PropTypes.func,
    setSelectedContextViewId: PropTypes.func,
    selectedContextViewId: PropTypes.any,
    copyElementHandler: PropTypes.func
  }

  componentDidMount () {
    const { params, position } = this.props

    position === 'top' &&
    this.setState({ selectedItemId: this.props.selectedTabId })

    position === 'bottom' &&
    this.setState({
      selectedItemId: typeof params.procedureId !== 'undefined'
      ? typeof params.selectedElementId !== 'undefined' ? params.selectedElementId : params.procedureId
      : null
    })
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (!isEqual(prevState.treeData, nextProps.treeData)) {
      const treeData = search(prevState.nodes, nextProps.treeData)
      return {
        treeData: nextProps.treeData,
        nodes: treeData
      }
    }
    return null
  }

  componentDidUpdate (prevProps, prevState) {
    const { params, position } = this.props

    if (this.props.position === 'top' && this.props.selectedTabId !== prevProps.selectedTabId) {
      this.setState({ selectedItemId: this.props.selectedTabId })
    }

    if (position === 'bottom') {
      const { clearDiagramData, setSelectedContextViewId, selectedContextViewId } = this.props

      if (!isEqual(params, prevProps.params)) {
        if (typeof params.procedureId !== 'undefined' && typeof params.selectedElementId === 'undefined') {
          this.setState({ selectedItemId: params.procedureId })
        } else if (typeof params.procedureId !== 'undefined' && typeof params.selectedElementId !== 'undefined') {
          this.setState({ selectedItemId: params.selectedElementId })
        } else if (this.state.selectedItemId !== null) {
          this.setState({ selectedItemId: null }, clearDiagramData())
        }
      }

      if (!isEqual(selectedContextViewId, prevProps.selectedContextViewId) &&
          !isEqual(selectedContextViewId, this.state.selectedItemId)) {
        const selectedItemId = selectedContextViewId || params.selectedElementId || params.procedureId || null
        this.setState({ selectedItemId })
        if (!isEmpty(selectedItemId) && !isEmpty(this.state.nodes)) {
          this.setEditorConsoleDataHandler(this.state.nodes, selectedItemId)
        }
      }

      if (!isEqual(this.state.selectedItemId, prevState.selectedItemId) &&
          !isEqual(this.state.selectedItemId, selectedContextViewId)) {
        setSelectedContextViewId(this.state.selectedItemId)
        if (!isEmpty(this.state.nodes)) {
          this.setEditorConsoleDataHandler(this.state.nodes, this.state.selectedItemId)
        }
      }

      if (!isEmpty(this.state.nodes) && !isEqual(prevState.nodes, this.state.nodes)) {
        this.setEditorConsoleDataHandler(this.state.nodes, this.state.selectedItemId)
      }
    }
  }

  setEditorConsoleDataHandler = (nodes, selectedItemId) => {
    const { getAttributes, getXmlPreview, params, loaderControl } = this.props
    const foundObj = findNested(nodes, selectedItemId) || {}
    getAttributes(foundObj)
    if (!isEmpty(foundObj)) {
      if (!foundObj.type.includes('Exception')) {
        loaderControl(true)
        getXmlPreview(
          params.projectId,
          params.otxFileId,
          `elements/${foundObj.type !== 'Import' ? foundObj.id : foundObj.document}`)
      } else {
        this.props.getXmlPreviewAction(
          foundObj.type !== 'Exception'
          ? `<exception xsi:type="ssm:${foundObj.type}" />`
          : '<exception />')
      }
    }
  }

  handleClick (item, isOtxFile, isProcedure) {
    const {
      params,
      loaderControl,
      setActiveConsoleIndex,
      copyElementHandler,
      activeConsoleIndex
    } = this.props
    if (item.isProcedureElement) {
      this.props.updateScroll()
      if (!isEqual(this.state.selectedItemId, params.selectedElementId) &&
          isEqual(item.otx_id, params.selectedElementId)) {
        this.setState({ selectedItemId: item.otx_id })
      } else {
        let url = `/project/${params.projectId}/otx/${params.otxFileId}/procedure/${item.procedureId}`
        if (typeof params.selectedElementId === 'undefined' || !isEqual(params.selectedElementId, item.otx_id)) {
          item.type && inheritsFromFlow(item.type)
          ? url += `/element/${item.otx_id}/selectedElement/${item.otx_id}`
          : item.lastParentFlowElement !== null
            ? url += `/element/${item.lastParentFlowElement}/selectedElement/${item.otx_id}`
            : url += `/selectedElement/${item.otx_id}`
        } else if (item.lastParentFlowElement !== null) {
          url += `/element/${item.lastParentFlowElement}/selectedElement/${item.lastParentFlowElement}`
        }
        copyElementHandler(returnInitValueHandler('copyObjectInit'))
        browserHistory.push(url)
      }
    }
    if (item.itemType && item.itemType === 'report') {
      activeConsoleIndex !== 0 && setActiveConsoleIndex(activeConsoleIndex, 0)
      if (item.id !== this.props.selectedTabId) {
        loaderControl(true)
        this.props.setSelectedIdTopTree(item.id)
        this.props.getReportInfo(params.projectId, item.id)
      } else {
        this.props.setSelectedIdTopTree(null)
        this.props.setReportAttributes()
      }
    }
    if (this.props.position === 'top' && !isOtxFile) {
      this.props.getPackageName(item.packageName || 'Procedures')
    }
    if (isOtxFile && !isProcedure && item.id) {
      this.props.updateScroll()
      if (item.id === params.otxFileId) {
        this.props.getOutlineTreeAction([])
        this.props.setSelectedIdTopTree(null)
        browserHistory.push(`/project/${params.projectId}`)
      } else {
        this.props.setSelectedIdTopTree(item.id)
        browserHistory.push(`/project/${params.projectId}/otx/${item.id}`)
      }
    } else if (!isOtxFile && isProcedure && item.otx_id) {
      let url = ''
      if (typeof params.procedureId === 'undefined' ||
          params.procedureId !== item.otx_id ||
          this.state.selectedItemId !== item.otx_id) {
        url = `/project/${params.projectId}/otx/${params.otxFileId}/procedure/${item.otx_id}`
        if (this.state.selectedItemId !== item.otx_id) {
          this.setState({ selectedItemId: item.otx_id })
        }
      } else {
        url = `/project/${params.projectId}/otx/${params.otxFileId}`
        this.props.clearDiagramData()
      }
      browserHistory.push(url)
    }
    if (item.type === 'Import' ||
        item.type === 'Validity' ||
        item.type === 'Signature' ||
        isGlobalDeclaration(item) ||
        isLocalDeclaration(item) ||
        isParameter(item) ||
        isThrows(item)) {
      this.props.updateScroll()
      if (this.state.selectedItemId !== item.id) {
        this.setState({ selectedItemId: item.id })
      } else if (typeof params.selectedElementId !== 'undefined') {
        this.setState({ selectedItemId: params.selectedElementId })
      } else if (typeof params.procedureId !== 'undefined') {
        this.setState({ selectedItemId: params.procedureId })
      } else {
        this.setState({ selectedItemId: null })
      }
    }
  }

  handleChange = nodes => {
    const { activeTopTabIndex, position, updateTreeData } = this.props
    this.setState({ nodes }, updateTreeData(nodes, position, activeTopTabIndex))
  }

  nodeSelectionHandler = (nodes, updatedNode) => {
    const objects = nodes.map(node => {
      if (node.id === updatedNode.id) {
        return {
          ...updatedNode,
          children: node.children ? this.selectNodes(node.children, updatedNode.state.selected) : []
        }
      }
      if (node.children) {
        return { ...node, children: this.nodeSelectionHandler(node.children, updatedNode) }
      }
      return node
    })
    return objects
  }

  selectNodes = (nodes, selected) =>
    nodes.map(n => ({
      ...n,
      children: n.children ? this.selectNodes(n.children, selected) : [],
      state: {
        ...n.state,
        selected
      }
    }))

  handleEvent (e, node) {
    e.preventDefault()
    e.persist()
    contextMenu.show({
      id: `menu_context-${this.props.position}`,
      event: e
    })
    setTimeout(() => {
      this.setState({
        nodeObject: node
      })
    }, 10)
  }

  iconsHandler = node => {
      let ico = ''
      if (node.type === 'node' || node.itemType === 'PACKAGE' || node.itemType === 'Execution') {
        ico += 'ico-tree-big '
        if (node.isOtxTree) {
          ico += 'icon-document'
          return ico
        }
        let openFolderIco = 'signature'
        let closeFolderIco = 'close-signature'
        if (node.isSsmTree) {
          openFolderIco = 'ssm'
          closeFolderIco = 'close-ssm'
        }
        ico += `icon-folder-${
          !isEmpty(node.children) && node.state.expanded ? openFolderIco : closeFolderIco}`
        return ico
      } else if (node.type && (node.type === 'activity' || node.type === 'system element') && !isEmpty(node.children)) {
        ico += `ico-tree-big icon-folder-${
          !isEmpty(node.children) && node.state.expanded ? 'ssm' : 'close-ssm'}`
        return ico
      } else if (node.itemType !== undefined && node.itemType === 'report' && node.result !== undefined) {
         ico = `ico-tree-small icon-${node.result === 'PASS' ? 'validation ico-pass' : 'clear ico-fail'}`
        return ico
      }
      ico += !node.name.includes('.ssm')
      ? `ico-tree-small ${iconSetter(node.type || node.itemType)}${this.isDangerousHandler(node)}`
      : 'icon-ssm ico-tree-big'
      return ico
  }

  isDangerousHandler (node) {
    return node.type === 'activity' && node.dangerous ? ' dangerous-icon' : ''
  }

  getMarginLeft = node => {
    let margin = node.deepness < 5
    ? 15 * node.deepness
    : 75 + 10 * (node.deepness - 5)
    node.deepness > 0 && isEmpty(node.children) && (margin += 20)
    return margin
  }

  render () {
    const {
      nodes,
      selectedItemId,
      nodeObject
    } = this.state
    const {
      position,
      reachedBreakPoint,
      menuContextActionHandler,
      isTesterRole
    } = this.props
    return (
      <div className='virtualized-tree__segment'>
        <ExtendedFilteringContainer nodes={nodes}>
          {({ nodes }) => (
            <div className='virtualized-tree__segment-list'>
              <Tree
                nodes={nodes}
                onChange={this.handleChange}
                extensions={{
                  updateTypeHandlers: {
                    [SELECT]: this.nodeSelectionHandler
                  }
                }}
              >
                {({ style, node, ...rest }) => (
                  <div style={{ ...style, marginLeft: this.getMarginLeft(node) }}>
                    <Expandable node={node} {...rest}>
                      <span
                        onContextMenu={e => showContextMenu(node, position, isTesterRole)
                          ? this.handleEvent(e, node) : e.preventDefault()}
                        data-node={node.id}
                        onClick={() =>
                          this.handleClick(
                            node,
                            node.itemType && node.itemType === 'FILE',
                            node.type === 'Procedure')}
                        id={`${position}-${node.name}`}
                        style={{
                          position: 'absolute',
                          whiteSpace: 'nowrap',
                          transition: 'background-color .5s linear',
                          backgroundColor:
                            selectedItemId === node.id
                            ? node.id === reachedBreakPoint
                              ? '#ffd23f' : '#8bc9e8'
                            : 'none'
                        }}
                      >
                        <span
                          className={`${this.iconsHandler(node)}`} >
                          {node.name}
                        </span>
                      </span>
                    </Expandable>
                  </div>
                )}
              </Tree>
            </div>)}
        </ExtendedFilteringContainer>
        <MemoizedContextMenu
          id={`menu_context-${position}`}
          position={position}
          menuContextActionHandler={menuContextActionHandler}
          node={nodeObject} />
      </div>
    )
  }
}
export default VirtualizedTreeComponent
