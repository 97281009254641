import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Popup } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import { nameRegex } from '../../../../store/addons'
import testIds from '../../../../../public/testIds.json'

class NewSignatureComponent extends Component {
  constructor (props) {
    super()
    this.state = {
      name: (props.editItem && props.editItem.name && props.editItem.name !== null) ? props.editItem.name : '',
      specification: props.editItem && props.editItem.specification && props.editItem.specification !== null
      ? props.editItem.specification : '',
      visibility: props.editItem && props.editItem.visibility && props.editItem.visibility !== null
      ? props.editItem.visibility : labels.modalPattern.inputs.visibility.options[1].value,
      nameError: false,
      visibilityError: false
    }
  }

  static propTypes = {
    // props from parent
    saveModal: PropTypes.func,
    closeModal: PropTypes.func,
    fileType: PropTypes.string,
    editItem: PropTypes.object
  }

  handleChangeInput = (stateName, value) => {
    const change = { ...this.state }
    change[stateName] = value
    this.setState(change)
    this.setState(change, () => this.validateBlur(stateName))
  }

  validateBlur = (stateName, validationName) => {
    const error = `${stateName}Error`
    const change = { ...this.state }
    change[error] = stateName === 'name' ? !nameRegex.test(this.state[stateName])
      : this.state[stateName] === '' || this.state[stateName] === null
    this.setState(change)
    if (validationName === 'formValidation') return change[error]
  }

  saveData = () => {
    if (this.isFormValid()) {
      const elId = (this.props.editItem && this.props.editItem.otx_id)
      ? this.props.editItem.otx_id : null
      this.props.saveModal(
        this.props.fileType,
        {
          otx_name: this.state.name,
          otx_specification: this.state.specification,
          visibility: this.state.visibility,
          type: 'Signature'
      },
      elId)
    }
  }

  isFormValid = () => (!this.validateBlur('name', 'formValidation') &&
    !this.validateBlur('visibility', 'formValidation'))

  render () {
    const {
      nameError,
      visibilityError
    } = this.state
    const { inputs } = labels.modalPattern
    return (
      <div className='wizard__form-container'>
        <Form className='wizard__form'>
          <Form.Group>
            <div className='wizard__form-info'>
              <Popup
                position='top center'
                content={labels.hints.signature.name}
                trigger={<Icon name='info' />} />
              <Form.Input
                id={testIds.newSignatureComponent.nameInput}
                label={inputs.name.label}
                placeholder={inputs.name.placeholder}
                type='text'
                value={this.state.name}
                onChange={(event, { value }) => this.handleChangeInput('name', value)}
                error={nameError}
                onBlur={() => this.validateBlur('name')}
                className='wizard__form-input'>
              </Form.Input>
            </div>
          </Form.Group>
          <Form.Group>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.TextArea
                id={testIds.newSignatureComponent.specificationTextArea}
                label={inputs.specification.label}
                value={this.state.specification}
                onChange={(event, { value }) => this.handleChangeInput('specification', value)}
                placeholder={inputs.specification.placeholder}
                className='wizard__form-input'/>
            </div>
          </Form.Group>
          <Form.Group>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                id={testIds.newSignatureComponent.visibilityDropDown}
                fluid
                label={inputs.visibility.label}
                options={inputs.visibility.options}
                onChange={(event, { value }) => this.handleChangeInput('visibility', value)}
                placeholder={inputs.modifiedVariable.placeholder}
                error={visibilityError}
                onBlur={() => this.validateBlur('visibility')}
                value={this.state.visibility} />
            </div>
          </Form.Group>
        </Form>
        <div className='wizard__footer'>
          <div>
            <Button
              id={testIds.newSignatureComponent.cancelButton}
              onClick={this.props.closeModal}
              content={labels.buttons.cancel} />
          </div>
          <div>
            <Button
              id={testIds.newSignatureComponent.saveButton}
              content={labels.buttons.save}
              onClick={this.saveData}
              positive />
          </div>
        </div>
      </div>
    )
  }
}

export default NewSignatureComponent
