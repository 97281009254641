import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './DiagramHeaderComponent.scss'
import DiagramToolBar from '../../../../../DiagramToolBar'
import DiagramElementDescriptionComponent from
'../../../DiagramElementDescriptionComponent/DiagramElementDescriptionComponent'
import ModalComponent from '../../../../../ModalComponent'
import labels from '../../../../../../../public/labels.json'
import { iconSetter } from '../../../../../../constants/common'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { browserHistory } from 'react-router'
import ReactTooltip from 'react-tooltip'
import { findNested } from '../../../../../../store/addons'

class DiagramHeaderComponent extends Component {
  constructor () {
    super()
    this.state = {
      colors: { textOnDark: '#f6f9fd', textOnLight: '#091032', headerColor: '#192354' },
      isModalOpen: false,
      modalType: '',
      modalTitle: '',
      modalText: '',
      parameters: [],
      input: [],
      output: [],
      inputOutput: [],
      throws: [],
      accessPathArray: [],
      isCollapsedHeader: false
    }
  }

  static propTypes = {
    isEmpty: PropTypes.bool,
    headerData: PropTypes.object,
    params: PropTypes.object,
    resetScrollToIndex: PropTypes.func,
    // props from Redux
    outlineData: PropTypes.array
  }

  handleDiagramDelete = (modalTitle, modalText) => {
    this.setState({
      isModalOpen: true,
      modalType: 'delete',
      modalTitle,
      modalText
    })
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    // initial return value
    let result = null

    // check for any changes in parametars
    if (nextProps.headerData && nextProps.headerData.parameters &&
      !isEqual(nextProps.headerData.parameters, prevState.parameters)) {
      result = {
        parameters: nextProps.headerData.parameters
      }

      const input = nextProps.headerData.parameters.filter(item => item.type === 'InParameter')
      const output = nextProps.headerData.parameters.filter(item => item.type === 'OutParameter')
      const inputOutput = nextProps.headerData.parameters.filter(item => item.type === 'InOutParameter')

      if (!isEqual(input, prevState.input)) {
        result.input = input
      }
      if (!isEqual(output, prevState.output)) {
        result.output = output
      }
      if (!isEqual(inputOutput, prevState.inputOutput)) {
        result.inputOutput = inputOutput
      }
    }

    // check for any changes in throws
    if (nextProps.headerData && nextProps.headerData.throws &&
      !isEqual(nextProps.headerData.throws, prevState.throws)) {
      // check if result was changed already by parameters
      if (result) {
        result.throws = nextProps.headerData.throws
      } else {
        result = {
          throws: nextProps.headerData.throws
        }
      }
    }

    return result
  }

  componentDidMount () {
   !isEmpty(this.props.outlineData) && this.accessPathCreator()
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.params.selectedElementId, this.props.params.selectedElementId) ||
        !isEqual(this.props.outlineData, prevProps.outlineData)) {
      this.accessPathCreator()
    }
  }

  accessPathCreator () {
    const { params } = this.props
    const tempObj = findNested(this.props.outlineData,
    params.selectedElementId || this.props.headerData.otx_id)
    const accessPathArray = []
    tempObj && tempObj.localisation !== undefined &&
    tempObj.localisation.forEach(el => {
      accessPathArray.push({
        name: el.name,
        url: this.urlCreator(el, params)
      })
    })
    this.setState({ accessPathArray })
  }

  urlCreator (el, params) {
    let url = ''
    if (el.type === 'Otx') {
       url = `/project/${params.projectId}`
    } else if (el.type === 'Procedure') {
      url = `/project/${params.projectId}/otx/${params.otxFileId}/procedure/${params.procedureId}`
    } else {
      url = el.parentFlowElement === null
      ? `/project/${params.projectId}/otx/${params.otxFileId}/procedure/${params.procedureId}` +
        `/selectedElement/${el.id}`
      : `/project/${params.projectId}/otx/${params.otxFileId}/procedure/${params.procedureId}` +
        `/element/${el.parentFlowElement}/selectedElement/${el.id}`
    }
    return url
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  renderListHandler = (name, isShort) => {
    const nameToState = {
      INPUT: 'input',
      OUTPUT: 'output',
      'IN/OUT': 'inputOutput',
      THROWS: 'throws'
    }
    if (this.state[nameToState[name]].length) {
      const object = [<div key={-1}>{name}:</div>]
      if (isShort && this.state[nameToState[name]].length > 5) {
        for (let index = 0; index < 4; index += 1) {
          name === 'THROWS'
            ? object.push(<div key={index}>{`${this.state[nameToState[name]][index].type}`}</div>)
            : object.push(<div key={index}>{this.returnShortText(
              this.state[nameToState[name]][index].otx_name +
            (this.state[nameToState[name]][index].dataType
              ? ':' + this.state[nameToState[name]][index].dataType.type : ''), isShort)}</div>)
        }
        object.push(<div>{`More (${this.state[nameToState[name]].length - 4})...`}</div>)
        return object
      } else {
        this.state[nameToState[name]].map((item, index) => {
          name === 'THROWS'
            ? object.push(<div key={index}>{`${item.type}`}</div>)
            : object.push(<div key={index}>{this.returnShortText(item.otx_name + (item.dataType
              ? ':' + item.dataType.type : ''), isShort)}</div>)
        })
        return object
      }
    }
    return <div>{name}: -</div>
  }

  returnShortText = (text, isShort) => {
    if (text.length > 20 && isShort) {
      return text.slice(0, 20) + '...'
    }
    return text
  }

  renderAccessPath () {
    return this.state.accessPathArray.map((el, index, arr) => {
      const isClickable = index + 1 !== arr.length && index !== 0
      return <span
        style={{ cursor: isClickable ? 'pointer' : 'default' }}
        className={isClickable ? 'link-style' : null}
        onClick={() => isClickable && browserHistory.push(el.url)}
        key={index}>
        {index === 0 ? './' : '/'}{el.name}
      </span>
    })
  }

  procedureNameToIcon = procedure => {
    const nameObject = {
      input: 'InParameter',
      output: 'OutParameter',
      inOut: 'InOutParameter',
      throws: 'Throw'
    }
    return nameObject[procedure]
  }

  onToggleCollapse = e => {
    const { isCollapsedHeader } = this.state
    e.stopPropagation()
    this.props.resetScrollToIndex()
    this.setState({
      isCollapsedHeader: !isCollapsedHeader
    })
  }

  render () {
    const {
      colors,
      isModalOpen,
      modalType,
      modalTitle,
      modalText,
      isCollapsedHeader
    } = this.state
    const { isEmpty, headerData } = this.props
    const { modalPattern, procedure } = labels
    const isProcedure = headerData.type && headerData.type === 'Procedure'
    return (
      <div
        className='diagram-header-container'
        style={{ margin: isEmpty ? '20px 0 0 0' : '20px 0' }}>
        <div className='diagram-header-style'>
          <div className='flex-container'>
            <span
              className={`${iconSetter(headerData.type)} diagram-icon-circle`}
              style={{ color: colors.textOnDark }}/>
            <div className='diagram-text-container' style={{ color: colors.textOnDark }}>
              <div className='diagram-subtitle'>{isProcedure ? procedure.name
                : headerData.otx_name + ': ' + headerData.type}</div>
              <div className={'diagram-title'}>
                {this.renderAccessPath()}
              </div>
            </div>
          </div>
          <DiagramToolBar
            colors={colors}
            isHeader={isProcedure}
            isShow={true}
            onToggleCollapse={this.onToggleCollapse}
            isCollapsedHeader={isCollapsedHeader}
            onDelete={e => this.handleDiagramDelete(modalPattern.deleteModal.title,
              modalPattern.deleteModal.confirmation)} />
          <ModalComponent
            isOpen={isModalOpen}
            modalType={modalType}
            modalTitle={modalTitle}
            modalText={modalText}
            closeModal={this.closeModal} />
        </div>
        {!isCollapsedHeader && <React.Fragment>
          {(headerData && (headerData.otx_specification !== '' && headerData.otx_specification !== null)) &&
            <DiagramElementDescriptionComponent colors={colors} description={headerData.otx_specification} />}
          {isProcedure &&
          <div className='add-padding'>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
              {['input', 'output', 'inOut', 'throws'].map((procedureName, i) => {
                return <React.Fragment key={i}>
                  <ReactTooltip id={i + 'x'}> {this.renderListHandler(procedure[procedureName], false)} </ReactTooltip>
                  <div data-tip data-for={i + 'x'}>
                    <div style={{ display: 'flex' }}>
                      <span
                      className={
                        `${iconSetter(this.procedureNameToIcon(procedureName))} diagram-icon-circle header-icon`}
                      style={{ color: colors.textOnLight }}/>
                      <div>{this.renderListHandler(procedure[procedureName], true)}</div>
                    </div>
                  </div>
                </React.Fragment>
              }
                )}
            </div>
          </div>}
        </React.Fragment>}
      </div>
    )
  }
}

export default DiagramHeaderComponent
