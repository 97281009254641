import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
// ------------------------------------
// Constants
// ------------------------------------
import axios from 'axios'
import { origin, protocol, getHeaders, errorHandle } from '../../../store/addons'
import { setActiveConsoleIndex } from './selectElement'
import { loaderControl } from './loaderControl'
import isEmpty from 'lodash/isEmpty'
import labels from '../../../../public/labels.json'

export const VALIDATE_OTX_FILE = 'VALIDATE_OTX_FILE'
// ------------------------------------
// Actions
// ------------------------------------
export const validateDiagramOtxFileAction = data => {
  return {
    type: VALIDATE_OTX_FILE,
    data
  }
}

export const validateDiagramOtxFile = (projectId, itemId) => {
return dispatch => {
  return new Promise((resolve, reject) => {
    axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${itemId}/procedures/validate`, getHeaders())
      .then(response => {
        const logs = !isEmpty(response.data)
          ? response.data
          : [labels.validation.success]
        dispatch(validateDiagramOtxFileAction(logs))
        dispatch(setActiveConsoleIndex(0, 4))
        dispatch(loaderControl())
        resolve()
      })
      .catch(error => {
        dispatch(validateDiagramOtxFileAction([]))
        dispatch(setActiveConsoleIndex(0, 4))
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
  })
}
}

export const actions = {
  validateDiagramOtxFile
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [VALIDATE_OTX_FILE]: (state, action) => {
    return {
      ...state,
      otxValidationData: action.data
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  otxValidationData: []
}

export default function diagramValidationReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
