import {
  GET_DRIVER_LIST,
  SET_DRIVER,
  SET_LOG_ITEM,
  SET_VARIABLE_WATCH_ITEM,
  RESET_ALL_LOGS,
  ACTIVATE_CLEAN_BUTTON,
  SET_SUT_STATUS_ITEM
} from '../actionTypeList'
import axios from 'axios'
import { getHeaders, origin, protocol, errorHandle } from '../../addons'
import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'

export const getDriverListAction = (data = []) => {
  return {
    type: GET_DRIVER_LIST,
    data
  }
}

const setDriverAction = (data = '') => {
  return {
    type: SET_DRIVER,
    data
  }
}

const setLogItemsAction = (data = '') => {
  return {
    type: SET_LOG_ITEM,
    data
  }
}

const setVariableWatchAction = (data = '') => {
  return {
    type: SET_VARIABLE_WATCH_ITEM,
    data
  }
}

const resetAllLogsAction = () => {
  return {
    type: RESET_ALL_LOGS
  }
}

const setSutStatusAction = (data = '') => {
  return {
    type: SET_SUT_STATUS_ITEM,
    data
  }
}

const activateCleanButtonAction = () => {
  return {
    type: ACTIVATE_CLEAN_BUTTON
  }
}

export const getDriverList = (projectType = 'ROBOT') => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/drivers?projectType=${projectType}`, getHeaders())
      .then(response => {
        const result = response.data.map((element, index) => {
            return {
              key: index,
              text: element,
              value: element
            }
          })
        dispatch(getDriverListAction(result))
        const driver = result &&
              result.length === 1 &&
              result[0].value === 'robot-mock'
              ? result[0].value
              : ''
        dispatch(setDriver(driver))
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const setDriver = (driver = '') => {
  return dispatch => {
    dispatch(setDriverAction(driver))
  }
}

export const setLogItems = (logItems = '') => {
  return dispatch => {
    dispatch(setLogItemsAction(logItems))
  }
}

export const setVariableWatch = (variableWatchItem = []) => {
  return dispatch => {
    dispatch(setVariableWatchAction(variableWatchItem))
  }
}

export const setSutStatus = (sutStatusItem = '') => {
  return dispatch => {
    dispatch(setSutStatusAction(sutStatusItem))
  }
}

export const resetAllLogs = () => {
  return dispatch => {
    dispatch(resetAllLogsAction())
  }
}

export const activateCleanButton = () => {
  return dispatch => {
    dispatch(activateCleanButtonAction())
  }
}

export const actions = {
  getDriverList,
  setDriver,
  setLogItems,
  setVariableWatch,
  activateCleanButton
}

const ACTION_HANDLERS = {
  [GET_DRIVER_LIST]: (state, action) => {
    return {
      ...state,
      driverList: action.data
    }
  },
  [SET_DRIVER]: (state, action) => {
    return {
      ...state,
      driver: action.data
    }
  },
  [SET_LOG_ITEM]: (state, action) => {
    return {
      ...state,
      logItems: [...state.logItems.concat([action.data])]
    }
  },
  [SET_VARIABLE_WATCH_ITEM]: (state, action) => {
    return {
      ...state,
      variableWatchItem: action.data
    }
  },
  [SET_SUT_STATUS_ITEM]: (state, action) => {
    return {
      ...state,
      sutStatusItem: action.data
    }
  },
  [RESET_ALL_LOGS]: state => {
    return {
      ...state,
      logItems: [],
      variableWatchItem: [],
      sutStatusItem: '',
      isDisabledButtonClean: true
    }
  },
  [ACTIVATE_CLEAN_BUTTON]: state => {
    return {
      ...state,
      isDisabledButtonClean: false
    }
  }
}

const initialState = {
  driverList: [],
  driver: '',
  logItems: [],
  variableWatchItem: [],
  sutStatusItem: '',
  isDisabledButtonClean: true
}

export default function driverReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
