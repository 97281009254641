import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
// ------------------------------------
// Constants
// ------------------------------------
import axios from 'axios'
import { origin, protocol, getHeaders, errorHandle, mapJsonPositionList, getDefaultValueFromType } from '../../addons'
import { createOptionVariablesList } from '../../../constants/common'
import { getOutlineTree } from './otxFile'
import { getAttributes, getXmlPreviewAction, setActiveTopTabIndex } from './diagramAttributes'
import { getPseudoCodeList } from './pseudoCode'
import { loaderControl } from './loaderControl'
import { copyElementAfterDiagramChangedHandler, clearAfterPasteHandler } from './selectElement'
import { setUniversalModalObject } from './universalModal'
import {
  OPEN_EDIT_MODAL,
  OPEN_REMOVE_MODAL,
  MODAL_ELEMENT,
  GET_VARIABLE_LIST,
  GET_VARIABLE_LIST_WITH_TYPES,
  GET_PROCEDURES_PARAMETERS_LIST,
  OPEN_ADD_VARIABLE,
  GET_DIAGRAM_LIST,
  GET_HEADER_DATA,
  CREATE_ELEMENT,
  READ_ELEMENT,
  UPDATE_OLD,
  UPDATE_ELEMENT,
  UPDATE_BREAKPOINT,
  DELETE_ELEMENT,
  CHANGE_DIAGRAM_COMPONENT_STATE,
  SET_BEFORE_PASTE_OBJECT
} from '../actionTypeList'
import isEmpty from 'lodash/isEmpty'

// ------------------------------------
// Actions
// ------------------------------------
export const openAddVariableAction = (isOpenAddVar = false) => {
  return {
    type: OPEN_ADD_VARIABLE,
    data: isOpenAddVar
  }
}
export const openRemoveModalAction = data => {
  return {
    type: OPEN_REMOVE_MODAL,
    data
  }
}

export const openEditModalAction = data => {
  return {
    type: OPEN_EDIT_MODAL,
    data
  }
}

export const modalElementAction = data => {
  return {
    type: MODAL_ELEMENT,
    data
  }
}

export const getDiagramListAction = (data = []) => {
  return {
    type: GET_DIAGRAM_LIST,
    data
  }
}

export const getHeaderDataAction = (data = {}) => {
  return {
    type: GET_HEADER_DATA,
    data
  }
}

export const setBeforePasteObjectAction = (data = []) => ({
  type: SET_BEFORE_PASTE_OBJECT,
  data
})

export const createDiagramElementAction = () => {
  return {
    type: CREATE_ELEMENT
  }
}

export const readDiagramElementAction = data => {
  return {
    type: READ_ELEMENT,
    data
  }
}

export const updateDiagramListAction = data => {
  return {
    type: UPDATE_OLD,
    data
  }
}

export const updateDiagramElementAction = () => {
  return {
    type: UPDATE_ELEMENT
  }
}

export const updateBreakpointAction = () => {
  return {
    type: UPDATE_BREAKPOINT
  }
}

export const deleteDiagramElementAction = () => {
  return {
    type: DELETE_ELEMENT
  }
}

export const getVariableListAction = data => {
  return {
    type: GET_VARIABLE_LIST,
    data
  }
}

export const getVariableListWithTypesAction = data => {
  return {
    type: GET_VARIABLE_LIST_WITH_TYPES,
    data
  }
}

export const getProceduresParametersListAction = data => {
  return {
    type: GET_PROCEDURES_PARAMETERS_LIST,
    data
  }
}

export const updateDiagramAfterDeleteElement = () => {
  return dispatch => {
    dispatch(deleteDiagramElementAction())
  }
}

export const openAddVariable = (isOpen = false) => {
  return dispatch => {
    dispatch(openAddVariableAction(isOpen))
  }
}

export const resetHeaderData = (data = {}) => {
  return dispatch => {
    dispatch(getHeaderDataAction(data))
  }
}

export const openRemoveModal = data => {
  return dispatch => {
    dispatch(modalElementAction(data))
    dispatch(openRemoveModalAction(true))
  }
}

export const closeRemoveModal = () => {
  return dispatch => {
    dispatch(openRemoveModalAction(false))
  }
}

export const openEditModal = data => {
  return dispatch => {
    dispatch(modalElementAction(data))
    dispatch(openEditModalAction(true))
  }
}

export const closeEditModal = () => {
  return dispatch => {
    dispatch(openEditModalAction(false))
  }
}

export const getVariableList = (projectId, itemId, procedureId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${itemId}/` +
      `procedures/${procedureId}/declarations/all`,
      getHeaders())
        .then(json => {
          const result = []
          for (let i = 0; i < json.data.length; i++) {
            result.push({ key: i, text: json.data[i].otx_name, value: json.data[i].otx_name, obj: json.data[i] })
          }
          dispatch(openAddVariableAction(false))
          dispatch(getVariableListAction(result))
          const optionVariableList = createOptionVariablesList(result)
          dispatch(getVariableListWithTypesAction(optionVariableList))
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const getProceduresParametersList = (projectId, itemId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${itemId}/callableprocedures`,
      getHeaders())
        .then(json => {
          const result = []
          for (let i = 0; i < json.data.length; i++) {
            result.push({
              key: i,
              text: json.data[i].otx_name,
              value: json.data[i].otx_name,
              parameters: json.data[i].parameters.map(element => {
                const change = {
                  ...element,
                  isSendActive: true,
                  dataType: element.dataType
                }
                change.dataType && (change.dataType.value = change.dataType.value ||
                  getDefaultValueFromType(change.dataType.type))
                return change
              })
            })
          }
          dispatch(getProceduresParametersListAction(result))
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const getDiagramList = (idProject, idItems, idProcedure, element = null,
  shouldGetAttributes = true, shouldUpdateDiagram = true) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${idProject}/items/${idItems}/procedures/${idProcedure}`,
      getHeaders())
        .then(json => {
          json.data.children = mapJsonPositionList(json.data.children, `${idProcedure}`)
          dispatch(getHeaderDataAction(json.data))
          shouldUpdateDiagram && dispatch(getDiagramListAction(json.data.children))
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          dispatch(loaderControl())
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const setElementActiveState =
(projectId = '', otxFileId = -1, procedureId = '', elementId = '', item = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/procedures/` +
      `${procedureId}/nodes/${elementId}`, item, getHeaders())
        .then(() => {
          dispatch(getPseudoCodeList(projectId, otxFileId))
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          dispatch(loaderControl())
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const getBreakPoint = (idProject, idItems, idProcedure) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${idProject}/items/${idItems}/procedures/${idProcedure}`,
      getHeaders())
        .then(json => {
          json.data = mapJsonPositionList([json.data], `${idProcedure}`)
          dispatch(getDiagramListAction(json.data))
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const createDiagramElement = (
  projectId = '', otxFileId = -1, procedureId = '', parentElementId = '', position = -1, data = {}) => {
  return dispatch => {
    return (new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}` +
      `/procedures/${procedureId}/nodes/${parentElementId}?position=${position}`, data, getHeaders())
      .then(() => {
        dispatch(createDiagramElementAction())
        dispatch(getOutlineTree(projectId, otxFileId))
        dispatch(openEditModalAction(false))
        dispatch(modalElementAction({}))
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    }))
  }
}

export const readDiagramElement = (projectId = '', otxFileId = -1, procedureId = '', elementId = '') => {
  return dispatch => {
    return (new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/procedures/` +
      `${procedureId}/nodes/${elementId}`, getHeaders())
      .then(response => {
        dispatch(readDiagramElementAction(response.data))
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
        reject(error)
      })
    }))
  }
}

export const updateDiagramElement = (
  projectId = '',
  otxFileId = -1,
  procedureId = '',
  elementId = '',
  newParentId = '',
  newPosition = 0,
  data = {}
) => {
  const url = data.isEdit
    ? `${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/procedures/${procedureId}/nodes/${elementId}`
    : `${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/procedures/` +
    `${procedureId}/nodes/${elementId}/move/${newParentId}?newPosition=${newPosition}`
  return dispatch => {
    return (new Promise((resolve, reject) => {
      axios.put(url, data, getHeaders())
      .then(() => {
        dispatch(updateDiagramElementAction())
        dispatch(getOutlineTree(projectId, otxFileId))
        dispatch(openEditModalAction(false))
        dispatch(modalElementAction({}))
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    }))
  }
}

export const updateDiagramList = data => dispatch => dispatch(updateDiagramListAction(data))

export const deleteDiagramElement = (projectId = '', otxFileId = -1, procedureId = '', elementId = '') => {
  return dispatch => {
    return (new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/procedures/` +
      `${procedureId}/nodes/${elementId}`, getHeaders())
      .then(() => {
        dispatch(getOutlineTree(projectId, otxFileId))
        dispatch(openRemoveModalAction(false))
        dispatch(copyElementAfterDiagramChangedHandler(elementId))
        dispatch(modalElementAction({}))
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    }))
  }
}

export const pasteDiagramElemets = (url = '', data = {}, params = {}) => {
  return dispatch => {
    return (new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${url}`,
      data, getHeaders())
      .then(() => {
        dispatch(updateDiagramElementAction())
        dispatch(getOutlineTree(params.projectId, params.otxFileId))
        dispatch(clearAfterPasteHandler())
        dispatch(loaderControl())
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    }))
  }
}

export const validateBeforePaste = (optionValidate, optionPaste, url = '', data = {}, params = {}) => {
  return dispatch => {
    return (new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${url}${optionValidate}`,
      data, getHeaders())
      .then(response => {
        const pasteUrl = `${url}${optionPaste}`
        if (isEmpty(response.data)) {
          dispatch(pasteDiagramElemets(pasteUrl, data, params))
        } else {
          const beforePasteObject = {
            logs: response.data,
            url: pasteUrl,
            data,
            params
          }
          dispatch(loaderControl())
          dispatch(setUniversalModalObject('pasteValidation'))
          dispatch(setBeforePasteObjectAction(beforePasteObject))
        }
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        dispatch(loaderControl())
        errorHandle(error)
        reject(error)
      })
    }))
  }
}

export const clearDiagramData = () => {
  return dispatch => {
    dispatch(getDiagramListAction())
    dispatch(getHeaderDataAction())
    dispatch(getAttributes({}))
    dispatch(getXmlPreviewAction(''))
    dispatch(setActiveTopTabIndex())
  }
}

export const actions = {
  getDiagramList,
  createDiagramElement,
  readDiagramElement,
  updateDiagramList,
  deleteDiagramElement,
  openRemoveModal,
  closeRemoveModal,
  getVariableList,
  getProceduresParametersList,
  resetHeaderData,
  getBreakPoint,
  loaderControl,
  clearDiagramData,
  setElementActiveState,
  updateDiagramAfterDeleteElement,
  pasteDiagramElemets,
  validateBeforePaste
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GET_DIAGRAM_LIST]: (state, action) => {
    return {
      ...state,
      diagramList: action.data
    }
  },
  [GET_HEADER_DATA]: (state, action) => {
    return {
      ...state,
      headerData: action.data
    }
  },
  [CHANGE_DIAGRAM_COMPONENT_STATE]: (state, action) => {
    return {
      ...state,
      [action.data.name]: action.data.value
    }
  },
  [CREATE_ELEMENT]: state => {
    return {
      ...state,
      updateDiagramListTime: new Date().getTime()
    }
  },
  [UPDATE_ELEMENT]: (state, action) => {
    return {
      ...state,
      updateDiagramListTime: new Date().getTime()
    }
  },
  [UPDATE_BREAKPOINT]: state => {
    return {
      ...state,
      updateDiagramListTime: new Date().getTime()
    }
  },
  [READ_ELEMENT]: (state, action) => {
    return {
      ...state,
      diagramElement: action.data
    }
  },
  [UPDATE_OLD]: (state, action) => {
    return {
      ...state,
      diagramList: action.data
    }
  },
  [DELETE_ELEMENT]: state => {
    return {
      ...state,
      updateDiagramListTime: new Date().getTime()
    }
  },
  [OPEN_REMOVE_MODAL]: (state, action) => {
    return {
      ...state,
      isOpenRemoveModal: action.data
    }
  },
  [MODAL_ELEMENT]: (state, action) => {
    return {
      ...state,
      modalElement: action.data
    }
  },
  [OPEN_EDIT_MODAL]: (state, action) => {
    return {
      ...state,
      isOpenEditModal: action.data
    }
  },
  [GET_VARIABLE_LIST]: (state, action) => {
    return {
      ...state,
      variableList: action.data
    }
  },
  [GET_VARIABLE_LIST_WITH_TYPES]: (state, action) => {
    return {
      ...state,
      variableListWithTypes: action.data
    }
  },
  [GET_PROCEDURES_PARAMETERS_LIST]: (state, action) => {
    return {
      ...state,
      proceduresWithParametersList: action.data
    }
  },
  [OPEN_ADD_VARIABLE]: (state, action) => {
    return {
      ...state,
      isOpenAddVar: action.data
    }
  },
  [SET_BEFORE_PASTE_OBJECT]: (state, action) => {
    return {
      ...state,
      beforePasteObject: action.data
    }
  }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  diagramList: [],
  headerData: {},
  diagramElement: null,
  updateDiagramListTime: 0,
  isOpenRemoveModal: false,
  modalElement: {},
  isOpenEditModal: false,
  variableList: [],
  variableListWithTypes: [],
  proceduresWithParametersList: [],
  isOpenAddVar: false,
  beforePasteObject: {}
}

export default function diagramElementReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
