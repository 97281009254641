import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
import { browserHistory } from 'react-router'
import axios from 'axios'
// ------------------------------------
// Constants
// ------------------------------------
import { origin, protocol, getHeaders, errorHandle, clearLocalStorage } from '../../../store/addons'
import {
  SET_USER_ROLE,
  SET_USER_ROLES,
  LOG_IN,
  LOG_OUT,
  RESET_STORE
} from '../actionTypeList'
import { loaderControl } from './loaderControl'

// ------------------------------------
// Actions
// ------------------------------------

export const logInAction = () => {
  return {
    type: LOG_IN
  }
}

export const logOutAction = () => {
  return {
    type: LOG_OUT
  }
}

export const resetStoreAction = () => {
  return {
    type: RESET_STORE
  }
}

export const setUserRoleAction = (isTester = false) => {
  return {
    type: SET_USER_ROLE,
    data: isTester
  }
}

export const setUserRolesAction = (roles = []) => {
  return {
    type: SET_USER_ROLES,
    data: roles
  }
}

export const logIn = (username, password) => {
  const hash = Buffer.from(username + ':' + password).toString('base64')
  return dispatch => {
    dispatch(loaderControl(true))
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/auth/login`, {}, { headers: { Authorization: 'Basic ' + hash } })
        .then(response => {
          localStorage.setItem('atenatoken', response.headers['x-auth-token'])
          localStorage.setItem('user', JSON.stringify(response.data))
          const { roles } = response.data
          const isTester = roles.includes('ROLE_TESTER')
          localStorage.setItem('isTesterRole', JSON.stringify(isTester))
          dispatch(setUserRoleAction(isTester))
          dispatch(setUserRolesAction(roles))
          dispatch(logInAction())
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error, 'login')
          dispatch(loaderControl())
          reject(error)
        })
    })
  }
}

export const logOut = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/auth/logout`, getHeaders())
        .then(() => {
          dispatch(setUserRoleAction())
          dispatch(setUserRolesAction())
          clearLocalStorage()
          dispatch(resetStoreAction())
          dispatch(logOutAction())
          browserHistory.push('/')
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const actions = {
  logIn,
  logOut
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOG_IN]: (state, action) => {
    return {
      ...state,
      isLoggedIn: true
    }
  },
  [LOG_OUT]: (state, action) => {
    return {
      ...state,
      isLoggedIn: false
    }
  },
  [SET_USER_ROLE]: (state, action) => {
    return {
      ...state,
      isTesterRole: action.data
    }
  },
  [SET_USER_ROLES]: (state, action) => {
    return {
      ...state,
      roles: action.data
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isLoggedIn: !!localStorage.getItem('atenatoken'),
  isTesterRole: localStorage.getItem('isTesterRole') === 'true',
  roles: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).roles : []
}

export default function authReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
