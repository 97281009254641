import { connect } from 'react-redux'
import ModalComponent from './ModalComponent.js'
import { clearDiagramData } from '../../store/thunk/reducerList/diagramElement'
import { getOutlineTreeAction, resetSsmList } from '../../store/thunk/reducerList/otxFile'
import {
  setProjectTabDataAction,
  setIsProjectContainOtxAction,
  shareProject
} from '../../store/thunk/reducerList/project'
import { setReportAttributes } from '../../store/thunk/reducerList/diagramAttributes'
import { downloadFile } from '../../store/thunk/reducerList/downloadFile'
import { setDriver } from '../../store/thunk/reducerList/driver'

const mapDispatchToProps = {
  clearDiagramData,
  getOutlineTreeAction,
  setProjectTabDataAction,
  resetSsmList,
  setReportAttributes,
  downloadFile,
  setDriver,
  setIsProjectContainOtxAction,
  shareProject
}

const mapStateToProps = state => ({
  projectType: state.project.projectType
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent)
