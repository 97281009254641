import { connect } from 'react-redux'
import DiagramToolBar from './DiagramToolBar.js'
import { setActiveConsoleIndex } from '../../store/thunk/reducerList/selectElement'
import { setElementActiveState } from '../../store/thunk/reducerList/diagramElement'
import { loaderControl } from '../../store/thunk/reducerList/loaderControl'

const mapDispatchToProps = {
    setActiveConsoleIndex,
    setElementActiveState,
    loaderControl
}

const mapStateToProps = state => ({
    headerData: state.diagramElement.headerData,
    attribute: state.diagramAttributes.attribute,
    activeConsoleIndex: state.selectElement.activeConsoleIndex,
    isTesterRole: state.auth.isTesterRole
})

export default connect(mapStateToProps, mapDispatchToProps)(DiagramToolBar)
