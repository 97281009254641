import { connect } from 'react-redux'
import ConditionalWizard from './LoopWizard.js'
import { setAddedVariable } from '../../../../store/thunk/reducerList/outlineTree'
import { getVariableList, openAddVariable } from '../../../../store/thunk/reducerList/diagramElement'

const mapDispatchToProps = {
    setAddedVariable,
    getVariableList,
    openAddVariable
}

const mapStateToProps = state => ({
    newVariable: state.outlineTree.newVariable,
    optionsVariableList: state.diagramElement.variableListWithTypes,
    variableList: state.diagramElement.variableList
})

export default connect(mapStateToProps, mapDispatchToProps)(ConditionalWizard)
