import { connect } from 'react-redux'
import EditorConsoleComponent from './EditorConsoleComponent'
import { getAttributes } from '../../store/thunk/reducerList/diagramAttributes'
import { setActiveConsoleIndex } from '../../store/thunk/reducerList/selectElement'

const mapDispatchToProps = { getAttributes, setActiveConsoleIndex }

const mapStateToProps = state => ({
    attribute: state.diagramAttributes.attribute,
    reportAttributes: state.diagramAttributes.reportAttributes,
    activeTopTabIndex: state.diagramAttributes.activeTopTabIndex,
    xmlPreview: state.diagramAttributes.xmlPreview,
    otxValidationData: state.diagramValidation.otxValidationData,
    activeIndex: state.selectElement.activeConsoleIndex,
    copiedNodes: state.selectElement.copiedNodes
})

export default connect(mapStateToProps, mapDispatchToProps)(EditorConsoleComponent)
