import React from 'react'
import { Modal, Grid, Icon, Button } from 'semantic-ui-react'
import labels from '../../../../public/labels.json'
import { bool, func } from 'prop-types'
import './PrivacyPolicyModal.scss'
import parse from 'html-react-parser'

const PrivacyPolicyModal = ({ isOpenProp, closeModal }) => {
  const { privacyPolicy, statutes, privacyPolicyButton } = labels.privacyPolicy
  return (
    <Modal
      open={isOpenProp}
      onClose={closeModal}
      className='privacy-policy-modal'
      centered>
      <Modal.Header>
        <Icon name='file' />
        {privacyPolicy}
      </Modal.Header>
      <div className='modal-content'>
        <Grid>
          <Grid.Row>
            {statutes.map(({ title, subsections }) =>
              <Grid.Column key={title} width={16}>
                {title &&
                  <div className='label'>
                    {title}
                  </div>}
                {subsections &&
                  <div className='inside-text'>
                    {subsections.map(subsection => parse(`${subsection}<br />`))}
                  </div>}
              </Grid.Column>)}
          </Grid.Row>
        </Grid>
      </div>
      <Modal.Actions>
        <Button onClick={closeModal} content={privacyPolicyButton} />
      </Modal.Actions>
    </Modal>
  )
}

PrivacyPolicyModal.propTypes = {
  isOpenProp: bool,
  closeModal: func
}

export default PrivacyPolicyModal
