import { connect } from 'react-redux'
import PseudocodeComponent from './PseudocodeComponent.js'
import { loaderControl } from '../../store/thunk/reducerList/loaderControl'
import { getPseudoCodeList } from '../../store/thunk/reducerList/pseudoCode'

const mapDispatchToProps = {
    loaderControl,
    getPseudoCodeList
}

const mapStateToProps = state => ({
    updateDiagramListTime: state.diagramElement.updateDiagramListTime,
    pseucoCodeList: state.pseudoCode.pseucoCodeList,
    isActiveLoader: state.loaderControl.isActiveLoader,
    shouldUpdateScroll: state.procedure.shouldUpdateScroll,
    selectedContextViewId: state.selectElement.selectedContextViewId,
    isTesterRole: state.auth.isTesterRole
})

export default connect(mapStateToProps, mapDispatchToProps)(PseudocodeComponent)
