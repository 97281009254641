import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
// ------------------------------------
// Constants
// ------------------------------------
import axios from 'axios'

import { origin, protocol, getHeaders, errorHandle } from '../../addons'

// ------------------------------------
// Actions
// ------------------------------------
export const validateTerm = (data, inputType = null) => {
  let url = `${protocol}//${origin}/api/terms/validate`
  inputType !== null && (url += `?variableType=${inputType}`)
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(url, data, getHeaders())
        .then(response => {
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const actions = {
  validateTerm
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default function termValidationReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
