import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './MainViewComponent.scss'
import { Button } from 'semantic-ui-react'
import { isEqual, isEmpty } from 'lodash'
import DiagramComponent from '../DiagramComponent'
import PseudocodeComponent from '../PseudocodeComponent'
import StateDiagramViewComponent from '../StateDiagramViewComponent'
import labels from '../../../public/labels.json'
import ModalComponent from '../ModalComponent'
import WizardPattern from '../WizardPatterns'
import {
  isSupportedElement, isGlobalDeclaration, isParameter, isLocalDeclaration
} from '../../constants/common'
import { throwTypes } from '../../constants/contextMenuLists'
import { browserHistory } from 'react-router'
import { findNested } from '../../store/addons'

const { buttons } = labels.mainView
class MainViewComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      componentToDisplay: 'blockDiagram',
      modalPlaceToAdd: {},
      position: null
    }
  }

  static propTypes = {
    // props from redux
    getDiagramList: PropTypes.func,
    getAttributes: PropTypes.func,
    getXmlPreview: PropTypes.func,
    loaderControl: PropTypes.func,
    headerData: PropTypes.object,
    updateDiagramListTime: PropTypes.number,
    getPseudoCodeList: PropTypes.func,
    isOpenRemoveModal: PropTypes.bool,
    modalElement: PropTypes.object,
    closeRemoveModal: PropTypes.func,
    deleteDiagramElement: PropTypes.func,
    removeDeclaration: PropTypes.func,
    removeParameter: PropTypes.func,
    removeLocalDeclaration: PropTypes.func,
    deleteValidity: PropTypes.func,
    removeSignature: PropTypes.func,
    removeProcedure: PropTypes.func,
    removeOtxOrPackage: PropTypes.func,
    deleteImport: PropTypes.func,
    deleteThrow: PropTypes.func,
    resetHeaderData: PropTypes.func,
    isActiveLoader: PropTypes.bool,
    isOpenEditModal: PropTypes.bool,
    closeEditModal: PropTypes.func,
    updateDiagramElement: PropTypes.func,
    createDiagramElement: PropTypes.func,
    diagramList: PropTypes.array,
    updateDiagramList: PropTypes.func,
    openEditModal: PropTypes.func,
    updatePseudoCodeListTime: PropTypes.number,
    updatePseudoCodeList: PropTypes.func,
    selectedContextViewId: PropTypes.any,
    setSelectedContextViewId: PropTypes.func,
    updateDiagramAfterDeleteElement: PropTypes.func,
    getStateDiagram: PropTypes.func,
    // props from parent
    params: PropTypes.object,
    updateScroll: PropTypes.func
  }

  componentDidMount () {
    const { params, getStateDiagram } = this.props
    if (typeof params.procedureId !== 'undefined') {
      this.getDiagramList()
    }
    if (typeof params.otxFileId !== 'undefined') {
      this.getPseudoCodeList()
      getStateDiagram(params.projectId, params.otxFileId)
    }
  }

  componentDidUpdate (prevProps) {
    if (typeof this.props.params.procedureId !== 'undefined') {
      const {
        updateDiagramListTime,
        params
      } = this.props
      if (updateDiagramListTime > prevProps.updateDiagramListTime) {
        this.getDiagramList()
      }
      if (!isEqual(params, prevProps.params)) {
        if (!isEqual(params.procedureId, prevProps.params.procedureId)) {
          this.getDiagramList()
        } else if (!isEqual(params.procedureElementId, prevProps.params.procedureElementId)) {
          this.getDiagramList()
        }
      }
    }
    if (typeof this.props.params.otxFileId !== 'undefined') {
      const {
        updateDiagramListTime,
        updatePseudoCodeListTime,
        params,
        getStateDiagram
      } = this.props
      if (updateDiagramListTime > prevProps.updateDiagramListTime ||
          updatePseudoCodeListTime > prevProps.updatePseudoCodeListTime ||
          !isEqual(params.otxFileId, prevProps.params.otxFileId)) {
        this.getPseudoCodeList()
      }
      if (!isEqual(params.otxFileId, prevProps.params.otxFileId)) {
        getStateDiagram(params.projectId, params.otxFileId)
      }
    }
  }

  getPseudoCodeList () {
    const { loaderControl, getPseudoCodeList, params } = this.props
    loaderControl(true)
    getPseudoCodeList(params.projectId, params.otxFileId)
  }

  getDiagramList (shouldGetAttributes = true, shouldUpdateDiagram = true) {
    const { loaderControl, getDiagramList, params } = this.props
    loaderControl(true)
    getDiagramList(
      params.projectId,
      params.otxFileId,
      typeof params.procedureElementId !== 'undefined'
      ? `${params.procedureId}/nodes/${params.procedureElementId}` : params.procedureId,
      typeof params.selectedElementId !== 'undefined'
      ? params.selectedElementId
      : typeof params.procedureElementId !== 'undefined' ? params.procedureElementId : null,
      shouldGetAttributes,
      shouldUpdateDiagram)
  }

  deleteElement = () => {
    const {
      params, modalElement, loaderControl, updateScroll, headerData,
      deleteDiagramElement, removeDeclaration, removeParameter, removeLocalDeclaration, deleteValidity,
      removeSignature, removeProcedure, removeOtxOrPackage, deleteImport, deleteThrow,
      updateDiagramAfterDeleteElement, updatePseudoCodeList
    } = this.props

    loaderControl(true)
    updateScroll(false)

    if (modalElement && modalElement.otx_id) {
      if (isSupportedElement(modalElement.type)) {
        this.checkId(modalElement, headerData.otx_id)
        const shoudChangeUrl = this.shoudChangeUrlHandler(modalElement)
        deleteDiagramElement(
          params.projectId,
          params.otxFileId,
          modalElement.procedureId !== undefined
          ? modalElement.procedureId : params.procedureId,
          modalElement.otx_id)
          .then(() => {
            if (shoudChangeUrl) {
              let url = `/project/${params.projectId}/otx/${params.otxFileId}/procedure/${params.procedureId}`
              if (isEqual(modalElement.otx_id, params.selectedElementId) &&
                  typeof params.procedureElementId !== 'undefined' &&
                  !isEqual(modalElement.otx_id, params.procedureElementId) &&
                  modalElement.lastParentFlowElement === undefined) {
                url += `/element/${params.procedureElementId}/selectedElement/${params.procedureElementId}`

                updateDiagramAfterDeleteElement()
              } else if (modalElement.lastParentFlowElement !== undefined &&
                         modalElement.lastParentFlowElement !== null) {
                url +=
                  `/element/${modalElement.lastParentFlowElement}/selectedElement/${modalElement.lastParentFlowElement}`

                !isEqual(modalElement.lastParentFlowElement, params.procedureElementId)
                ? updatePseudoCodeList()
                : updateDiagramAfterDeleteElement()
              } else {
                typeof params.selectedElementId !== 'undefined' && typeof params.procedureElementId === 'undefined'
                ? updateDiagramAfterDeleteElement()
                : updatePseudoCodeList()
              }
              browserHistory.push(url)
            } else {
              updateDiagramAfterDeleteElement()
            }
          })
      } else if (isGlobalDeclaration(modalElement)) {
          removeDeclaration(params.projectId, params.otxFileId, modalElement.otx_id)
          .then(() => {
            this.selectedContextViewIdHandler(modalElement.otx_id)
          })
      } else if (isParameter(modalElement)) {
        removeParameter(params.projectId,
                        params.otxFileId,
                        modalElement,
                        headerData.type === 'Procedure',
                        modalElement.signatureId)
                        .then(() => {
                          this.selectedContextViewIdHandler(modalElement.otx_id)
                        })
      } else if (isLocalDeclaration(modalElement)) {
        removeLocalDeclaration(params.projectId,
                               params.otxFileId,
                               modalElement.procedureId,
                               modalElement.otx_id)
                               .then(() => {
                                this.selectedContextViewIdHandler(modalElement.otx_id)
                              })
      } else if (modalElement.type === 'Validity') {
        deleteValidity(params.projectId, params.otxFileId, modalElement.otx_id)
        .then(() => {
          this.selectedContextViewIdHandler(modalElement.otx_id)
        })
      } else if (modalElement.type === 'Signature') {
        removeSignature(params.projectId,
                        params.otxFileId,
                        modalElement.otx_id)
                        .then(() => {
                          this.selectedContextViewIdHandler(modalElement.otx_id)
                        })
      } else if (modalElement.type === 'Procedure') {
        removeProcedure(params.projectId,
                        params.otxFileId,
                        modalElement.otx_id)
                        .then(() => {
                          if (isEqual(modalElement.otx_id, params.procedureId)) {
                            const url = `/project/${params.projectId}/otx/${params.otxFileId}`
                            browserHistory.push(url)
                          }
                        })
      }
    } else if ((modalElement.name && modalElement.name.includes('.otx')) ||
                (modalElement.itemType !== undefined && modalElement.itemType === 'PACKAGE')) {
      const parentId = modalElement.parents.length > 1
      ? modalElement.parents[modalElement.parents.length - 1]
      : 0
      if (modalElement.id === params.otxFileId || findNested(modalElement.children, params.otxFileId)) {
        browserHistory.push(`/project/${params.projectId}`)
      }
      removeOtxOrPackage(params.projectId,
                    modalElement.id,
                    parentId,
                    modalElement.id === params.otxFileId)
    } else if (modalElement.type === 'Import') {
      deleteImport(params.projectId, params.otxFileId, modalElement.document)
      .then(() => {
        this.selectedContextViewIdHandler(modalElement.id)
      })
    } else if (throwTypes.includes(modalElement.name)) {
      deleteThrow(params.projectId, params.otxFileId, modalElement,
                  headerData.type === 'Procedure', modalElement.signatureId)
                  .then(() => {
                    this.selectedContextViewIdHandler(modalElement.id)
                  })
    }
  }

  selectedContextViewIdHandler = elId => {
    const { selectedContextViewId, params, setSelectedContextViewId } = this.props
    if (isEqual(selectedContextViewId, elId)) {
      let newSelectedId = null
      typeof params.selectedElementId !== 'undefined'
      ? (newSelectedId = params.selectedElementId)
      : typeof params.procedureId !== 'undefined' && (newSelectedId = params.procedureId)
      setSelectedContextViewId(newSelectedId)
    }
  }

  shoudChangeUrlHandler (element) {
    const { params } = this.props
      let shoudChangeUrl = false
      const loop = el => {
        if (typeof params.selectedElementId !== 'undefined' &&
            el.otx_id &&
            params.selectedElementId === el.otx_id) {
          shoudChangeUrl = true
        } else if (el.children && el.children.length > 0 && !shoudChangeUrl) {
          el.children.forEach(item => loop(item))
        }
      }
      loop(element)
      return shoudChangeUrl
  }

  checkId = (arr, id) => {
    if (arr.otx_id === id) {
      this.props.resetHeaderData()
    } else if (arr.children.length) {
      for (let i = 0; i < arr.children.length; i++) {
        this.checkId(arr.children[i], id)
      }
    }
  }

  setModalText = () => {
    const { modalElement } = this.props
    const { deleteModal } = labels.modalPattern
    let text = ''
    if (modalElement) {
      if (modalElement.name && modalElement.name.indexOf('.otx') !== -1) {
        text += `${modalElement.name} document`
      } else if (modalElement.type === 'Validity') {
        text += `${modalElement.name} validity`
      } else if (
        modalElement.type === 'InParameter' ||
        modalElement.type === 'InOutParameter' ||
        modalElement.type === 'OutParameter'
      ) {
        text += `${modalElement.name} parameter`
      } else if (modalElement.type === 'Signature') {
        text += `${modalElement.name} signature`
      } else if (throwTypes.includes(modalElement.name)) {
        text += `${modalElement.type} throw`
      } else if (modalElement.type) {
        text += `${modalElement.otx_name && modalElement.otx_name !== null
          ? `${modalElement.otx_name} ${modalElement.type}`
          : modalElement.type}`
      } else if (modalElement.itemType === 'PACKAGE') {
        text += `${modalElement.itemType.toLowerCase()}: ${modalElement.name}`
        if (!isEmpty(modalElement.children)) {
          text += ` ${deleteModal.package[modalElement.children.length > 1 ? 1 : 0]}`
        }
      }
    }

    return `${deleteModal.confirmation} ${text}?`
  }

  saveModal = itemToAdd => {
    const { params, loaderControl, updateScroll, updateDiagramElement, createDiagramElement, headerData } = this.props
    const { modalPlaceToAdd, position } = this.state
    updateScroll(false)
    if (isSupportedElement(itemToAdd.type)) {
      loaderControl(true)
      if (itemToAdd.isEdit) {
        updateDiagramElement(
          params.projectId,
          params.otxFileId,
          params.procedureId,
          itemToAdd.otx_id,
          null,
          0,
          itemToAdd
        )
      } else if (modalPlaceToAdd && modalPlaceToAdd.parentId) {
        let parentId = modalPlaceToAdd.parentId.split('/')
        if (parentId.length > 1) { parentId = parentId[parentId.length - 1] }
          createDiagramElement(
            params.projectId,
            params.otxFileId,
            params.procedureId,
            position ? parentId : modalPlaceToAdd.key,
            position ? position === 'top'
            ? modalPlaceToAdd.position : modalPlaceToAdd.position + 1 : 0,
              itemToAdd)
      } else {
            createDiagramElement(
              params.projectId,
              params.otxFileId,
              params.procedureId,
              headerData.type === 'Procedure' ? params.procedureId : headerData.otx_id,
              0,
              itemToAdd)
      }
    }
  }

  openModal = (itemToAdd, placeToAdd, position) => {
    this.setState({
      modalPlaceToAdd: placeToAdd,
      position
    })
    this.props.openEditModal(itemToAdd)
  }

  switchView = componentToDisplay => {
    if (this.state.componentToDisplay !== componentToDisplay) {
      if (componentToDisplay !== 'stateDiagram') {
        this.props.loaderControl(true)
        this.props.updateScroll()
      }
      setTimeout(() => {
        this.setState({ componentToDisplay })
      }, 0)
    }
  }

  displayComponentHander () {
    const { componentToDisplay } = this.state
    const { params } = this.props
    switch (componentToDisplay) {
      case 'stateDiagram':
        return <StateDiagramViewComponent params={params}/>
      case 'pseudoCode':
        return <PseudocodeComponent params={params} />
      default:
        return <DiagramComponent params={params} openModal={this.openModal} />
    }
  }

  render () {
    const { params, isOpenEditModal, isOpenRemoveModal, closeRemoveModal, modalElement, isActiveLoader } = this.props
    const isDisabledButton = typeof params.otxFileId === 'undefined' || isActiveLoader
    const { componentToDisplay } = this.state
    return (
      <div className='main-view'>
        <div className='main-view__toolbar'>
          <Button.Group>
            {Object.entries(buttons).map(([key, value]) =>
              <Button
                compact
                key={key}
                style={{ fontSize: '0.9rem' }}
                onClick={() => this.switchView(key)}
                className={`${componentToDisplay === key ? 'main-view__toolbar-active' : ''}`}
                disabled={isDisabledButton}
                content={value} />)}
          </Button.Group>
        </div>
        <div className='main-view__content'>
          {this.displayComponentHander()}
        </div>
        {isOpenRemoveModal &&
          <ModalComponent
            isOpen={isOpenRemoveModal}
            modalType='delete'
            closeModal={closeRemoveModal}
            modalTitle={labels.modalPattern.deleteModal.title}
            modalText={this.setModalText()}
            deleteElement={this.deleteElement} />}
        {isOpenEditModal &&
          <WizardPattern
            isOpen={isOpenEditModal}
            closeModal={this.props.closeEditModal}
            saveModal={this.saveModal}
            modalItemToAdd={modalElement}
            params={params} />}
      </div>
    )
  }
}

export default MainViewComponent
