import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Item, animation, Submenu } from 'react-contexify'
import { contextMenuListHandler } from '../../constants/common'

const contextMenu = ({ id, node, menuContextActionHandler }) => {
  return (
    <Menu id={id} animation={animation.fade}>
      {contextMenuListHandler(node).map((item, index) => item.label && item.children
      ? <Submenu
      label={item.label}
      id={`subMenu-${index}`}>
        {item.children && item.children.map((el, i) =>
          <Item
            key={i}
            id={`subMenuItem-${index}-${i}`}
            onClick={() => menuContextActionHandler(el, node)}>
            {el.text}
          </Item>)}
      </Submenu>
      : <Item
        key={index}
        id={`item-${index}`}
        onClick={() => menuContextActionHandler(item, node)}>
        {item.text}
      </Item>)}
    </Menu>
  )
}

contextMenu.propTypes = {
  id: PropTypes.string,
  node: PropTypes.objec,
  menuContextActionHandler: PropTypes.func
}

export const MemoizedContextMenu = React.memo(contextMenu)
