import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { isEmpty, isEqual } from 'lodash'
import { inheritsFromFlow, isDiagramElement } from '../../../../constants/common'
import './SingleLineComponent.scss'

class SingleLineComponent extends Component {
  static propTypes = {
    // props from Redux
    updateScroll: PropTypes.func,
    setBreakPoint: PropTypes.func,
    removeBreakPoint: PropTypes.func,
    loaderControl: PropTypes.func,
    reachedBreakPoint: PropTypes.string,
    isProcedureRunning: PropTypes.bool,
    setSelectedContextViewId: PropTypes.func,
    // props from parent
    params: PropTypes.object,
    singleLine: PropTypes.object,
    selectedContextViewId: PropTypes.any,
    isTesterRole: PropTypes.bool
  }

  backgroundSetter = el => {
    const { isProcedureRunning, reachedBreakPoint, selectedContextViewId } = this.props
    let color = '#ffffff'
    if (!isProcedureRunning && !isEmpty(el.id) &&
        el.id === selectedContextViewId) {
      color = '#8bc9e8'
    } else if (isProcedureRunning && !isEmpty(el.id) && isEqual(reachedBreakPoint, el.id)) {
      color = '#ffd23f'
    } else if (el.index % 2 !== 0) {
      color = '#fafafa'
    }
    return color
  }

  selectElement = el => {
    const { isProcedureRunning, selectedContextViewId, setSelectedContextViewId } = this.props
    if (!isProcedureRunning && !isEmpty(el.id)) {
      this.props.updateScroll(false)
      if (isDiagramElement(el.type) && (!isEmpty(el.procedureId) || el.type === 'Procedure')) {
        const { params } = this.props
        let shouldUpdateLink = true
        let url = `/project/${params.projectId}/otx/${params.otxFileId}`
        if ((el.id !== params.procedureId && el.type === 'Procedure') ||
            (el.id === params.procedureId && params.selectedElementId)) {
            url += `/procedure/${el.id}`
        } else if (el.type !== 'Procedure' && el.id === params.selectedElementId) {
          url += `/procedure/${el.procedureId}`
          if (el.id !== selectedContextViewId) {
            shouldUpdateLink = false
            setSelectedContextViewId(el.id)
          }
        } else if (el.type !== 'Procedure' && el.id !== params.selectedElementId) {
          if (inheritsFromFlow(el.type)) {
            url += `/procedure/${el.procedureId}/element/${el.id}/selectedElement/${el.id}`
          } else if (el.parentId) {
            url += `/procedure/${el.procedureId}/element/${el.parentId}/selectedElement/${el.id}`
          } else {
            url += `/procedure/${el.procedureId}/selectedElement/${el.id}`
          }
        }
        shouldUpdateLink && browserHistory.push(url)
      } else {
        setSelectedContextViewId(!isEqual(el.id, selectedContextViewId) ? el.id : null)
      }
    }
  }

  breakpointHandler = item => {
    const { params } = this.props
    if (item.breakpoint === 'UNSET') {
      this.props.loaderControl(true)
      this.props.setBreakPoint(params.projectId, params.otxFileId, item.id, item.procedureId)
    } else {
      this.props.loaderControl(true)
      this.props.removeBreakPoint(params.projectId, params.otxFileId, item.id, item.procedureId)
    }
  }

  render () {
    const { singleLine, selectedContextViewId, isTesterRole } = this.props
    const breakpoint = singleLine.breakpoint
    const hoverClass = !isEmpty(singleLine.id)
    ? selectedContextViewId === singleLine.id
      ? 'add-hover-selected' : 'add-hover'
    : ''
    return (
      <div
        key={singleLine.index }
        style={{ backgroundColor: this.backgroundSetter(singleLine) }}
        className={`singleline ${hoverClass}`} >
        <div>
          <span>{singleLine.index + 1}</span>
        </div>
        <div
          className='singleline__breakpoint' >
          {breakpoint !== 'UNAVAILABLE' &&
            <span
              onClick={() => !isTesterRole && this.breakpointHandler(singleLine)}
              className={`singleline__breakpoint-${breakpoint === 'SET' ? 'is-set' : 'is-unset'}`} />}
        </div>
        <div
          style={{
            display: 'flex',
            paddingLeft: (singleLine.indent * 25) + 10,
            width: 'calc(100% - 49px)'
          }}
          onClick={() => this.selectElement(singleLine)} >
          {!isEmpty(singleLine.content) && singleLine.content.map((el, i) =>
            <span
              style={{
                whiteSpace: 'pre',
                color: el.color,
                fontWeight: el.bold ? 'bold' : 'normal'
              }}
              key={singleLine.index + i}>
              {el.text}
            </span>
          )}
        </div>
      </div>)
  }
}

export default SingleLineComponent
