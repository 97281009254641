import React from 'react'
import { array } from 'prop-types'
import ValidationLogs from '../../../Utilities/ValidationLogs'

const BeforePasteComponent = ({ descriptions, logs }) => (
  <div>
    {descriptions[0] && <div
      style={{ paddingBottom: 10 }}
      className='universalModalComponent__content-description'>
      {descriptions[0]}
    </div>}
    <div className='universalModalComponent__content-logs'>
      <ValidationLogs logs={logs} />
    </div>
    {descriptions[1] && <div
      className='universalModalComponent__content-description'
      style={{ paddingTop: 5, display: 'flex', justifyContent: 'center' }}>
      {descriptions[1]}
    </div>}
  </div>
)

BeforePasteComponent.propTypes = {
  logs: array,
  descriptions: array
}

export default BeforePasteComponent
