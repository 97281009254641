import {
  isValidInteger,
  isValidFloat,
  isValidOptionalInteger,
  isValidOptionalFloat
} from './numbers'
import {
  shouldValidateFloat,
  shouldValidateNumber,
  shouldValidateString,
  shouldValidateBoolean
} from './valueTypes'
import { isValidString, isValidStringOptional } from './strings'
import isEmpty from 'lodash/isEmpty'

export const validateValueByType = (value, type, stateName = null) => {
  const isOptional = isOptionalFieldHandler(stateName)
  if (shouldValidateNumber(type)) {
    if (isOptional) {
      return isValidOptionalInteger(value)
    }
    return isValidInteger(value)
  } else if (shouldValidateString(type)) {
    if (isOptional) {
      return isValidStringOptional(value)
    }
    return isValidString(value)
  } else if (shouldValidateFloat(type)) {
    if (isOptional) {
      return isValidOptionalFloat(value)
    }
    return isValidFloat(value)
  } else if (shouldValidateBoolean(type)) {
    return value.toString() === 'true' ||
    value.toString() === 'false' ||
    (isOptional && value === '')
  } else {
    return true
  }
}

export const isOptionalFieldHandler = stateName => {
  const optionalfields = [
    'defaultTerm',
    'initialValueTerm',
    'instructionTerm',
    'InvokeActivity',
    'InvokeActivityAndWait',
    'titleTerm',
    'messageOptionalTerm',
    'restrictionTerm',
    'commandTerm',
    'editorTermArrayOptinal'
  ]
  return !isEmpty(stateName) && optionalfields.includes(stateName)
}

export const checkIsValidListHandler = listToCheck => {
  const nonValidItems = listToCheck.find(el => {
    if (el.key && el.value) {
      return el.key.isValid === false || el.value.isValid === false
    } else {
      return el.isValid === false
    }
  })
  return !isEmpty(listToCheck) && isEmpty(nonValidItems)
}
