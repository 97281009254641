import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { DndProvider, createDndContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import MainViewComponent from '../../components/MainViewComponent'

const diagram = props => {
  const manager = useRef(createDndContext(HTML5Backend))
  return (
    <DndProvider manager={manager.current.dragDropManager}>
      <MainViewComponent params={props.params} />
    </DndProvider>
  )
}

diagram.propTypes = {
  params: PropTypes.object
}

export default diagram
