import { isNull, isDiagramEnded, isLoop, isCondition, isSsmExtention } from '../constants/common'
import {
  logElements,
  bitFieldModifierList,
  listModifierList,
  mapModifierList,
  exceptionValueOfList
} from './ItemTypes'
import * as termTypes from '../utilites/terms/terms'
import terms from '../../public/terms.json'
import isEmpty from 'lodash/isEmpty'
import { summaryLineLabels, diagram } from '../../public/labels.json'

const termsWithObligatoryChildren = termTypes.termsWithObligatoryChildren(terms)

const placeholder = 'NOT SUPPORTED ELEMENT'
const NOT_DEFINED = 'NOT DEFINED'
const notSupportedTermType = 'NOT SUPPORTED TYPE'

export const summaryLineCreatorHandler = item => {
  if (item.type === 'ProcedureCall') {
    const summaryLine = createProcedureCallSummaryLine(item)
    return summaryLine
  } else if (item.type === 'Assignment') {
    const summaryLine = createAssignmentSummaryLine(item)
    return summaryLine
  } else if (isLogElement(item.type)) {
    const summaryLine = createLogSummaryLine(item)
    return summaryLine
  } else if (isListModifier(item.type)) {
    const summaryLine = createListSummaryLine(item)
    return summaryLine
  } else if (isMapModifier(item.type)) {
    const summaryLine = createMapSummaryLine(item)
    return summaryLine
  } else if (isBitFieldModifier(item.type)) {
    const summaryLine = createBitFieldSummaryLine(item)
    return summaryLine
  } else if (isSsmExtention(item.type)) {
    const summaryLine = createSsmSummaryLine(item)
    return summaryLine
  } else if (item.type === 'CatchingFlow') {
    return createCatchingFlowSummaryLine(item)
  } else if (isDiagramEnded(item.type)) {
    const summaryLine = createEndSummaryLine(item)
    return summaryLine
  } else if (isLoop(item.type)) {
    const summaryLine = createLoopSummaryLine(item)
    return summaryLine
  } else if (isCondition(item.type)) {
    const summaryLine = createConditionSummaryLine(item)
    return summaryLine
  } else {
    return [item.otx_name || item.type]
  }
}

export const isListModifier = itemType => listModifierList.includes(itemType)

export const isMapModifier = itemType => mapModifierList.includes(itemType)

export const isBitFieldModifier = itemType => bitFieldModifierList.includes(itemType)

export const isLogElement = itemType => logElements.includes(itemType)

export const createProcedureCallSummaryLine = item => {
    const summaryLine = []
    if (item.calledProcedureName && !isEmpty(item.calledProcedureName)) {
      summaryLine.push(`${summaryLineLabels.calledProcedure}: ${item.calledProcedureName}`)
    }
    if (item.inArguments && !isEmpty(item.inArguments)) {
      summaryLine.push(procedureCallListCreator(item.inArguments, 'inArguments'))
    }
    if (item.outArguments && !isEmpty(item.outArguments)) {
      summaryLine.push(procedureCallListCreator(item.outArguments, 'outArguments'))
    }
    if (item.inOutArguments && !isEmpty(item.inOutArguments)) {
      summaryLine.push(procedureCallListCreator(item.inOutArguments, 'inOutArguments'))
    }
    return summaryLine
}

const procedureCallListCreator = (list, type) => {
  let text = ''
  text += `${summaryLineLabels[type]}: [ `
  list.forEach((argument, i) => {
    if (argument.param !== undefined) {
      text += `${i !== 0 ? ', ' : ''}${argument.param}`
    }
    if (argument.variable !== undefined) {
      text += ` = ${summaryLineHandler(argument.variable)}`
    }
    if (argument.term !== undefined) {
      text += ` = ${summaryLineHandler(argument.term)}`
    }
  })
  text += ' ]'
  return text
}

export const createAssignmentSummaryLine = item => {
  const summaryLine = `${diagram.assign}: ${item.content && item.content.length > 1 ? (item.content[0]
  ? item.content[0].name : '') +
  ' = ' + (item.content[1] ? summaryLineHandler(item.content[1]) : '') : diagram.redifine}`
  return [summaryLine]
}

export const createListSummaryLine = item => {
  const summaryLine = []
  const listName = item.list && item.list.name ? item.list.name : NOT_DEFINED
  if (item.type === 'ListClear') {
      summaryLine.push(`${summaryLineLabels.list}: ${listName}`)
  } else if (item.type === 'ListRemove') {
      summaryLine.push(`${summaryLineLabels.list}: ${listName}`)
      summaryLine.push(`${summaryLineLabels.index}: ${!isEmpty(item.index)
        ? summaryLineHandler(item.index) : NOT_DEFINED}`)
      summaryLine.push(`${summaryLineLabels.count}: ${!isEmpty(item.count)
        ? summaryLineHandler(item.count) : NOT_DEFINED}`)
  } else if (item.type === 'ListAppend') {
      let elementsToAppend = ''
      if (isEmpty(item.item)) {
        elementsToAppend = NOT_DEFINED
      } else {
        elementsToAppend += itemsListCreator(item.item)
      }
      summaryLine.push(`${listName}: [ ${elementsToAppend} ]`)
  } else if (item.type === 'ListInsert') {
      let elementsToInsert = ''
      if (isEmpty(item.item)) {
        elementsToInsert = NOT_DEFINED
      } else {
        elementsToInsert += itemsListCreator(item.item)
      }
      summaryLine.push(`${summaryLineLabels.list}: ${listName}`)
      summaryLine.push(`${summaryLineLabels.index}: ${!isEmpty(item.index)
        ? summaryLineHandler(item.index) : NOT_DEFINED}`)
      summaryLine.push(`${summaryLineLabels.items}: [ ${elementsToInsert} ]`)
  } else if (item.type === 'ListConcatenate') {
      let listsToConcatenate = ''
      if (isEmpty(item.otherList)) {
        listsToConcatenate = NOT_DEFINED
      } else {
        listsToConcatenate += `[ ${itemsListCreator(item.otherList)} ]`
      }
      summaryLine.push(`${summaryLineLabels.list}: ${listName}`)
      summaryLine.push(`${summaryLineLabels.listToConcatenate}: ${listsToConcatenate}`)
  }
  return summaryLine
}

export const createLogSummaryLine = item => {
  const summaryLine = []
    switch (item.type) {
      case 'WriteLog':
        summaryLine.push(`${summaryLineLabels.message}: ${!isEmpty(item.message)
          ? summaryLineHandler(item.message)
          : NOT_DEFINED}`)
        break
      case 'ChoiceDialog':
        summaryLine.push(`${!isEmpty(item.options)
          ? `${summaryLineLabels.option}: ${summaryLineHandler(item.options)}` : NOT_DEFINED}`)
        summaryLine.push(`${summaryLineLabels.result}: ${!isEmpty(item.result) && item.result.name !== undefined
        ? item.result.name : NOT_DEFINED}`)
        break
      case 'InputDialog':
        summaryLine.push(`${summaryLineLabels.result}: ${!isEmpty(item.result) && item.result.name !== undefined
        ? item.result.name : NOT_DEFINED}`)
        break
      case 'ConfirmDialog':
        if (item.title && item.title.value) {
          summaryLine.push(`${summaryLineLabels.title}: ${item.title.value}`)
        }
        if (item.message && item.message.value) {
          summaryLine.push(`${summaryLineLabels.message}: ${item.message.value}`)
        }
        if (isEmpty(summaryLine)) {
          summaryLine.push(summaryLineLabels.blank)
        }
        break
  }
    return summaryLine
}

export const createMapSummaryLine = item => {
    const modifiedMapName = item.map.name
    let text = ''
    const result = [`Map: ${modifiedMapName}`]
    let arrayName = ''
    if (item.type === 'MapUnion') {
        arrayName = 'otherMap'
    } else if (item.type === 'MapPut') {
        arrayName = 'item'
    } else if (item.type === 'MapClear') {
        return result
    } else if (item.type === 'MapRemove') {
        arrayName = 'keys'
    }
    if (!isEmpty(item[arrayName])) {
      text = summaryLineLabels[arrayName]
      result.push(`${text}: [ ${itemsListCreator(item[arrayName])} ]`)
    }
    return result
}

export const createBitFieldSummaryLine = item => {
    let summaryLine = ''
    if (item.type === 'BitSet') {
        summaryLine = placeholder
    } else if (item.type === 'BitShiftLeft') {
        summaryLine = placeholder
    } else if (item.type === 'BitShiftRight') {
        summaryLine = placeholder
    } else if (item.type === 'BitAppend') {
        summaryLine = placeholder
    } else if (item.type === 'BitReplace') {
        summaryLine = placeholder
    }
    return [summaryLine]
}

export const createSsmSummaryLine = item => {
    const summaryLine = []
    if (item.type === 'AddDataListener' && item.mappings.length) {
        let addDataListenerSummary = `${summaryLineLabels.ssmMapping}: [ `
        item.mappings.map((mapping, i) => {
          addDataListenerSummary += `${i > 0 ? ', ' : ''}${mapping.variableName} : ${mapping.ssmName}`
        })
        addDataListenerSummary += ' ]'
        summaryLine.push(addDataListenerSummary)
    } else if (item.type === 'AddPackageListener') {
        summaryLine.push(`${summaryLineLabels.result}: ${item.result.name}`)
    } else if (item.type === 'InvokeActivity' || item.type === 'InvokeActivityAndWait') {
        const resultVar = isNull(item.resultState)
        ? NOT_DEFINED : item.resultState.name
        summaryLine.push(`${summaryLineLabels.ssmActivity}: ${resultVar}`)
        summaryLine.push(`${summaryLineLabels.activity}: ${item.name}`)
        if (item.arguments && !isEmpty(item.arguments)) {
          summaryLine.push(`${summaryLineLabels.ssmArguments}: ${getSsmActivityArgs(item)}`)
        }
    } else if (item.type === 'TerminateActivity') {
        summaryLine.push(`${summaryLineLabels.activity}: ${item.name}`)
    } else if (item.type === 'GetActivityState') {
        const resultState = item.activityState.name ? item.activityState.name : NOT_DEFINED
        const ssmName = item.name
        summaryLine.push(`${resultState} : ${ssmName}`)
    } else if ((item.type === 'Connect' || item.type === 'Disconnect')) {
        const resultVar = item.result !== null ? item.result.name : NOT_DEFINED
        summaryLine.push(`${summaryLineLabels.result}: ${resultVar}`)
    } else if (item.type === 'Wait') {
      summaryLine.push(`${summaryLineLabels.timeout}: ${summaryLineHandler(item.timeout)}`)
    } else if (item.type === 'AddEventListener') {
      summaryLine.push(`${item.mapping.variableName} : ${item.mapping.ssmName}`)
    } else if (item.type === 'ServerCommand') {
      summaryLine.push(`${summaryLineLabels.command}: ${summaryLineHandler(item.command)}`)
    }
    return summaryLine
}

export const getSsmActivityArgs = item => {
  let result = ''
  if (item.arguments && !isEmpty(item.arguments)) {
    result += '[ '
    item.arguments.forEach((argument, i) => {
      result += `${i > 0 ? ', ' : ''}${argument.ssmName}: ${
        argument.term !== null &&
        argument.term.type &&
        argument.term.type !== 'NULL_OBJECT'
          ? summaryLineHandler(argument.term)
          : NOT_DEFINED}`
    })
    result += ' ]'
  } else {
    result = NOT_DEFINED
  }
  return result
}

export const createCatchingFlowSummaryLine = item => {
    let result = ''
    const exceptionType = item.realisation.exceptionType.type
    let variableName = ''
    if (item.realisation.handle && item.realisation.handle.name) {
        variableName = item.realisation.handle.name
    }
    result += exceptionType
    if (variableName) {
        result += `: ${variableName}`
    }
    return [result]
}

export const createEndSummaryLine = item => {
    if (item.type === 'Break' || item.type === 'Continue') {
        return [item.target === '' ? summaryLineLabels.noTarget : `${summaryLineLabels.target}: ${item.target}`]
    } else if (item.type === 'Throw') {
        const result = createThrowSummaryLine(item)
        return [result]
    } else {
      return [item.type]
    }
}

export const createThrowSummaryLine = item => {
    if (item && item.realisation) {
        if (item.realisation.type === 'UserExceptionLiteral') {
            return `${summaryLineLabels.userException}: ${item.realisation.qualifier}, ${item.realisation.text}`
        } else {
            return item.realisation.valueOf === '' ? NOT_DEFINED : `Exception : ${item.realisation.valueOf}`
        }
    }
}

export const createLoopSummaryLine = item => {
    if (item.type === 'WhileLoop' && item.test) {
    let result = ''
    result += showTermType(item.test)
    return [result]
    } else if (item.type === 'ForLoop') {
        let result = ''
        item.configuration.map((param, i) => {
          if (i === 0) {
            result += `${summaryLineHandler(param)} `
          } else if (i === 1) {
            result += `(${summaryLineHandler(param)}, `
          } else {
            result += `${summaryLineHandler(param)})`
          }
        })
        return [result]
    } else if (item.type === 'ForEachLoop' && item.collection) {
        let result = ''
        if (item.collection) {
          result += summaryLineHandler(item.collection)
        }
        if (item.locator) {
          result += ` <- ${summaryLineHandler(item.locator)}`
        }
        return [result]
    }
}

export const createConditionSummaryLine = item => {
    let result = ''
    if (item.type === 'If' || item.type === 'ElseIf') {
        if (!isEmpty(item.condition)) {
          result += showTermType(item.condition)
        }
    }
    return [result]
}

export const summaryLineHandler = term => {
  let result = ''
  if (term.type) {
    if (termsWithObligatoryChildren.includes(term.type)) {
        result = `${term.type}(${itemsListCreator(term.children)})`
        return result
    } else if (term.type.includes('Value') &&
              term.valueOf !== undefined &&
              !exceptionValueOfList.includes(term.type)) {
      return term.valueOf
    } else if (term.type.includes('Literal') && term.value !== undefined) {
      return term.value
    } else if (term.term !== undefined) {
      result = `${term.type}(${!isEmpty(term.term) ? summaryLineHandler(term.term) : NOT_DEFINED})`
      return result
    } else if (term.name !== undefined && term.type.includes('Variable')) {
      return term.name
    } else {
     result = advancedTypesTermCreator(term)
     return result
    }
  } else {
    return JSON.stringify(term)
  }
}

const advancedTypesTermCreator = term => {
  let result = ''
  switch (term.type) {
    case 'Round':
      result = `${term.type}(${!isEmpty(term.numeral) ? summaryLineHandler(term.numeral) : NOT_DEFINED})`
      return result
    case 'ListLiteral':
      result += term.type
      if (term.itemList && !isEmpty(term.itemList)) {
        result += `: [ ${itemsListCreator(term.itemList)} ]`
      }
      return result
    case 'MapLiteral':
        result += term.type
      if (term.items && !isEmpty(term.items)) {
        result += `: { ${itemsListCreator(term.items)} }`
      }
      return result
    case 'MapItem':
      if (term.key && !isEmpty(term.key)) {
        result += summaryLineHandler(term.key)
      }
      if (term.value && !isEmpty(term.value)) {
        result += `: ${summaryLineHandler(term.value)}`
      }
      return result
    case 'DecodeInteger':
      result = `${term.type}(${summaryLineHandler(term.bytes)}, ${term.encodingType}, ${term.byteOrder})`
      return result
    case 'EncodeInteger':
      result = `${term.type}(${summaryLineHandler(term.integer)}` +
      `, ${term.encodingType}, ${term.byteOrder}, ${term.encodingSize})`
      return result
    case 'ExceptionValue':
    case 'GetActivityStateName':
    case 'GetConnectionSutName':
    case 'GetConnectionSutStatus':
    case 'GetConnectionSutVersion':
    case 'GetEventName':
    case 'GetEventTimestamp':
    case 'GetReportingDataEngineeringValue':
    case 'GetReportingDataItemType':
    case 'GetReportingDataLimitsCheck':
    case 'GetReportingDataLoadedFromPacket':
    case 'GetReportingDataRawValue':
    case 'GetReportingDataSsmName':
    case 'GetReportingDataState':
    case 'GetReportingDataTimestamp':
    case 'IsConnectionActive':
      result = `${term.type}(${term.valueOf})`
      return result
    case 'ListContainsValue':
      result = `${term.type}(${summaryLineHandler(term.list)}, ${summaryLineHandler(term.value)})`
      return result
    case 'ListCopy':
      result = `${term.type}(${summaryLineHandler(term.otherList)})`
      return result
    case 'ListCreate':
      result = term.type
      if (term.items && !isEmpty(term.items)) {
        result += `(${itemsListCreator(term.items)})`
      }
      return result
    case 'ListGetLength':
      result = `${term.type}(${summaryLineHandler(term.list)})`
      return result
    case 'MapContainsKey':
    case 'MapContainsValue':
    case 'MapGetKeyList':
    case 'MapGetSize':
    case 'MapGetValueList':
      result = `${term.type}(${summaryLineHandler(term.map)}`
      if (term.key !== undefined) {
        result += `, ${summaryLineHandler(term.key)})`
      } else if (term.value !== undefined) {
        result += `, ${summaryLineHandler(term.value)})`
      } else {
        result += ')'
      }
      return result
    case 'MapCopy':
      result = `${term.type}(${summaryLineHandler(term.otherMap)})`
      return result
    case 'MapCreate':
      result = term.type
      if (term.items && !isEmpty(term.items)) {
        result += `({ ${itemsListCreator(term.items)} })`
      }
      return result
    case 'StringConcatenate':
      result = term.type
      if (term.stringTerms !== undefined) {
        result += `(${itemsListCreator(term.stringTerms)})`
      }
      return result
    case 'UserExceptionCreate':
      result = term.type
      if (term.qualifier !== undefined) {
        result += `(${summaryLineHandler(term.qualifier)}`
      }
      if (term.text !== undefined) {
        result += `, ${summaryLineHandler(term.text)}`
      }
      result += ')'
      return result
    case 'SsmEventDataLiteral':
    case 'SsmReportingDataLiteral':
      result = `${term.type}(`
      if (term.ssmName !== undefined) {
        result += `${summaryLineHandler(term.ssmName)}`
      }
      if (term.itemType !== undefined && term.itemType.value !== undefined) {
        result += `, ${term.itemType.value}`
      }
      if (term.rawValue !== undefined) {
        result += `, ${summaryLineHandler(term.rawValue)}`
      }
      if (term.engineeringValue !== undefined) {
        result += `, ${summaryLineHandler(term.engineeringValue)}`
      }
      if (term.state !== undefined) {
        result += `, ${term.state}`
      }
      if (term.limitsCheck !== undefined) {
        result += `, ${term.limitsCheck}`
      }
      if (term.limitsCheck !== undefined) {
        result += `, ${term.limitsCheck}`
      }
      if (term.timestamp !== undefined) {
        result += `, ${term.timestamp}`
      }
      if (term.loadedFromPacket !== undefined) {
        result += `, ${term.loadedFromPacket}`
      }
      result += ')'
      return result
    case 'GetExceptionOriginatorNode':
    case 'GetExceptionQualifier':
    case 'GetExceptionText':
      result = term.type
      if (term.exception !== undefined) {
        if (term.exception.qualifier !== undefined &&
            term.exception.text !== undefined) {
          result += `(${term.exception.qualifier}, ${term.exception.text})`
        }
        if (term.exception.valueOf !== undefined) {
          result += `(${summaryLineHandler(term.exception)})`
        }
      }
      return result
    case 'GetStackTrace':
      result = `${term.type}(`
      if (term.exception !== undefined) {
        result += summaryLineHandler(term.exception)
      }
      result += ')'
      return result
    case 'IsValid':
      result = `${term.type}(`
      if (term.valid !== undefined) {
        result += term.valid
      }
      result += ')'
      return result
    case 'ByteFieldGetSize':
      result = `${term.type}(`
      if (term.byteField !== undefined) {
        result += summaryLineHandler(term.byteField)
      }
      result += ')'
      return result
    case 'ConnectionLiteral':
      result = `${term.type}(`
      if (term.active !== undefined) {
        result += term.active
      }
      if (term.sutStatus !== undefined) {
        result += `, ${summaryLineHandler(term.sutStatus)}`
      }
      if (term.sutName !== undefined) {
        result += `, ${summaryLineHandler(term.sutName)}`
      }
      if (term.sutVersion !== undefined) {
        result += `, ${summaryLineHandler(term.sutVersion)}`
      }
      result += ')'
      return result
    default:
      return notSupportedTermType
  }
}

export const itemsListCreator = items => {
  let result = ''
  const iterator = () => items.forEach((el, index) => {
    result += `${index > 0 ? ', ' : ''}${summaryLineHandler(el)}`
  })
  !isEmpty(items) && iterator()
  return result
}

export const showTermType = term => {
  let result = ''
  if (term.type !== undefined && (term.type === 'BooleanValue' || term.type === 'BooleanLiteral')) {
    result += `${term.type}: `
  }
  result += summaryLineHandler(term)
  return result
}
