import React from 'react'
import { Form, Table, TableCell, Button } from 'semantic-ui-react'
import './ReportsComponent.scss'
import labels from '../../../../../public/labels.json'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

const options = [
  { key: 1, text: 'test 1', value: 'test 1' },
  { key: 2, text: 'test 2', value: 'test 2' },
  { key: 3, text: 'test 3', value: 'test 3' },
  { key: 4, text: 'test 4', value: 'test 4' },
  { key: 5, text: 'test 5', value: 'test 5' }
]

const records = [
  'row Test 1',
  'row Test 2',
  'row Test 3',
  'row Test 4',
  'row Test 5',
  'row Test 6',
  'row Test 7',
  'row Test 8',
  'row Test 9',
  'row Test 10',
  'row Test 11',
  'row Test 12',
  'row Test 13',
  'row Test 14',
  'row Test 15',
  'row Test 16',
  'row Test 17',
  'row Test 18',
  'row Test 19',
  'row Test 20'
]

const {
  headerTitle,
  headerDescription,
  startDate,
  endDate,
  otxFiles,
  projects,
  results,
  users,
  searchButton
} = labels.reportsComponent

const ReportsComponent = () => {
  return (
    <div className='reports'>
      <div className='reports__header'>
        <h2>{headerTitle}</h2>
        <h5>{headerDescription}</h5>
      </div>
      <Form className='reports__form'>
        <div>
          <div className='field'>
            <label>{startDate.label}</label>
            <Datetime
              closeOnSelect
              timeFormat={false}
              inputProps={{
                placeholder: startDate.placeholder
              }} />
          </div>
        </div>
        <div>
          <div className='field'>
            <label>{endDate.label}</label>
            <Datetime
              closeOnSelect
              timeFormat={false}
              inputProps={{
                placeholder: endDate.placeholder
              }} />
          </div>
        </div>
        <div>
          <Form.Select
            fluid
            search
            label={otxFiles.label}
            options={options}
            placeholder={otxFiles.placeholder} />
        </div>
        <div>
          <Form.Select
            fluid
            search
            label={projects.label}
            options={options}
            placeholder={projects.placeholder} />
        </div>
        <div>
          <Form.Select
            fluid
            search
            label={results.label}
            options={options}
            placeholder={results.placeholder} />
        </div>
        <div>
          <Form.Select
            fluid
            search
            label={users.label}
            options={options}
            placeholder={users.placeholder}/>
        </div>
        <div>
          <Button fluid content={searchButton} />
        </div>
      </Form>
      <div className='reports__table'>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>header 1</Table.HeaderCell>
              <Table.HeaderCell width={3}>header 2</Table.HeaderCell>
              <Table.HeaderCell width={3}>header 3</Table.HeaderCell>
              <Table.HeaderCell width={3}>header 4</Table.HeaderCell>
              <Table.HeaderCell width={3}>header 5</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {records.map((el, index) =>
              <Table.Row key={index}>
                <TableCell>{el}</TableCell>
                <TableCell>{el}</TableCell>
                <TableCell>{el}</TableCell>
                <TableCell>{el}</TableCell>
                <Table.Cell>{el}</Table.Cell>
              </Table.Row>)}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}

export default ReportsComponent
