import axios from 'axios'
import {
  origin,
  protocol,
  getHeaders,
  getHeadersDocuments,
  errorHandle,
  loop,
  executionsTabDataCreator
} from '../../../store/addons'
import { browserHistory } from 'react-router'
import {
  GET_PROJECT_LIST,
  GET_PROJECT,
  ADD_PROJECT,
  UPDATE_PROJECT_LIST,
  GET_PROJECT_TYPES,
  SET_PROJECT_TYPE,
  SET_OTX_FILE_LIST,
  CLEAR_PROJECT_LIST_TIME,
  SET_PROJECT_TAB_DATA,
  SET_EXECUTIONS_TAB_DATA,
  SET_IS_PROJECT_CONTAIN_OTX
} from '../actionTypeList'
import { setActiveConsoleIndex, setSsmId } from '../reducerList/selectElement'
import { getOutlineTreeAction, getSsmList } from './otxFile'
import { getHeaderDataAction } from './diagramElement'
import { getAttributes } from './diagramAttributes'
import { getDriverList } from './driver'
import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
import { loaderControl } from './loaderControl'
import { cloneDeep, isEmpty } from 'lodash'

export const getProjectListAction = data => {
  return {
    type: GET_PROJECT_LIST,
    data
  }
}

export const getProjectAction = data => {
  return {
    type: GET_PROJECT,
    data
  }
}

export const setOtxFileListAction = data => {
  return {
    type: SET_OTX_FILE_LIST,
    data
  }
}

export const addProjectAction = data => {
  return {
    type: ADD_PROJECT,
    data
  }
}

export const updateProjectListAction = data => {
  return {
    type: UPDATE_PROJECT_LIST,
    data
  }
}

export const clearProjectListTimeAction = () => {
  return {
    type: CLEAR_PROJECT_LIST_TIME
  }
}

export const getProjectTypesListAction = data => {
  return {
    type: GET_PROJECT_TYPES,
    data
  }
}

export const setProjectTypeAction = data => {
  return {
    type: SET_PROJECT_TYPE,
    data
  }
}

export const setProjectTabDataAction = data => {
  return {
    type: SET_PROJECT_TAB_DATA,
    data
  }
}

export const setExecutionsTabDataAction = data => {
  return {
    type: SET_EXECUTIONS_TAB_DATA,
    data
  }
}

export const setIsProjectContainOtxAction = data => {
  return {
    type: SET_IS_PROJECT_CONTAIN_OTX,
    data
  }
}

export const clearProjectListTime = () => {
  return dispatch => {
    dispatch(clearProjectListTimeAction())
  }
}

export const getProjectList = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects`, getHeaders())
        .then(response => {
          dispatch(getProjectListAction(response.data))
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const getProject = (id, shouldClearData = false, shouldUpdateWholeProject = true) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${id}`, getHeaders())
        .then(response => {
            if (shouldUpdateWholeProject) {
              dispatch(setSsmId(response.data.devices.length ? response.data.devices[0].id : -1))
              shouldClearData && dispatch(getAttributes({}))
              shouldClearData && dispatch(getHeaderDataAction({}))
              dispatch(getProjectAction(response.data))
              shouldClearData && dispatch(getOutlineTreeAction([]))
              dispatch(setActiveConsoleIndex(4, 0))
            }
            dispatch(setProjectExplorerData(response.data, shouldUpdateWholeProject))
            dispatch(loaderControl())
            resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          dispatch(loaderControl())
          reject(error)
        })
    })
  }
}

// .slice(0, -4) -> delete '.otx' from name
export const setOtxFileList = (procedureList = []) => {
  return dispatch => {
    const result = []
    let i
    let nextStep = procedureList.map(item => { return { ...item, package: '' } })
    let willBeNextStep = []
    let isProjectContainOtx = false
    while (nextStep.length) {
      for (i = 0; i < nextStep.length; i++) {
        if (nextStep[i].itemType === 'PACKAGE' && nextStep[i].childItems.length) {
          nextStep[i].childItems.map(child => {
            if (child.itemType === 'FILE') {
              !isProjectContainOtx && (isProjectContainOtx = true)
              result.push({
                index: result.length,
                key: result.length,
                text: child.name.slice(0, -4),
                value: child.name.slice(0, -4),
                package: nextStep[i].package === ''
                  ? nextStep[i].name
                  : `${nextStep[i].package}.${nextStep[i].name}`
              })
            } else if (child.itemType === 'PACKAGE' && child.childItems.length) {
              willBeNextStep.push({
                ...child,
                package: nextStep[i].package === ''
                  ? nextStep[i].name
                  : `${nextStep[i].package}.${nextStep[i].name}`
              })
            }
          })
        } else if (nextStep[i].itemType === 'FILE') {
          result.push({
            index: result.length,
            key: result.length,
            text: nextStep[i].name.slice(0, -4),
            value: nextStep[i].name.slice(0, -4),
            package: nextStep[i].package === ''
              ? nextStep[i].name
              : `${nextStep[i].package}.${nextStep[i].name}`
          })
        }
      }
      nextStep = willBeNextStep
      willBeNextStep = []
    }

    dispatch(setIsProjectContainOtxAction(isProjectContainOtx))
    dispatch(setOtxFileListAction(result))
  }
}

export const setProjectExplorerData = (project, shouldUpdateWholeProject = true) => {
  const collapsedElementsArray = JSON.parse(localStorage.getItem('projectTabDataCollapsedElements')) || []
  return dispatch => {
    const projectCopy = cloneDeep(project)

    if (!isEmpty(projectCopy.procedures)) {
      loop(projectCopy.procedures, '0', '0', false,
           false, false, '', false, false, false, false, null, [], 2, collapsedElementsArray)
    }

    let executionsTab = []
    if (!isEmpty(projectCopy.reports)) {
      executionsTab = executionsTabDataCreator(projectCopy.reports)
    }

    const projectTab = [
        {
          key: '0',
          name: projectCopy.name,
          id: projectCopy.id
          ? typeof projectCopy.id === 'number' ? projectCopy.id.toString() : projectCopy.id
          : projectCopy.name,
          treeId: projectCopy.id ? projectCopy.id : projectCopy.name,
          type: 'node',
          state: {
            expanded: !collapsedElementsArray.some(el => el === projectCopy.id.toString())
          },
          children: projectCopy.procedures
      }
    ]
    const projectType = project.projectType === null ? 'ROBOT' : project.projectType
    shouldUpdateWholeProject && dispatch(setProjectTypeAction(project.projectType))
    shouldUpdateWholeProject && dispatch(getDriverList(projectType))
    !isEmpty(project.devices) && dispatch(getSsmList(project.id, project.devices[0].id))
    dispatch(setOtxFileList(project.procedures))
    dispatch(setProjectTabDataAction(projectTab))
    dispatch(setExecutionsTabDataAction(executionsTab))
  }
}

export const addProject = project => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      const json = JSON.stringify(project.data)
      data.append('data', new Blob([json], { type: 'application/json' }))
      data.append('ssm', project.ssm)
      axios.post(`${protocol}//${origin}/api/projects`, data, getHeadersDocuments())
       .then(response => {
         dispatch(addProjectAction(response.data))
         dispatch(getOutlineTreeAction([]))
         dispatch(getHeaderDataAction({}))
         dispatch(getAttributes({}))
         dispatch(setProjectExplorerData(response.data))
         browserHistory.push(`/project/${response.data.id}`)
         dispatch(loaderControl())
         resolve()
       })
       .catch(error => {
         const errorMessage = parseError(error)
         dispatch(Notifications.error(errorMessage))
         dispatch(loaderControl())
         errorHandle(error)
         reject(error)
       })
    })
  }
}

export const updateProject = data => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${data.id}`, data, getHeaders())
        .then(() => {
          dispatch(updateProjectListAction((new Date()).getTime()))
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const deleteProject = id => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/${id}`, getHeaders())
        .then(() => {
          dispatch(updateProjectListAction((new Date()).getTime()))
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(loaderControl())
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const getProjectTypesList = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/types`, getHeaders())
        .then(response => {
          const result = response.data.map((element, index) => {
            return {
              key: index,
              text: element,
              value: element
            }
          })
          dispatch(getProjectTypesListAction(result))
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const shareProject = (projectId, body) => {
  return dispatch => {
    dispatch(loaderControl(true))
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/share`, body, getHeaders())
       .then(res => {
         dispatch(loaderControl())
         resolve()
       })
       .catch(error => {
         const errorMessage = parseError(error)
         dispatch(Notifications.error(errorMessage))
         dispatch(loaderControl())
         errorHandle(error)
         reject(error)
       })
    })
  }
}

export const actions = {
  getProjectList,
  getProject,
  addProject,
  updateProject,
  deleteProject,
  getProjectTypesList,
  shareProject
}

const ACTION_HANDLERS = {
  [GET_PROJECT_LIST]: (state, action) => {
    return {
      ...state,
      projectList: action.data
    }
  },
  [GET_PROJECT]: (state, action) => {
    return {
      ...state,
      project: action.data
    }
  },
  [ADD_PROJECT]: (state, action) => {
    return {
      ...state,
      project: action.data
    }
  },
  [UPDATE_PROJECT_LIST]: (state, action) => {
    return {
      ...state,
      updateProjectListTime: action.data
    }
  },
  [CLEAR_PROJECT_LIST_TIME]: state => {
    return {
      ...state,
      updateProjectListTime: 0
    }
  },
  [GET_PROJECT_TYPES]: (state, action) => {
    return {
      ...state,
      projectTypesList: action.data
    }
  },
  [SET_PROJECT_TYPE]: (state, action) => {
    return {
      ...state,
      projectType: action.data
    }
  },
  [SET_OTX_FILE_LIST]: (state, action) => {
    return {
      ...state,
      otxFileList: action.data
    }
  },
  [SET_PROJECT_TAB_DATA]: (state, action) => {
    return {
      ...state,
      projectTabData: action.data
    }
  },
  [SET_EXECUTIONS_TAB_DATA]: (state, action) => {
    return {
      ...state,
      executionsTabData: action.data
    }
  },
  [SET_IS_PROJECT_CONTAIN_OTX]: (state, action) => {
    return {
      ...state,
      isProjectContainOtx: action.data
    }
  }
}

const initialState = {
  projectList: [],
  project: null,
  updateProjectListTime: 0,
  projectTypesList: [],
  projectType: '',
  otxFileList: [],
  projectTabData: [],
  executionsTabData: [],
  isProjectContainOtx: false
}

export default function projectReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
