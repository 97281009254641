import { connect } from 'react-redux'
import NewValidityComponent from './NewValidityComponent'
import { getOtxVariableList } from '../../../../store/thunk/reducerList/otxFile'

const mapDispatchToProps = {
    getOtxVariableList
}

const mapStateToProps = state => ({
    otxVariableList: state.otxFile.otxVariableList
})

export default connect(mapStateToProps, mapDispatchToProps)(NewValidityComponent)
