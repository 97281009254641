import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import { getDiagramTerm } from '../../../../constants/common'
import { createConditionSummaryLine, summaryLineHandler } from '../../../../constants/summaryLineCreator'
import moment from 'moment'
import { cloneDeep } from 'lodash'

class AttributesTabComponent extends Component {
    static propTypes = {
        dataToDisplay: PropTypes.obj
    }

    renderAttributesTab = obj => {
        const newObject = obj.parameters ? Object.assign(obj, {
          'parameters IN': obj.parameters.filter(item => item.type === 'InParameter'),
          'parameters OUT': obj.parameters.filter(item => item.type === 'OutParameter'),
          'parameters USES': obj.parameters.filter(item => item.type === 'InOutParameter')
        }) : obj
        return Object.keys(newObject).map((key, i) => {
            if (key !== 'kind' &&
                key !== 'key' &&
                key !== 'parentId' &&
                key !== 'children' &&
                key !== 'position' &&
                key !== 'hasSiblings' &&
                key !== 'isEdit' &&
                key !== 'isSelect' &&
                key !== 'id' &&
                key !== 'otx_id' &&
                key !== 'parameters' &&
                key !== 'treeId' &&
                key !== 'state' &&
                key !== 'isProcedureElement' &&
                key !== 'localisation' &&
                key !== 'procedureId' &&
                key !== 'lastParentFlowElement' &&
                obj[key] !== null) {
              return <Table.Row key={i}>
                <Table.Cell>
                  {this.handleRowName(key)}
                </Table.Cell>
                <Table.Cell>
                  {(typeof obj[key] === 'string' || typeof obj[key] === 'number')
                  ? key !== 'startDate' && key !== 'endDate' && key !== 'generated'
                    ? obj[key] : moment(obj[key]).format('MM-DD-YYYY, hh:mm:ss')
                  : this.displayData(key, obj[key], obj)}
                </Table.Cell>
              </Table.Row>
            }
        })
    }

    handleRowName = key => {
      switch (key) {
        case 'activityState':
          return 'Activity State Variable'
        default:
          return key
      }
    }

    displayData = (key, item, node) => {
        const { diagram } = labels
        let result = ''
        switch (key) {
            case 'parameters IN':
            case 'parameters OUT':
            case 'parameters USES':
            case 'throws':
            case 'declarations':
                result = item.map((element, index) => {
                    return <div key={index}>
                      {`${element.otx_name}: ${(element.dataType ? element.dataType.type : '')}`}</div>
                })
                return result
            case 'content':
                return `${diagram.assign}: ${item && item.length > 1 ? item[0].name +
                    ' = ' + getDiagramTerm(item[1]) : diagram.redifine}`
            case 'condition':
                return createConditionSummaryLine(node)
            case 'configuration':
                if (item[0] && item[0].name && item[1] && item[1].value && item[2] && item[2].value) {
                  return `${diagram.for}: ${item[0].name} = ${item[1].value} ${diagram.to} ${item[2].value}`
                } else {
                  return 'Unsupported'
                }
            case 'resultState':
              return item.name && item.type ? `${item.name}: ${item.type}` : ''
            case 'arguments':
              result = item.map((element, index) => {
                return <div key={index}>{`${element.ssmName}: ${(element.term
                  ? `${element.term.value} (${element.term.type})` : '')}`}</div>
              })
              return result
            case 'isExceptionThrown':
              return `${item}`
            case 'inArguments':
            case 'inOutArguments':
            case 'outArguments':
              result = item.map((element, index) => {
                return <div key={index}>{`${element.param}: ${(element.term
                  ? `${element.term.value} (${element.term.type})` : '')}`}</div>
              })
              return result
            case 'printToLog':
              return String(item)
            case 'mappings':
              result = item.map((mapping, index) =>
                <div key={index}>{mapping.variableName + ' : ' + mapping.ssmName}</div>)
              return result
            case 'result':
              result = <div>{item.name}</div>
              return result
            case 'activityState':
              result = <div>{item.name}</div>
              return result
            case 'timeout':
              return summaryLineHandler(item)
            case 'isBreakpointSet':
              return String(item)
            case 'comments':
              return ''
            case 'dataType':
                return (item.type && item.type !== null) ? item.type : ''
            case 'steps':
            case 'output':
              result = item.map((el, index) =>
                <div key={index}>{el}</div>)
              return result
            default:
                return 'Unsupported'
        }
    }

    render () {
        const { editorConsoleComponent } = labels
        const { dataToDisplay } = this.props
        return (
          <Table compact='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width='1'>
                  {editorConsoleComponent.name}
                </Table.HeaderCell>
                <Table.HeaderCell width='10'>
                  {editorConsoleComponent.value}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.renderAttributesTab(cloneDeep(dataToDisplay))}
            </Table.Body>
          </Table>
        )
    }
}

export default AttributesTabComponent
