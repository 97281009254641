import { connect } from 'react-redux'
import NewProjectComponent from './NewProjectComponent.js'
import { getProjectTypesList } from '../../store/thunk/reducerList/project'

const mapDispatchToProps = {
    getProjectTypesList
}
const mapStateToProps = state => ({
    projectTypesList: state.project.projectTypesList
})

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectComponent)
