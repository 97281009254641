import React, { useState, useEffect } from 'react'
import { bool, func, object } from 'prop-types'
import { Modal, Button, Icon, Form } from 'semantic-ui-react'
import { returnInitValueHandler } from '../../../../constants/initValues'
import labels from '../../../../../public/labels.json'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

const { inputs, buttons, sdvModalComponent } = labels

export const SdvModalComponent = ({ isModalOpen, saveModal, closeModal, editObject, setEditObject }) => {
  const [newNode, setNewNode] = useState(returnInitValueHandler('sdvNodeInit'))
  const [isSaveClicked, setIsSaveClicked] = useState(false)

  useEffect(() => {
    if (!isEmpty(editObject) && !isEqual(newNode, editObject)) {
      setNewNode(editObject.node)
    }
    return () => {
      !isEmpty(editObject) && setEditObject({})
    }
  }, [editObject])

  const saveModalHandler = () => {
    setIsSaveClicked(true)
    if (checkIsFormValid()) {
      saveModal(newNode, editObject.id)
    }
  }

  const checkIsFormValid = () => {
    if (newNode.type !== '') {
      const fieldsToCheckByType = {
        state: ['type', 'title']
      }
      const types = fieldsToCheckByType[newNode.type.toLowerCase()]
      let isValid = true
      types.forEach(type => newNode[type] === '' && (isValid = false))
      return isValid
    } else {
      return false
    }
  }

  return (
    <Modal
      size='small'
      open={isModalOpen}>
      <div className='universalModalComponent__header'>
        <Icon circular inverted size='large' name={'plus square outline'} />
        <div className='universalModalComponent__header-text'>
          <div>{sdvModalComponent.title}</div>
          <div>{sdvModalComponent.subtitle}</div>
        </div>
      </div>
      <div
        className='universalModalComponent__content'
        style={{
          maxHeight: window.innerHeight * 0.5,
          minHeight: 300
        }}>
        <Form>
          <Form.Input
            label={inputs.title.label}
            placeholder={inputs.title.placeholder}
            value={newNode.title || ''}
            error={newNode.title === '' && isSaveClicked}
            onChange={(e, { value }) => setNewNode({ ...newNode, title: value })} />
        </Form>
      </div>
      <div className='universalModalComponent__footer'>
        <Button
          content={buttons.cancel}
          onClick={closeModal} />
        <Button
          positive
          content={buttons.save}
          onClick={saveModalHandler} />
      </div>
    </Modal>
  )
}

SdvModalComponent.propTypes = {
  isModalOpen: bool,
  closeModal: func,
  saveModal: func,
  setEditObject: func,
  editObject: object
}
