import axios from 'axios'
import { origin, protocol, getHeaders, errorHandle, mapPseudoCode } from '../../../store/addons'
import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
import { loaderControl } from './loaderControl'
import {
  GET_PSEUDO_CODE_LIST,
  UPDATE_PSEUDO_CODE_LIST,
  GET_PSEUDO_CODE_ATTRIBUTES
} from '../actionTypeList'

// ------------------------------------
// Actions
// ------------------------------------

export const updatePseudoCodeListAction = () => {
  return {
    type: UPDATE_PSEUDO_CODE_LIST
  }
}

export const updatePseudoCodeList = () =>
  dispatch => dispatch(updatePseudoCodeListAction())

export const getPseudoCodeListAction = (data = []) => {
    return {
      type: GET_PSEUDO_CODE_LIST,
      data
    }
}

export const getPseudoCodeAttributesAction = (data = []) => {
  return {
    type: GET_PSEUDO_CODE_ATTRIBUTES,
    data
  }
}

export const getPseudoCodeList = (projectId, otxFileId) => {
  const pseudocodeType = localStorage.getItem('pseudocodeType') || 'JAVA'
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/pseudocode?type=${pseudocodeType}`,
      getHeaders())
        .then(json => {
          json.data.lines = mapPseudoCode(json.data.lines)
          dispatch(getPseudoCodeListAction(json.data.lines))
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          dispatch(loaderControl())
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const getPseudoCodeAttributes = () => {
  return dispatch => {
    dispatch(loaderControl(true))
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/mapper`,
      getHeaders())
        .then(response => {
          dispatch(getPseudoCodeAttributesAction(response.data))
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          dispatch(loaderControl())
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const setPseudoCodeAttributes = json => {
  return dispatch => {
    dispatch(loaderControl(true))
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/mapper`, json, getHeaders())
        .then(() => {
          const tempArr = window.location.pathname.split('/')
          if (tempArr.includes('otx') && tempArr.length >= 5) {
            dispatch(getPseudoCodeList(tempArr[2], tempArr[4]))
          } else {
            dispatch(loaderControl())
          }
          dispatch(getPseudoCodeAttributesAction(json))
          resolve()
        })
        .catch(error => {
          dispatch(loaderControl())
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const actions = {
  getPseudoCodeList,
  getPseudoCodeAttributes
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [GET_PSEUDO_CODE_LIST]: (state, action) => {
        return {
            ...state,
            pseucoCodeList: action.data
        }
    },
    [UPDATE_PSEUDO_CODE_LIST]: state => {
      return {
        ...state,
        updatePseudoCodeListTime: new Date().getTime()
      }
    },
    [GET_PSEUDO_CODE_ATTRIBUTES]: (state, action) => {
      return {
        ...state,
        pseudoCodeAttributes: action.data
      }
    }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { pseucoCodeList: [], updatePseudoCodeListTime: 0, pseudoCodeAttributes: {} }

export default function pseudoCodeReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
