import { connect } from 'react-redux'
import NewProcedureComponent from './NewProcedureComponent'
import { getSignatures, getValidities } from '../../../../store/thunk/reducerList/parameters'

const mapDispatchToProps = {
    getSignatures,
    getValidities
}
const mapStateToProps = state => ({
    signatures: state.parameters.signatures,
    validities: state.parameters.validities
})

export default connect(mapStateToProps, mapDispatchToProps)(NewProcedureComponent)
