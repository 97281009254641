import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './EditorConsoleComponent.scss'
import { Tab, List } from 'semantic-ui-react'
import labels from '../../../public/labels.json'
import AttributesTabComponent from './components/AttributesTabComponent'
import XmlPreviewTabComponent from './components/XmlPreviewTabComponent'
import ProcedureRunConsoleComponent from './components/ProcedureRunConsoleComponent'
import SutStatusComponent from './components/SutStatusComponent'
import VariableWatch from './components/VariableWatch'
import ValidationLogs from '../Utilities/ValidationLogs'
import { iconSetter } from '../../constants/common'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

class EditorConsoleComponent extends Component {
  state = {
      itemId: '',
      items: [],
      length: null,
      itemName: ''
    }

  static propTypes = {
    tabs: PropTypes.array,
    position: PropTypes.string,
    attribute: PropTypes.object,
    otxValidationData: PropTypes.array,
    activeIndex: PropTypes.number,
    setActiveConsoleIndex: PropTypes.func,
    xmlPreview: PropTypes.string,
    reportAttributes: PropTypes.object,
    activeTopTabIndex: PropTypes.number,
    // props form parent
    params: PropTypes.object,
    copiedNodes: PropTypes.object
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (!isEqual(prevState.items, nextProps.items) &&
    (prevState.length, nextProps.length) &&
    (prevState.itemName, nextProps.item.name)) {
      return {
        items: nextProps.items,
        itemName: nextProps.item.name,
        length: nextProps.length
      }
    }
    return null
  }

  changeActiveTabHandler = (event, { activeIndex }) => {
    this.props.setActiveConsoleIndex(this.props.activeIndex, activeIndex)
  }

  renderPanes = () => {
    const {
      otxValidationData,
      attribute,
      params,
      xmlPreview,
      activeTopTabIndex,
      copiedNodes,
      reportAttributes
    } = this.props
    const { editorConsoleComponent } = labels
    const panes = [
      {
        menuItem: editorConsoleComponent.attributes,
        render: () => <Tab.Pane attached={false} style={{ padding: 0 }}>
          {params.procedureId !== '' && params.otxFileId !== -1 &&
            <AttributesTabComponent
              dataToDisplay={isEmpty(reportAttributes) || activeTopTabIndex !== 2
                ? attribute
                : reportAttributes} />
          }
        </Tab.Pane>
      },
      {
        menuItem: editorConsoleComponent.console,
        render: () => <Tab.Pane attached={false} style={{ padding: '2px 10px' }}>
          <ProcedureRunConsoleComponent params={params}/>
        </Tab.Pane>
        },
      {
        menuItem: editorConsoleComponent.xmlPreview,
        render: () => <Tab.Pane attached={false} style={{ padding: 0 }}>
          {params.procedureId !== '' && params.otxFileId !== -1 &&
          <XmlPreviewTabComponent xmlPreview={xmlPreview} />}
        </Tab.Pane>
      },
      {
        menuItem: editorConsoleComponent.variableWatch,
        render: () => <Tab.Pane attached={false} style={{ padding: 0 }}>
          <VariableWatch params={params} />
        </Tab.Pane>
      },
      {
        menuItem: editorConsoleComponent.problems,
        render: () => <Tab.Pane attached={false} style={{ padding: '2px 10px' }}>
          <ValidationLogs logs={otxValidationData}/>
        </Tab.Pane>
      },
      {
        menuItem: editorConsoleComponent.sutStatus,
        render: () => <Tab.Pane attached={false} style={{ padding: '2px 10px' }}>
          <SutStatusComponent params={params}/>
        </Tab.Pane>
      }
    ]
    if (!isEmpty(copiedNodes.elementsToBeCopied)) {
      panes.push({
        menuItem: `${editorConsoleComponent.clipBoard} (${copiedNodes.elementsToBeCopied.length})`,
        render: () =>
          <Tab.Pane attached={false} style={{ padding: 0 }}>
            <List celled>
              {this.props.copiedNodes.nodesToBeCopied.map((element, index) =>
                <List.Item key={index}>
                  <div style={{ display: 'flex', margin: '10px 5px' }}>
                    <span className={`${iconSetter(element.type || '')}`} />
                    <span style={{ marginLeft: 10 }}>
                      {`${editorConsoleComponent.type}: ${element.type}${element.otx_name
                        ? `, ${editorConsoleComponent.name}: ${element.otx_name}` : ''}`}
                    </span>
                  </div>
                </List.Item>)}
            </List>
          </Tab.Pane>
      })
    }
    return panes
  }

  render () {
    return (
      <div className='max-size editor-console-container'>
        <Tab
          className={'editor-table-container'}
          activeIndex={this.props.activeIndex}
          onTabChange={this.changeActiveTabHandler}
          menu={{ secondary: true, pointing: true }}
          panes={this.renderPanes()} />
      </div>
    )
  }
}
export default EditorConsoleComponent
