import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Popup } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import { loopStructureCreator, conditionSample } from '../../../../constants/itemStructureCreator'
import { createLoopSummaryLine } from '../../../../constants/summaryLineCreator'
import { nameRegex } from '../../../../store/addons'
import TermEditorComponent from '../../../TermEditor/TermEditorComponent'
import testIds from '../../../../../public/testIds.json'
import isEmpty from 'lodash/isEmpty'
import {
  returnInitTermHandler,
  termEditorHandler,
  termCreatorHandler
} from '../../../../utilites/terms/terms'

export default class LoopWizard extends Component {
  constructor (props) {
    super()
    this.state = {
      basicName: (props.modalItemToAdd &&
      props.modalItemToAdd.otx_name && props.modalItemToAdd.type === 'Loop') ? props.modalItemToAdd.otx_name : '',
      basicSpecification: (props.modalItemToAdd && props.modalItemToAdd.otx_specification &&
      props.modalItemToAdd.type === 'Loop')
      ? props.modalItemToAdd.otx_specification : '',
      technicalName: (props.modalItemToAdd &&
      props.modalItemToAdd.otx_name && props.modalItemToAdd.type !== 'Loop') ? props.modalItemToAdd.otx_name : '',
      technicalSpecification: (props.modalItemToAdd && props.modalItemToAdd.otx_specification &&
      props.modalItemToAdd.type !== 'Loop')
      ? props.modalItemToAdd.otx_specification : '',
      iterator: 'i',
      listOrMap: (props.modalItemToAdd.type ===
         'ForEachLoop' && props.modalItemToAdd.collection !== undefined) ? props.modalItemToAdd.collection
         : {},
      locator: (props.modalItemToAdd.type === 'ForEachLoop' &&
      props.modalItemToAdd.locator !== undefined) ? props.modalItemToAdd.locator : {},
      selectedWhileLoop: (props.modalItemToAdd.type === 'WhileLoop' &&
      props.modalItemToAdd.isPostTested !== undefined)
      ? props.modalItemToAdd.isPostTested : true,
      condition: (props.modalItemToAdd.type === 'WhileLoop' &&
      props.modalItemToAdd.test !== undefined)
       ? props.modalItemToAdd.test : conditionSample,
      basicNameError: false,
      technicalNameError: false,
      iteratorError: false,
      locatorError: false,
      listOrMapError: false,
      isSaveClicked: false,
      assignedVal: {},
      startValueParameter: returnInitTermHandler('Integer'),
      endValueParameter: returnInitTermHandler('Integer'),
      whileLoopParameter: returnInitTermHandler('Boolean')
    }
  }

  static propTypes = {
    modalItemToAdd: PropTypes.object,
    saveModal: PropTypes.func,
    closeModal: PropTypes.func,
    item: PropTypes.object,
    newVariable: PropTypes.any,
    setAddedVariable: PropTypes.func,
    optionsVariableList: PropTypes.array,
    getVariableList: PropTypes.func,
    openAddVariable: PropTypes.func,
    onTabChange: PropTypes.func,
    isFirstTabActive: PropTypes.bool,
    variableList: PropTypes.array,
    params: PropTypes.object
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let result = null
    if (nextProps.newVariable && nextProps.item.type === 'ForEachLoop') {
      nextProps.setAddedVariable()
      if (result) {
        result.locator = {
          type: `${nextProps.newVariable.dataType.type}Variable`,
          name: nextProps.newVariable.otx_name
        }
      } else {
        result = {
          locator: {
            type: `${nextProps.newVariable.dataType.type}Variable`,
            name: nextProps.newVariable.otx_name
          }
        }
      }
    }

    return result
  }

  componentDidMount = () => {
    const { item, params } = this.props
    if (item.type === 'ForLoop' && item.configuration) {
      const startValueParameter = termCreatorHandler(item.configuration[1], 'Integer')
      const endValueParameter = termCreatorHandler(item.configuration[2], 'Integer')
      this.setState({
        iterator: item.configuration[0].name,
        startValueParameter,
        endValueParameter
      })
    } else if (item.type === 'WhileLoop' && item.test && !isEmpty(item.test)) {
      const whileLoopParameter = termCreatorHandler(item.test, 'Boolean')
      this.setState({ whileLoopParameter })
    }
    if (typeof params.projectId !== 'undefined' &&
        typeof params.otxFileId !== 'undefined' &&
        typeof params.procedureId !== 'undefined') {
      this.props.getVariableList(
        params.projectId,
        params.otxFileId,
        params.procedureId).then(() => {
          if (item.type === 'ForEachLoop' && this.state.listOrMap.valueOf !== undefined) {
            const assignedVal = this.props.variableList.find(el => el.value === this.state.listOrMap.valueOf)
            this.setState({ assignedVal: assignedVal && assignedVal.obj ? assignedVal.obj : {} })
          }
        })
    }
  }

  toggleWhileLoop = e => {
    this.setState({
      selectedWhileLoop: e.target.value === 'do-while-loop'
    })
  }

  getSummaryLine = (item, condition) => {
    if (item.type === 'ForEachLoop') {
      item.collection = condition
    } else if (item.type === 'WhileLoop') {
      item.test = condition
    }
    const summaryLine = createLoopSummaryLine(item)
    return summaryLine
  }

  handleChangeInput = (stateName, value) => {
    const change = { ...this.state }
    change[stateName] = value
    if (stateName === 'basicName' || stateName === 'technicalName' || stateName === 'iterator') {
      change[`${stateName}Error`] = this.validateBlur(stateName, null, change[stateName])
    }
    this.setState(change)
  }

  validateBlur = (stateName, validationName, val) => {
    let value = val
    if (validationName === 'formValidation') {
      value = this.state[stateName]
    }
    const change = { ...this.state }
    const error = `${stateName}Error`
    switch (stateName) {
      case 'basicName':
      case 'technicalName':
      case 'iterator':
        change[error] = !nameRegex.test(value)
        break
      case 'listOrMap':
      case 'locator':
        change[error] = isEmpty(value)
        break
    }
    if (validationName === 'formValidation') {
      this.setState(change)
      return change[error]
    } else {
      return change[error]
    }
  }

  handleChangeDropDown = (stateName, value) => {
    const change = {}
    const error = `${stateName}Error`
    if (stateName === 'listOrMap') {
      const assignedVal = this.props.variableList.find(el => el.obj.otx_name === value).obj
      change.assignedVal = assignedVal
      change[stateName] = { valueOf: assignedVal.otx_name, type: `${assignedVal.dataType.type}Value` }
      change.locator = ''
      change.locatorError = false
      change[error] = !nameRegex.test(value)
    } else if (stateName === 'locator') {
      const assignedVal = this.props.variableList.find(el => el.obj.otx_name === value).obj
      change[stateName] = {
        type: `${assignedVal.dataType.type}Variable`,
        children: [],
        name: value
      }
      change[error] = !nameRegex.test(value)
    } else {
      change[stateName] = value
    }
    this.setState(change)
  }

  termEditorFieldHandler = (stateName, value, propertyName) => {
    const newTerm = termEditorHandler(this.state[stateName], stateName, value, propertyName)
    this.setState({ [stateName]: newTerm })
  }

  isFormValid = itemType => {
    if (itemType === 'Loop') {
      return !this.validateBlur('basicName', 'formValidation')
    } else if (itemType === 'ForLoop') {
      return (!this.validateBlur('technicalName', 'formValidation') &&
              !this.validateBlur('iterator', 'formValidation')) &&
              this.state.startValueParameter.isValid &&
              this.state.endValueParameter.isValid
    } else if (itemType === 'ForEachLoop') {
      return (!this.validateBlur('technicalName', 'formValidation') &&
              !this.validateBlur('listOrMap', 'formValidation') &&
              !this.validateBlur('locator', 'formValidation'))
    } else if (itemType === 'WhileLoop') {
      return (!this.validateBlur('technicalName', 'formValidation')) &&
              this.state.whileLoopParameter.isValid
    }
  }

  saveModal = e => {
    e.preventDefault()
    this.setState({ isSaveClicked: true }, () => {
      const { saveModal, modalItemToAdd } = this.props
      if (this.isFormValid(modalItemToAdd.type) && (modalItemToAdd.children[0]
      ? this.isFormValid(modalItemToAdd.children[0].type) : true)) {
          const modifiedModalItemToAdd = loopStructureCreator(modalItemToAdd, this.state)
          saveModal(modifiedModalItemToAdd)
      }
    })
  }

  renderButtons = () => {
    const { buttons } = labels
    if (this.props.modalItemToAdd.isEdit || !this.props.modalItemToAdd.children.length) {
      return (
        <div>
          <Button
            id={testIds.wizardInputs.finishButton}
            onClick={e => this.saveModal(e)}
            content={buttons.finish}
            positive />
        </div>
      )
    } else {
      return (
        <div>
          {this.props.isFirstTabActive
          ? <Button
              id={testIds.wizardInputs.nextButton}
              disabled={this.state.basicNameError}
              onClick={() => !this.validateBlur('basicName', 'formValidation') && this.props.onTabChange(false, true)}
              content={buttons.next} />
          : <Button
              id={testIds.wizardInputs.previousButton}
              onClick={() => this.props.onTabChange(true, false)}
              content={buttons.previous} />}
          {(!this.props.isFirstTabActive || !this.props.modalItemToAdd.children.length) &&
          <Button
            id={testIds.wizardInputs.finishButton}
            onClick={e => this.saveModal(e)}
            content={buttons.finish}
            positive />}
        </div>
      )
    }
  }

  render () {
    const {
      item,
      closeModal
    } = this.props
    const {
      basicName,
      basicSpecification,
      technicalName,
      technicalSpecification,
      iterator,
      locator,
      listOrMap,
      listOrMapError,
      selectedWhileLoop,
      basicNameError,
      technicalNameError,
      iteratorError,
      locatorError,
      assignedVal
    } = this.state
    const { inputs, radioButtons } = labels.modalPattern
    const { buttons, hints } = labels
    const { optionsVariableList } = this.props
    return (
      <React.Fragment>
        <div className='wizard__form-container'>
          <Form.Group className='wizard__form'>
            {item.type === 'Loop' && <React.Fragment>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Popup
                    position='top center'
                    content={hints.diagramModal.basicName}
                    trigger={<Icon name='info' />} />
                  <Form.Input
                    id={testIds.wizardInputs.nameInput}
                    error={basicNameError}
                    onBlur={() => this.validateBlur('basicName')}
                    label={inputs.name.label}
                    placeholder={inputs.name.placeholder}
                    value={basicName}
                    type='text'
                    onChange={(event, { value }) => this.handleChangeInput('basicName', value)}
                    className='wizard__form-input' />
                </div>
              </Form.Group>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Icon name='info' className='info-hidden' />
                  <Form.TextArea
                    id={testIds.wizardInputs.descriptionTextArea}
                    label={inputs.specification.label}
                    onChange={(event, { value }) => this.handleChangeInput('basicSpecification', value)}
                    placeholder={inputs.specification.placeholder}
                    value={basicSpecification}
                    className='wizard__form-input' />
                </div>
              </Form.Group>
            </React.Fragment>}
            {(item.type === 'ForLoop' ||
              item.type === 'ForEachLoop' ||
              item.type === 'WhileLoop') && <React.Fragment>
                <Form.Group>
                  <div className='wizard__form-info'>
                    <Icon name='info' className='info-hidden' />
                    <Form.Input
                      id={testIds.wizardInputs.technicalNameInput}
                      error={technicalNameError}
                      onBlur={() => this.validateBlur('technicalName')}
                      label={inputs.name.label}
                      placeholder={inputs.name.placeholder}
                      value={technicalName}
                      type='text'
                      onChange={(event, { value }) => this.handleChangeInput('technicalName', value)}
                      className='wizard__form-input' />
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className='wizard__form-info'>
                    <Icon name='info' className='info-hidden' />
                    <Form.TextArea
                      id={testIds.wizardInputs.technicalDescriptionTextArea}
                      label={inputs.specification.label}
                      onChange={(event, { value }) => this.handleChangeInput('technicalSpecification', value)}
                      placeholder={inputs.specification.placeholder}
                      value={technicalSpecification}
                      className='wizard__form-input' />
                  </div>
                </Form.Group>
              </React.Fragment>}
            {item.type === 'ForLoop' && <React.Fragment>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Icon name='info' className='info-hidden' />
                  <Form.Input
                    id={testIds.wizardInputs.iteratorInput}
                    error={iteratorError}
                    onBlur={() => this.validateBlur('iterator')}
                    label={inputs.iterator.label}
                    onChange={(event, { value }) => this.handleChangeInput('iterator', value)}
                    placeholder={inputs.condition.placeholder}
                    className='wizard__form-input'
                    value={iterator} />
                </div>
              </Form.Group>
              <TermEditorComponent
                changeHandler={this.termEditorFieldHandler}
                element={this.state.startValueParameter}
                stateName='startValueParameter'
                item={item}
                index={0}
                isSaveClicked={this.state.isSaveClicked}
                labelsProp={inputs.startValue}
                variableList={this.props.variableList.filter(variable =>
                  variable.obj.dataType ? variable.obj.dataType.type === 'Integer' : false)}
              />
              <TermEditorComponent
                changeHandler={this.termEditorFieldHandler}
                element={this.state.endValueParameter}
                stateName='endValueParameter'
                item={item}
                index={1}
                isSaveClicked={this.state.isSaveClicked}
                labelsProp={inputs.endValue}
                variableList={this.props.variableList.filter(variable =>
                  variable.obj.dataType ? variable.obj.dataType.type === 'Integer' : false)}
              />
            </React.Fragment>}
            {item.type === 'ForEachLoop' && <React.Fragment>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Popup
                    position='top center'
                    content={hints.diagramModal.forEachLoop.listOrMap}
                    trigger={<Icon name='info' className='info' />} />
                  <Form.Select
                    id={testIds.wizardInputs.listOrMapDropDown}
                    error={listOrMapError}
                    onBlur={() => this.validateBlur('listOrMap')}
                    label={inputs.listOrMap.label}
                    onChange={(e, { value }) => this.handleChangeDropDown('listOrMap', value)}
                    options={optionsVariableList.filter(el => (el.type === 'Map' || el.type === 'List'))}
                    placeholder={inputs.listOrMap.placeholder}
                    value={!isEmpty(listOrMap) && listOrMap.valueOf !== undefined
                      ? listOrMap.valueOf : ''}
                  />
                </div>
              </Form.Group>
              <Form.Group className='wizard__form-col2'>
                <div className='wizard__form-info'>
                  <Popup
                    position='top center'
                    content={hints.diagramModal.forEachLoop.locator}
                    trigger={<Icon name='info' className='info' />} />
                  <Form.Select
                    id={testIds.wizardInputs.locatorDropDown}
                    error={locatorError}
                    onBlur={() => this.validateBlur('locator')}
                    label={inputs.locator.label}
                    onChange={(e, { value }) => this.handleChangeDropDown('locator', value)}
                    options={optionsVariableList.filter(el => (
                      assignedVal &&
                      assignedVal.dataType &&
                      el.object.obj.dataType.type ===
                      (assignedVal.dataType.type.includes('Map') ? assignedVal.dataType.keyType.type : 'Integer'))
                    )}
                    placeholder={inputs.locator.placeholder}
                    value={locator.name || ''} />
                </div>
                <Button
                  id={testIds.wizardInputs.addVariableButton}
                  content={buttons.addVariable}
                  onClick={() => this.props.openAddVariable(true)} />
              </Form.Group>
            </React.Fragment>}
            {item.type === 'WhileLoop' && <React.Fragment>
              <div className='wizard__form-radio-container'>
                <div className='wizard__form-radio'>
                  <input
                    id={testIds.wizardInputs.doWhileLoop}
                    type='radio'
                    checked={selectedWhileLoop}
                    value={radioButtons.whileLoop}
                    onChange={event => this.toggleWhileLoop(event)}
                    label={radioButtons.whileLoop} />
                  <label>{radioButtons.whileLoop}</label>
                </div>
                <div className='wizard__form-radio'>
                  <input
                    id={testIds.wizardInputs.whileDoLoop}
                    type='radio'
                    checked={!selectedWhileLoop}
                    onChange={event => this.toggleWhileLoop(event)}
                    value={radioButtons.whileDoLoop}
                    label={radioButtons.whileDoLoop} />
                  <label>{radioButtons.whileDoLoop}</label>
                </div>
                <div className='wizard__form-info' style={{ marginLeft: '10px' }}>
                  <Popup
                    position='top center'
                    content={hints.diagramModal.whileLoop.checkboxes}
                    trigger={<Icon name='info' />} />
                </div>
              </div>
              <TermEditorComponent
                changeHandler={this.termEditorFieldHandler}
                element={this.state.whileLoopParameter}
                stateName='whileLoopParameter'
                isSaveClicked={this.state.isSaveClicked}
                item={item}
                index={null}
                variableList={this.props.variableList.filter(variable =>
                  variable.obj.dataType ? variable.obj.dataType.type === 'Boolean' : false)}
              />
              </React.Fragment>}
            <div className='wizard__footer'>
              <div>
                <Button
                  id={testIds.wizardInputs.cancelButton}
                  onClick={closeModal}
                  content={buttons.cancel} />
              </div>
              {this.renderButtons()}
            </div>
          </Form.Group>
        </div>
      </React.Fragment>
    )
  }
}
