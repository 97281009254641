import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './LoginComponent.scss'
import { Button, Form } from 'semantic-ui-react'
import labels from '../../../../public/labels.json'
import testIds from '../../../../public/testIds.json'

class LoginComponent extends Component {
  constructor (props) {
    super()
    this.state = {
      name: '',
      password: '',
      nameError: false,
      passwordError: false,
      isRemembered: false
    }
  }

  static propTypes = {
    logIn: PropTypes.func,
    isLoggedIn: PropTypes.bool
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.saveHandler()
    }
  }

  handleChange (value, name) {
    const change = {}
    change[name] = value
    this.setState(change, () => this.validateBlur(name))
  }

  validateBlur = (stateName, validationName) => {
    const error = this.state[stateName] === ''
    this.setState({ [`${stateName}Error`]: error })
    if (validationName === 'formValidation') return error
  }

  saveHandler = () => {
    if (this.checkIsFormValid()) {
      this.props.logIn(this.state.name, this.state.password)
    }
  }

  checkIsFormValid () {
    let isValid = true
    const fieldsToCheck = ['name', 'password']
    fieldsToCheck.forEach(name => {
      const tempIsValid = !this.validateBlur(name, 'formValidation')
      if (isValid && !tempIsValid) {
        isValid = tempIsValid
      }
    })
    return isValid
  }

  render () {
    const { name, nameError, password, passwordError } = this.state
    const { loginComponent } = labels
    return (
      <div className='loginComponent'>
        <div className='loginComponent__left'>
          <div className='loginComponent__left-header'>
            <div>
              {loginComponent.welcome}
            </div>
            <div>
              {loginComponent.pleaseLogin}
            </div>
            <Form onKeyPress={this.handleKeyPress}>
              <Form.Input
                id={testIds.loginComponent.userNameInput}
                label={loginComponent.username}
                data-test='username'
                onChange={(e, { value }) => this.handleChange(value, 'name')}
                onBlur={() => this.validateBlur('name')}
                value={name}
                type='text'
                fluid
                error={nameError}
                placeholder={loginComponent.enterUsername} />
              <Form.Input
                id={testIds.loginComponent.passwordInput}
                label={loginComponent.password}
                onChange={(e, { value }) => this.handleChange(value, 'password')}
                onBlur={() => this.validateBlur('password')}
                value={password}
                fluid
                autoComplete='on'
                data-test='password'
                type='password'
                error={passwordError}
                placeholder={loginComponent.enterPassword}/>
              <Button
                fluid
                disabled={nameError || passwordError}
                style={{ marginTop: 45 }}
                id={testIds.loginComponent.loginButton}
                content={loginComponent.login}
                onClick={this.saveHandler} />
            </Form>
          </div>
        </div>
        <div className='loginComponent__right'>
          <img src='/login.png' />
          <img src='/atena-logo-welcome-screen.svg' />
        </div>
      </div>
    )
  }
}

export default LoginComponent
