export const severityLevelOptions = [
  { key: 0, value: 'TRACE', text: 'TRACE' },
  { key: 1, value: 'DEBUG', text: 'DEBUG' },
  { key: 2, value: 'INFO', text: 'INFO' },
  { key: 3, value: 'WARN', text: 'WARN' },
  { key: 4, value: 'ERROR', text: 'ERROR' },
  { key: 5, value: 'FATAL', text: 'FATAL' }
]

export const messageTypeOptions = [
  { key: 0, value: 'INFO', text: 'INFO' },
  { key: 1, value: 'WARNING', text: 'WARNING' },
  { key: 2, value: 'ERROR', text: 'ERROR' },
  { key: 3, value: 'YESNO_QUESTION', text: 'YES NO QUESTION' },
  { key: 4, value: 'YESNOCANCEL_QUESTION', text: 'YES NO CANCEL QUESTION' }
]
