import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
// ------------------------------------
// Constants
// ------------------------------------
import axios from 'axios'
import { GET_SIGNATURE, GET_VALIDITIES, GET_PARENT_LOOPS } from '../actionTypeList'
import { origin, protocol, getHeaders, errorHandle, mapJsonToDisplayList } from '../../addons'
import { loaderControl } from './loaderControl'
// ------------------------------------
// Actions
// ------------------------------------

const getSignaturesAction = data => {
  return {
    type: GET_SIGNATURE,
    data
  }
}

const getValiditiesAction = data => {
  return {
    type: GET_VALIDITIES,
    data
  }
}

const getParentLoopsAction = data => {
  return {
    type: GET_PARENT_LOOPS,
    data
  }
}

export const getSignatures = (projectId = '', otxFileId = -1) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/signatures`,
      getHeaders())
        .then(response => {
          const tempData = (response.data && response.data[0])
          ? mapJsonToDisplayList(response.data)
          : []
          dispatch(getSignaturesAction(tempData))
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const getValidities = (projectId = '', otxFileId = -1) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/validities/all`, getHeaders())
        .then(response => {
          const tempData = (response.data && response.data[0]) ? mapJsonToDisplayList(response.data, true) : []
          dispatch(getValiditiesAction(tempData))
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const getParentLoops = (projectId, itemId, procedureId, parentId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(
        // eslint-disable-next-line max-len
        `${protocol}//${origin}/api/projects/${projectId}/items/${itemId}/procedures/${procedureId}/nodes/${parentId}/parentLoops`,
        getHeaders())
        .then(response => {
          dispatch(getParentLoopsAction(response.data))
          dispatch(loaderControl())
          resolve(response)
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(loaderControl())
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const actions = {
  getSignatures,
  getValidities,
  getParentLoops
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GET_SIGNATURE]: (state, action) => {
    return {
      ...state,
      signatures: action.data
    }
  },
  [GET_VALIDITIES]: (state, action) => {
    return {
      ...state,
      validities: action.data
    }
  },
  [GET_PARENT_LOOPS]: (state, action) => {
    return {
      ...state,
      parentLoops: action.data
    }
  }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  signatures: [],
  validities: [],
  parentLoops: []
}

export default function parametersReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
