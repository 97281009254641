import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'
import { Icon, Popup } from 'semantic-ui-react'
import './DiagramContentComponent.scss'
import { dndTypes } from '../../../../constants/ItemTypes'
import SingleDiagramElementComponent from '../SingleDiagramElementComponent'
import DiagramHeaderComponent from './components/DiagramHeaderComponent'
import { diagramElementModifer, idChanger } from '../../../../constants/common'
import { returnInitValueHandler } from '../../../../constants/initValues'
import testIds from '../../../../../public/testIds.json'
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache
} from 'react-virtualized'
import { isEqual, isEmpty } from 'lodash'
import { findNested } from '../../../../store/addons'
import { MemoizedCopyPasteMenu } from './components/CopyPasteMenuComponent/CopyPasteMenu'
import { contextMenu } from 'react-contexify'
import { browserHistory } from 'react-router'
import labels from '../../../../../public/labels.json'

const diagramTarget = {
    drop (props, monitor) {
      if (monitor.isOver({ shallow: true })) {
        const itemToAdd = monitor.getItem().item
        if (itemToAdd.kind === 'tree_list') {
          itemToAdd.kind = 'diagram_list'
          const placeToAdd = null
          props.openModal(diagramElementModifer(itemToAdd, placeToAdd, props), placeToAdd, null)
       }
      }
    },
    canDrop (props, monitor) {
      if (monitor.isOver({ shallow: true })) {
        const itemToAdd = monitor.getItem().item
        const result = itemToAdd => {
            if (itemToAdd.type !== 'endNode' && itemToAdd.name !== 'Else If' && itemToAdd.name !== 'Else') {
                return true
            }
            return false
        }

        return result(itemToAdd)
      }
    }
}

const dropTargetId = idChanger()

class DiagramContentComponent extends Component {
  constructor (props) {
    super(props)
    this.cache = new CellMeasurerCache({ defaultHeight: 122, fixedWidth: true })
    this.state = {
      scrollToIndex: undefined,
      scrollToAlignment: 'start',
      nodeObject: {},
      option: '',
      parentNode: {},
      boxPosition: '',
      contextMenuId: 'menu_context-copyPaste'
    }
  }

  static propTypes = {
      connectDropTarget: PropTypes.func,
      diagramData: PropTypes.array,
      addToEmptyElement: PropTypes.func,
      addElement: PropTypes.func,
      isOver: PropTypes.bool,
      canDrop: PropTypes.bool,
      openModal: PropTypes.func,
      headerData: PropTypes.object,
      otxFileId: PropTypes.number,
      toolBarHandler: PropTypes.func,
      params: PropTypes.object,
      reachedBreakPoint: PropTypes.string,
      isProcedureRunning: PropTypes.bool,
      shouldUpdateScroll: PropTypes.bool,
      copyElementHandler: PropTypes.func,
      updateScroll: PropTypes.func,
      validateBeforePaste: PropTypes.func,
      nodesToCopyObject: PropTypes.object,
      copiedNodes: PropTypes.object,
      loaderControl: PropTypes.func
  }

  componentDidMount () {
    const { diagramData, params } = this.props
    if (!isEmpty(diagramData) && params.selectedElementId) {
      this.scrollToNestedElementIndex(diagramData, params.selectedElementId)
    }
  }

  componentDidUpdate (prevProps) {
    const { diagramData, reachedBreakPoint, params, isProcedureRunning, shouldUpdateScroll } = this.props
    if (!isEqual(diagramData, prevProps.diagramData) && !isEmpty(prevProps.diagramData)) {
      this.cache.clearAll()
      if (shouldUpdateScroll && !isProcedureRunning && params.selectedElementId) {
        this.scrollToNestedElementIndex(diagramData, params.selectedElementId)
      } else if (isProcedureRunning) {
        this.scrollToNestedElementIndex(diagramData, reachedBreakPoint)
      } else if (typeof this.state.scrollToIndex !== 'undefined') {
        this.setState({ scrollToIndex: undefined, scrollToAlignment: 'start' })
      }
    } else if (params.selectedElementId && !isEmpty(diagramData) && isEmpty(prevProps.diagramData)) {
      this.scrollToNestedElementIndex(diagramData, params.selectedElementId)
    } else if (shouldUpdateScroll &&
               !isProcedureRunning &&
               !isEqual(params.selectedElementId, prevProps.params.selectedElementId) &&
               isEqual(params.procedureElementId, prevProps.params.procedureElementId)) {
      this.scrollToNestedElementIndex(diagramData, params.selectedElementId)
    }

    if (reachedBreakPoint !== '' && !isEqual(reachedBreakPoint, prevProps.reachedBreakPoint)) {
      this.scrollToNestedElementIndex(diagramData, reachedBreakPoint)
    } else if (reachedBreakPoint === '' && prevProps.reachedBreakPoint !== '') {
      this.setState({ scrollToIndex: 0, scrollToAlignment: 'start' })
    }
  }

  scrollToNestedElementIndex (diagramData, selectedElementId) {
  const el = findNested(diagramData, selectedElementId)
    if (!isEmpty(el)) {
      const tempNum = el.elementLength / 3
      const elPostiion = el.position + 1
      let scrollToIndex = el.position
      let scrollToAlignment = 'start'
      if (el.parentPosition !== null) {
        scrollToIndex = el.parentPosition
        switch (el.elementLength) {
          case 1:
            scrollToAlignment = 'start'
            break
          case 2:
            scrollToAlignment = 'center'
            break
          default:
            if (tempNum < elPostiion && tempNum * 2 >= elPostiion) {
              scrollToAlignment = 'center'
            } else if (tempNum * 2 < elPostiion) {
              scrollToAlignment = 'end'
            }
        }
      }
      this.setState({ scrollToIndex, scrollToAlignment })
    }
  }

  renderDiagram = (data, parent = null, elementWidth) => data.map(item => {
    item.hasSiblings = parent === null ? false : parent.children.length > 1
    if (item.level < 3) {
      if (item.children.length > 0) {
          return (
            <SingleDiagramElementComponent
              params={this.props.params}
              hasChild={item.children.length}
              selectItemHandler={this.selectItemHandler}
              parent={parent}
              key={item.otx_id || item.key}
              addToEmptyElement={this.props.addToEmptyElement}
              addElement={this.props.addElement}
              openModal={this.props.openModal}
              toolBarHandler={this.props.toolBarHandler}
              elementWidth={elementWidth}
              onContextMenuHandler={this.onContextMenuHandler}
              item={item} >
              {this.renderDiagram(item.children, item, elementWidth)}
            </SingleDiagramElementComponent>
          )
        }
        return <SingleDiagramElementComponent
                params={this.props.params}
                hasChild={null}
                selectItemHandler={this.selectItemHandler}
                parent={parent}
                key={item.otx_id || item.key}
                addToEmptyElement={this.props.addToEmptyElement}
                addElement={this.props.addElement}
                openModal={this.props.openModal}
                toolBarHandler={this.props.toolBarHandler}
                elementWidth={elementWidth}
                onContextMenuHandler={this.onContextMenuHandler}
                item={item} />
      }
    })

    resetScrollToIndex = () => this.setState({ scrollToIndex: undefined })

    onContextMenuHandler = (e, nodeObject, option, parentNode = null, boxPosition = '') => {
      e.preventDefault()
      e.stopPropagation()
      const { contextMenuId } = this.state
      if (e.ctrlKey === false) {
        this.setState({
          nodeObject,
          option,
          parentNode,
          boxPosition
        }, contextMenu.show({
          id: contextMenuId,
          event: e
        }))
      }
    }

    copyPasteActionHandler = (node, action, boxPosition = '', isContextOptionClicked = false) => {
      if (action === 'copy' || action === 'cut') {
        this.resetScrollToIndex()
        this.copiedListHandler(node, isContextOptionClicked, action)
      } else if (action === 'paste') {
        const { params, validateBeforePaste, copiedNodes, loaderControl } = this.props
        const optionPaste = copiedNodes.action === 'copy' ? 'copy-paste' : 'cut-paste'
        const optionValidate = 'paste-validation'
        let parentNodeId = params.procedureId
        let position = 0
        switch (boxPosition) {
          case 'top':
          case 'bottom':
            if (node.parentId !== undefined) {
              const tempArr = node.parentId.split('/')
              parentNodeId = tempArr[tempArr.length - 1]
            }
            position = node.position
            if (boxPosition === 'bottom') position += 1
          break
          case '':
            parentNodeId = node.otx_id
          break
        }
        const url = `${params.projectId}/items/${params.otxFileId}/procedures/${params.procedureId}` +
        `/parent-nodes/${parentNodeId}/position/${position}/`
        const data = {
          elementsToBeCopied: copiedNodes.elementsToBeCopied,
          source: copiedNodes.source
        }
        loaderControl(true)
        validateBeforePaste(optionValidate, optionPaste, url, data, params)
      }
    }

    copiedListHandler (node, isContextOptionClicked, action) {
      const { nodesToCopyObject, copyElementHandler, params } = this.props
      const newNodesToCopyObject = returnInitValueHandler('copyObjectInit')
      const source = {
        projectId: params.projectId,
        otxId: params.otxFileId,
        procedureId: params.procedureId,
        procedureElementId: params.procedureElementId
      }
      const sourceWasChanged = !isEmpty(nodesToCopyObject.source) && !isEqual(nodesToCopyObject.source, source)
      if (nodesToCopyObject.elementsToBeCopied.includes(node.otx_id)) {
        newNodesToCopyObject.nodesToBeCopied = isContextOptionClicked
        ? [...nodesToCopyObject.nodesToBeCopied]
        : nodesToCopyObject.nodesToBeCopied.filter(el => el.otx_id !== node.otx_id)
        newNodesToCopyObject.nodesToBeCopied.forEach(el => newNodesToCopyObject.elementsToBeCopied.push(el.otx_id))
      } else {
        const containNestedNodes = !!nodesToCopyObject.nodesToBeCopied
          .filter(el => el.level > 0).length || node.level > 0
        newNodesToCopyObject.nodesToBeCopied = containNestedNodes || sourceWasChanged
        ? [] : [...nodesToCopyObject.nodesToBeCopied]
        if (params.selectedElementId) {
          const el = findNested(this.props.diagramData, params.selectedElementId)
          if (!isEmpty(el) && el.level === 0 && el.otx_id !== node.otx_id) {
            newNodesToCopyObject.nodesToBeCopied.push(el)
          }
        }
        newNodesToCopyObject.nodesToBeCopied.push(node)
        if (newNodesToCopyObject.nodesToBeCopied.length > 1) {
          newNodesToCopyObject.nodesToBeCopied.sort((a, b) => a.position - b.position)
        }
        newNodesToCopyObject.nodesToBeCopied.forEach(el => newNodesToCopyObject.elementsToBeCopied.push(el.otx_id))
      }
      if (!isEmpty(newNodesToCopyObject.elementsToBeCopied)) {
        newNodesToCopyObject.source = source
        newNodesToCopyObject.isContextOptionClicked = isContextOptionClicked
        newNodesToCopyObject.action = action
      }
      if (params.selectedElementId) {
        this.selectItem(params.selectedElementId)
      }
      copyElementHandler(newNodesToCopyObject)
    }

    selectItem = (otxId, node = {}) => {
      const { params, updateScroll } = this.props
      const isEqualToSelect = isEqual(params.selectedElementId, otxId)
      let url = `/project/${params.projectId}/otx/${params.otxFileId}/procedure/${params.procedureId}`
      if (!isEqualToSelect) {
        typeof params.procedureElementId !== 'undefined'
        ? (url += `/element/${params.procedureElementId}/selectedElement/${otxId}`)
        : (url += `/selectedElement/${otxId}`)
      } else {
        typeof params.procedureElementId !== 'undefined' &&
        (url += `/element/${params.procedureElementId}/selectedElement/${params.procedureElementId}`)
      }
      browserHistory.push(url)
      updateScroll(false)
      if (!isEmpty(node) && node.otx_id) {
        const { copyElementHandler } = this.props
        copyElementHandler(returnInitValueHandler('copyObjectInit'))
      }
    }

    selectItemHandler = (otxId, e = null, node = {}) => {
      if (e) {
        e.stopPropagation()
        e.preventDefault()
        if (e.ctrlKey || e.metaKey) {
          this.copyPasteActionHandler(node, 'copy')
        } else {
          this.selectItem(otxId, node)
        }
      }
    }

    render () {
        const {
          diagramData,
          connectDropTarget,
          isOver,
          canDrop,
          headerData,
          copiedNodes
        } = this.props
        const {
          scrollToIndex,
          scrollToAlignment,
          nodeObject,
          parentNode,
          boxPosition,
          contextMenuId,
          option
        } = this.state
        const isOverDrop = (isOver && canDrop && diagramData.length === 0)
        return (
          <div className='diagram-content-container'>
            {(headerData && (headerData.localisation || headerData.type)) &&
            <React.Fragment>
              <DiagramHeaderComponent
                params={this.props.params}
                resetScrollToIndex={this.resetScrollToIndex}
                headerData={headerData}
                isEmpty={diagramData.length > 0} />
            </React.Fragment>}
            {diagramData.length > 0
            ? <div style={{ width: '100%', height: '100vh', display: 'flex' }}>
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    style={{ outline: 'none' }}
                    width={width}
                    height={height}
                    rowHeight={this.cache.rowHeight}
                    deferredMeasurementCache={this.cache}
                    rowCount={diagramData.length}
                    scrollToIndex={scrollToIndex}
                    scrollToAlignment={scrollToAlignment}
                    rowRenderer={({ key, index, style, parent }) => {
                    const item = diagramData[index]
                    return (
                      <CellMeasurer
                        key={key}
                        cache={this.cache}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}>
                        {({ measure, registerChild }) => (
                          <div ref={registerChild}
                            style={style}>
                            <div onLoad={measure} style={{ display: 'flex', justifyContent: 'center' }}>
                              {this.renderDiagram([item], null, width)}
                            </div>
                          </div>
                        )}
                      </CellMeasurer>)
                  }}
                />
                )}
              </AutoSizer>
            </div>
            : connectDropTarget(
              <div
                id={testIds.diagramContentComponent.targetContiner}
                key={dropTargetId}
                onContextMenu={e => this.onContextMenuHandler(e, headerData, 'paste')}
                className='target-container'
                style={{
                  backgroundColor: isOverDrop ? '#f1ffef' : 'white',
                  border: isOverDrop && '1px dotted #9396a2'
                }}>
                <Popup
                  trigger={
                    <Icon name='plus' size='large' style={{ color: '#9396a2' }} />}
                  content={labels.hints.diagramContentComponent.plusIcon}
                  position='bottom center'
                  size='tiny' />
              </div>)}
            <MemoizedCopyPasteMenu
              node={nodeObject}
              option={option}
              copyPasteActionHandler={this.copyPasteActionHandler}
              copiedNodes={copiedNodes.nodesToBeCopied}
              parent={parentNode}
              boxPosition={boxPosition}
              id={contextMenuId} />
          </div>)
    }
}

export default DropTarget(dndTypes.DIAGRAM, diagramTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
  monitor
}))(DiagramContentComponent)
