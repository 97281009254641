export const numRegex = /^-?\d+$/
export const positiveNumRegex = /^\d+$/
export const floatRegex = /^[+-]?\d+(\.\d+)?$/

export const isValidInteger = int => numRegex.test(int)

export const isValidPositiveInteger = int => positiveNumRegex.test(int)

export const isValidFloat = float => floatRegex.test(float)

export const isValidOptionalInteger = int => numRegex.test(int) || int === ''

export const isValidOptionalFloat = float => floatRegex.test(float) || float === ''
