import { connect } from 'react-redux'
import StateDiagramViewComponent from './StateDiagramViewComponent.js'
import { postStateDiagram } from '../../store/thunk/reducerList/stateDiagram'
import { loaderControl } from '../../store/thunk/reducerList/loaderControl'
import { getOtxVariableList } from '../../store/thunk/reducerList/otxFile'

const mapDispatchToProps = {
  postStateDiagram,
  loaderControl,
  getOtxVariableList
}

const mapStateToProps = state => ({
  isProcedureRunning: state.procedure.isProcedureRunning,
  reachedNodeId: state.procedure.reachedNodeId,
  nodesDataStateProp: state.stateDiagram.nodesDataState,
  isActiveLoader: state.loaderControl.isActiveLoader,
  otxVariableList: state.otxFile.otxVariableList
})

export default connect(mapStateToProps, mapDispatchToProps)(StateDiagramViewComponent)
