import { connect } from 'react-redux'
import BodyDiagramComponent from './BodyDiagramComponent.js'

const mapStateToProps = state => ({
  proceduresWithParametersList: state.diagramElement.proceduresWithParametersList,
  reachedBreakPoint: state.procedure.reachedBreakPoint,
  isTesterRole: state.auth.isTesterRole,
  elementsToBeCopied: state.selectElement.nodesToCopyObject.elementsToBeCopied,
  cutElements: state.selectElement.copiedNodes.action === 'cut'
  ? state.selectElement.copiedNodes.elementsToBeCopied : []
})

export default connect(mapStateToProps)(BodyDiagramComponent)
