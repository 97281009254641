import React from 'react'
import './InfoComponent.scss'
import PropTypes from 'prop-types'

const InfoComponent = ({ title }) => {
  return (
    <div className='info-container'>
      <div className='info-container__rectangle'>
        <span>{title}</span>
      </div>
    </div>
  )
}

InfoComponent.propTypes = {
  title: PropTypes.string
}

export default InfoComponent
