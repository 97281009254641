import { connect } from 'react-redux'
import AttributesTabComponent from './AttributesTabComponent'

const mapDispatchToProps = {
}
const mapStateToProps = state => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(AttributesTabComponent)
