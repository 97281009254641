import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal, Form, Button, Message, Dropdown, Icon } from 'semantic-ui-react'
import labels from '../../../../public/labels.json'
import terms from '../../../../public/terms.json'
import { validateTerm } from '../../../store/thunk/reducerList/termValidation'
import { summaryLineHandler } from '../../../constants/summaryLineCreator'
import { iconSetter } from '../../../constants/common'
import isEmpty from 'lodash/isEmpty'
import './TermWizard.scss'

const clipboardApi = navigator.clipboard
class TermWizard extends Component {
  constructor (props) {
    super()
    this.state = {
      assignedVal: props.assignedVal,
      isJsonSyntaxError: false,
      errorMessage: '',
      selectedTermPattern: labels.modalPattern.newTerm.selectedTermPlaceholder,
      selectedVariableText: ''
    }
  }

  static propTypes = {
      onSaveChangedTermValue: PropTypes.func,
      isModalOpen: PropTypes.func,
      onCloseModal: PropTypes.func,
      assignedVal: PropTypes.array,
      condition: PropTypes.array,
      branchCondition: PropTypes.array,
      listOrMap: PropTypes.array,
      validateTerm: PropTypes.func,
      inputType: PropTypes.string,
      variableList: PropTypes.array
  }

  onTermValueChange = event => {
    try {
      this.setState({
        isJsonSyntaxError: false,
        assignedVal: JSON.parse(event.target.value)
      })
    } catch (error) {
      this.setState({
        isJsonSyntaxError: true,
        errorMessage: error
      })
    }
  }

  getSelectedTerm = termType => {
    terms.map(term => {
      if (term.type && (term.type === termType)) {
        this.setState({ selectedTermPattern: term })
      } else if (term.children) {
        this.getSelectedTermFromChildren(term, termType)
      }
    })
  }

  getSelectedTermFromChildren = (terms, termType) => {
    terms.children.map(term => {
      if (term.type && (term.type === termType)) {
        this.setState({ selectedTermPattern: term })
      } else if (term.children) {
        this.getSelectedTermFromChildren(term, termType)
      }
    })
  }

  jsonToString = assignedVal => {
    const parsedString = JSON.stringify(assignedVal, null, 1)
    return parsedString
  }

  renderMenu = termsProp => termsProp.map(term => term.name
    ? <Dropdown item text={term.name} scrolling={term.name === 'BooleanTerm'} pointing>
      <Dropdown.Menu>
        <Dropdown.Header>{labels.modalPattern.newTerm.extends}: {term.name}</Dropdown.Header>
        {!isEmpty(term.children) && this.renderMenu(term.children)}
      </Dropdown.Menu>
    </Dropdown>
    : <Dropdown.Item onClick={() => this.getSelectedTerm(term.type)}>{term.type}</Dropdown.Item>)

  saveModal = () => {
    const { assignedVal } = this.state
    const {
      onSaveChangedTermValue,
      validateTerm,
      inputType
     } = this.props
    validateTerm(assignedVal, inputType)
    .then(() => onSaveChangedTermValue(assignedVal))
  }

  variableCopyHandler (text) {
    if (clipboardApi) {
      const { selectedVariableText } = this.state
      const textToSet = text === selectedVariableText ? '' : text
      clipboardApi.writeText(textToSet)
      this.setState({ selectedVariableText: textToSet })
    }
  }

  termCopyHandler = () => {
    const { selectedTermPattern } = this.state
    clipboardApi.writeText(this.jsonToString(selectedTermPattern))
    this.setState({ selectedVariableText: selectedTermPattern })
  }

  render () {
    const { term, errors, newTerm } = labels.modalPattern
    const { buttons } = labels
    const {
      isModalOpen,
      onCloseModal,
      variableList
    } = this.props
    const {
      assignedVal,
      isJsonSyntaxError,
      errorMessage,
      selectedTermPattern,
      selectedVariableText
    } = this.state
    const summaryLine = isJsonSyntaxError || isEmpty(assignedVal)
      ? ''
      : summaryLineHandler(assignedVal).toString()
    return (
      <Modal
        open={isModalOpen}
        className='termWizard'
        centered={true}>
        <div className='termWizard__header'>
          <div className='termWizard__header-content'>
            <div className='termWizard__header-logo'>
              <span className='icon-procedure' />
            </div>
            <div className='termWizard__header-text'>
              <h2>{term.title}</h2>
              <p>{term.description}</p>
            </div>
          </div>
        </div>
        <Form className='termWizard__form' error>
          <div className='termWizard__form-container'>
            <div className='termWizard__form-container-library'>
              {clipboardApi && <Icon name='copy' className='copy-icon' onClick={this.termCopyHandler}/>}
              <div className='termWizard__form-label'>{newTerm.termsLibrary}</div>
              <div className='term-dropdown'>
                <Dropdown item text='Terms'>
                  <Dropdown.Menu style={{ width: '100%' }}>
                    <Dropdown.Header>{newTerm.extends}: {newTerm.term}</Dropdown.Header>
                    {this.renderMenu(terms)}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Form.TextArea
                style={{ resize: 'none', backgroundColor: '#F8F8F9', height: '100%' }}
                readOnly
                value={this.jsonToString(selectedTermPattern)} />
            </div>
            <div className='termWizard__form-container-term'>
              <div className='termWizard__form-label'>{newTerm.yourTerm}</div>
              <Form.TextArea
                style={{ resize: 'none', height: '100%' }}
                error={isJsonSyntaxError}
                onChange={this.onTermValueChange}
                defaultValue={this.jsonToString(assignedVal)} />
            </div>
            <div className='termWizard__form-container-variables'>
              <div className='termWizard__form-label'>{newTerm.availableVariables}</div>
              <div className='list-container'>
                {variableList.map(variable =>
                  <div
                    key={variable.key}
                    className='list-item'
                    style={selectedVariableText === variable.text
                      ? { backgroundColor: '#1E2B70', color: 'white' }
                      : { backgroundColor: 'white' }}
                    onClick={() => this.variableCopyHandler(variable.text)}>
                    <span className={iconSetter(variable.obj.type || '')} />
                    <span>{`${variable.text}: ${variable.obj.dataType.type}`}</span>
                  </div>)}
              </div>
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <div className='termWizard__form-label'>{newTerm.preview}</div>
            <Message error={isJsonSyntaxError} style={{ marginTop: 0 }}>
              <span style={{ fontWeight: 'bold', marginRight: 5 }}>{
                isJsonSyntaxError ? errors.syntaxError : 'Term:'}
              </span>
              <span>
                {isJsonSyntaxError ? errorMessage.message : summaryLine}
              </span>
            </Message>
          </div>
        </Form>
        <div className='termWizard__footer'>
          <div>
            <Button
              onClick={onCloseModal}
              content={buttons.cancel} />
          </div>
          <div>
            <Button
              disabled={isJsonSyntaxError}
              onClick={this.saveModal}
              content={buttons.save}
              positive />
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({})
const mapDispatchToProps = { validateTerm }

export default connect(mapStateToProps, mapDispatchToProps)(TermWizard)
