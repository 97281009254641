import React, { Component } from 'react'
import './BarComponent.scss'
import PropTypes from 'prop-types'
import { Icon, Popup, Menu, Select } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import { origin, protocol } from '../../../../store/addons'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { browserHistory } from 'react-router'
import ConfirmModalComponent from '../../../ConfirmModalComponent'

let stompClient = null

class BarComponent extends Component {
  constructor () {
    super()
    this.state = {
      mode: '',
      isBreakPoint: false,
      couldStop: false,
      startedOtxId: '',
      showPopup: true,
      confirmModalObject: {},
      isOpenModal: false
    }
  }

  static propTypes = {
    // props from parent
    params: PropTypes.object,
    // props from redux
    validateDiagramOtxFile: PropTypes.func,
    ssmId: PropTypes.number,
    activeConsoleIndex: PropTypes.number,
    isProcedureRunning: PropTypes.bool,
    setIsProcedureRunning: PropTypes.func,
    setActiveConsoleIndex: PropTypes.func,
    driverList: PropTypes.array,
    setDriver: PropTypes.func,
    driver: PropTypes.string,
    setLogItems: PropTypes.func,
    setVariableWatch: PropTypes.func,
    stopProcedure: PropTypes.func,
    nextStep: PropTypes.func,
    resetAllLogs: PropTypes.func,
    setSutStatus: PropTypes.func,
    sutStatusItem: PropTypes.string,
    loaderControl: PropTypes.func,
    getProject: PropTypes.func,
    setReachedBreakPoint: PropTypes.func,
    isDisabledButtonClean: PropTypes.bool,
    activateCleanButton: PropTypes.func,
    variableWatchItem: PropTypes.array,
    logItems: PropTypes.array,
    setReachedNodeId: PropTypes.func
  }

  componentDidMount () {
    this.showPopupHandler()
    this.resizeScreen()
    if (this.props.isProcedureRunning) {
      this.props.setIsProcedureRunning()
    }
  }

  componentWillUnmount () {
    this.resetWebSocketHandler()
    this.resizeScreen(false)
  }

  componentDidUpdate (prevProps) {
    const {
      isProcedureRunning,
      params,
      activateCleanButton,
      sutStatusItem,
      variableWatchItem,
      logItems,
      resetAllLogs
    } = this.props
    if (!isProcedureRunning && prevProps.isProcedureRunning) {
      activateCleanButton()
    }
    if (!isProcedureRunning &&
        (!isEmpty(logItems) || !isEmpty(variableWatchItem) || sutStatusItem !== '') &&
        (!isEqual(params.otxFileId, prevProps.params.otxFileId) ||
         !isEqual(params.procedureId, prevProps.params.procedureId))) {
      resetAllLogs()
    }
  }

  resizeScreen (didMount = true) {
    if (didMount) {
      window.addEventListener('resize', this.showPopupHandler)
    } else {
      window.removeEventListener('resize', this.showPopupHandler)
    }
  }

  showPopupHandler = () => {
    if (window.innerWidth >= 1445 && this.state.showPopup) {
      this.setState({ showPopup: false })
    } else if (window.innerWidth < 1445 && !this.state.showPopup) {
      this.setState({ showPopup: true })
    }
  }

  sendName = mode => {
    const { params } = this.props
   mode === 'run' ? stompClient.send(`/api/atena/procedures/run/${params.projectId}` +
    `/items/${params.otxFileId}/devices/${this.props.ssmId}/drivers/${this.props.driver}`)
    : stompClient.send(`/api/atena/procedures/debug/${params.projectId}` +
    `/items/${params.otxFileId}/devices/${this.props.ssmId}/drivers/${this.props.driver}`)
  }

  connect = mode => {
    const { params } = this.props
    const socket = new SockJS(`${protocol}//${origin}/api/public/stream/open`)
    this.props.resetAllLogs()
    stompClient = Stomp.over(socket)
    stompClient.debug = null
    this.setState({ startedOtxId: params.otxFileId })
    mode === 'run' ? stompClient.connect({}, () => {
      this.sendName(mode)
      stompClient.subscribe('/user/api/procedure/run/output', executionLogLine => {
        this.props.setLogItems(executionLogLine.body)
        if (executionLogLine.body.includes('START engine!')) {
          this.setState({ couldStop: true })
        }
        if (executionLogLine.body.includes('STOP engine!')) {
          this.setState({ mode: '', couldStop: false })
          this.props.setIsProcedureRunning()
          this.props.setReachedNodeId()
          this.props.getProject(params.projectId, false, false)
          if (stompClient !== null) {
            stompClient.disconnect()
            stompClient = null
          }
        }
      })
      stompClient.subscribe('/user/api/procedure/run/variables', executionLogLine => {
        this.props.setVariableWatch(JSON.parse(executionLogLine.body))
      })
      stompClient.subscribe('/user/api/procedure/run/sutstatus', executionLogLine => {
        this.props.setSutStatus(executionLogLine.body)
      })
      stompClient.subscribe('/user/api/procedure/user-input', executionLogLine => {
        this.confirmModalObjectHandler(JSON.parse(executionLogLine.body))
      })
      stompClient.subscribe('/user/api/procedure/sdv/state/reached', response => {
        this.props.setReachedNodeId(response.body)
      })
      }, () => {
        this.setState({ mode: '' })
        this.props.setIsProcedureRunning()
      }
    )
    : stompClient.connect({}, () => {
      this.sendName(mode)
      stompClient.subscribe('/user/api/procedure/debug/output', executionLogLine => {
        this.props.setLogItems(executionLogLine.body)
        if (executionLogLine.body.includes('START engine!')) {
          this.setState({ couldStop: true })
        }
        if (executionLogLine.body.includes('STOP engine!') || executionLogLine.body.includes('PARSER ERROR')) {
          this.setState({ mode: '', isBreakPoint: false, couldStop: false })
          this.props.setReachedBreakPoint()
          this.props.setReachedNodeId()
        }
      })
      stompClient.subscribe('/user/api/procedure/run/variables', executionLogLine => {
        this.props.setVariableWatch(JSON.parse(executionLogLine.body))
      })
      stompClient.subscribe('/user/api/procedure/run/sutstatus', executionLogLine => {
        this.props.setSutStatus(executionLogLine.body)
      })
      stompClient.subscribe('/user/api/procedure/debug/breakpoints/reached', executionLogLine => {
        if (executionLogLine.body === 'END') {
            browserHistory.push(`/project/${params.projectId}/otx/${params.otxFileId}/procedure/${params.procedureId}`)
            this.setState({ isBreakPoint: false, couldStop: false })
            if (stompClient !== null) {
              stompClient.disconnect()
              this.props.setReachedBreakPoint()
              this.props.setIsProcedureRunning()
              this.props.getProject(params.projectId, false, false)
              stompClient = null
            }
        } else {
          !this.state.isBreakPoint && this.setState({ isBreakPoint: true })
          const idsObj = JSON.parse(executionLogLine.body)
          let url = `/project/${params.projectId}/otx/${idsObj.otxId}/procedure/${idsObj.procedureId}`
          idsObj.parentNodeId !== '' && idsObj.parentNodeId !== idsObj.procedureId &&
          (url += `/element/${idsObj.parentNodeId}`)
          url += `/selectedElement/${idsObj.nodeId}`
          browserHistory.push(url)
          this.props.setReachedBreakPoint(idsObj.nodeId)
        }
      })
      stompClient.subscribe('/user/api/procedure/user-input', executionLogLine => {
        this.confirmModalObjectHandler(JSON.parse(executionLogLine.body))
      })
      stompClient.subscribe('/user/api/procedure/sdv/state/reached', response => {
        this.props.setReachedNodeId(response.body)
      })
    }, () => {
      this.setState({ mode: '' })
      this.props.setIsProcedureRunning()
    })
  }

  confirmModalObjectHandler = confirmModalObject => {
    if (!isEmpty(confirmModalObject)) {
      if (!isEmpty(confirmModalObject.options)) {
        const tempList = (confirmModalObject.options.map((el, index) => {
          return { text: el, value: index, key: index }
        }))
        confirmModalObject.options = tempList
      }
      this.setState({ confirmModalObject, isOpenModal: true })
    }
  }

  handleRunProcedure = mode => {
    this.setState({ mode },
      this.props.setActiveConsoleIndex(this.props.activeConsoleIndex, 1),
      this.props.setIsProcedureRunning(true),
      this.connect(mode))
  }

  handleValidateOtxFile = () => {
    const { params } = this.props
    this.props.loaderControl(true)
    this.props.validateDiagramOtxFile(params.projectId, params.otxFileId)
  }

  resetWebSocketHandler = () => {
    const {
      params,
      stopProcedure,
      isProcedureRunning,
      setIsProcedureRunning,
      setReachedBreakPoint,
      resetAllLogs
    } = this.props
    const { startedOtxId, couldStop, mode } = this.state
    if (stompClient !== null) {
      couldStop &&
      stopProcedure(params.projectId, startedOtxId || params.otxFileId, mode)
      mode === 'debug' &&
      browserHistory.push(`/project/${params.projectId}/otx/${params.otxFileId}/procedure/${params.procedureId}`)
      stompClient.disconnect()
      couldStop && this.setState({ couldStop: false })
      mode !== '' && this.setState({ mode: '' })
      stompClient = null
    }
    isProcedureRunning && setIsProcedureRunning()
    setReachedBreakPoint()
    resetAllLogs()
  }

  nextStepHandler = (step) => {
    const { params, nextStep } = this.props
    const { startedOtxId } = this.state
    nextStep(params.projectId, startedOtxId, step)
    this.state.isBreakPoint && this.setState({ isBreakPoint: false })
  }

  closeModal = isOpenModal => this.setState({ isOpenModal })

  render () {
    const {
      params,
      driver,
      isProcedureRunning,
      driverList,
      sutStatusItem,
      isDisabledButtonClean,
      ssmId
    } = this.props
    const {
      couldStop,
      startedOtxId,
      mode,
      isBreakPoint,
      showPopup,
      confirmModalObject,
      isOpenModal
    } = this.state
    const { barComponent } = labels
    return (
      <div className='barComponent'>
        <div className='barComponent__left-container'>
          <Popup
            content={isProcedureRunning && !isEmpty(sutStatusItem) ? sutStatusItem : 'DISCONNECT'}
            position='bottom center'
            trigger={<div className={`barComponent__left-container-${
              isProcedureRunning &&
              !isEmpty(sutStatusItem) &&
              sutStatusItem !== 'DISCONNECT'
              ? 'led-green' : 'led-red'}`} />} />
          <Select
            onChange={(event, { value }) => this.props.setDriver(value)}
            fluid
            value={driver}
            placeholder={labels.modalPattern.inputs.driver.placeholder}
              options={driverList} />
        </div>
        <div className='barComponent__right-container'>
          <Menu>
            <Menu.Item
              data-test={`barButton-${barComponent.run.name}`}
              disabled={
                ssmId === -1 ||
                driver === '' ||
                isProcedureRunning ||
                typeof params.projectId === 'undefined' ||
                typeof params.procedureId === 'undefined' ||
                typeof params.otxFileId === 'undefined'}
              onClick={() => this.handleRunProcedure('run')}
              active={false}>
              {showPopup
              ? <Popup
                trigger={<span className='icon-run'/>}
                content={barComponent.run.name}
                position='bottom center'/>
              : <span className='icon-run'>{barComponent.run.name}</span>}
            </Menu.Item>
            <Menu.Item
              data-test={`barButton-${barComponent.debug.name}`}
              disabled={
                ssmId === -1 ||
                driver === '' ||
                isProcedureRunning ||
                typeof params.projectId === 'undefined' ||
                typeof params.procedureId === 'undefined' ||
                typeof params.otxFileId === 'undefined'}
              onClick={() => this.handleRunProcedure('debug')}>
              {showPopup
              ? <Popup
                trigger={<span className='icon-debug'/>}
                content={barComponent.debug.name}
                position='bottom center'/>
              : <span className='icon-debug'>{barComponent.debug.name}</span>}
            </Menu.Item>
            <Menu.Item
              data-test={`barButton-${barComponent.validate.name}`}
              disabled={
                isProcedureRunning ||
                typeof params.projectId === 'undefined' ||
                typeof params.procedureId === 'undefined' ||
                typeof params.otxFileId === 'undefined'}
              active={false}
              onClick={() => this.handleValidateOtxFile()} >
              {showPopup
              ? <Popup
                trigger={<span className='icon-validation'/>}
                content={barComponent.validate.name}
                position='bottom center'/>
              : <span className='icon-validation'>{barComponent.validate.name}</span>}
            </Menu.Item>
            <Menu.Item
              data-test={`barButton-${barComponent.stop.name}`}
              disabled={
                driver === '' ||
                !couldStop}
              onClick={() =>
                this.props.stopProcedure(params.projectId, startedOtxId || params.otxFileId, mode)}>
              {showPopup
              ? <Popup
                trigger={<span className='icon-stop-filled'/>}
                content={barComponent.stop.name}
                position='bottom center'/>
              : <span className='icon-stop-filled'>{barComponent.stop.name}</span>}
            </Menu.Item>
            <Menu.Item
              data-test={`barButton-${barComponent.stepOver.name}`}
              onClick={() => this.nextStepHandler('step-over')}
              disabled={!(mode === 'debug' && isProcedureRunning && isBreakPoint)}>
              {showPopup
              ? <Popup
                trigger={<span className='icon-step-over-filled'/>}
                content={barComponent.stepOver.name}
                position='bottom center'/>
              : <span className='icon-step-over-filled'>{barComponent.stepOver.name}</span>}
            </Menu.Item>
            <Menu.Item
              data-test={`barButton-${barComponent.stepInto.name}`}
              onClick={() => this.nextStepHandler('step-into')}
              disabled={!(mode === 'debug' && isProcedureRunning && isBreakPoint)}>
              {showPopup
              ? <Popup
                trigger={<span className='icon-import-filled'/>}
                content={barComponent.stepInto.name}
                position='bottom center'/>
              : <span className='icon-import-filled'>{barComponent.stepInto.name}</span>}
            </Menu.Item>
            <Menu.Item
              data-test={`barButton-${barComponent.stepOut.name}`}
              onClick={() => this.nextStepHandler('step-out')}
              disabled={!(mode === 'debug' && isProcedureRunning && isBreakPoint)}>
              {showPopup
              ? <Popup
                trigger={<span className='icon-export-filled'/>}
                content={barComponent.stepOut.name}
                position='bottom center'/>
              : <span className='icon-export-filled'>{barComponent.stepOut.name}</span>}
            </Menu.Item>
            <Menu.Item
              disabled={!(mode === 'debug' && isProcedureRunning && isBreakPoint)}
              data-test={`barButton-${barComponent.nextBreakpoint.name}`}
              onClick={() => this.nextStepHandler('step-breakpoint')}>
              {showPopup
              ? <Popup
                trigger={<span className='icon-next-breakpoint-filled'/>}
                content={barComponent.nextBreakpoint.name}
                position='bottom center'/>
              : <span className='icon-next-breakpoint-filled'>{barComponent.nextBreakpoint.name}</span>}
            </Menu.Item>
            <Menu.Item
              onClick={this.resetWebSocketHandler}
              data-test={`barButton-${barComponent.reset.name}`}
              disabled={!isProcedureRunning}>
              {showPopup
              ? <Popup
                trigger={<Icon name='refresh'/>}
                content={barComponent.reset.name}
                position='bottom center'/>
              : <span><Icon name='refresh'/>{barComponent.reset.name}</span>}
            </Menu.Item>
            <Menu.Item
              disabled={isProcedureRunning || isDisabledButtonClean}
              data-test={`barButton-${barComponent.clean.name}`}
              onClick={() => this.props.resetAllLogs()}>
              {showPopup
              ? <Popup
                trigger={<span className='icon-remove'/>}
                content={barComponent.clean.name}
                position='bottom center'/>
              : <span className='icon-remove'>{barComponent.clean.name}</span>}
            </Menu.Item>
          </Menu>
        </div>
        {isOpenModal &&
        <ConfirmModalComponent
          confirmModalObject={confirmModalObject}
          closeModal={this.closeModal}
          params={params} />}
      </div>
    )
  }
}
export default BarComponent
