import { isEqual, isEmpty } from 'lodash'
import labels from '../../public/labels.json'
import {
  globalDeclarationList,
  validitiesList,
  signaturesList,
  proceduresList,
  parametersList,
  localDeclarationList,
  ImportList,
  ThrowList,
  throwTypes,
  contextMenusupportedNames,
  contextMenusupportedTypes
} from './contextMenuLists'
import { browserHistory } from 'react-router'
import {
  allActionTypes,
  loopsList,
  conditionnalsList,
  endedList,
  elementTypesWithVariableList,
  allElementTypes,
  flowElementsList,
  inheritsFromFlowList,
  isNodesList,
  isBreakPointList,
  ssmExtentionList
} from './ItemTypes'

export const idChanger = () => (new Date()).valueOf() + Math.random()

export const iconSetter = type => {
  switch (type) {
    case 'Action':
      return 'icon-action-filled'
    case 'ProcedureCall':
      return 'icon-procedure-filled'
    case 'Group':
      return 'icon-group-filled'
    case 'Parallel':
      return 'icon-pause-filled'
    case 'Handler':
      return 'icon-handler-filled'
    case 'Loop':
      return 'icon-loop-filled'
    case 'Branch':
      return 'icon-branch-filled'
    case 'End':
      return 'icon-end-filled'
    case 'MapUnion':
      return 'icon-union-filled'
    case 'MapRemove':
      return 'icon-remove-item-filled'
    case 'MapClear':
      return 'icon-clear-filled'
    case 'MapPut':
      return 'icon-add-item-filled'
    case 'GroupElement':
      return 'icon-group-element-filled'
    case 'Lane':
      return 'icon-lane-filled'
    case 'CatchingFlow':
      return 'icon-handler-catch-filled'
    case 'Finally':
      return 'icon-handler-finally-filled'
    case 'MutexGroup':
      return 'icon-mutex-filled'
    case 'Try':
      return 'icon-handler-filled'
    case 'ForLoop':
    case 'ForEachLoop':
      return 'icon-undo-filled'
    case 'WhileLoop':
      return 'icon-flow-filled'
    case 'If':
    case 'ElseIf':
    case 'Else':
      return 'icon-if-filled'
    case 'Break':
      return 'icon-break-filled'
    case 'Continue':
      return 'icon-continue-filled'
    case 'Return':
      return 'icon-return-filled'
    case 'TerminateLanes':
      return 'icon-terminate-filled'
    case 'Throw':
      return 'icon-throws-filled'
    case 'ListRemove':
      return 'icon-remove-item-filled'
    case 'ListClear':
      return 'icon-clear-filled'
    case 'ListAppend':
      return 'icon-add-item-filled'
    case 'ListInsert':
      return 'icon-insert-filled'
    case 'ListConcatenate':
      return 'icon-union-filled'
    case 'BitSet':
      return 'icon-set-bit-filled'
    case 'BitShiftLeft':
      return 'icon-arrow-left-filled'
    case 'BitShiftRight':
      return 'icon-arrow-right-filled'
    case 'BitReplace':
      return 'icon-replace-filled'
    case 'BitAppend':
      return 'icon-add-item-filled'
    case 'AddDataListener':
    case 'AddEventListener':
    case 'AddPackageListener':
      return 'icon-ssm-listener-filled'
    case 'InvokeActivity':
      return 'icon-ssm-invoke-filled'
    case 'InvokeActivityAndWait':
      return 'icon-ssm-invoke-filled'
    case 'TerminateActivity':
      return 'icon-ssm-terminate-filled'
    case 'GetActivityState':
      return 'icon-ssm-state-filled'
    case 'Connect':
      return 'icon-connect-filled'
    case 'Disconnect':
      return 'icon-disconnect-filled'
    case 'Wait':
      return 'icon-wait-filled'
    case 'Assignment':
      return 'icon-assiagn-filled'
    case 'Import':
      return 'icon-arrow-down-filled'
    case 'Validity':
    case 'ConfirmDialog':
      return 'icon-validation-filled'
    case 'GlobalConstantDeclaration':
    case 'ConstantDeclaration':
      return 'icon-constant-filled'
    case 'ContextVariableDeclaration':
      return 'icon-context-filled'
    case 'DocumentVariableDeclaration':
    case 'VariableDeclaration':
      return 'icon-variable-filled'
    case 'InParameter':
      return 'icon-arrow-down-filled'
    case 'OutParameter':
      return 'icon-arrow-up-filled'
    case 'InOutParameter':
      return 'icon-arrow-up-down-filled'
    case 'Signature':
      return 'icon-signatures'
    case 'report':
      return 'icon-document'
    case 'FILE':
      return 'icon-signatures'
    case 'Procedure':
    case 'ServerCommand':
      return 'icon-procedure-filled'
    case 'activity argument':
    case 'activity':
      return 'icon-activity-filled'
    case 'reporting data':
      return 'icon-data-filled'
    case 'event':
      return 'icon-event-filled'
    case 'WriteLog':
      return 'icon-write-log-filled'
    case 'InputDialog':
      return 'icon-input-filled'
    case 'ChoiceDialog':
      return 'icon-choice-filled'
    case 'pasteValidation':
      return 'icon-warning-filled'
    default:
      return type.includes('Exception')
        ? 'icon-throws-filled'
        : 'icon-debug'
  }
}

export const colorsAndIconsSetter = item => {
  const icon = iconSetter(item.type)
  const colors = {
    action: { textOnDark: '#fbf1f0', textOnLight: '#091032', headerColor: '#de4426', icon },
    group: { textOnDark: '#f6f5f5', textOnLight: '#091032', headerColor: '#ab351d', icon },
    parallel: { textOnDark: '#f5f2f2', textOnLight: '#091032', headerColor: '#5e352c', icon },
    handler: { textOnDark: '#f6f4f5', textOnLight: '#091032', headerColor: '#6b2d44', icon },
    loop: { textOnDark: '#f0fbf7', textOnLight: '#091032', headerColor: '#27686f', icon },
    branch: { textOnDark: '#f6f7fa', textOnLight: '#091032', headerColor: '#1e2b6f', icon },
    mutex: { textOnDark: '#f6f7fa', textOnLight: '#091032', headerColor: '#1a444a', icon },
    end: { textOnDark: '#f9f6fd', textOnLight: '#091032', headerColor: '#440e74', icon },
    disabled: { textOnDark: 'lightgray', textOnLight: 'black', headerColor: 'gray', icon }
  }

  if (typeof item.disabled !== 'undefined' && item.disabled) {
    return colors.disabled
  } else if (item.type === 'Group' || item.type === 'GroupElement') {
    return colors.group
  } else if (item.type === 'Parallel' || item.type === 'Lane') {
    return colors.parallel
  } else if (item.type === 'Handler' || item.type === 'Try' ||
    item.type === 'CatchingFlow' || item.type === 'Finally') {
    return colors.handler
  } else if (item.type === 'Loop' || item.type === 'ForLoop' ||
    item.type === 'ForEachLoop' || item.type === 'WhileLoop') {
    return colors.loop
  } else if (item.type === 'MutexGroup') {
    return colors.mutex
  } else if (item.type === 'Branch' || item.type === 'If' || item.type === 'ElseIf' || item.type === 'Else') {
    return colors.branch
  } else if (isDiagramEnded(item.type)) {
    return colors.end
  } else {
    return colors.action
  }
}

export const couldDrop = (item, parent, props) => {
  if (!parent) {
    if (isDiagramEnded(item.type) && props.boxPosition === 'bottom' && !isDiagramEnded(props.item.type)) {
      return true
    } else if (isDiagramEnded(props.item.type) && props.boxPosition === 'top' && !isDiagramEnded(item.type)) {
        return (isNode(item.type) ||
        (item.kind === 'tree_list' &&
        item.type !== 'ElseIf' &&
        item.type !== 'Else'))
    }
    return !isDiagramEnded(props.item.type) &&
            (isNode(item.type) ||
            (item.kind === 'tree_list' &&
            item.type !== 'ElseIf' &&
            item.type !== 'Else' &&
            !isDiagramEnded(item.type)))
  } else if (!isDiagramEnded(props.item.type)) {
    if (isDiagramEnded(item.type)) {
      return (parent.type === 'MutexGroup') ||
        (!props.boxPosition && isFlow(parent.type)) ||
        (props.boxPosition === 'bottom' && isFlow(parent.type))
    }
    switch (parent.type) {
      case 'Action':
        return isActionRealisation(item.type)
      case 'Branch':
        return isSubTreeAddEnalbed('If', 'ElseIf', 'Else', item, parent, props)
      case 'Loop':
        return (item.type === 'ForLoop' || item.type === 'ForEachLoop' ||
        item.type === 'WhileLoop') && parent.children.length === 0
      case 'Group':
        return item.type === 'GroupElement'
      case 'Parallel':
        return item.type === 'Lane'
      case 'Handler':
        return isSubTreeAddEnalbed('Try', 'CatchingFlow', 'Finally', item, parent, props)
      default: // 'branchFlow' || 'loopRealization' || 'groupFlow' || 'parallelFlow' || 'handlerFlow' || 'mutexFlow'
        if (parent.type) {
          if (!isActionRealisation(parent.type) &&
              item.type !== 'ElseIf' &&
              item.type !== 'Else' &&
              (isNode(item.type) || item.kind === 'tree_list')) {
            return true
          }
        }
        return false
    }
  } else if (isDiagramEnded(props.item.type) && props.boxPosition === 'top' && !isDiagramEnded(item.type) && !parent) {
    return (isNode(item.type) ||
    (item.kind === 'tree_list' &&
    item.type !== 'ElseIf' &&
    item.type !== 'Else'))
}
  return false
}

export const isActionRealisation = type => allActionTypes.includes(type)

export const isLoop = itemType => loopsList.includes(itemType)

export const isCondition = itemType => conditionnalsList.includes(itemType)

const isSubTreeAddEnalbed = (first, second, third, item, parent, props) => {
  if (item.type === first) {
    return (!props.boxPosition || (props.boxPosition === 'top' &&
    parent.children.filter(element => element.type === first).length === 0))
  } else if (item.type === second) {
    return ((props.boxPosition === 'top' &&
      (props.item.type === third || props.item.type === second))) ||
      (props.boxPosition === 'bottom' && (props.item.type === first || props.item.type === second))
  } else if (item.type === third) {
    return props.boxPosition === 'bottom' && props.item.type !== third
  }
  return false
}

export const isDiagramEnded = itemType => endedList.includes(itemType)

export const getDiagramTerm = item => {
  if (!item.children.length) {
    if (item.term !== undefined) {
      return ((typeof item.term.value !== 'undefined' &&
      item.term.value !== null &&
      item.term.value.toString()) || item.term.valueOf)
    }
    return ((typeof item.value !== 'undefined' &&
            item.value !== null &&
            item.value.toString()) || item.valueOf)
  }
  let response = `${item.type}(`
  for (let i = 0; i < item.children.length; i++) {
    response += getDiagramTerm(item.children[i]) + ', '
  }
  return (response.substring(0, response.length - 2) + ')')
}

export const isFlow = type => flowElementsList.includes(type)

export const inheritsFromFlow = type => inheritsFromFlowList.includes(type)

export const isNode = type => isNodesList.includes(type)

export const diagramElementModifer = (itemToAdd, placeToAdd, props) => {
    switch (itemToAdd.type) {
      case 'Assignment':
      case 'ProcedureCall':
      case 'ListRemove':
      case 'ListClear':
      case 'ListAppend':
      case 'ListInsert':
      case 'ListConcatenate':
      case 'MapUnion':
      case 'MapRemove':
      case 'MapClear':
      case 'MapPut':
      case 'BitSet':
      case 'BitShiftLeft':
      case 'BitShiftRight':
      case 'BitAppend':
      case 'BitReplace':
      case 'AddDataListener':
      case 'AddPackageListener':
      case 'InvokeActivity':
      case 'InvokeActivityAndWait':
      case 'TerminateActivity':
      case 'GetActivityState':
      case 'Connect':
      case 'Disconnect':
      case 'Wait':
      case 'AddEventListener':
      case 'WriteLog':
      case 'ChoiceDialog':
      case 'InputDialog':
      case 'ConfirmDialog':
      case 'ServerCommand':
        if (placeToAdd === null ||
            ((props.boxPosition && !isActionRealisation(placeToAdd.type)) ||
            (!props.boxPosition && placeToAdd.type !== 'Action'))) {
          const tempToAdd = {
            name: 'Action',
            kind: 'diagram_list',
            type: 'Action',
            id: `${idChanger()}`,
            key: `${idChanger()}`,
            isSelect: false,
            parentId: props.item ? props.item.parentId : localStorage.getItem('idProcedure'),
            position: props.index ? props.index : 0,
            children: [newDiagramElementModifier(itemToAdd, props)]
          }
          return tempToAdd
        }
        return newDiagramElementModifier(itemToAdd, props)
      case 'GroupElement':
        if ((placeToAdd === null || (placeToAdd.level === 0 && props.boxPosition)) ||
            (((placeToAdd.type !== 'Group' && !props.boxPosition) ||
              (props.parent && props.parent.type !== 'Group')))) {
          const tempToAdd = {
            name: 'Group',
            kind: 'diagram_list',
            type: 'Group',
            id: `${idChanger()}`,
            key: `${idChanger()}`,
            children: [itemToAdd]
          }
          return tempToAdd
        }
        return itemToAdd
      case 'Lane':
        if (placeToAdd === null ||
            ((props.boxPosition && placeToAdd.type !== 'Lane') ||
            (!props.boxPosition && placeToAdd.type !== 'Parallel'))) {
          const tempToAdd = {
            name: 'Parallel',
            kind: 'diagram_list',
            type: 'Parallel',
            id: `${idChanger()}`,
            key: `${idChanger()}`,
            children: [itemToAdd]
          }
          return tempToAdd
        }
        return itemToAdd
      case 'Try':
      case 'CatchingFlow':
      case 'Finally':
        if (placeToAdd === null ||
            (props.boxPosition &&
             placeToAdd.type !== 'Try' &&
             placeToAdd.type !== 'CatchingFlow' &&
             placeToAdd.type !== 'Finally') ||
            (!props.boxPosition && placeToAdd.type !== 'Handler')) {
          const tempTry = {
            name: 'Try',
            kind: 'diagram_list',
            type: 'Try',
            id: `${idChanger()}`,
            key: `${idChanger()}`,
            children: []
          }
          const tempToAdd = {
            name: 'Handler',
            kind: 'diagram_list',
            type: 'Handler',
            id: `${idChanger()}`,
            key: `${idChanger()}`,
            children: []
          }
          switch (itemToAdd.type) {
            case 'Try':
              tempToAdd.children = [itemToAdd]
              return tempToAdd
            case 'Finally':
              tempToAdd.children = [tempTry, itemToAdd]
              return tempToAdd
            case 'CatchingFlow':
              tempToAdd.children = [tempTry, itemToAdd]
              return tempToAdd
            default:
              return itemToAdd
          }
        }
        return itemToAdd
      case 'ForLoop':
      case 'ForEachLoop':
      case 'WhileLoop':
          if (placeToAdd === null ||
              ((props.boxPosition &&
                placeToAdd.type !== 'ForLoop' &&
                placeToAdd.type !== 'ForEachLoop' &&
                placeToAdd.type !== 'WhileLoop') ||
              (!props.boxPosition && placeToAdd.type !== 'Loop'))) {
          const tempToAdd = {
            name: 'Loop',
            kind: 'diagram_list',
            type: 'Loop',
            id: `${idChanger()}`,
            key: `${idChanger()}`,
            children: [itemToAdd]
          }
          return tempToAdd
        }
        return itemToAdd
      case 'If':
        if ((placeToAdd === null ||
            (placeToAdd.type === 'Branch' && props.boxPosition) ||
            placeToAdd.type !== 'Branch')) {
          const tempToAdd = {
            name: 'Branch',
            kind: 'diagram_list',
            type: 'Branch',
            id: `${idChanger()}`,
            key: `${idChanger()}`,
            children: [itemToAdd]
          }
          return tempToAdd
        }
        return itemToAdd
      default:
        return newDiagramElementModifier(itemToAdd, props)
    }
}

const newDiagramElementModifier = (itemToAdd, props) => {
  const tempItemToAdd = Object.assign({
    name: itemToAdd.name,
    type: itemToAdd.type,
    position: props.index ? props.boxPosition === 'top' ? props.index : props.index + 1 : 0,
    parentId: props.item ? props.item.parentId : localStorage.getItem('idProcedure'),
    key: `${idChanger()}`,
    kind: 'diagram_list',
    isSelect: false,
    id: `${idChanger()}`,
    children: []
  })
  return tempItemToAdd
}

export const isContainItself = (children, item) => {
  const tempArr = []
  const checkFunc = child => child.filter(obj => {
    if (isEqual(item, obj)) {
      tempArr.push(obj)
    } else if (obj.children.length > 0) {
      checkFunc(obj.children)
    }
  })
  checkFunc(children)
  if (tempArr && tempArr[0]) {
    return false
  }
  return true
}

export const isSupportedElement = type => allElementTypes.includes(type)

export const isPosibbleToRemove = item =>
!((item.type === 'Try' && item.hasSiblings) || (item.type === 'If' && item.hasSiblings))

export const contextMenuListHandler = item => {
  const { contextMenu } = labels
  switch (item.name) {
    case 'Global Declarations':
      return globalDeclarationList
    case 'Validities':
      return validitiesList
    case 'Signatures':
      return signaturesList
    case 'Parameters':
      return parametersList
    case 'Local Declarations':
      return localDeclarationList
    case 'Imports':
      return ImportList
    case 'Throws':
      return ThrowList
    case 'IN':
      return [parametersList[0]]
    case 'INOUT':
      return [parametersList[1]]
    case 'OUT':
      return [parametersList[2]]
    case 'CONSTANTS':
      return item.key.slice(0, 3) === '0-3' ? [localDeclarationList[1]] : [globalDeclarationList[0]]
    case 'VARIABLES':
      return item.key.slice(0, 3) === '0-3' ? [localDeclarationList[0]] : [globalDeclarationList[1]]
    case 'CONTEXTS':
      return [globalDeclarationList[2]]
    default:
      if (item.type === 'Else' || item.type === 'Lane' || item.type === 'Try' || item.type === 'Finally') {
        return contextMenu.remove
      } else if (item.type === 'If' && item.hasSiblings !== undefined) {
        return contextMenu.edit
      } else if (throwTypes.includes(item.name)) {
        if (item.type === 'UserException') {
          return contextMenu.editRemove
        } else {
          return contextMenu.remove
        }
      } else if (item.name === 'Procedures' && item.itemType === undefined) {
        return proceduresList
      } else if (item.type === 'node') {
        return contextMenu.download
      } else if (item.name && item.name.includes('.otx') && item.itemType === 'FILE') {
        return contextMenu.downloadEditRemove
      } else if (item.name && item.generated !== undefined) {
        return contextMenu.reports
      } else if (item.itemType === 'PACKAGE' && item.packageName !== undefined) {
        return contextMenu.remove
      }
      return contextMenu.editRemove
  }
}

export const throwElementRegex = new RegExp('^0-3-[0-9]-2-[0-9]$')

export const showContextMenu = (item, position, isTesterRole) => {
  if (isTesterRole && position === 'top') {
    return item.itemType !== undefined && item.itemType === 'report'
  } else if (item.type !== 'Try' && !isTesterRole) {
    return (couldDownloadFile(item, position) ||
         isGlobalDeclaration(item) ||
         isParameter(item) ||
         contextMenusupportedNames.includes(item.name) ||
         contextMenusupportedTypes.includes(item.type) ||
         (item.itemType !== undefined && item.itemType === 'PACKAGE') ||
         throwTypes.includes(item.type) ||
         (item.name === 'Procedures' && position === 'bottom')) ||
         (item.type && item.type !== 'node' && (isFlow(item.type) || isNode(item.type) || isDiagramEnded(item.type)))
  }
  return !isTesterRole && item.hasSiblings === undefined
}

const couldDownloadFile = (item, position) =>
(position === 'top' && item.itemType !== 'Execution' && !item.parents.length) || item.name.includes('.otx')

export const isGlobalDeclaration = item => {
  return item.type &&
  (item.type === 'GlobalConstantDeclaration' ||
   item.type === 'DocumentVariableDeclaration' ||
   item.type === 'ContextVariableDeclaration')
}

export const isParameter = item => {
  return item.type &&
  (item.type === 'OutParameter' ||
   item.type === 'InParameter' ||
   item.type === 'InOutParameter')
}

export const isLocalDeclaration = item => {
  return item.type &&
  (item.type === 'VariableDeclaration' ||
   item.type === 'ConstantDeclaration')
}

export const setSpecificationTitle = item => {
  const { modalPattern } = labels
  const itemType = item.children.length ? item.children[0].type : item.type
  switch (itemType) {
    case 'Action':
      return modalPattern.action.description
    case 'Assignment':
      return modalPattern.assignAValue.description
    case 'ProcedureCall':
      return modalPattern.callAProcedure.description
    case 'Loop':
      return modalPattern.loop.description
    case 'ForLoop':
      return modalPattern.forLoop.description
    case 'ForEachLoop':
      return modalPattern.forEachLoop.description
    case 'WhileLoop':
      return modalPattern.whileLoop.description
    case 'Branch':
      return modalPattern.branch.description
    case 'If':
      return modalPattern.newConditionalIfLane.description
    case 'ElseIf':
      return modalPattern.branch.description
    case 'Else':
      return modalPattern.branch.description
    case 'Group':
      return modalPattern.group.description
    case 'GroupElement':
      return modalPattern.groupNextOption.description
    case 'Parallel':
      return modalPattern.parallel.description
    case 'Lane':
      return modalPattern.parallel.description
    case 'MutexGroup':
      return modalPattern.mutexGroup.description
    case 'Handler':
      return modalPattern.handler.description
    case 'Try':
      return modalPattern.handler.description
    case 'CatchingFlow':
      return modalPattern.handler.description
    case 'Finally':
      return modalPattern.handler.description
    case 'ListRemove':
      return modalPattern.removeListItems.description
    case 'ListClear':
      return modalPattern.clearAList.description
    case 'ListAppend':
      return modalPattern.appendItemsToAList.description
    case 'ListInsert':
      return modalPattern.insertItemsToAList.description
    case 'ListConcatenate':
      return modalPattern.concatenateLists.description
    case 'MapUnion':
      return modalPattern.unionMaps.description
    case 'MapRemove':
      return modalPattern.removeMapItems.description
    case 'MapClear':
      return modalPattern.clearAMap.description
    case 'MapPut':
      return modalPattern.putItemsToAMap.description
    case 'BitSet':
      return modalPattern.setABit.description
    case 'BitShiftLeft':
      return modalPattern.shiftByteFieldLeft.description
    case 'BitShiftRight':
      return modalPattern.shiftByteFieldRight.description
    case 'BitAppend':
      return modalPattern.appendAByteField.description
    case 'BitReplace':
      return modalPattern.replaceASubByteField.description
    case 'Break':
      return modalPattern.breakALoop.description
    case 'Continue':
      return modalPattern.continueALoop.description
    case 'Return':
      return modalPattern.completeAProcedure.description
    case 'TerminateLanes':
      return modalPattern.terminateLanes.description
    case 'Throw':
      return modalPattern.throwException.description
    case 'AddDataListener':
      return modalPattern.addDataListener.description
    case 'AddPackageListener':
      return modalPattern.addPackageListener.description
    case 'InvokeActivity':
      return modalPattern.invokeActivity.description
    case 'InvokeActivityAndWait':
      return modalPattern.invokeActivityAndWait.description
    case 'TerminateActivity':
      return modalPattern.terminateActivity.description
    case 'GetActivityState':
      return modalPattern.getActivityState.description
    case 'Connect':
      return modalPattern.connect.description
    case 'Disconnect':
      return modalPattern.disconnect.description
    case 'Wait':
      return modalPattern.wait.description
    case 'AddEventListener':
      return modalPattern.addEventListener.description
    case 'WriteLog':
      return modalPattern.writeLog.description
    case 'ChoiceDialog':
      return modalPattern.choiceDialog.description
    case 'InputDialog':
      return modalPattern.inputDialog.description
    case 'ConfirmDialog':
      return modalPattern.confirmDialog.description
    case 'ServerCommand':
      return modalPattern.serverCommand.description
    default:
      return '--- UNDEFINED ---'
  }
}

export const isThrows = item => {
  return item.type &&
  (item.type === 'Exception' ||
  item.type === 'ConnectionException' ||
  item.type === 'UserException' ||
  item.type === 'InvalidReferenceException' ||
  item.type === 'ArithmeticException' ||
  item.type === 'ConcurrentModificationException' ||
  item.type === 'AmbiguousCallException' ||
  item.type === 'OutOfBoundsException' ||
  item.type === 'TypeMismatchException')
}

export const createOptionVariablesList = variableList => {
  const optionsVariableList = []
  variableList.map(variable => {
    const variableOption = {
      value: variable.value,
      key: variable.value + Math.random(),
      text: `${variable.text} : ${variable.obj.dataType === null ? 'NULL_OBJECT'
      : variable.obj.dataType.type}`,
      type: variable.obj.dataType.type,
      object: variable
    }
    optionsVariableList.push(variableOption)
  })
  return optionsVariableList
}

export const shouldGetVariableList = item => elementTypesWithVariableList.includes(item.type) ||
  (item.type === 'Action' && item.children[0] && elementTypesWithVariableList.includes(item.children[0].type))

export const isNull = item => item === null

export const isBreakPoint = type => isBreakPointList.includes(type)

export const setProcedureIfExist = (procedures, projectId, id) => {
  let searchingId = null
  if (!window.location.pathname.includes('/procedure/') &&
      procedures &&
      !isEmpty(procedures)) {
    const defaultProcedure = procedures.filter(element => element.otx_name === 'main')
    searchingId = !isEmpty(defaultProcedure)
    ? defaultProcedure[0].otx_id
    : procedures[0].otx_id
  }
  searchingId !== null &&
  browserHistory.push(`/project/${projectId}/otx/${id}/procedure/${searchingId}`)
}

export const isDiagramElement = type => {
  switch (type) {
    case 'Validity':
    case 'GlobalConstantDeclaration':
    case 'DocumentVariableDeclaration':
    case 'ContextVariableDeclaration':
    case 'ConcurrentModificationException':
    case 'InParameter':
    case 'OutParameter':
    case 'InOutParameter':
    case 'ConstantDeclaration':
    case 'VariableDeclaration':
      return false
    default:
      return true
  }
}

export const storeCollapsedElements = (nodes, localStorageName) => {
  const tempArr = []
  const loop = data => {
    data.forEach(item => {
      item.id !== undefined &&
      item.state !== undefined &&
      item.state.expanded === false &&
      tempArr.push(item.id)
      if (item.children && item.children.length) {
        return loop(item.children)
      }
    })
  }
  loop(nodes)
  localStorage.setItem(localStorageName, JSON.stringify(tempArr))
}

export const fontColorSetter = element => {
  if (element.includes('WARNING')) {
    return 'warning-font'
  } else if (element.includes('ERROR')) {
    return 'error-font'
  } else if (element.includes('DANGEROUS')) {
    return 'dangerous-font'
  } else if (element.includes('success')) {
    return 'success-font'
  } else {
    return 'normal-font'
  }
}

export const isSsmExtention = itemType => ssmExtentionList.includes(itemType)
