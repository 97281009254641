import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Popup } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import { nameRegex } from '../../../../store/addons'
import { createOptionVariablesList } from '../../../../constants/common'
import testIds from '../../../../../public/testIds.json'
export default class EndWizard extends Component {
  constructor (props) {
    super()
    this.state = {
      exceptionType: 'UserException',
      exceptionTypeError: false,
      qualifier: props.item.realisation && props.item.realisation.qualifier ? props.item.realisation.qualifier : '',
      qualifierError: false,
      text: props.item.realisation && props.item.realisation.text ? props.item.realisation.text : '',
      textError: false,
      basicName: (props.modalItemToAdd && props.modalItemToAdd.otx_name) ? props.modalItemToAdd.otx_name : '',
      basicSpecification: (props.modalItemToAdd && props.modalItemToAdd.otx_specification)
      ? props.modalItemToAdd.otx_specification : '',
      itemToAdd: {},
      targetLoopToBreak: (props.modalItemToAdd.type === 'Break' && props.modalItemToAdd.target !== undefined)
      ? props.modalItemToAdd.target : '',
      targetLoopToContinue: (props.modalItemToAdd.type === 'Continue' && props.modalItemToAdd.target !== undefined)
      ? props.modalItemToAdd.target : '',
      basicNameError: false,
      shouldGetExceptionType: true,
      optionsList: [],
      exceptionVariable: '',
      exceptionVariableError: false,
      parentLoopOptions: []
    }
  }

  static propTypes = {
    // props from parent
    modalItemToAdd: PropTypes.object,
    saveModal: PropTypes.func,
    closeModal: PropTypes.func,
    item: PropTypes.object,
    params: PropTypes.object,
    // props from redux
    loaderControl: PropTypes.func,
    getVariableList: PropTypes.func,
    getParentLoops: PropTypes.func,
    openAddVariable: PropTypes.func,
    variableList: PropTypes.array,
    parentLoops: PropTypes.array,
    newVariable: PropTypes.object
  }

  componentDidMount = () => {
    const { params, getVariableList, item } = this.props
    if (typeof params.projectId !== 'undefined' &&
        typeof params.otxFileId !== 'undefined' &&
        typeof params.procedureId !== 'undefined') {
        getVariableList(params.projectId, params.otxFileId, params.procedureId)
    }
    if (item && item.realisation && item.realisation.type !== 'UserExceptionLiteral') {
      this.getExceptionType(item, this.props.variableList)
    }
    if (item && item.type && (item.type === 'Break' || item.type === 'Continue')) {
      this.getParentLoops()
    }
  }

  getParentLoops = () => {
    const { item, params, loaderControl, getParentLoops } = this.props
    const projectId = params.projectId ? params.projectId : null
    const itemId = params.otxFileId ? params.otxFileId : null
    const procedureId = params.procedureId ? params.procedureId : null
    const parentId = this.getParentId(item)
    loaderControl(true)
    getParentLoops(projectId, itemId, procedureId, parentId)
      .then(() => {
        const parentLoopOptions = []
        if (this.props.parentLoops.length) {
          this.props.parentLoops.map((loop, i) => {
            parentLoopOptions.push({
              value: loop.otx_name,
              key: i,
              text: loop.otx_name
            })
          })
        }
        this.setState({ parentLoopOptions })
      })
  }

  getParentId = item => {
    let result = null
    if (item.parentId && !item.parentId.includes('/nodes')) {
      result = item.parentId
    } else if (this.props.params.selectedElementId !== undefined) {
      result = this.props.params.selectedElementId
    }
    return result
  }

  componentDidUpdate (prevProps) {
    const { item, variableList, newVariable } = this.props
    if (item &&
        item.realisation &&
        item.realisation.type !== 'UserExceptionLiteral' &&
        this.props.variableList.length &&
        this.state.exceptionType === 'UserException' &&
        this.state.shouldGetExceptionType) {
          const exceptionType = this.getExceptionType(item, this.props.variableList)
          const exceptionVariable = item.realisation.valueOf
          const optionsList = this.getFilteredExceptionVariables(this.props.variableList, exceptionType)
          this.setState({
            exceptionType,
            exceptionVariable,
            optionsList,
            shouldGetExceptionType: false
          })
        }
    if (item &&
        this.state.exceptionType !== 'UserException' &&
        prevProps.variableList.length &&
        variableList.length &&
        prevProps.variableList.length !== variableList.length) {
          if (newVariable !== null && (newVariable.dataType.type === this.state.exceptionType)) {
            const optionsList = this.getFilteredExceptionVariables(this.props.variableList, this.state.exceptionType)
            this.setState({
              optionsList,
              exceptionVariable: newVariable.otx_name
            })
          }
        }
  }

  getExceptionType = (exception, variableList) => {
    let exceptionType = ''
    if (variableList.length) {
      const exceptionName = exception.realisation.valueOf
      variableList.map(variable => {
        if (variable.obj.otx_name === exceptionName) {
          exceptionType = variable.obj.dataType.type
        }
      })
    }
    return exceptionType
  }

  getFilteredExceptionVariables = (variableList, exceptionType) => {
    let fileteredVariables = []
    let optionsList = []
    if (variableList.length) {
      fileteredVariables = variableList.filter(variable => variable.obj.dataType.type === exceptionType)
    }
    if (fileteredVariables.length) {
      optionsList = createOptionVariablesList(fileteredVariables)
    }
    return optionsList
  }

  handleChangeInput = (stateName, value) => {
    let optionsList = []
    if (stateName === 'exceptionType' && value !== 'UserException') {
      optionsList = this.getFilteredExceptionVariables(this.props.variableList, value)
      this.setState({
        optionsList,
        [stateName]: value,
        exceptionVariable: ''
      })
    } else {
      this.setState({
        [stateName]: value
      }, () => {
        if (stateName === 'basicName' || stateName === 'exceptionType' ||
          stateName === 'qualifier' || stateName === 'text') {
          this.validateBlur(stateName)
        }
      })
    }
  }

  validateBlur = (stateName, shouldReturnValue = false) => {
    const error = stateName === 'basicName'
      ? !nameRegex.test(this.state[stateName])
      : this.state[stateName] === '' || this.state[stateName] === null

    this.setState({
      [`${stateName}Error`]: error
    })

    if (shouldReturnValue) return error
  }

  isFormValid = itemType => {
    if (itemType === 'Break' ||
        itemType === 'Continue' ||
        itemType === 'Return' ||
        itemType === 'TerminateLanes') {
        return !this.validateBlur('basicName', true)
    } else if (itemType === 'Throw' && this.state.exceptionType === 'UserException') {
        return !(
          this.validateBlur('basicName', true) ||
          this.validateBlur('exceptionType', true) ||
          this.validateBlur('qualifier', true) ||
          this.validateBlur('text', true)
        )
    } else if (itemType === 'Throw' && this.state.exceptionType !== 'UserException') {
      return !this.validateBlur('basicName', true)
    } else if (itemType === 'Throw') {
      return !(this.validateBlur('basicName', true) || this.validateBlur('exceptionType', true))
    }
  }

  saveModal = e => {
    e.preventDefault()
    const { saveModal, modalItemToAdd } = this.props
    if (this.isFormValid(modalItemToAdd.type)) {
          const {
            basicName,
            basicSpecification,
            targetLoopToBreak,
            targetLoopToContinue
          } = this.state
            modalItemToAdd.otx_name = basicName
            modalItemToAdd.otx_specification = basicSpecification
            if (modalItemToAdd.type === 'Break') {
              modalItemToAdd.target = targetLoopToBreak
            } else if (modalItemToAdd.type === 'Continue') {
              modalItemToAdd.target = targetLoopToContinue
            } else if (modalItemToAdd.type === 'Throw') {
              modalItemToAdd.realisation = this.state.exceptionType === 'UserException' ? {
                type: 'UserExceptionLiteral',
                qualifier: this.state.qualifier,
                text: this.state.text,
                children: []
              } : {
                type: 'ExceptionValue',
                valueOf: this.state.exceptionVariable,
                children: []
              }
            }
          saveModal(modalItemToAdd)
      }
  }

render () {
  const {
    basicName,
    basicSpecification,
    targetLoopToBreak,
    targetLoopToContinue,
    basicNameError,
    optionsList
  } = this.state
  const {
    closeModal,
    item
  } = this.props
  const { inputs } = labels.modalPattern
  const { buttons, hints } = labels
  return (
    <React.Fragment>
      <div className='wizard__form-container'>
        <Form.Group className='wizard__form'>
          {(item.type === 'Break' ||
            item.type === 'Continue' ||
            item.type === 'Return' ||
            item.type === 'TerminateLanes' ||
            item.type === 'Throw') && <React.Fragment>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Popup
                    position='top center'
                    content={hints.diagramModal.basicName}
                    trigger={<Icon name='info' />} />
                  <Form.Input
                    id={testIds.endWizard.nameInput}
                    error={basicNameError}
                    onBlur={this.validateBlur.bind(this, 'basicName')}
                    label={inputs.name.label}
                    placeholder={inputs.name.placeholder}
                    value={basicName}
                    type='text'
                    onChange={(e, { value }) => this.handleChangeInput('basicName', value)}
                    className='wizard__form-input' />
                </div>
              </Form.Group>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Icon name='info' className='info-hidden' />
                  <Form.TextArea
                    id={testIds.endWizard.descriptionTextArea}
                    label={inputs.specification.label}
                    onChange={(e, { value }) => this.handleChangeInput('basicSpecification', value)}
                    placeholder={inputs.specification.placeholder}
                    value={basicSpecification}
                    className='wizard__form-input' />
                </div>
              </Form.Group>
            </React.Fragment>}
          {item.type === 'Break' && <React.Fragment>
            <Form.Group>
              <div className='wizard__form-info'>
                <Popup
                  trigger={<Icon name='info' className='info' />}
                  position='top center'
                  content={hints.diagramModal.break.targetLoopToBreak} />
                <Form.Select
                  fluid
                  search
                  id={testIds.endWizard.targetLoopToBreakDropDown}
                  label={inputs.targetLoopToBreak.label}
                  placeholder={inputs.targetLoopToBreak.placeholder}
                  options={this.state.parentLoopOptions}
                  onChange={(e, { value }) => this.handleChangeInput('targetLoopToBreak', value)}
                  value={targetLoopToBreak} />
              </div>
            </Form.Group>
            </React.Fragment>}
          {item.type === 'Continue' && <React.Fragment>
            <Form.Group>
              <div className='wizard__form-info'>
                <Popup
                  trigger={<Icon name='info' className='info' />}
                  position='top center'
                  content={hints.diagramModal.continue.targetLoopToContinue} />
                <Form.Select
                    fluid
                    search
                    id={testIds.endWizard.targetLoopToContinueDropDown}
                    label={inputs.targetLoopToContinue.label}
                    placeholder={inputs.targetLoopToContinue.placeholder}
                    options={this.state.parentLoopOptions}
                    onChange={(e, { value }) => this.handleChangeInput('targetLoopToContinue', value)}
                    value={targetLoopToContinue} />
              </div>
            </Form.Group>
            </React.Fragment>}
          {item.type === 'Throw' && <React.Fragment>
            <Form.Group>
              <section className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Select
                  fluid
                  search
                  id={testIds.endWizard.typeDropDown}
                  label={labels.modalPattern.inputs.type.labelNonRequired}
                  placeholder={labels.modalPattern.inputs.type.placeholder}
                  options={labels.throw_type_list}
                  value={this.state.exceptionType}
                  error={this.state.exceptionTypeError}
                  onChange={(event, { value }) => this.handleChangeInput('exceptionType', value)}
                  onBlur={this.validateBlur.bind(this, 'exceptionType')} />
              </section>
            </Form.Group>

            {this.state.exceptionType !== 'UserException' &&
            <Form.Group className='wizard__form-col2'>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Select
                  fluid
                  id={testIds.endWizard.handlingVariableDropDown}
                  label={labels.modalPattern.inputs.throw.label}
                  placeholder={labels.modalPattern.inputs.throw.placeholder}
                  options={optionsList}
                  value={this.state.exceptionVariable}
                  error={this.state.exceptionVariableError}
                  onChange={(event, { value }) => this.handleChangeInput('exceptionVariable', value)}
                  onBlur={this.validateBlur.bind(this, 'exceptionVariable')} />
              </div>
              <Button
                id={testIds.endWizard.addVariableButton}
                content={buttons.addVariable}
                onClick={() => this.props.openAddVariable(true)} />
            </Form.Group>}

            {this.state.exceptionType === 'UserException' &&
            <Form.Group>
              <section className='wizard__form-info'>
                <Popup
                  trigger={<Icon name='info' className='info' />}
                  position='top center'
                  content={hints.diagramModal.throw.qualifier} />
                <Form.Input
                  id={testIds.endWizard.qualifierParamInput}
                  label={labels.modalPattern.inputs.qualifier_param.label}
                  placeholder={labels.modalPattern.inputs.qualifier_param.placeholder}
                  type='text'
                  value={this.state.qualifier}
                  onChange={event => this.handleChangeInput('qualifier', event.target.value)}
                  onBlur={this.validateBlur.bind(this, 'qualifier')}
                  error={this.state.qualifierError}
                  className='wizard__form-input' >
                </Form.Input>
              </section>

              <section className='wizard__form-info'>
                <Popup
                  trigger={<Icon name='info' className='info' />}
                  position='top center'
                  content={hints.diagramModal.throw.text} />
                <Form.Input
                  id={testIds.endWizard.textParamInput}
                  label={labels.modalPattern.inputs.text_param.label}
                  placeholder={labels.modalPattern.inputs.text_param.placeholder}
                  type='text'
                  value={this.state.text}
                  onChange={event => this.handleChangeInput('text', event.target.value)}
                  onBlur={this.validateBlur.bind(this, 'text')}
                  error={this.state.textError}
                  className='wizard__form-input' >
                </Form.Input>
              </section>
            </Form.Group>}

            </React.Fragment>}
        </Form.Group>
      </div>
      <div className='wizard__footer'>
        <div>
          <Button
              id={testIds.endWizard.cancelButton}
             onClick={closeModal}
             content={buttons.cancel} />
        </div>
        <div>
          <Button
            id={testIds.endWizard.finishButton}
             onClick={e => this.saveModal(e)}
             content={buttons.finish}
             positive />
        </div>
      </div>
    </React.Fragment>)
}
}
