import { ACTIVE_LOADER } from '../actionTypeList'

// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------

export const loaderControlAction = (isActiveLoader = false) => {
    return {
      type: ACTIVE_LOADER,
      data: isActiveLoader
    }
}

export const loaderControl = (isActiveLoader = false) =>
dispatch => dispatch(loaderControlAction(isActiveLoader ? 1 : -1))

export const actions = {
    loaderControl
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [ACTIVE_LOADER]: (state, action) => {
      const localState = { ...state }
      let counter = action.data + localState.isActiveLoader
      counter = counter >= 0 ? counter : 0
        return {
            ...state,
            isActiveLoader: counter
        }
    }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { isActiveLoader: 0 }

export default function loaderControlReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
