import React from 'react'
import { bool } from 'prop-types'
import { browserHistory } from 'react-router'

const NotFound = ({ isLoggedIn }) => {
  browserHistory.push(isLoggedIn ? '/dashboard' : '/')
  return <div>Not Found</div>
}
NotFound.propTypes = {
  isLoggedIn: bool
}

export default NotFound
