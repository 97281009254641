
import { createListModifier } from './listModifiersCreator'
import { createMapModifier } from './mapModifiersCreator'
import { createBitFieldModifier } from './bitFieldModifiersCreator'
import { cloneDeep, isEmpty } from 'lodash'

export const conditionSample = {
  type: 'IsGreater',
  children: [
      {
          type: 'IntegerLiteral',
          value: 999,
          children: []
      },
      {
          type: 'IntegerLiteral',
          value: 111,
          children: []
      }
  ]
}

export const listOrMapSample = {
  type: 'ListLiteral',
  dataType: {
      type: 'Integer',
      value: 0
  },
  itemList: [
      {
          type: 'IntegerLiteral',
          value: 1
      },
      {
          type: 'IntegerLiteral',
          value: 2
      },
      {
          type: 'IntegerLiteral',
          value: 3
      },
      {
          type: 'IntegerLiteral',
          value: 4
      }
  ]
}

export const loopStructureCreator = (modalItemToAdd, componentState) => {
  let result = modalItemToAdd
    const {
        basicName,
        basicSpecification,
        technicalName,
        technicalSpecification,
        locator,
        selectedWhileLoop,
        listOrMap
    } = componentState
    if (basicName !== '') {
      result.otx_name = basicName
      result.otx_specification = basicSpecification
    } else if (technicalName !== '') {
      result.otx_name = technicalName
      result.otx_specification = technicalSpecification
    }
    if (result.children.length) {
      result.children[0].otx_name = technicalName
      result.children[0].otx_specification = technicalSpecification
    }
    switch (modalItemToAdd.type) {
        case 'ForLoop':
          result = Object.assign(result, {
            type: 'ForLoop',
            configuration: [
              {
                  type: 'IntegerVariable',
                  children: [],
                  name: componentState.iterator
              },
              componentState.startValueParameter.term,
              componentState.endValueParameter.term
            ]
          })
          break
        case 'ForEachLoop':
            modalItemToAdd.configuration = []
            modalItemToAdd.collection = listOrMap
            modalItemToAdd.locator = locator
            break
        case 'WhileLoop':
            modalItemToAdd.children = []
            modalItemToAdd.configuration = []
            modalItemToAdd.test = componentState.whileLoopParameter.term
            modalItemToAdd.isPostTested = selectedWhileLoop
            break
      }
      if (result.children[0]) {
          switch (result.children[0].type) {
            case 'ForLoop':
              result.children = [(Object.assign(modalItemToAdd.children[0], {
                type: 'ForLoop',
                configuration: [
                  {
                      type: 'IntegerVariable',
                      children: [],
                      name: componentState.iterator
                  },
                  componentState.startValueParameter.term,
                  componentState.endValueParameter.term
                ]
              }))]
              break
            case 'ForEachLoop':
                modalItemToAdd.children = [
                  {
                    otx_name: technicalName,
                    otx_specification: technicalSpecification,
                    type: 'ForEachLoop',
                    configuration: [],
                    collection: modalItemToAdd.collection = listOrMap,
                    locator
                  }
                ]
              break
            case 'WhileLoop':
                modalItemToAdd.children = [
                  {
                    otx_name: technicalName,
                    otx_specification: technicalSpecification,
                    type: 'WhileLoop',
                    children: [],
                    configuration: [],
                    test : componentState.whileLoopParameter.term,
                    isPostTested: selectedWhileLoop
                  }
                ]
              break
          }
      }
      return modalItemToAdd
}

export const conditionalStrucureCreator = (modalItemToAdd, componentState) => {
  const {
    basicName,
    basicSpecification,
    technicalName,
    technicalSpecification
  } = componentState
  switch (modalItemToAdd.type) {
    case 'Branch':
      modalItemToAdd.otx_name = basicName
      modalItemToAdd.otx_specification = basicSpecification
    break
    case 'If':
      modalItemToAdd.otx_name = technicalName
      modalItemToAdd.otx_specification = technicalSpecification
      modalItemToAdd.condition = componentState.parameter.term
      break
    case 'ElseIf':
      modalItemToAdd.otx_name = technicalName
      modalItemToAdd.otx_specification = technicalSpecification
      modalItemToAdd.condition = componentState.parameter.term
      break
    case 'Else':
      modalItemToAdd.otx_name = technicalName
      modalItemToAdd.otx_specification = technicalSpecification
      break
  }
  if (modalItemToAdd.children[0]) {
      switch (modalItemToAdd.children[0].type) {
        case 'If':
          modalItemToAdd.children = [
            {
              otx_name: technicalName,
              otx_specification: technicalSpecification,
              type: 'If',
              condition: componentState.parameter.term
            }
          ]
          break
      }
  }
  return modalItemToAdd
}

export const handlerStructureCreator = (item, componentState) => {
  let result = item

  if (componentState.basicName !== '') {
    result.otx_name = componentState.basicName
    result.otx_specification = componentState.basicSpecification
    if (componentState.technicalName !== '' && item.children.length === 2) {
      result.children[1].otx_name = componentState.technicalName
      result.children[1].otx_specification = componentState.technicalSpecification
    }
  } else if (componentState.technicalName !== '') {
    result.otx_name = componentState.technicalName
    result.otx_specification = componentState.technicalSpecification
  }

  switch (result.type) {
    case 'Handler':
      break
    case 'Try':
      break
    case 'CatchingFlow':
      result = Object.assign(result, {
        realisation: {
            type: 'CatchingFlowRealisation',
            children: [],
            exceptionType: {
                type: componentState.caughtExceptionType,
                children: []
            },
            handle: componentState.handlingVar ? {
                type: 'ExceptionVariable',
                children: [],
                name: componentState.handlingVar
            } : null
        }
      })
      break
    case 'Finally':
      break
  }

  if (result.children.length === 2 && result.children[1].type === 'CatchingFlow') {
    result.children[1] = Object.assign(result.children[1], {
      realisation: {
          type: 'CatchingFlowRealisation',
          children: [],
          exceptionType: {
              type: componentState.caughtExceptionType,
              children: []
          },
          handle: componentState.handlingVar ? {
              type: 'ExceptionVariable',
              children: [],
              name: componentState.handlingVar
          } : null
      }
    })
  }

  return result
}

export const actionStructureCreator = (modalItemToAdd, componentState) => {
  const {
    basicName,
    basicSpecification,
    validFor,
    modifiedList,
    modifiedMap,
    modifiedByteField,
    editorTermArray,
    indexTerm,
    countTerm
  } = componentState

let result = modalItemToAdd

result.otx_name = basicName
result.otx_specification = basicSpecification

switch (result.type) {
  case 'ListRemove':
  case 'ListClear':
  case 'ListAppend':
  case 'ListInsert':
  case 'ListConcatenate':
      result = createListModifier(
        result.type,
        result,
        {
          validFor,
          modifiedList,
          editorTermArray,
          indexTerm,
          countTerm
        })
    break
  case 'MapUnion':
  case 'MapRemove':
  case 'MapClear':
  case 'MapPut':
      result = createMapModifier(
        result.type,
        result,
        {
          validFor,
          modifiedMap,
          editorTermArray
        })
    break
  case 'BitSet':
  case 'BitShiftLeft':
  case 'BitShiftRight':
  case 'BitAppend':
  case 'BitReplace':
    result = createBitFieldModifier(
        result.type,
        result,
        {
          validFor,
          modifiedByteField,
          editorTermArray
        })
    break
  case 'Wait':
      result = Object.assign(result, {
        timeout: componentState.timeoutTerm.term,
        validity: componentState.validFor !== '' ? componentState.validFor : null
    })
    break
  case 'Assignment':
    result.validity = componentState.validFor !== '' ? componentState.validFor : null
    result.content = [
      componentState.assignedValue.type.includes('Exception')
      ? { children: [], name: componentState.assignedValue.name, type: 'ExceptionVariable' }
      : componentState.assignedValue,
      componentState.actionPatternParameter.term.type.includes('Exception') &&
      componentState.actionPatternParameter.term.valueOf !== undefined
      ? { valueOf: componentState.actionPatternParameter.term.valueOf, children: [], type: 'ExceptionValue' }
      : componentState.actionPatternParameter.term
    ]
    break
  case 'AddDataListener':
      result = Object.assign(result, {
        type: 'AddDataListener',
        validity: componentState.validFor !== '' ? componentState.validFor : null,
        printToLog: componentState.printToLog,
        mappings: componentState.ssmReportingDataList.filter(element => element.reportingDataValue !== '' &&
        element.otxVariableValue !== '').map(element => {
          return {
            type: 'Mapping',
            ssmName: element.reportingDataValue,
            variableName: element.otxVariableValue
          }
        })
    })
    break
  case 'AddPackageListener':
      result.printToLog = componentState.printToLog
      result.validity = componentState.validFor !== '' ? componentState.validFor : null
      result.result = {
        type: 'MapVariable',
        name: componentState.result
      }
    break
  case 'InvokeActivity':
      result = Object.assign(result, {
        name: componentState.ssmActivityName,
        type: 'InvokeActivity',
        validity: componentState.validFor !== '' ? componentState.validFor : null,
        arguments: activityArgumentsCreator(componentState.editorTermArrayOptinal),
        resultState: componentState.ssmActivityStateVar === '' ? null : {
          type: 'ActivityStateVariable',
          children: [],
          name: componentState.ssmActivityStateVar
        }
      })
    break
  case 'InvokeActivityAndWait':
    result = Object.assign(result, {
      name: componentState.ssmActivityName,
      type: 'InvokeActivityAndWait',
      validity: componentState.validFor !== '' ? componentState.validFor : null,
      arguments: activityArgumentsCreator(componentState.editorTermArrayOptinal),
      resultState: componentState.ssmActivityStateVar === '' ? null : {
        type: 'ActivityStateVariable',
        children: [],
        name: componentState.ssmActivityStateVar
      }
    })
    break
  case 'Connect':
    result.validity = componentState.validFor !== '' ? componentState.validFor : null
    result.result = componentState.result ? {
      type: 'ConnectionVariable',
      name: componentState.result
    } : null
  break
  case 'TerminateActivity':
    result.name = componentState.ssmActivityName
    result.validity = componentState.validFor !== '' ? componentState.validFor : null
  break
  case 'GetActivityState':
    result.name = componentState.ssmActivityName
    result.validity = componentState.validFor !== '' ? componentState.validFor : null
    result.activityState = {
      type: 'ActivityStateVariable',
      name: componentState.ssmActivityStateVar,
      children: []
    }
  break
  case 'Disconnect':
    result.validity = componentState.validFor !== '' ? componentState.validFor : null
    result.result = componentState.resultBool ? {
      type: 'BooleanVariable',
      name: componentState.resultBool,
      validity: componentState.validFor !== '' ? componentState.validFor : null
    } : null
  break
  case 'AddEventListener':
      result.printToLog = componentState.printToLog
      result.validity = componentState.validFor !== '' ? componentState.validFor : null
      result.mapping = {
        type: 'Mapping',
        ssmName: componentState.mappedSsmReportingData,
        variableName: componentState.mappedOtxVar
    }
    break
  case 'ProcedureCall':
    result = Object.assign(result, {
      otx_name: null,
      otx_specification: null,
      type: 'ProcedureCall',
      children: modalItemToAdd.children,
      calledProcedureName: componentState.calledProcedure,
      validity: componentState.validFor !== '' ? componentState.validFor : null,
      isExceptionThrown: componentState.throwAmbiguousCallException,
      inArguments: componentState.inParameterList.map(element => {
        const term = cloneDeep(element.term)
        if (term.type.includes('Exception')) {
          term.value !== undefined && (term.type = 'ExceptionLiteral')
          term.valueOf !== undefined && (term.type = 'ExceptionValue')
        }
        return {
          type: 'InArgument',
          children: [],
          param: element.name,
          term
        }
      }),
      inOutArguments: componentState.inOutParameterList.map(element => {
        let type = ''
        if (element.type.includes('Exception')) {
          type = 'ExceptionVariable'
        } else {
          type = `${element.type}Variable`
        }
        return {
          type: 'InOutArgument',
          children: [],
          param: element.name,
          variable: {
            type,
            children: [],
            name: element.valueOfVariable
          }
        }
      }),
      outArguments: componentState.outParameterList.map(element => {
        let type = ''
        if (element.type.includes('Exception')) {
          type = 'ExceptionVariable'
        } else {
          type = `${element.type}Variable`
        }
        return {
          type: 'OutArgument',
          children: [],
          param: element.name,
          variable: {
            type,
            children: [],
            name: element.valueOfVariable
          }
        }
      })
    })
    break
  case 'WriteLog':
    result = Object.assign(result,
      {
        validity: componentState.validFor !== '' ? componentState.validFor : null,
        severityLevelTerm: {
            type: 'SeverityLevelLiteral',
            value: componentState.severityLevel
        },
        target: null,
        message: componentState.messageTerm.term
      }
    )
    break
  case 'ChoiceDialog':
    result = Object.assign(result,
      {
        validity: componentState.validFor !== '' ? componentState.validFor : null,
        title: checkOptionalParameter(componentState.titleTerm.term),
        message: checkOptionalParameter(componentState.messageOptionalTerm.term),
        options: componentState.optionsTerm.term,
        default: checkOptionalParameter(componentState.defaultTerm.term),
        result: {
          type: 'IntegerVariable',
          name: componentState.result
       }
      }
    )
    break
  case 'InputDialog':
    result = Object.assign(result,
      {
        validity: componentState.validFor !== '' ? componentState.validFor : null,
        title: checkOptionalParameter(componentState.titleTerm.term),
        message: checkOptionalParameter(componentState.messageOptionalTerm.term),
        initialValue: checkOptionalParameter(componentState.initialValueTerm.term),
        instruction: checkOptionalParameter(componentState.instructionTerm.term),
        restriction: checkOptionalParameter(componentState.restrictionTerm.term),
        result: {
          type: 'StringVariable',
          name: componentState.result
       }
      }
    )
    break
    case 'ConfirmDialog':
    result = Object.assign(result,
      {
        validity: componentState.validFor !== '' ? componentState.validFor : null,
        title: checkOptionalParameter(componentState.titleTerm.term),
        message: checkOptionalParameter(componentState.messageOptionalTerm.term),
        messageType: componentState.messageType !== ''
        ? {
          type: 'MessageTypeLiteral',
          value: componentState.messageType
        }
        : null,
        result: componentState.result !== ''
        ? {
          type: 'ConfirmationTypeVariable',
          name: componentState.result
        }
        : null
      }
    )
    break
    case 'ServerCommand':
      result = Object.assign(result,
        {
          validity: componentState.validFor !== '' ? componentState.validFor : null,
          result: componentState.result !== ''
            ? {
              type: 'StringVariable',
              name: componentState.result
            }
            : null,
          exitCode: componentState.exitCode !== ''
            ? {
              type: 'IntegerVariable',
              name: componentState.exitCode
            }
            : null,
          command: componentState.commandTerm.term
        }
      )
    break
  }
  if (!isEmpty(result.children)) {
      switch (result.children[0].type) {
        case 'ListRemove':
        case 'ListClear':
        case 'ListAppend':
        case 'ListInsert':
        case 'ListConcatenate':
            result.children[0] = createListModifier(
              result.children[0].type,
              result.children[0],
              {
                validFor,
                modifiedList,
                editorTermArray,
                indexTerm,
                countTerm
              })
          break
        case 'MapUnion':
        case 'MapRemove':
        case 'MapClear':
        case 'MapPut':
            result.children[0] = createMapModifier(
              result.children[0].type,
              result.children[0],
              {
                validFor,
                modifiedMap,
                editorTermArray
              })
          break
        case 'BitSet':
        case 'BitShiftLeft':
        case 'BitShiftRight':
        case 'BitAppend':
        case 'BitReplace':
          result.children[0] = createBitFieldModifier(
              result.children[0].type,
              result.children[0],
              {
                validFor,
                modifiedByteField,
                editorTermArray
              })
          break
        case 'Wait':
            result.children[0] = Object.assign(result.children[0], {
              timeout: componentState.timeoutTerm.term,
              validity: componentState.validFor !== '' ? componentState.validFor : null
          })
          break
        case 'Assignment':
          result.children[0].validity = componentState.validFor !== '' ? componentState.validFor : null
          result.children[0].content = [
            componentState.assignedValue.type.includes('Exception')
            ? { children: [], name: componentState.assignedValue.name, type: 'ExceptionVariable' }
            : componentState.assignedValue,
            componentState.actionPatternParameter.term.type.includes('Exception') &&
            componentState.actionPatternParameter.term.valueOf !== undefined
            ? { valueOf: componentState.actionPatternParameter.term.valueOf, children: [], type: 'ExceptionValue' }
            : componentState.actionPatternParameter.term
          ]
          break
        case 'AddDataListener':
          result.children[0] = Object.assign(result.children[0], {
            type: 'AddDataListener',
            validity: componentState.validFor !== '' ? componentState.validFor : null,
            printToLog: componentState.printToLog,
            mappings: componentState.ssmReportingDataList.filter(element => element.reportingDataValue !== '' &&
            element.otxVariableValue !== '').map(element => {
              return {
                type: 'Mapping',
                ssmName: element.reportingDataValue,
                variableName: element.otxVariableValue
              }
            })
          })
          break
        case 'AddPackageListener':
            result.children[0].printToLog = componentState.printToLog
            result.children[0].validity = componentState.validFor !== '' ? componentState.validFor : null
            result.children[0].result = {
              type: 'MapVariable',
              name: componentState.result
            }
          break
        case 'InvokeActivity':
          result.children[0] = Object.assign(result.children[0], {
            name: componentState.ssmActivityName,
            type: 'InvokeActivity',
            validity: componentState.validFor !== '' ? componentState.validFor : null,
            arguments: activityArgumentsCreator(componentState.editorTermArrayOptinal),
            resultState: componentState.ssmActivityStateVar === '' ? null : {
              type: 'ActivityStateVariable',
              children: [],
              name: componentState.ssmActivityStateVar
            }
          })
          break
        case 'InvokeActivityAndWait':
          result.children[0] = Object.assign(result.children[0], {
            name: componentState.ssmActivityName,
            type: 'InvokeActivityAndWait',
            validity: componentState.validFor !== '' ? componentState.validFor : null,
            arguments: activityArgumentsCreator(componentState.editorTermArrayOptinal),
            resultState: componentState.ssmActivityStateVar === '' ? null : {
              type: 'ActivityStateVariable',
              children: [],
              name: componentState.ssmActivityStateVar
            }
          })
          break
        case 'Connect':
          result.children[0].validity = componentState.validFor !== '' ? componentState.validFor : null
          result.children[0].result = componentState.result ? {
            type: 'ConnectionVariable',
            name: componentState.result
          } : null
          break
        case 'Disconnect':
          result.children[0].validity = componentState.validFor !== '' ? componentState.validFor : null
          result.children[0].result = componentState.resultBool ? {
            type: 'BooleanVariable',
            name: componentState.resultBool,
            validity: componentState.validFor !== '' ? componentState.validFor : null
          } : null
          break
        case 'TerminateActivity':
          result.children[0].name = componentState.ssmActivityName
          result.children[0].validity = componentState.validFor !== '' ? componentState.validFor : null
          break
        case 'GetActivityState':
          result.children[0].name = componentState.ssmActivityName
          result.children[0].validity = componentState.validFor !== '' ? componentState.validFor : null
          result.children[0].activityState = {
            type: 'ActivityStateVariable',
            name: componentState.ssmActivityStateVar,
            children: []
          }
          break
        case 'AddEventListener':
          result.children[0].printToLog = componentState.printToLog
          result.children[0].validity = componentState.validFor !== '' ? componentState.validFor : null
          result.children[0].mapping = {
            type: 'Mapping',
            ssmName: componentState.mappedSsmReportingData,
            variableName: componentState.mappedOtxVar
          }
          break
        case 'ProcedureCall':
            result.children[0] = {
              otx_name: null,
              otx_specification: null,
              type: 'ProcedureCall',
              children: modalItemToAdd.children[0].children,
              validity: componentState.validFor !== '' ? componentState.validFor : null,
              calledProcedureName: componentState.calledProcedure,
              isExceptionThrown: componentState.throwAmbiguousCallException,
              inArguments: componentState.inParameterList.map(element => {
                const term = cloneDeep(element.term)
                if (term.type.includes('Exception')) {
                  term.value !== undefined && (term.type = 'ExceptionLiteral')
                  term.valueOf !== undefined && (term.type = 'ExceptionValue')
                }
                return {
                  type: 'InArgument',
                  children: [],
                  param: element.name,
                  term
                }
              }),
              inOutArguments: componentState.inOutParameterList.map(element => {
                let type = ''
                if (element.type.includes('Exception')) {
                  type = 'ExceptionVariable'
                } else {
                  type = `${element.type}Variable`
                }
                return {
                  type: 'InOutArgument',
                  children: [],
                  param: element.name,
                  variable: {
                    type,
                    children: [],
                    name: element.valueOfVariable
                  }
                }
              }),
              outArguments: componentState.outParameterList.map(element => {
                let type = ''
                if (element.type.includes('Exception')) {
                  type = 'ExceptionVariable'
                } else {
                  type = `${element.type}Variable`
                }
                return {
                  type: 'OutArgument',
                  children: [],
                  param: element.name,
                  variable: {
                    type,
                    children: [],
                    name: element.valueOfVariable
                  }
                }
              })
            }
            break
        case 'WriteLog':
          result.children[0] = Object.assign(result.children[0],
            {
              validity: componentState.validFor !== '' ? componentState.validFor : null,
              severityLevelTerm: {
                  type: 'SeverityLevelLiteral',
                  value: componentState.severityLevel
              },
              target: null,
              message: componentState.messageTerm.term
            }
          )
          break
        case 'ChoiceDialog':
          result.children[0] = Object.assign(result.children[0],
            {
              validity: componentState.validFor !== '' ? componentState.validFor : null,
              title: checkOptionalParameter(componentState.titleTerm.term),
              message: checkOptionalParameter(componentState.messageOptionalTerm.term),
              options: componentState.optionsTerm.term,
              default: checkOptionalParameter(componentState.defaultTerm.term),
              result: {
                type: 'IntegerVariable',
                name: componentState.result
             }
            }
          )
          break
        case 'InputDialog':
          result.children[0] = Object.assign(result.children[0],
            {
              validity: componentState.validFor !== '' ? componentState.validFor : null,
              title: checkOptionalParameter(componentState.titleTerm.term),
              message: checkOptionalParameter(componentState.messageOptionalTerm.term),
              initialValue: checkOptionalParameter(componentState.initialValueTerm.term),
              instruction: checkOptionalParameter(componentState.instructionTerm.term),
              restriction: checkOptionalParameter(componentState.restrictionTerm.term),
              result: {
                type: 'StringVariable',
                name: componentState.result
             }
            }
          )
          break
        case 'ConfirmDialog':
          result.children[0] = Object.assign(result.children[0],
            {
              validity: componentState.validFor !== '' ? componentState.validFor : null,
              title: checkOptionalParameter(componentState.titleTerm.term),
              message: checkOptionalParameter(componentState.messageOptionalTerm.term),
              messageType: componentState.messageType !== ''
              ? {
                type: 'MessageTypeLiteral',
                value: componentState.messageType
              }
              : null,
              result: componentState.result !== ''
              ? {
                type: 'ConfirmationTypeVariable',
                name: componentState.result
              }
              : null
            }
          )
          break
        case 'ServerCommand':
          result.children[0] = Object.assign(result.children[0],
            {
              validity: componentState.validFor !== '' ? componentState.validFor : null,
              result: componentState.result !== ''
                ? {
                  type: 'StringVariable',
                  name: componentState.result
                }
                : null,
              exitCode: componentState.exitCode !== ''
                ? {
                  type: 'IntegerVariable',
                  name: componentState.exitCode
                }
                : null,
              command: componentState.commandTerm.term
            }
          )
          break
      }
  }
  return result
}

const activityArgumentsCreator = arr => arr.map(element => {
  const isTermValid =
    (element.term.value !== undefined && element.term.value !== '') ||
    (typeof element.term.valueOf === 'string' && element.term.valueOf !== '') ||
    (element.term.value === undefined && typeof element.term.valueOf !== 'string')
  const term = isTermValid ? element.term : null
  return {
    type: 'SsmActivityArgument',
    children: [],
    ssmName: element.name,
    term
  }
})

const checkOptionalParameter = term => {
  if (term.value !== undefined) {
    return term.value !== '' ? term : null
  } else if (term.valueOf !== undefined) {
    return term.valueOf !== '' ? term : null
  }
  return term
}
