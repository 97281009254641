import React from 'react'
import { Rect, Text } from 'react-konva'
import { string, bool, func } from 'prop-types'
import { nodeSize, icons } from '../../Utilities/common'
import { ButtonImage } from './ButtonImage'

export const RectNode = ({ title, isSelected, removeNodeHandler, editNodeHandler, id }) => {
  return <>
    <Rect
      width={nodeSize.width}
      height={nodeSize.height}
      fill='white'
      cornerRadius={3}
      shadowColor={isSelected ? 'green' : '#666668'}
      shadowBlur={isSelected ? 15 : 10}
      shadowOpacity={isSelected ? 1 : 0.3}
      stroke={isSelected ? 'green' : '#666668'}
      strokeWidth={isSelected ? 1 : 0.5}
    />
    <Rect
      width={nodeSize.width}
      height={30}
      fill={'#303D7A'}
      cornerRadius={[3, 3, 0, 0]}
      shadowOpacity={0.3}
      stroke='#666668'
      strokeWidth={0.5}
    />
    <Text
      text={title}
      fill='white'
      ellipsis
      padding={10}
      width={nodeSize.width - 30}
      height={30}
      align='left'
      fontSize={12} />
    <ButtonImage
      url={icons.edit}
      x={nodeSize.width - 40}
      handleClick={editNodeHandler}
      y={7}
      id={id} />
    <ButtonImage
      url={icons.delete}
      x={nodeSize.width - 20}
      handleClick={removeNodeHandler}
      y={7}
      id={id} />
  </>
}

RectNode.propTypes = {
  title: string,
  id: string,
  isSelected: bool,
  removeNodeHandler: func,
  editNodeHandler: func
}
