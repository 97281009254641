import React, { Component } from 'react'
import { Form, Icon, Button, Segment } from 'semantic-ui-react'
import labels from '../../../../../../../public/labels.json'
import PropTypes from 'prop-types'
import ClearSelect from '../../../../../Utilities/ClearSelect'
import TermEditorComponent from '../../../../../TermEditor/TermEditorComponent'
import { isEmpty, isEqual, cloneDeep } from 'lodash'
import testIds from '../../../../../../../public/testIds.json'
import { termEditorHandler, returnInitTermHandler, termCreatorHandler } from '../../../../../../utilites/terms/terms'

class ListModifiers extends Component {
  state = {
    editorTermArray: [],
    listItemType: '',
    filteredList: []
  }

    static propTypes = {
      item: PropTypes.object,
      optionsVariableList: PropTypes.array,
      validities: PropTypes.array,
      openAddVariable: PropTypes.func,
      onSelect: PropTypes.func,
      onInput: PropTypes.func,
      onValidate: PropTypes.func,
      validFor: PropTypes.string,
      modifiedList: PropTypes.array,
      modifiedListError: PropTypes.bool,
      listRemove: PropTypes.obj,
      variableList: PropTypes.array,
      editorTermArrayCreator: PropTypes.func,
      isSaveClicked: PropTypes.bool,
      changeHandler: PropTypes.func,
      indexTerm: PropTypes.object,
      countTerm: PropTypes.object
    }

    componentDidUpdate (prevProps, prevState) {
      if (!isEmpty(this.props.optionsVariableList) &&
          !isEmpty(this.props.modifiedList) &&
          (!isEqual(this.props.optionsVariableList, prevProps.optionsVariableList) ||
           !isEqual(this.props.modifiedList, prevProps.modifiedList))) {
        this.createTermEditorArray(!isEqual(this.props.modifiedList, prevProps.modifiedList))
      }
    }

    createTermEditorArray (modifiedListChanged) {
      const { item } = this.props
      const listItemType = this.findListType(item.type)
      const editorTermArray = []
      let newTerm = {}
      switch (item.type) {
        case 'ListAppend':
        case 'ListInsert':
        case 'ListConcatenate':
          this.setState({ listItemType })
          if (((item.item && !isEmpty(item.item)) ||
               (item.otherList && !isEmpty(item.otherList))) &&
                !modifiedListChanged) {
            cloneDeep(item.item || item.otherList).forEach(el => {
              newTerm = termCreatorHandler(el, listItemType)
              editorTermArray.push(newTerm)
            })
          } else {
            newTerm = returnInitTermHandler(listItemType)
            editorTermArray.push(newTerm)
          }
          this.setState({ editorTermArray }, this.props.editorTermArrayCreator(editorTermArray))
        break
      }
    }

    findListType (itemType) {
      const selectedList = this.props.optionsVariableList.find(obj => obj.value === this.props.modifiedList)
      let type = ''
      if (!isEmpty(selectedList)) {
        if (itemType === 'ListConcatenate') {
          type = selectedList.object.obj.dataType.type
        } else {
          type = selectedList.object.obj.dataType.itemType.type
        }
      }
      this.filterListhandler(itemType, selectedList.object.obj.dataType.itemType.type)
      return type
    }

    filterListhandler (itemType, listItemType) {
      const { variableList, optionsVariableList } = this.props
      const filteredList = itemType === 'ListConcatenate'
      ? optionsVariableList.filter(variable =>
        variable.object.obj.dataType ? variable.object.obj.dataType.itemType.type === listItemType : false)
      : variableList.filter(variable =>
        variable.obj.dataType ? variable.obj.dataType.type === listItemType : false)

      this.setState({ filteredList })
    }

    addNew = () => {
      const { listItemType } = this.state
      const { item } = this.props
      const editorTermArray = cloneDeep(this.state.editorTermArray)
      switch (item.type) {
        case 'ListAppend':
        case 'ListInsert':
        case 'ListConcatenate':
          if (listItemType) {
            const newTerm = returnInitTermHandler(listItemType)
            editorTermArray.push(newTerm)
          }
        break
      }
      this.setState({ editorTermArray }, this.props.editorTermArrayCreator(editorTermArray))
    }

    termEditorFieldHandler = (stateName, value, propertyName, index) => {
      const newTerm = termEditorHandler(this.state[stateName][index], stateName, value, propertyName)
      const newListOfTerms = [...this.state[stateName]]
      newListOfTerms[index] = newTerm

      this.setState({
        [stateName]: newListOfTerms
      }, this.props.editorTermArrayCreator(newListOfTerms))
    }

    removeElement = index => {
      const editorTermArray = cloneDeep(this.state.editorTermArray)
        editorTermArray.splice(index, 1)
      this.setState({ editorTermArray }, this.props.editorTermArrayCreator(editorTermArray))
    }

    render () {
      const {
        item,
        optionsVariableList,
        validities,
        openAddVariable,
        validFor,
        modifiedList,
        modifiedListError,
        variableList,
        isSaveClicked,
        changeHandler,
        indexTerm,
        countTerm
      } = this.props
      const { inputs } = labels.modalPattern
      const { buttons } = labels
      const { editorTermArray, listItemType, filteredList } = this.state
      const { actionWizard } = testIds
      return (<React.Fragment>
        <React.Fragment>
          <Form.Group>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                  fluid
                  id={actionWizard.validForDropDown}
                  label={inputs.validFor.label}
                  options={validities}
                  onChange={(e, { value }) => this.props.onSelect('validFor', value)}
                  placeholder={inputs.validFor.placeholder}
                  value={validFor} />
              {validFor !== '' && <ClearSelect handleClick={() => this.props.onSelect('validFor', '')} />}
            </div>
          </Form.Group>
          <Form.Group className='wizard__form-col2'>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                  error={modifiedListError}
                  onBlur={() => this.props.onValidate('modifiedList')}
                  id={actionWizard.modifiedList}
                  fluid
                  label={inputs.modifiedList.label}
                  placeholder={inputs.modifiedList.placeholder}
                  options={optionsVariableList}
                  onChange={(e, { value }) => this.props.onSelect('modifiedList', value)}
                  value={modifiedList} />
            </div>
            <Button
              id={actionWizard.addVariableButton}
              content={buttons.addVariable}
              onClick={() => openAddVariable(true)}/>
          </Form.Group>
        </React.Fragment>
        {(item.type === 'ListRemove' || item.type === 'ListInsert') &&
          <TermEditorComponent
            changeHandler={changeHandler}
            element={indexTerm}
            stateName='indexTerm'
            item={item}
            labelsProp={inputs.indexTerm}
            index={null}
            isSaveClicked={isSaveClicked}
            variableList={variableList.filter(variable =>
              variable.obj.dataType ? variable.obj.dataType.type === indexTerm.type : false)}
          />}
        {item.type === 'ListRemove' &&
          <TermEditorComponent
            changeHandler={changeHandler}
            element={countTerm}
            stateName='countTerm'
            item={item}
            labelsProp={inputs.countTerm}
            index={null}
            isSaveClicked={isSaveClicked}
            variableList={variableList.filter(variable =>
              variable.obj.dataType ? variable.obj.dataType.type === countTerm.type : false)}
          />}
        {(item.type === 'ListAppend' ||
          item.type === 'ListInsert' ||
          item.type === 'ListConcatenate') &&
          !isEmpty(editorTermArray) &&
          editorTermArray.map((el, index) =>
            <Segment
              key={index}
              className='flex-container'
              style={{
                background:'#f9fafb',
                alignItems: 'flex-end',
                marginRight: 16
              }}>
              <TermEditorComponent
                changeHandler={this.termEditorFieldHandler}
                element={el}
                stateName='editorTermArray'
                item={item}
                labelProp={labels.modalPattern.inputs.variable_edit_type_choose.label}
                index={index}
                isSaveClicked={isSaveClicked}
                variableList={filteredList}
                removeElement={this.removeElement}
                elementsAmount={editorTermArray.length}
              />
            </Segment>)}
        {!isEmpty(listItemType) && item.type !== 'ListClear' && item.type !== 'ListRemove' &&
        <div className='add-button'>
          <Button
            id={actionWizard.addButton}
            content={buttons.addNewItem}
            positive
            onClick={this.addNew} />
        </div>}
      </React.Fragment>
    )
  }
}

export default ListModifiers
