import labels from '../../public/labels.json'

export const parseError = error => {
    const { errors } = labels.modalPattern
    const errorMessage = {
        position: 'tl',
        autoDismiss: 4
    }
    if (error.response) {
    if (error.response.status) {
      errorMessage.title = `${errors.error} ${error.response.status}`
      if (error.response.data && error.response.data.message) {
        errorMessage.message = error.response.data.message
      }
    }
  } else if (error.request) {
    errorMessage.message = errors.failedWithNetwork
  } else {
    errorMessage.message = `${errors.failedWithError} ${error.message}`
  }
  return errorMessage
}
