import axios from 'axios'
import {
  SET_IS_PROCEDURE_RUNNING,
  SET_REACHED_BREAKPOINT,
  SHOULD_UPDATE_SCROLL,
  SET_REACHED_NODE_ID
} from '../actionTypeList'
import { updateBreakpointAction } from './diagramElement'
import { getHeaders, origin, protocol, errorHandle } from '../../addons'
import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
import { loaderControl } from './loaderControl'

const setIsProcedureRunningAction = (isProcedureRunning = false) => {
  return {
    type: SET_IS_PROCEDURE_RUNNING,
    data: isProcedureRunning
  }
}

const setReachedBreakPointAction = (reachedBreakPoint = '') => {
  return {
    type: SET_REACHED_BREAKPOINT,
    data: reachedBreakPoint
  }
}

const setReachedNodeIdAction = (reachedNodeId = '') => ({
  type: SET_REACHED_NODE_ID,
  data: reachedNodeId
})

const updateScrollAction = (shouldUpdateScroll = true) => {
  return {
    type: SHOULD_UPDATE_SCROLL,
    data: shouldUpdateScroll
  }
}

export const updateScroll = (shouldUpdateScroll = true) =>
  dispatch => dispatch(updateScrollAction(shouldUpdateScroll))

export const setIsProcedureRunning = (isProcedureRunning = false) =>
  dispatch => dispatch(setIsProcedureRunningAction(isProcedureRunning))

export const setReachedNodeId = reachedNodeId =>
  dispatch => dispatch(setReachedNodeIdAction(reachedNodeId))

export const setReachedBreakPoint = (reachedBreakPoint = '') => {
  return dispatch => {
    dispatch(setReachedBreakPointAction(reachedBreakPoint))
  }
}

export const setBreakPoint = (projectId, otxFileId, elId) => {
  return dispatch => {
    axios.post(`${protocol}//${origin}/api/breakpoint/${projectId}/items/${otxFileId}?astId=${elId}`,
      null, getHeaders())
      .then(() => {
        dispatch(updateBreakpointAction())
        dispatch(loaderControl())
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(loaderControl())
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
      })
  }
}

export const removeBreakPoint = (projectId, otxFileId, elId) => {
  return dispatch => {
    axios.delete(`${protocol}//${origin}/api/breakpoint/${projectId}/items/${otxFileId}?astId=${elId}`,
    getHeaders())
      .then(() => {
        dispatch(updateBreakpointAction())
        dispatch(loaderControl())
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(loaderControl())
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
      })
  }
}

export const stopProcedure = (projectId, otxFileId, mode) => {
  const url = mode === 'run'
  ? `/api/procedure/stop/${projectId}/items/${otxFileId}` : `/api/debug/stop/${projectId}/items/${otxFileId}`
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}${url}`, getHeaders())
      .then(() => {
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const nextStep = (projectId, itemId, step) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/debug/${step}?projectId=${projectId}&itemId=${itemId}`, {}, getHeaders())
      .then(() => {
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const confirmModalController = (url, body) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}${url}`, body,
      getHeaders())
      .then(() => {
        resolve()
      })
      .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const actions = {
  setIsProcedureRunning,
  stopProcedure,
  setBreakPoint,
  nextStep,
  updateScroll,
  confirmModalController,
  setReachedNodeId
}

const ACTION_HANDLERS = {
  [SET_IS_PROCEDURE_RUNNING]: (state, action) => {
    return {
      ...state,
      isProcedureRunning: action.data
    }
  },
  [SET_REACHED_BREAKPOINT]: (state, action) => {
    return {
      ...state,
      reachedBreakPoint: action.data
    }
  },
  [SHOULD_UPDATE_SCROLL]: (state, action) => {
    return {
      ...state,
      shouldUpdateScroll: action.data
    }
  },
  [SET_REACHED_NODE_ID]: (state, action) => {
    return {
      ...state,
      reachedNodeId: action.data
    }
  }
}

const initialState = {
  isProcedureRunning: false,
  isBreakPoint: false,
  couldStop: false,
  reachedBreakPoint: '',
  shouldUpdateScroll: true,
  reachedNodeId: ''
}

export default function procedureReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
