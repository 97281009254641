import { connect } from 'react-redux'
import ProcedureRunConsoleComponent from './ProcedureRunConsoleComponent'

const mapDispatchToProps = {}

const mapStateToProps = state => ({
  isProcedureRunning: state.procedure.isProcedureRunning,
  ssmId: state.selectElement.ssmId,
  driver: state.driver.driver,
  logItems: state.driver.logItems
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureRunConsoleComponent)
