import { combineReducers } from 'redux'
import locationReducer from './thunk/reducerList/location'
import authReducer from './thunk/reducerList/auth'
import diagramValidationReducer from './thunk/reducerList/diagramValidation'
import diagramAttributesReducer from './thunk/reducerList/diagramAttributes'
import projectReducer from './thunk/reducerList/project'
import selectElementReducer from './thunk/reducerList/selectElement'
import otxFileReducer from './thunk/reducerList/otxFile'
import diagramElementReducer from './thunk/reducerList/diagramElement'
import outlineTreeReducer from './thunk/reducerList/outlineTree'
import parametersReducer from './thunk/reducerList/parameters'
import procedureReducer from './thunk/reducerList/procedure'
import driverReducer from './thunk/reducerList/driver'
import downloadFileReducer from './thunk/reducerList/downloadFile'
import termValidationReducer from './thunk/reducerList/termValidation'
import loaderContorlReducer from './thunk/reducerList/loaderControl'
import pseudoCodeReducer from './thunk/reducerList/pseudoCode'
import universalModalReducer from './thunk/reducerList/universalModal'
import stateDiagramReducer from './thunk/reducerList/stateDiagram'
import { reducer as notifications } from 'react-notification-system-redux'

export const makeRootReducer = asyncReducers => {
  const appReducer = combineReducers({
    location: locationReducer,
    auth: authReducer,
    diagramValidation: diagramValidationReducer,
    diagramAttributes: diagramAttributesReducer,
    project: projectReducer,
    selectElement: selectElementReducer,
    otxFile: otxFileReducer,
    diagramElement: diagramElementReducer,
    outlineTree: outlineTreeReducer,
    parameters: parametersReducer,
    procedure: procedureReducer,
    driver: driverReducer,
    downloadFile: downloadFileReducer,
    termValidation: termValidationReducer,
    loaderControl: loaderContorlReducer,
    pseudoCode: pseudoCodeReducer,
    universalModal: universalModalReducer,
    stateDiagram: stateDiagramReducer,
    notifications,
    ...asyncReducers
  })
  return (state, action) => {
    if (action.type === 'RESET_STORE') {
      state = {
        auth: state.auth
      }
    }
    return appReducer(state, action)
  }
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
