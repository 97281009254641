import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Popup } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import { nameRegex } from '../../../../store/addons'
import TermEditorComponent from '../../../TermEditor/TermEditorComponent'
import testIds from '../../../../../public/testIds.json'
import {
  returnInitTermHandler,
  termEditorHandler,
  termCreatorHandler
} from '../../../../utilites/terms/terms'

export default class NewValidityComponent extends Component {
  constructor () {
    super()
    this.state = {
      name: '',
      specification: '',
      visibility: labels.modalPattern.inputs.visibility.options[1].value,
      validityExpression: '',
      validityExpressionTerm: '',
      otxId: '',
      nameError: false,
      visibilityError: false,
      valueOfError: false,
      parameter: returnInitTermHandler('Boolean'),
      componentName: 'newValidityComponent'
    }
  }

  static propTypes = {
    // props from parent
    saveModal: PropTypes.func,
    closeModal: PropTypes.func,
    fileType: PropTypes.string,
    editItem: PropTypes.object,
    otxVariableList: PropTypes.array,
    getOtxVariableList: PropTypes.func,
    params: PropTypes.object
  }

  componentDidMount = () => {
    const { params } = this.props
    if (typeof params.projectId !== 'undefined' && typeof params.otxFileId !== 'undefined') {
      this.props.getOtxVariableList(params.projectId, params.otxFileId)
    }
    if (this.props.editItem && this.props.editItem.type && this.props.editItem.type === 'Validity') {
      const { editItem } = this.props
      const parameter = termCreatorHandler(editItem.realisation, 'Boolean')
      this.setState({
        parameter,
        specification: editItem.otx_specification,
        name: editItem.otx_name,
        visibility: editItem.visibility,
        otxId: editItem.otx_id
       })
    }
  }

  handleChangeInput = (stateName, value) => {
    this.setState({
      [stateName]: value
    }, () => {
      if (stateName === 'name' || stateName === 'visibility') {
        this.validateBlur(stateName)
      }
    })
  }

  validateBlur = (stateName, validationName) => {
    const error = `${stateName}Error`
    const change = { ...this.state }
    change[error] = stateName === 'name' ? !nameRegex.test(this.state[stateName])
      : this.state[stateName] === '' || this.state[stateName] === null
    this.setState(change)
    if (validationName === 'formValidation') return change[error]
  }

  termEditorFieldHandler = (stateName, value, propertyName) => {
    const newTerm = termEditorHandler(this.state[stateName], stateName, value, propertyName)
    this.setState({ [stateName]: newTerm })
  }

  saveData = () => {
    const {
      name,
      specification,
      visibility,
      otxId
    } = this.state
    const { saveModal, fileType } = this.props
    if (this.isFormValid()) {
      saveModal(fileType, {
        otx_name: name,
        otx_id: otxId,
        otx_specification: specification,
        type: 'Validity',
        realisation: this.state.parameter.term,
        visibility
      })
    }
  }

  isFormValid = () => (
    !this.validateBlur('name', 'formValidation') &&
    !this.validateBlur('visibility', 'formValidation')
  )

  render () {
    const { inputs } = labels.modalPattern
    const {
      nameError,
      visibilityError,
      parameter,
      componentName
    } = this.state
    return (
      <React.Fragment>
        <div className='wizard__form-container'>
          <Form className='wizard__form'>
            <Form.Group>
              <div className='wizard__form-info'>
                <Popup
                  position='top center'
                  content={labels.hints.validity.name}
                  trigger={<Icon name='info' />} />
                <Form.Input
                  id={`${componentName}_${testIds.nameInput}`}
                  label={inputs.name.label}
                  placeholder={inputs.name.placeholder}
                  type='text'
                  value={this.state.name}
                  onChange={(event, { value }) => this.handleChangeInput('name', value)}
                  error={nameError}
                  onBlur={() => this.validateBlur('name')}
                  className='wizard__form-input'>
                </Form.Input>
              </div>
            </Form.Group>
            <Form.Group>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.TextArea
                  id={`${componentName}_${testIds.specificationTextArea}`}
                  label={inputs.specification.label}
                  value={this.state.specification}
                  onChange={(event, { value }) => this.handleChangeInput('specification', value)}
                  placeholder={inputs.specification.placeholder}
                  className='wizard__form-input'/>
              </div>
            </Form.Group>
            <Form.Group>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Select
                  fluid
                  id={`${componentName}_${testIds.visibilityDropDown}`}
                  label={inputs.visibility.label}
                  options={inputs.visibility.options}
                  onChange={(event, { value }) => this.handleChangeInput('visibility', value)}
                  error={visibilityError}
                  onBlur={() => this.validateBlur('visibility')}
                  placeholder={inputs.modifiedVariable.placeholder}
                  value={this.state.visibility} />
              </div>
            </Form.Group>
            <TermEditorComponent
              changeHandler={this.termEditorFieldHandler}
              element={parameter}
              stateName='parameter'
              item={parameter}
              index={null}
              variableList={this.props.otxVariableList.filter(variable =>
                variable.obj.dataType ? variable.obj.dataType.type === 'Boolean' : false)}
            />
          </Form>
          <div className='wizard__footer'>
            <div>
              <Button
                onClick={this.props.closeModal}
                content={labels.buttons.cancel} />
            </div>
            <div>
              <Button
                onClick={this.saveData}
                content={labels.buttons.save}
                positive />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
