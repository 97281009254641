import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './NewProjectComponent.scss'
import { Form, Button, Icon, Modal } from 'semantic-ui-react'
import labels from '../../../public/labels.json'
import { nameRegex } from '../../store/addons'
import testIds from '../../../public/testIds.json'

class NewProjectComponent extends Component {
  constructor () {
      super()
      this.state = {
        name: '',
        nameError: false,
        description: '',
        fileInputValue: '',
        projectType: '',
        login: '',
        password: ''
      }
  }

  static propTypes = {
    isNewProjectOpen: PropTypes.bool,
    closeNewProject: PropTypes.func,
    saveModal: PropTypes.func,
    modalType: PropTypes.string,
    onSaveEditedProject: PropTypes.func,
    editProject: PropTypes.object,
    getProjectTypesList: PropTypes.func,
    projectTypesList: PropTypes.array
  }

  componentDidMount = () => {
    this.props.getProjectTypesList()
    .then(() => {
      const { projectTypesList } = this.props
      projectTypesList &&
      projectTypesList.length === 1 &&
      projectTypesList[0].value === 'ROBOT' &&
      this.setState({ projectType: projectTypesList[0].value })
    })
    this.props.modalType === 'editProject' && this.setState({
      name: this.props.editProject.name,
      description: this.props.editProject.description,
      projectType: this.props.editProject.projectType !== null ? this.props.editProject.projectType : ''
    })
  }

  validateBlur = (stateName, shouldResultBeReturned = false) => {
    const error = `${stateName}Error`
    const change = { ...this.state }
    change[error] = stateName === 'name' ? !nameRegex.test(this.state[stateName])
      : this.state[stateName] === '' || this.state[stateName] === null
    this.setState(change)
    if (shouldResultBeReturned) return change[error]
  }

  handleChangeInput = (stateName, event) => {
    const change = { ...this.state }
    change[stateName] = event.target.value
    this.setState(change)
  }

  handleChangeDropDown = (stateName, value) => {
    const change = { ...this.state }
    change[stateName] = value
    this.setState(change)
  }

  onFileInput = event => {
    this.setState({
      fileInputValue: event.target.files[0]
    })
  }

  componentWillUnmount () {
    this.setState({
      name: '',
      description: '',
      fileInputValue: ''
    })
  }

  handleItemToAdd = () => {
    const { name, description, fileInputValue, projectType, login, password } = this.state
    const itemToAdd = { data: {} }
    itemToAdd.data.name = name
    itemToAdd.data.description = description
    itemToAdd.data.projectType = projectType
    if (this.state.projectType === 'ROBOT' && fileInputValue !== '') itemToAdd.ssm = fileInputValue
    if (this.state.projectType === 'EGSCC_MCM') {
      itemToAdd.data.egsccLogin = login
      itemToAdd.data.egsccPassword = password
    }
    return itemToAdd
  }

  render () {
    const {
      isNewProjectOpen,
      closeNewProject,
      saveModal,
      modalType,
      onSaveEditedProject,
      projectTypesList
    } = this.props
    const {
      name,
      nameError,
      projectType,
      login,
      password,
      description
    } = this.state
    const { modalPattern, buttons } = labels
    return (
      <Modal className='modal__container'
      centered={true}
      open={isNewProjectOpen}>
        <div className='modal__header'>
          <div className='modal__header-content'>
            <div className='modal__header-logo'>
              {modalType === 'addProject' && <Icon name='folder' />}
              {modalType === 'editProject' && <Icon name='pencil alternate' />}
            </div>
            <div className='modal__header-text'>
              {modalType === 'addProject' && <h2>{modalPattern.newProject.title}</h2>}
              {modalType === 'addProject' && <p>{modalPattern.newProject.description}</p>}
              {modalType === 'editProject' && <h2>{modalPattern.editProject.title}</h2>}
              {modalType === 'editProject' && <p>{modalPattern.editProject.description}</p>}
            </div>
          </div>
        </div>
        <div className='modal__form-container'>
          <Form className='modal__form' name='new-project'>
            <Form.Group>
              <div className='modal__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Input
                  id={testIds.newProjectComponent.nameInput}
                  error={this.state.nameError}
                  onBlur={() => this.validateBlur('name')}
                  label={modalPattern.inputs.name.label}
                  placeholder={modalPattern.inputs.name.placeholder}
                  className='modal__form-input'
                  value={this.state.name}
                  onChange={event => this.handleChangeInput('name', event)} />
              </div>
            </Form.Group>
            <Form.Group>
              <div className='modal__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.TextArea
                  id={testIds.newProjectComponent.descriptionTextArea}
                  label={modalPattern.inputs.description.label}
                  placeholder={modalPattern.inputs.description.placeholder}
                  className='modal__form-input'
                  value={description}
                  onChange={event => this.handleChangeInput('description', event)} />
              </div>
            </Form.Group>
            {modalType === 'addProject' && <Form.Group>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Select
                  fluid
                  id={testIds.newProjectComponent.projectTypeDropDown}
                  label={modalPattern.inputs.projectType.label}
                  placeholder={modalPattern.inputs.projectType.placeholder}
                  options={projectTypesList}
                  onChange={(event, { value }) => this.handleChangeDropDown('projectType', value)}
                  value={projectType} />
              </div>
              </Form.Group>}
            {(modalType === 'addProject' && projectType === 'ROBOT') && <Form.Group>
              <div className='modal__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Input
                  id={testIds.newProjectComponent.testedSystemSpecificationInput}
                  label={modalPattern.inputs.testedSystemSpecification.label}
                  type='file'
                  className='modal__form-input'
                  onChange={event => this.onFileInput(event)} />
              </div>
            </Form.Group>}
            {(modalType === 'addProject' && projectType === 'EGSCC_MCM') &&
              <Form.Group className='wizard__form-col1'>
                <div className='modal__form-info'>
                  <Icon name='info' className='info-hidden' />
                  <Form.Input
                    id={testIds.newProjectComponent.userNameInput}
                    label={labels.loginComponent.username}
                    placeholder={labels.loginComponent.enterUsername}
                    className='modal__form-input'
                    value={login}
                    onChange={event => this.handleChangeInput('login', event)} />
                </div>
              </Form.Group>}
            {(modalType === 'addProject' && projectType === 'EGSCC_MCM') &&
              <Form.Group className='wizard__form-col1'>
                <div className='modal__form-info'>
                  <Icon name='info' className='info-hidden' />
                  <Form.Input
                    id={testIds.newProjectComponent.passwordInput}
                    label={labels.loginComponent.password}
                    type='password'
                    placeholder={labels.loginComponent.enterPassword}
                    className='modal__form-input'
                    value={password}
                    onChange={event => this.handleChangeInput('password', event)} />
                </div>
              </Form.Group>}
          </Form>
        </div>
        <div className='modal__footer'>
          <div>
            <Button
              id={testIds.newProjectComponent.cancelButton}
              content={buttons.cancel}
              onClick={closeNewProject} />
          </div>
          <div>
            {modalType === 'addProject' &&
            <Button
              id={testIds.newProjectComponent.finishButton}
              positive
              disabled={
                name === '' ||
                nameError ||
                projectType === '' ||
                (login === '' && projectType === 'EGSCC_MCM') ||
                (password === '' && projectType === 'EGSCC_MCM')}
              content={buttons.finish}
              onClick={ () => {
                const itemToAdd = this.handleItemToAdd()
                saveModal(itemToAdd)
              }} />}
            {modalType === 'editProject' &&
            <Button
            id={testIds.newProjectComponent.saveButton}
              positive
              disabled={name === '' || nameError || projectType === ''}
              content={buttons.save}
              onClick={() => onSaveEditedProject(name, description)} />}
          </div>
        </div>
      </Modal>)
  }
}

export default NewProjectComponent
