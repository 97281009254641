import { connect } from 'react-redux'
import DiagramContentComponent from './DiagramContentComponent.js'
import { copyElementHandler } from '../../../../store/thunk/reducerList/selectElement'
import { validateBeforePaste } from '../../../../store/thunk/reducerList/diagramElement'
import { updateScroll } from '../../../../store/thunk/reducerList/procedure'
import { loaderControl } from '../../../../store/thunk/reducerList/loaderControl'

const mapDispatchToProps = {
  copyElementHandler,
  updateScroll,
  validateBeforePaste,
  loaderControl
}
const mapStateToProps = state => ({
  reachedBreakPoint: state.procedure.reachedBreakPoint,
  isProcedureRunning: state.procedure.isProcedureRunning,
  shouldUpdateScroll: state.procedure.shouldUpdateScroll,
  nodesToCopyObject: state.selectElement.nodesToCopyObject,
  copiedNodes: state.selectElement.copiedNodes
})

export default connect(mapStateToProps, mapDispatchToProps)(DiagramContentComponent)
