import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'
import { dndTypes } from '../../../../../../constants/ItemTypes'
import { Icon } from 'semantic-ui-react'
import {
  diagramElementModifer,
  couldDrop,
  isContainItself,
  isSupportedElement,
  isNode,
  isDiagramEnded
} from '../../../../../../constants/common'

const overlayTarget = {
    drop (props, monitor, component) {
      if (monitor.isOver({ shallow: true })) {
        const placeToAdd = props.item
        const position = props.boxPosition
        const itemToAdd = monitor.getItem().item
        const diagramKind = 'diagram_list'
        if (itemToAdd.kind === diagramKind) {
            itemToAdd.id !== placeToAdd.id && props.addElement(itemToAdd, placeToAdd, position)
        } else {
          itemToAdd.kind = diagramKind
          isSupportedElement(itemToAdd.type) &&
          props.openModal(diagramElementModifer(itemToAdd, placeToAdd, props), placeToAdd, position)
        }
      }
    },
    canDrop (props, monitor) {
      if (monitor.isOver({ shallow: true })) {
        const item = monitor.getItem().item
        const parent = props.parent
        if (props.item.id !== item.id && isContainItself(item.children, props.item)) {
          return couldDrop(item, parent, props)
        }
      }
    }
  }

class OverlayComponent extends Component {
    constructor () {
        super()
        this.state = {
          elements: [],
          prevId: null
       }
    }

    static propTypes = {
        item: PropTypes.object,
        connectDropTarget: PropTypes.func,
        canDrop: PropTypes.bool,
        isOver: PropTypes.bool,
        boxPosition: PropTypes.string,
        parent: PropTypes.object,
        onContextMenuHandler: PropTypes.func
    }

    renderArrow = (position, index, type) => position === 'top' && index > 0 && (isNode(type) || isDiagramEnded(type))

    render () {
      const { connectDropTarget, isOver, canDrop, boxPosition, item, parent, onContextMenuHandler } = this.props
      const isOverDrop = isOver && canDrop
      return connectDropTarget(
        <div
          id={`overlayComponent_${boxPosition}-${item.type}-${item.position}`}
          onContextMenu={e => onContextMenuHandler(e, item, 'paste', parent, boxPosition)}
          style={{
            height: 30,
            width: '100%',
            border: isOverDrop && '1px dotted #9396a2',
            backgroundColor: isOverDrop && '#f1ffef',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          {isOverDrop
          ? <Icon name='plus' size='large' style={{ color: '#9396a2', margin: 0 }} />
          : this.renderArrow(boxPosition, item.position, item.type) &&
          <Icon
            style={{ color: '#9396a2', margin: 0 }}
            size='large'
            name='long arrow alternate down' />}
        </div>)
    }
}

export default DropTarget(dndTypes.DIAGRAM, overlayTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }))(OverlayComponent)
