import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Popup } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import { handlerStructureCreator } from '../../../../constants/itemStructureCreator.js'
import { nameRegex } from '../../../../store/addons'
import { isEqual } from 'lodash'
import testIds from '../../../../../public/testIds.json'

export default class HandlerWizard extends Component {
  constructor (props) {
    super()
    this.state = {
      basicName: (props.modalItemToAdd &&
      props.modalItemToAdd.otx_name && props.modalItemToAdd.type === 'Handler') ? props.modalItemToAdd.otx_name : '',
      basicSpecification: (props.modalItemToAdd && props.modalItemToAdd.otx_specification &&
      props.modalItemToAdd.type === 'Handler')
      ? props.modalItemToAdd.otx_specification : '',
      technicalName: (props.modalItemToAdd &&
      props.modalItemToAdd.otx_name && props.modalItemToAdd.type !== 'Handler') ? props.modalItemToAdd.otx_name : '',
      technicalSpecification: (props.modalItemToAdd && props.modalItemToAdd.otx_specification &&
      props.modalItemToAdd.type !== 'Handler')
      ? props.modalItemToAdd.otx_specification : '',
      caughtExceptionType: '',
      handlingVar: '',
      itemToAdd: {},
      basicNameError: false,
      technicalNameError: false,
      caughtExceptionTypeError: false,
      variableList: []
    }
  }

  static propTypes = {
    // *** props from parent below ***
    modalItemToAdd: PropTypes.object,
    saveModal: PropTypes.func,
    closeModal: PropTypes.func,
    item: PropTypes.object,
    onTabChange: PropTypes.func,
    isFirstTabActive: PropTypes.bool,
    params: PropTypes.object,
    // *** props from reducer below ***
    getVariableList: PropTypes.func,
    variableList: PropTypes.array,
    openAddVariable: PropTypes.func,
    newVariable: PropTypes.object
  }

  componentDidMount = () => {
    const { params, getVariableList, item } = this.props
    if (typeof params.projectId !== 'undefined' &&
        typeof params.otxFileId !== 'undefined' &&
        typeof params.procedureId !== 'undefined') {
        getVariableList(params.projectId, params.otxFileId, params.procedureId)
    }
    if (item.realisation) { // we are editing (not adding) Catch
      this.setState({
        caughtExceptionType: item.realisation.exceptionType.type,
        handlingVar: item.realisation.handle ? item.realisation.handle.name : ''
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!isEqual(prevProps.newVariable, this.props.newVariable)) {
      const handlingVar = this.props.newVariable.otx_name || ''
      this.setState({ handlingVar })
    }
  }

  handleChangeInput = (stateName, e) => {
    const change = { ...this.state }
    change[stateName] = e.target.value
    this.setState(change)
    this.setState(change, () => this.validateBlur(stateName))
  }

  handleChangeDropDown = (stateName, value) => {
    const change = {}
    change[stateName] = value
    isEqual(stateName, 'caughtExceptionType') && (change.handlingVar = '')
    this.setState(change)
  }

  validateBlur = (stateName, validationName) => {
    const error = `${stateName}Error`
    const change = { ...this.state }
    change[error] = stateName === 'basicName' || stateName === 'technicalName' ? !nameRegex.test(this.state[stateName])
      : this.state[stateName] === '' || this.state[stateName] === null
    this.setState(change)
    if (validationName === 'formValidation') return change[error]
  }

  isFormValid = itemType => {
    if (itemType === 'Try' ||
        itemType === 'Finally') {
        return true
    } else if (itemType === 'Handler') {
        return !this.validateBlur('basicName', 'formValidation')
    } else if (itemType === 'CatchingFlow') {
        return (!this.validateBlur('technicalName', 'formValidation') &&
                !this.validateBlur('caughtExceptionType', 'formValidation'))
    }
  }

  saveModal = e => {
    e.preventDefault()
    const { saveModal, modalItemToAdd } = this.props
    if (this.isFormValid(modalItemToAdd.type) &&
    (modalItemToAdd.children[0] ? this.isFormValid(modalItemToAdd.children[0].type) : true) &&
    (modalItemToAdd.children[1] ? this.isFormValid(modalItemToAdd.children[1].type) : true)) {
      const data = handlerStructureCreator(modalItemToAdd, this.state)
      saveModal(data)
    }
  }

  renderButtons = () => {
    const { buttons } = labels
    if (this.props.modalItemToAdd.isEdit || !this.props.modalItemToAdd.children.length) {
      return (
        <div>
          <Button
            id={testIds.wizardInputs.finishButton}
            onClick={e => this.saveModal(e)}
            content={buttons.finish}
            positive />
        </div>
      )
    } else {
      return (
        <div>
          {this.props.isFirstTabActive
          ? <Button
              id={testIds.wizardInputs.nextButton}
              disabled={this.state.basicNameError}
              onClick={() => !this.validateBlur('basicName', 'formValidation') && this.props.onTabChange(false, true)}
              content={buttons.next} />
          : <Button
              id={testIds.wizardInputs.previousButton}
              onClick={() => this.props.onTabChange(true, false)}
              content={buttons.previous} />}
          {(!this.props.isFirstTabActive || !this.props.modalItemToAdd.children.length) &&
          <Button
            id={testIds.wizardInputs.finishButton}
            onClick={e => this.saveModal(e)}
            content={buttons.finish}
            positive />}
        </div>)
    }
  }

   filterVariableList = (variableList, type) => variableList.filter(item => (item.obj.dataType &&
    item.obj.dataType.type && item.obj.dataType.type === type))

  render () {
    const {
      basicName,
      basicSpecification,
      technicalName,
      technicalSpecification,
      caughtExceptionType,
      handlingVar,
      basicNameError,
      technicalNameError,
      caughtExceptionTypeError
    } = this.state
    const {
      closeModal,
      item,
      variableList
    } = this.props
    const { inputs } = labels.modalPattern
    const { buttons, modalPattern, hints } = labels
      if (item.type === 'Handler') {
      return (
        <React.Fragment>
          <div className='wizard__form-container'>
            <Form.Group className='wizard__form'>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Popup
                    position='top center'
                    content={hints.diagramModal.basicName}
                    trigger={<Icon name='info' />} />
                  <Form.Input
                    id={testIds.wizardInputs.nameInput}
                    error={basicNameError}
                    onBlur={() => this.validateBlur('basicName')}
                    label={inputs.name.label}
                    placeholder={inputs.name.placeholder}
                    value={basicName}
                    type='text'
                    onChange={event => this.handleChangeInput('basicName', event)}
                    className='wizard__form-input' />
                </div>
              </Form.Group>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Icon name='info' className='info-hidden' />
                  <Form.TextArea
                    id={testIds.wizardInputs.descriptionTextArea}
                    label={inputs.specification.label}
                    onChange={event => this.handleChangeInput('basicSpecification', event)}
                    placeholder={inputs.specification.placeholder}
                    value={basicSpecification}
                    className='wizard__form-input' />
                </div>
              </Form.Group>
            </Form.Group>
          </div>
          <div className='wizard__footer'>
            <div>
              <Button
                id={testIds.wizardInputs.cancelButton}
                onClick={closeModal}
                content={buttons.cancel} />
            </div>
            {this.renderButtons()}
          </div>
        </React.Fragment>
      )
      } else if (item.type === 'CatchingFlow') {
        return (
          <React.Fragment>
            <div className='wizard__form-container'>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Icon name='info' className='info-hidden' />
                  <Form.Input
                    id={testIds.wizardInputs.technicalNameInput}
                    error={technicalNameError}
                    onBlur={() => this.validateBlur('technicalName')}
                    label={inputs.name.label}
                    placeholder={inputs.name.placeholder}
                    value={technicalName}
                    type='text'
                    onChange={event => this.handleChangeInput('technicalName', event)}
                    className='wizard__form-input' />
                </div>
              </Form.Group>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Icon name='info' className='info-hidden' />
                  <Form.TextArea
                    id={testIds.wizardInputs.technicalDescriptionTextArea}
                    label={inputs.specification.label}
                    onChange={event => this.handleChangeInput('technicalSpecification', event)}
                    placeholder={inputs.specification.placeholder}
                    value={technicalSpecification}
                    className='wizard__form-input' />
                </div>
              </Form.Group>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Popup
                    trigger={<Icon name='info' className='info' />}
                    position='top center'
                    content={hints.diagramModal.catchingFlow.caughtExceptionType} />
                  <Form.Select
                    id={testIds.wizardInputs.caughtExceptionTypeDropDown}
                    error={caughtExceptionTypeError}
                    onBlur={() => this.validateBlur('caughtExceptionType')}
                    fluid
                    label={inputs.caughtExceptionType.label}
                    options={labels.catching_flow_exception_list}
                    onChange={(e, { value }) => this.handleChangeDropDown('caughtExceptionType', value)}
                    placeholder={inputs.caughtExceptionType.placeholder}
                    value={caughtExceptionType} />
                </div>
              </Form.Group>
              <Form.Group className='wizard__form-col2'>
                <div className='wizard__form-info'>
                  <Popup
                    trigger={<Icon name='info' className='info' />}
                    position='top center'
                    content={hints.diagramModal.catchingFlow.handlingVar} />
                  <Form.Select
                    fluid
                    id={testIds.wizardInputs.handlingVariableDropDown}
                    label={inputs.handlingVariable.label}
                    options={this.filterVariableList(variableList, caughtExceptionType)}
                    onChange={(e, { value }) => this.handleChangeDropDown('handlingVar', value)}
                    placeholder={inputs.handlingVariable.placeholder}
                    value={handlingVar} />
                </div>
                <Button
                  id={testIds.wizardInputs.addVariableButton}
                  content={buttons.addVariable}
                  onClick={() => this.props.openAddVariable(true)} />
              </Form.Group>
            </div>
            <div className='wizard__footer'>
              <div>
                <Button
                  id={testIds.wizardInputs.cancelButton}
                  onClick={closeModal}
                  content={buttons.cancel} />
              </div>
              {this.renderButtons()}
            </div>
          </React.Fragment>
      )
    } else if (item.type === 'Try' || item.type === 'Finally') {
        return (
          <React.Fragment>
            <p className='modalcomponent__body-warning'>
              {item.type === 'Try' ? modalPattern.handler.modalWarningTry
              : modalPattern.handler.modalWarningFinally}
            </p>
            <div className='wizard__footer'>
              <div>
                <Button
                  id={testIds.wizardInputs.cancelButton}
                  onClick={closeModal}
                  content={buttons.cancel} />
              </div>
              {this.renderButtons()}
            </div>
          </React.Fragment>
      )
    }
  }
}
