import React, { Component } from 'react'
import { Form, Button, Icon } from 'semantic-ui-react'
import ClearSelect from '../../../Utilities/ClearSelect'
import PropTypes from 'prop-types'
import labels from '../../../../../public/labels.json'

class BitFieldModifiers extends Component {
    static propTypes = {
        optionsVariableList: PropTypes.array,
        validities: PropTypes.array,
        openAddVariable: PropTypes.func,
        onSelect: PropTypes.func,
        onValidate: PropTypes.func,
        validFor: PropTypes.string,
        modifiedByteField: PropTypes.array,
        modifiedByteFieldError: PropTypes.bool,
        listRemove: PropTypes.obj
    }

    render () {
        const {
            optionsVariableList,
            validities,
            openAddVariable,
            validFor,
            modifiedByteField,
            modifiedByteFieldError,
            onSelect,
            onValidate
        } = this.props
        const { inputs } = labels.modalPattern
        const { buttons } = labels
        return (
          <React.Fragment>
            <Form.Group>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Select
                    label={inputs.validFor.label}
                    options={validities}
                    onChange={(e, { value }) => onSelect('validFor', value)}
                    placeholder={inputs.validFor.placeholder}
                    value={validFor} />
                {validFor !== '' && <ClearSelect handleClick={() => onSelect('validFor', '')} />}
              </div>
            </Form.Group>
            <Form.Group className='wizard__form-col2'>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Select
                    error={modifiedByteFieldError}
                    onBlur={() => onValidate('modifiedByteField')}
                    label={inputs.modifiedByteField.label}
                    placeholder={inputs.modifiedByteField.placeholder}
                    options={optionsVariableList}
                    onChange={(e, { value }) => onSelect('modifiedByteField', value)}
                    value={modifiedByteField} />
              </div>
              <Button content={buttons.addVariable} onClick={() => openAddVariable(true)}/>
            </Form.Group>
          </React.Fragment>
        )
    }
}

export default BitFieldModifiers
