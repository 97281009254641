import EndWizard from './EndWizard'
import { connect } from 'react-redux'
import { getVariableList, openAddVariable } from '../../../../store/thunk/reducerList/diagramElement'
import { loaderControl } from '../../../../store/thunk/reducerList/loaderControl'
import { getParentLoops } from '../../../../store/thunk/reducerList/parameters'

const mapDispatchToProps = {
  getVariableList,
  getParentLoops,
  openAddVariable,
  loaderControl
}

const mapStateToProps = state => ({
  variableList: state.diagramElement.variableList,
  parentLoops: state.parameters.parentLoops,
  newVariable: state.outlineTree.newVariable
})

export default connect(mapStateToProps, mapDispatchToProps)(EndWizard)
