import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import './StateDiagramViewComponent.scss'
import { Button } from 'semantic-ui-react'
import { Stage, Layer } from 'react-konva'
import { NodeComponent } from './components/NodeComponent/NodeComponent'
import { ArrowComponent } from './components/ArrowComponent/ArrowComponent'
import { SdvModalComponent } from './components/SdvModalComponent/SdvModalComponent'
import { v4 as uuidv4 } from 'uuid'
import isEqual from 'lodash/isEqual'
import { bool, object, func, array } from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import TermWizard from '../TermEditor/components/TermWizard'
import { summaryLineHandler } from '../../constants/summaryLineCreator'

const StateDiagramViewComponent = ({
  isProcedureRunning,
  params,
  postStateDiagram,
  loaderControl,
  nodesDataStateProp,
  isActiveLoader,
  reachedNodeId,
  otxVariableList,
  getOtxVariableList
}) => {
  const diagramView = useRef(null)

  const [dimenssions, setDimenssions] = useState({ width: 0, height: 0 })
  const [nodesDataState, setNodesDataState] = useState(nodesDataStateProp)
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 })
  const [isStateModalOpen, setIsStateModalOpen] = useState(false)
  const [isArrowModalOpen, setIsArrowModalOpen] = useState(false)
  const [selectedId, setselectedId] = useState('')
  const [editObject, setEditObject] = useState({})

  const setDimenssionsHandler = () => {
    setDimenssions({
      width: diagramView.current.clientWidth,
      height: diagramView.current.clientHeight
    })
  }

  useLayoutEffect(() => {
    if (diagramView.current) {
      setDimenssionsHandler()
    }
    window.addEventListener('resize', setDimenssionsHandler)
    return () => {
      window.removeEventListener('resize', setDimenssionsHandler)
    }
  }, [])

  useEffect(() => {
    const { width, height } = dimenssions
    if (isEmpty(nodesDataStateProp.nodes) && width !== 0 && height !== 0) {
      const newNodes = {
        [uuidv4()]: { type: 'START', x: 50, y: height / 2 },
        [uuidv4()]: { type: 'END', x: width - 50, y: height / 2 }
      }
      setNodesDataState({ ...nodesDataState, nodes: newNodes })
    } else if (!isEmpty(nodesDataStateProp.nodes) && !isEqual(nodesDataStateProp, nodesDataState)) {
      setNodesDataState({ ...nodesDataStateProp })
    }
  }, [nodesDataStateProp, dimenssions])

  useEffect(() => {
    typeof params.otxFileId !== 'undefined' && setStagePos({ x: 0, y: 0 })
  }, [params.otxFileId])

  useEffect(() => {
    if (reachedNodeId !== selectedId) {
      setselectedId(reachedNodeId)
    }
  }, [reachedNodeId])

  useEffect(() => {
    if (isArrowModalOpen && typeof params.projectId !== 'undefined' && typeof params.otxFileId !== 'undefined') {
      getOtxVariableList(params.projectId, params.otxFileId)
    }
  }, [isArrowModalOpen])

  const stagePosHandler = e => {
    setStagePos(e.currentTarget.position())
  }

  const updateNodes = (id, x, y) => {
    const newNodes = { ...nodesDataState }
    newNodes.nodes[id] = { ...newNodes.nodes[id], x, y }
    setNodesDataState(newNodes)
  }

  const addNewNode = (newNode, x = 50, y = 50) => {
    const name = uuidv4()
    const { nodes } = nodesDataState
    const newNodes = {
      ...nodes,
      [name]: { ...newNode, x, y }
    }
    nodesDataHandler({ ...nodesDataState, nodes: newNodes })
  }

  const removeNodeHandler = id => {
    if (!isProcedureRunning) {
      const nodes = { ...nodesDataState.nodes }
      const connections = nodesDataState.connections
        .filter(connection => connection.from !== id && connection.to !== id)
      delete nodes[id]
      nodesDataHandler({ ...nodesDataState, nodes, connections })
    }
  }

  const editNodeHandler = id => {
    if (!isProcedureRunning) {
      setEditObject({ node: { ...nodesDataState.nodes[id] }, id })
      setIsStateModalOpen(true)
    }
  }

  const editArrowHandler = connection => {
    if (!isProcedureRunning) {
      const newEditObject = connection
      setEditObject(newEditObject)
      setIsArrowModalOpen(true)
    }
  }

  const connectionsHandler = (id) => {
    if (!isProcedureRunning) {
      if (selectedId !== '' && selectedId !== id) {
        const newConnection = { from: selectedId, to: id, conditions: [], summaryLine: '' }
        const connections = nodesDataState.connections
          .filter(connection => !(connection.from === newConnection.from && connection.to === newConnection.to))

        if (isEqual(connections, nodesDataState.connections)) {
          connections.push(newConnection)
        }

        nodesDataHandler({ ...nodesDataState, connections })
        setselectedId('')
      } else if (selectedId === id) {
        setselectedId('')
      } else {
        setselectedId(id)
      }
    }
  }

  const saveSdvModal = (newNode, id) => {
    if (id) {
      const nodes = { ...nodesDataState.nodes, [id]: newNode }
      const newNodesDataState = { ...nodesDataState, nodes }
      nodesDataHandler(newNodesDataState)
    } else {
      addNewNode(newNode)
    }
  }

  const saveTermModal = newCondition => {
    const connection = {
      ...editObject,
      conditions: [newCondition],
      summaryLine: summaryLineHandler(newCondition)
    }
    const connections = [...nodesDataState.connections]
    const index = connections.findIndex(obj => isEqual(editObject, obj))
    if (index !== -1) {
      connections[index] = connection
    }
    const newNodesDataState = { ...nodesDataState, connections }
    nodesDataHandler(newNodesDataState)
    setIsArrowModalOpen(false)
  }

  const nodesDataHandler = (newNodes, shouldUpdateState = true) => {
    loaderControl(true)
    postStateDiagram(params.projectId, params.otxFileId, newNodes).then(() => {
      shouldUpdateState && setNodesDataState(newNodes)
      !isEmpty(editObject) && setEditObject({})
      loaderControl()
    })
    isStateModalOpen && setIsStateModalOpen(false)
  }

  return (
    <div className='state-diagram'>
      <div
        className='state-diagram__mainContainer chequered-pattern'
        ref={diagramView}
        key='StateDiagramViewComponent'>
        {typeof params.otxFileId !== 'undefined' &&
        dimenssions.width &&
        dimenssions.height &&
        !isEmpty(nodesDataState.nodes) &&
        <Stage
          width={dimenssions.width}
          height={dimenssions.height}
          preventDefault
          draggable={!isProcedureRunning}
          onDragEnd={stagePosHandler}
          x={stagePos.x}
          y={stagePos.y}>
          <Layer>
            {!isEmpty(nodesDataState.connections) &&
              nodesDataState.connections.map((connection, i) => {
                const fromNode = nodesDataState.nodes[connection.from]
                const toNode = nodesDataState.nodes[connection.to]
                if (fromNode && toNode) {
                  return <ArrowComponent
                    key={i}
                    index={i}
                    editArrowHandler={editArrowHandler}
                    connection={connection}
                    fromNode={fromNode}
                    toNode={toNode} />
                }
            })}
            {Object.entries(nodesDataState.nodes).map(([id, props], i) => (
              <NodeComponent
                key={i}
                id={id}
                updateNodes={updateNodes}
                selectedId={selectedId}
                isProcedureRunning={isProcedureRunning}
                connectionsHandler={connectionsHandler}
                removeNodeHandler={removeNodeHandler}
                editNodeHandler={editNodeHandler}
                {...props} />
              ))}
          </Layer>
        </Stage>}
      </div>
      <div className='state-diagram__toolbar'>
        <Button
          onClick={() => setIsStateModalOpen(true)}
          style={{ fontSize: '0.7rem', margin: 0 }}
          disabled={isProcedureRunning || isActiveLoader}
          positive
          icon='plus' />
        <Button
          onClick={() => nodesDataHandler(nodesDataState)}
          style={{ fontSize: '0.7rem', margin: '5px 0 0 0' }}
          disabled={isProcedureRunning || isActiveLoader}
          icon='save' />
      </div>
      {isStateModalOpen &&
        <SdvModalComponent
          isModalOpen={isStateModalOpen}
          editObject={editObject}
          setEditObject={setEditObject}
          saveModal={saveSdvModal}
          closeModal={() => {
            setEditObject({})
            setIsStateModalOpen(false)
          }}/>}
      {isArrowModalOpen &&
        <TermWizard
          isModalOpen={isArrowModalOpen}
          onSaveChangedTermValue={saveTermModal}
          onCloseModal={() => {
            setEditObject({})
            setIsArrowModalOpen(false)
          }}
          inputType={'Boolean'}
          variableList={otxVariableList}
          assignedVal={
            !isEmpty(editObject.conditions)
              ? { ...editObject.conditions[0] }
              : {}}
        />}
    </div>
  )
}

StateDiagramViewComponent.propTypes = {
  isProcedureRunning: bool,
  isActiveLoader: bool,
  reachedNodeId: bool,
  params: object,
  nodesDataStateProp: object,
  postStateDiagram: func,
  loaderControl: func,
  getOtxVariableList: func,
  otxVariableList: array
}

export default StateDiagramViewComponent
