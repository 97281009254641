import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './DiagramComponent.scss'
import ElementsListComponent from './components/ElementsListComponent'
import DiagramContentComponent from './components/DiagramContentComponent'
import InfoComponent from '../InfoComponent'
import { isSupportedElement } from '../../constants/common'
import labels from '../../../public/labels.json'
import { isEmpty } from 'lodash'
import { browserHistory } from 'react-router'

class DiagramComponent extends Component {
    static propTypes = {
      // props from redux
      diagramList: PropTypes.array,
      updateDiagramList: PropTypes.func,
      headerData: PropTypes.object,
      createDiagramElement: PropTypes.func,
      updateDiagramElement: PropTypes.func,
      modalElement: PropTypes.object,
      openRemoveModal: PropTypes.func,
      isOpenEditModal: PropTypes.func,
      openEditModal: PropTypes.func,
      closeEditModal: PropTypes.func,
      removeParameter: PropTypes.func,
      loaderControl: PropTypes.func,
      setBreakPoint: PropTypes.func,
      removeBreakPoint: PropTypes.func,
      deleteThrow: PropTypes.func,
      isActiveLoader: PropTypes.bool,
      isProjectContainOtx: PropTypes.bool,
      // props from parent
      params: PropTypes.object,
      openModal: PropTypes.func,
      updateScroll: PropTypes.func
    }

    componentDidMount () {
      this.props.isActiveLoader && this.props.loaderControl(false)
    }

    addElement = (itemToAdd, placeToAdd, position) => {
      const { updateDiagramElement, params } = this.props
      this.props.loaderControl(true)
      this.props.updateScroll(false)
      isSupportedElement(itemToAdd.type) &&
      updateDiagramElement(
          params.projectId,
          params.otxFileId,
          params.procedureId,
          itemToAdd.otx_id,
          placeToAdd.parentId.indexOf('/nodes/') === -1 ? placeToAdd.parentId : this.props.headerData.otx_id,
          position
          ? this.positionHandler(itemToAdd, placeToAdd, position) : 0,
          itemToAdd
        )
    }

    positionHandler = (itemToAdd, placeToAdd, position) => {
      if (itemToAdd.position < placeToAdd.position &&
          itemToAdd.parentId === placeToAdd.parentId) {
            return position === 'top' ? placeToAdd.position - 1 : placeToAdd.position
      }
      return position === 'top' ? placeToAdd.position : placeToAdd.position + 1
    }

    addToEmptyElement = (itemToAdd, placeToAdd) => {
      const { updateDiagramElement, params } = this.props
      this.props.loaderControl(true)
      this.props.updateScroll(false)
      isSupportedElement(itemToAdd.type) &&
      updateDiagramElement(
          params.projectId,
          params.otxFileId,
          params.procedureId,
          itemToAdd.otx_id,
          placeToAdd.otx_id,
          0,
          itemToAdd
        )
    }

  toolBarHandler = (item, buttonType, e) => {
    e.stopPropagation()
    const { params } = this.props
    switch (buttonType) {
      case 'edit':
        if (item !== undefined) {
          const tempItem = Object.assign({}, item)
          tempItem.children = []
          tempItem.isEdit = true
          this.props.openEditModal(tempItem)
        }
        break
      case 'remove':
          this.props.openRemoveModal(item)
          break
      case 'search':
          browserHistory.push(`/project/${params.projectId}/otx/${params.otxFileId}` +
          `/procedure/${params.procedureId}/element/${item.otx_id}/selectedElement/${item.otx_id}`)
          break
      case 'isBreakPoint':
        this.props.loaderControl(true)
        this.props.updateScroll(false)
        if (item.isBreakpointSet) {
          this.props.removeBreakPoint(params.projectId, params.otxFileId, item.otx_id, params.procedureId)
        } else {
          this.props.setBreakPoint(params.projectId, params.otxFileId, item.otx_id, params.procedureId)
        }
        this.props.loaderControl(false)
        break
    }
  }

  render () {
    const {
      headerData,
      diagramList,
      params,
      isActiveLoader,
      isProjectContainOtx
    } = this.props
    return (
      <div className='max-size diagram-container'>
        <div className='main-container'>
          <div className='main-content chequered-pattern'>
            {!isEmpty(headerData) && typeof params.procedureId !== 'undefined'
              ? <DiagramContentComponent
                openModal={this.props.openModal}
                diagramData={diagramList}
                headerData={headerData}
                addElement={this.addElement}
                addToEmptyElement={this.addToEmptyElement}
                toolBarHandler={this.toolBarHandler}
                params={params} />
              : !isActiveLoader && typeof params.procedureId === 'undefined' &&
                <InfoComponent
                  title={labels.infoComponent.title[
                    isProjectContainOtx
                      ? typeof params.otxFileId === 'undefined' ? 0 : 1
                      : 2]} />
            }
          </div>
        </div>
        <ElementsListComponent />
      </div>
    )
  }
}

export default DiagramComponent
