import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Popup } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import InitialValueComponent from '../InitialValueComponent'
import { nameRegex } from '../../../../store/addons'
import { isValidOptionalFloat, isValidOptionalInteger } from '../../../../utilites/validation/numbers'
import { cloneDeep } from 'lodash/lang'
import testIds from '../../../../../public/testIds.json'

class NewLocalDeclarationComponent extends Component {
  constructor (props) {
    super()
    this.state = {
      name: props.editItem && props.editItem.otx_name
        ? props.editItem.otx_name : '',
      specification: props.editItem && props.editItem.otx_specification
        ? props.editItem.otx_specification : '',
      datatype: props.editItem && props.editItem.dataType && props.editItem.dataType.type
        ? props.editItem.dataType.type : 'ActivityState',
      initValueError: false,
      keyType: {
        value : '',
        error: false
      },
      valueType: {
        value: '',
        error: false
      },
      initValue: ''
    }
  }

  static propTypes = {
    // props from parent
    saveModal: PropTypes.func,
    closeModal: PropTypes.func,
    fileType: PropTypes.string,
    editItem: PropTypes.object,
    isOpenAddVar: PropTypes.bool,
    openAddVariable: PropTypes.func
  }

  componentDidMount () {
    const { editItem } = this.props
    if (editItem.dataType) {
      if (editItem.dataType.keyType) {
        this.setState({ keyType: { ...this.state.keyType, value: editItem.dataType.keyType.type } })
      }
      if (editItem.dataType.type === 'List' && editItem.dataType.itemType) {
        this.setState({ keyType: { ...this.state.keyType, value: editItem.dataType.itemType.type } })
      }
      if (editItem.dataType.valueType) {
        this.setState({ valueType: { ...this.state.valueType, value: editItem.dataType.valueType.type } })
      }
      if (typeof editItem.dataType.value !== 'undefined' && editItem.dataType.value !== null) {
        this.setState({ initValue: editItem.dataType.value })
      }
    }
  }

  handleChangeInput = (stateName, value) => {
    const change = { ...this.state }
    change[stateName] = value
    if (stateName === 'datatype' && this.state.datatype !== value) {
      change.initValue = ''
    }
    this.setState(change)
    this.setState(change, () => this.validateBlur(stateName, null, value))
  }

  validateBlur = (stateName, validationName, value) => {
    const error = `${stateName}Error`
    const change = { ...this.state }
    if (stateName === 'name') {
      change[error] = !nameRegex.test(this.state[stateName])
    } else if (change.datatype === 'Integer') {
      change[error] = !isValidOptionalInteger(value)
    } else if (change.datatype === 'Float') {
      change[error] = !isValidOptionalFloat(value)
    } else {
      change[error] = this.state[stateName] === '' || this.state[stateName] === null
    }
    this.setState(change)
    if (validationName === 'formValidation') return change[error]
  }

  getValueFromType = (type = '', value) => {
    switch (type) {
      case 'Float':
      case 'Integer': {
        return Number(value)
      }
      case 'Boolean': {
        return value === 'true'
      }
      default: {
        return value
      }
    }
  }

  validateRequiredTypes () {
    let isValid = true
    if (this.state.datatype === 'Map' || this.state.datatype === 'List') {
      const keyType = cloneDeep(this.state.keyType)
      if (keyType.value === '') {
        keyType.error = true
        isValid = false
      }
      this.setState({ keyType })
    }
    if (this.state.datatype === 'Map') {
      const valueType = cloneDeep(this.state.valueType)
      if (valueType.value === '') {
        valueType.error = true
        isValid = false
      }
      this.setState({ valueType })
    } else if (this.state.datatype === 'Integer') {
      isValid = isValidOptionalInteger(this.state.initValue)
    } else if (this.state.datatype === 'Float') {
      isValid = isValidOptionalFloat(this.state.initValue)
    }
    return isValid
  }

  isFormValid = () => (!this.validateBlur('name', 'formValidation') && this.validateRequiredTypes())

  saveData = () => {
    if (this.isFormValid()) {
      const data = {
        otx_name: this.state.name,
        otx_specification: this.state.specification,
        type: this.props.fileType,
        children: []
      }

      switch (this.state.datatype) {
        case 'Map':
          data.dataType = {
            type: 'Map',
            children: [],
            keyType: {
                type: this.state.keyType.value
            },
            valueType: {
                type: this.state.valueType.value
            },
            init: {
                type: 'MapLiteral',
                keyType: {
                    type: this.state.keyType.value
                },
                valueType: {
                    type: this.state.valueType.value
                },
                items: []
            }
          }
          break
        case 'List':
          data.dataType = {
            type: 'List',
            children: [],
            itemType: {
              type: this.state.keyType.value,
              children: [],
              value: null
            }
          }
          break
        default:
          data.dataType = {
            type: this.state.datatype,
            children: [],
            value: this.state.initValue === '' ? null : this.getValueFromType(this.state.datatype, this.state.initValue)
          }
          break
      }
      this.props.saveModal(this.props.fileType, data, this.props.editItem.otx_id)
    }
  }

  render () {
    const { inputs } = labels.modalPattern
    const {
      nameError,
      initValueError
    } = this.state
    return (
      <div className='wizard__form-container'>
        <Form className='wizard__form'>
          <Form.Group>
            <div className='wizard__form-info'>
              <Popup
                position='top center'
                content={labels.hints.localDeclaration.name}
                trigger={<Icon name='info' />} />
              <Form.Input
                label={inputs.name.label}
                id={testIds.newLocalDeclarationComponent.nameInput}
                placeholder={inputs.name.placeholder}
                type='text'
                value={this.state.name}
                onChange={(event, { value }) => this.handleChangeInput('name', value)}
                error={nameError}
                onBlur={() => this.validateBlur('name')}
                className='wizard__form-input'>
              </Form.Input>
            </div>
          </Form.Group>
          <Form.Group>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.TextArea
                label={inputs.specification.label}
                id={testIds.newLocalDeclarationComponent.specificationTextArea}
                value={this.state.specification}
                onChange={(event, { value }) => this.handleChangeInput('specification', value)}
                placeholder={inputs.specification.placeholder}
                className='wizard__form-input'/>
            </div>
          </Form.Group>
          <Form.Group>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                search
                label={inputs.datatype.label}
                options={inputs.datatype.options}
                id={testIds.newLocalDeclarationComponent.dataTypeDropDown}
                onChange={(event, { value }) => this.handleChangeInput('datatype', value)}
                placeholder={inputs.modifiedVariable.placeholder}
                value={this.state.datatype} />
            </div>
          </Form.Group>
          {this.state.datatype !== '' &&
            <InitialValueComponent
              initValueError={initValueError}
              handleChangeInput={this.handleChangeInput}
              dataType={this.state.datatype}
              keyType={this.state.keyType}
              valueType={this.state.valueType}
              initValue={this.state.initValue} />}
        </Form>
        <div className='wizard__footer'>
          <div>
            <Button
              id={testIds.newLocalDeclarationComponent.cancelButton}
              onClick={this.props.closeModal}
              content={labels.buttons.cancel} />
          </div>
          <div>
            <Button
              id={testIds.newLocalDeclarationComponent.saveButton}
              onClick={this.saveData}
              content={labels.buttons.save}
              positive />
          </div>
        </div>
      </div>
    )
  }
}

export default NewLocalDeclarationComponent
