import { connect } from 'react-redux'
import WrapperTreeComponent from './WrapperTreeComponent'
import {
  getDiagramList,
  openRemoveModal,
  openEditModal,
  clearDiagramData,
  openAddVariable
} from '../../store/thunk/reducerList/diagramElement'
import {
  getProject,
  setProjectTabDataAction,
  setExecutionsTabDataAction
} from '../../store/thunk/reducerList/project'
import { loaderControl } from '../../store/thunk/reducerList/loaderControl'
import {
  getOutlineTree,
  importOtxFile,
  addOtxFile,
  updateOtxFile,
  importOtxFileFromEgscc,
  getOutlineTreeAction,
  setDevicesTabData,
  getOtx
} from '../../store/thunk/reducerList/otxFile'
import { getXmlPreview, setActiveTopTabIndex } from '../../store/thunk/reducerList/diagramAttributes'
import {
  addProcedure,
  addSignature,
  addValidity,
  putValidity,
  addDeclaration,
  addParameter,
  addLocalDeclaration,
  editLocalDeclaration,
  editDeclaration,
  editParameter,
  editSignature,
  editProcedure,
  addImport,
  deleteImport,
  editImport,
  addThrow,
  editThrow
} from '../../store/thunk/reducerList/outlineTree'
import { downloadFile, generateRaport } from '../../store/thunk/reducerList/downloadFile'

const mapDispatchToProps = {
    getOutlineTree,
    getDiagramList,
    importOtxFile,
    addOtxFile,
    updateOtxFile,
    getXmlPreview,
    addProcedure,
    addSignature,
    addValidity,
    putValidity,
    addDeclaration,
    addParameter,
    editParameter,
    addLocalDeclaration,
    editLocalDeclaration,
    openRemoveModal,
    openEditModal,
    editDeclaration,
    loaderControl,
    openAddVariable,
    editSignature,
    editProcedure,
    importOtxFileFromEgscc,
    getOutlineTreeAction,
    downloadFile,
    addImport,
    deleteImport,
    editImport,
    addThrow,
    editThrow,
    getProject,
    clearDiagramData,
    generateRaport,
    setActiveTopTabIndex,
    setDevicesTabData,
    setProjectTabDataAction,
    setExecutionsTabDataAction,
    getOtx
}
const mapStateToProps = state => ({
    outlineData: state.otxFile.outlineTree,
    devicesDataTab: state.otxFile.devicesDataTab,
    headerData: state.diagramElement.headerData,
    addNewFileResponseTime: state.otxFile.updateOtxFileModalTime,
    projectTabData: state.project.projectTabData,
    executionsTabData: state.project.executionsTabData,
    isOpenAddVar: state.diagramElement.isOpenAddVar,
    isProcedureRunning: state.procedure.isProcedureRunning,
    isTesterRole: state.auth.isTesterRole,
    roles: state.auth.roles,
    projectType: state.project.projectType
})

export default connect(mapStateToProps, mapDispatchToProps)(WrapperTreeComponent)
