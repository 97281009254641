import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './WrapperTreeComponent.scss'
import { Tab, Dropdown } from 'semantic-ui-react'
import VirtualizedTreeComponent from './components/VirtualizedTreeComponent'
import labels from '../../../public/labels.json'
import testIds from '../../../public/testIds.json'
import { isEqual, isEmpty } from 'lodash'
import ModalComponent from '../ModalComponent/index'
import OtxDocumentModal from '../OtxDocumentModal/index'
import AddNewFileModal from '../AddNewFileModal'
import {
  isDiagramEnded,
  isNode,
  isFlow,
  isGlobalDeclaration,
  isParameter,
  isLocalDeclaration
} from '../../constants/common'
import { topTreePanes } from '../../constants/treePanes'
import { throwTypes } from '../../constants/contextMenuLists'

class WrapperTreeComponent extends Component {
  constructor (props) {
    super()
    this.state = {
      isModalComponentOpen: false,
      modalOpenTime: 0,
      modalType: '',
      modalTitle: '',
      modalText: 'Do you want to leave the current project?',
      package: 'Procedures',
      items: [],
      editItem: {},
      contextItem: {},
      contextElementId: '',
      isSignatureElement: false,
      activeTopTabIndex: 0,
      selectedTopTreeIds: [props.params.otxFileId || null, null, null]
    }
  }

  static propTypes = {
    // props from parent
    params: PropTypes.object,
    // props from redux
    getOutlineTree: PropTypes.func,
    outlineData: PropTypes.array,
    devicesDataTab: PropTypes.array,
    getDiagramList: PropTypes.func,
    importOtxFile: PropTypes.func,
    headerData: PropTypes.object,
    addNewFileResponseTime: PropTypes.number,
    addOtxFile: PropTypes.func,
    getXmlPreview: PropTypes.func,
    addProcedure: PropTypes.func,
    addSignature: PropTypes.func,
    addValidity: PropTypes.func,
    putValidity: PropTypes.func,
    addDeclaration: PropTypes.func,
    addParameter: PropTypes.func,
    addLocalDeclaration: PropTypes.func,
    openRemoveModal: PropTypes.func,
    openEditModal: PropTypes.func,
    editDeclaration: PropTypes.func,
    editParameter: PropTypes.func,
    editLocalDeclaration: PropTypes.func,
    loaderControl: PropTypes.func,
    isOpenAddVar: PropTypes.bool,
    openAddVariable: PropTypes.func,
    editSignature: PropTypes.func,
    editProcedure: PropTypes.func,
    importOtxFileFromEgscc: PropTypes.func,
    getOutlineTreeAction: PropTypes.func,
    downloadFile: PropTypes.func,
    addImport: PropTypes.func,
    deleteImport: PropTypes.func,
    editImport: PropTypes.func,
    addThrow: PropTypes.func,
    editThrow: PropTypes.func,
    getProject: PropTypes.func,
    clearDiagramData: PropTypes.func,
    generateRaport: PropTypes.func,
    projectTabData: PropTypes.array,
    executionsTabData: PropTypes.array,
    setActiveTopTabIndex: PropTypes.func,
    isProcedureRunning: PropTypes.bool,
    setDevicesTabData: PropTypes.func,
    setProjectTabDataAction: PropTypes.func,
    setExecutionsTabDataAction: PropTypes.func,
    isTesterRole: PropTypes.bool,
    updateOtxFile: PropTypes.func,
    projectType: PropTypes.string,
    getOtx: PropTypes.func,
    roles: PropTypes.array
  }

  componentDidMount () {
    const { params, getOutlineTree, getProject, loaderControl } = this.props
    if (typeof params.projectId !== 'undefined') {
      loaderControl(true)
      getProject(params.projectId)
    }
    if (typeof params.projectId !== 'undefined' && typeof params.otxFileId !== 'undefined') {
      getOutlineTree(params.projectId, params.otxFileId)
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let result = null
    if (nextProps.addNewFileResponseTime > prevState.modalOpenTime) {
      result ? (
        result = Object.assign(result, { isModalComponentOpen: false })
      ) : (
        result = { isModalComponentOpen: false }
      )
    }
    return result
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(this.props.params, prevProps.params)) {
      const { loaderControl, getOutlineTree, params, getOutlineTreeAction, isProcedureRunning } = this.props
      if (typeof params.otxFileId === 'undefined') {
        getOutlineTreeAction([])
        this.props.clearDiagramData()
      } else if (prevProps.params.otxFileId !== 'undefined' && !isEqual(prevProps.params.otxFileId, params.otxFileId)) {
        loaderControl(true)
        localStorage.removeItem('outlineDataCollapsedElements')
        this.props.clearDiagramData()
        getOutlineTree(params.projectId, params.otxFileId, isProcedureRunning)
      }
    }
  }

  handleAddNewFile = (modalTitle, fileType, modalMode) => {
    this.setState({
      isModalComponentOpen: true,
      modalOpenTime: Date.now(),
      fileType,
      modalTitle,
      modalType: 'add-file',
      modalMode
    })
  }

  handleExpandTree = (expandAction, isOutlineTree) => {
    const { activeTopTabIndex } = this.state
    const isExpanded = expandAction === 'expand'
    let localStorageName = ''
    const tempArr = []
    const loop = (data, isExpand) => {
      data.forEach(item => {
          if (item.state !== undefined) {
            item.state.expanded = isExpand
            !isExpand &&
            tempArr.push(item.id)
          }
         if (item.children) {
          return loop(item.children, isExpand)
        }
      })
    }
    if (!isOutlineTree) {
      if (activeTopTabIndex === 0) {
        localStorageName = 'projectTabDataCollapsedElements'
        const projectTabData = [...this.props.projectTabData]
        loop(projectTabData, isExpanded)
        this.props.setProjectTabDataAction(projectTabData)
       } else if (activeTopTabIndex === 1) {
        localStorageName = 'devicesDataTabCollapsedElements'
        const devicesDataTab = [...this.props.devicesDataTab]
        loop(devicesDataTab, isExpanded)
        this.props.setDevicesTabData(devicesDataTab)
       } else if (activeTopTabIndex === 2) {
        localStorageName = 'executionsDataTabCollapsedElements'
        const executionsTabData = [...this.props.executionsTabData]
        loop(executionsTabData, isExpanded)
        this.props.setExecutionsTabDataAction(executionsTabData)
       }
    } else {
      localStorageName = 'outlineDataCollapsedElements'
      const outlineData = [...this.props.outlineData]
      loop(outlineData, isExpanded)
      this.props.getOutlineTreeAction(outlineData)
    }
    localStorage.setItem(localStorageName, JSON.stringify(tempArr))
  }

  dropdownClickHandler = (modalTitle, modalType) => {
    this.setState({
      isModalComponentOpen: true,
      modalOpenTime: Date.now(),
      modalTitle,
      modalType
    })
  }

  handleDropdownItemClick = (el, isOutlineTree) => {
    switch (el.id) {
      case 103:
        this.handleAddNewFile(el.name, 'otx')
        break
      case 104:
          this.handleAddNewFile(el.name, 'ssm')
          break
      case 105:
          this.handleAddNewFile(el.name, 'postOtxFile')
          break
      case 106:
          this.handleExpandTree('expand', isOutlineTree)
          break
      case 107:
          this.handleExpandTree('collapse', isOutlineTree)
          break
      case 108:
        this.setState({ fileType: '' }, this.dropdownClickHandler(el.name, 'switchProject'))
          break
      case 109:
        this.exportFileHandler(el.name, 'exportActivity')
          break
      case 110:
        this.setState({ fileType: '' }, this.dropdownClickHandler(el.name, 'shareProject'))
          break
      case 200:
        this.handleAddNewFile(el.name, 'AddImport')
          break
      case 201:
          this.handleAddNewFile(el.name, 'postNewValidity')
          break
      case 202:
      case 203:
      case 204:
          this.setState({ editItem: {}, contextItem: { text: el.name, action: el.type } },
            this.handleAddNewFile(el.name, el.type))
          break
      case 205:
          this.handleAddNewFile(el.name, 'postNewProcedure')
          break
      case 206:
          this.handleAddNewFile(el.name, 'postNewSignature')
          break
      case 207:
          this.handleExpandTree('expand', isOutlineTree)
          break
      case 208:
          this.handleExpandTree('collapse', isOutlineTree)
          break
    }
  }

  exportFileHandler = (modalTitle, fileType) => {
    this.setState({
      isModalComponentOpen: true,
      modalOpenTime: Date.now(),
      fileType,
      modalTitle,
      modalType: 'export-file'
    })
  }

  dataHandler = (option = 'add', data, id) => {
    const { loaderControl, addOtxFile, params, updateOtxFile } = this.props
    if (option === 'add') {
      loaderControl(true)
      addOtxFile(params.projectId, data)
      .then(() => this.setState({ isModalComponentOpen: false }))
    } else {
      const shouldUpdate = params.otxFileId === id
      updateOtxFile(params.projectId, id, data, shouldUpdate)
      .then(() => this.setState({ isModalComponentOpen: false, editItem: {} }))
    }
  }

  closeModal = () => {
    this.props.openAddVariable(false)
    this.setState({
      editItem: {},
      isModalComponentOpen: false
    })
  }

  saveModal = (fileType, data, elId = '') => {
    const { params, headerData } = this.props
    const { contextElementId, isSignatureElement, contextItem, editItem } = this.state
    this.props.loaderControl(true)
    switch (fileType) {
      case 'Procedure':
        this.props.editProcedure(params.projectId, params.otxFileId, data)
        break
      case 'postNewProcedure':
        this.props.addProcedure(params.projectId, params.otxFileId, data)
        break
      case 'postNewSignature':
        this.props.addSignature(params.projectId, params.otxFileId, data)
        break
      case 'postNewValidity':
        this.props.addValidity(params.projectId, params.otxFileId, data)
        break
      case 'Validity':
        this.props.putValidity(params.projectId, params.otxFileId, data)
        break
      case 'Import':
        if (data && elId) {
          this.props.editImport(params.projectId, params.otxFileId, elId, data)
        } else if (data && !elId) {
          this.props.addImport(params.projectId, params.otxFileId, data)
        }
        break
      case 'Throw':
        if (data && elId) {
          this.props.editThrow(
            params.projectId,
            params.otxFileId,
            contextElementId,
            elId,
            data,
            isSignatureElement)
        } else if (data && !elId) {
          this.props.addThrow(
            params.projectId,
            params.otxFileId,
            contextElementId,
            data,
            headerData.type === 'Procedure',
            isSignatureElement)
        }
        break
      case 'DocumentVariableDeclaration':
      case 'GlobalConstantDeclaration':
      case 'ContextVariableDeclaration':
          if (contextItem && contextItem.action && contextItem.action !== 'edit') {
            this.props.addDeclaration(params.projectId, params.otxFileId, data)
          } else {
            data.otx_id = elId
            this.props.editDeclaration(params.projectId, params.otxFileId, elId, data)
          }
          break
      case 'InOutParameter':
      case 'OutParameter':
      case 'InParameter':
          if (contextItem && contextItem.action && contextItem.action !== 'edit') {
            this.props.addParameter(
              params.projectId,
              params.otxFileId,
              contextElementId,
              data,
              headerData.type === 'Procedure',
              isSignatureElement)
          } else {
            data.otx_id = elId
            const parentId = editItem.procedureId
            ? editItem.procedureId : editItem.signatureId
            this.props.editParameter(
              params.projectId,
              params.otxFileId,
              parentId,
              elId,
              data, this.props.headerData.type === 'Procedure',
              isSignatureElement)
          }
          break
      case 'ConstantDeclaration':
      case 'VariableDeclaration':
          if ((contextItem && contextItem.action && contextItem.action !== 'edit') ||
              this.props.isOpenAddVar) {
            this.props.addLocalDeclaration(
              params.projectId,
              params.otxFileId,
              this.props.isOpenAddVar
              ? params.procedureId : contextElementId,
              data,
              this.props.isOpenAddVar)
          } else {
            this.props.editLocalDeclaration(
              params.projectId,
              params.otxFileId,
              editItem.procedureId,
              elId,
              data)
          }
        break
      case 'Signature':
        this.props.editSignature(
          params.projectId,
          params.otxFileId,
          elId,
          data)
        break
    }
  }

  checkFileType = fileType => {
    return fileType === 'postNewProcedure' ||
          fileType === 'postNewSignature' ||
          fileType === 'postNewValidity' ||
          fileType === 'postNewGlobalDeclaration' ||
          fileType === 'postNewConstant' ||
          fileType === 'postNewVariable' ||
          fileType === 'GlobalConstantDeclaration' ||
          fileType === 'DocumentVariableDeclaration' ||
          fileType === 'ContextVariableDeclaration' ||
          fileType === 'OutParameter' ||
          fileType === 'InParameter' ||
          fileType === 'InOutParameter' ||
          fileType === 'ConstantDeclaration' ||
          fileType === 'VariableDeclaration' ||
          fileType === 'Validity' ||
          fileType === 'Signature' ||
          fileType === 'Procedure' ||
          fileType === 'AddImport' ||
          fileType === 'Import' ||
          fileType === 'AddThrow' ||
          fileType === 'Throw'
  }

  menuContextActionHandler = (contextItem, outlineItem) => {
    this.setState({
      contextElementId: outlineItem.procedureId || outlineItem.signatureId || '',
      isSignatureElement: !!outlineItem.signatureId
    })
    const { params } = this.props
    switch (contextItem.action) {
      case 'GlobalConstantDeclaration':
      case 'DocumentVariableDeclaration':
      case 'ContextVariableDeclaration':
      case 'InOutParameter':
      case 'OutParameter':
      case 'InParameter':
      case 'ConstantDeclaration':
      case 'VariableDeclaration':
      case 'AddImport':
      case 'AddThrow':
        this.setState({ editItem: {}, contextItem },
            this.handleAddNewFile(contextItem.text, contextItem.action))
          break
      case 'addNewValidity':
          this.handleAddNewFile('Add Validity', 'postNewValidity')
          break
      case 'addNewSignature':
        this.setState({ editItem: {}, contextItem },
          this.handleAddNewFile('Add Signature', 'postNewSignature'))
          break
      case 'addNewProcedure':
          this.handleAddNewFile('Add Procedure', 'postNewProcedure')
          break
      case 'edit':
        if (outlineItem.type && outlineItem.type !== 'node' &&
          (isFlow(outlineItem.type) || isNode(outlineItem.type) || isDiagramEnded(outlineItem.type))) {
              const tempItem = Object.assign({}, outlineItem)
              tempItem.children = []
              tempItem.isEdit = true
              this.props.openEditModal(tempItem)
        } else if (isGlobalDeclaration(outlineItem)) {
          this.setState({ editItem: outlineItem, contextItem },
            this.handleAddNewFile(contextItem.text, outlineItem.type))
        } else if (isParameter(outlineItem)) {
          this.setState({ editItem: outlineItem, contextItem },
            this.handleAddNewFile(contextItem.text, outlineItem.type))
        } else if (isLocalDeclaration(outlineItem)) {
          this.setState({ editItem: outlineItem, contextItem },
            this.handleAddNewFile(contextItem.text, outlineItem.type))
        } else if (outlineItem.type === 'Validity') {
          this.setState({ editItem: outlineItem, contextItem },
          this.handleAddNewFile(contextItem.text, outlineItem.type))
        } else if (outlineItem.type === 'Signature') {
          this.setState({ editItem: outlineItem, contextItem },
            this.handleAddNewFile(contextItem.text, outlineItem.type))
        } else if (outlineItem.type === 'Procedure') {
          this.setState({ editItem: outlineItem, contextItem },
            this.handleAddNewFile(contextItem.text, outlineItem.type))
        } else if (outlineItem.type === 'Import') {
          this.setState({ editItem: outlineItem, contextItem },
            this.handleAddNewFile(contextItem.text, outlineItem.type))
        } else if (throwTypes.includes(outlineItem.name)) {
          this.setState({ editItem: outlineItem, contextItem },
            this.handleAddNewFile(contextItem.text, 'Throw'))
        } else if (outlineItem.itemType === 'FILE' && outlineItem.name.includes('.otx')) {
          this.props.loaderControl(true)
          this.setState({ contextItem },
            this.handleAddNewFile(contextItem.text, 'postOtxFile'),
            this.props.getOtx(params.projectId, outlineItem.id)
              .then(otxData => {
                this.setState({ editItem: { ...otxData, id: outlineItem.id } })
              })
          )
        }
        break
      case 'remove':
          if (outlineItem.type && outlineItem.type !== 'node' &&
              (isFlow(outlineItem.type) || isNode(outlineItem.type) || isDiagramEnded(outlineItem.type))) {
                const tempItem = Object.assign({}, outlineItem)
                this.props.openRemoveModal(tempItem)
          } else if (isGlobalDeclaration(outlineItem)) {
              const tempItem = Object.assign({}, outlineItem)
              this.props.openRemoveModal(tempItem)
          } else if (isParameter(outlineItem)) {
            const tempItem = Object.assign({}, outlineItem)
            this.props.openRemoveModal(tempItem)
          } else if (isLocalDeclaration(outlineItem)) {
              const tempItem = Object.assign({}, outlineItem)
              this.props.openRemoveModal(tempItem)
          } else if (outlineItem.type === 'Validity') {
            const tempItem = Object.assign({}, outlineItem)
            this.props.openRemoveModal(tempItem)
          } else if (outlineItem.type === 'Signature') {
            const tempItem = Object.assign({}, outlineItem)
            this.props.openRemoveModal(tempItem)
          } else if (outlineItem.type === 'Procedure') {
            const tempItem = Object.assign({}, outlineItem)
            this.props.openRemoveModal(tempItem)
          } else if ((outlineItem.itemType === 'FILE' && outlineItem.name.includes('.otx')) ||
                      outlineItem.itemType === 'PACKAGE') {
            const tempItem = Object.assign({}, outlineItem)
            this.props.openRemoveModal(tempItem)
          } else if (outlineItem.type === 'Import') {
            const tempItem = Object.assign({}, outlineItem)
            this.props.openRemoveModal(tempItem)
          } else if (throwTypes.includes(outlineItem.name)) {
            const tempItem = Object.assign({}, outlineItem)
            this.props.openRemoveModal(tempItem)
          }
          break
      case 'download':
        if (outlineItem) {
          const id = outlineItem.name.includes('.otx') || outlineItem.isSsmTree
          ? `${params.projectId}/items/${outlineItem.id}` : outlineItem.id
          const name = outlineItem.name.includes('.otx')
          ? outlineItem.name
          : outlineItem.isSsmTree ? `${outlineItem.name}.ssm` : `${outlineItem.name}.zip`
          const downloadUrl = `projects/${id}/export`
          this.props.downloadFile(downloadUrl, name)
        }
        break
      case 'generate':
        if (outlineItem && contextItem) {
          let url = `api/projects/${params.projectId}/reports/${outlineItem.id}`
          const withSteps = contextItem.text.includes('with steps')
          let fileType = ''
          if (contextItem.text.includes('docx')) {
            fileType = 'docx'
            url += `/${fileType}?withSteps=${withSteps}`
          } else if (contextItem.text.includes('xml')) {
            fileType = 'xml'
            url += `/${fileType}?withSteps=${withSteps}`
          } else if (contextItem.text.includes('pdf')) {
            fileType = 'pdf'
            url += `/${fileType}?withSteps=${withSteps}`
          } else {
            fileType = 'txt'
            url += '/log'
          }
          const status = outlineItem.result === 'PASS' ? labels.reportStatuses.pass : labels.reportStatuses.fail
          const raportName = `${outlineItem.name.replace('.otx', '')}_${status}.${fileType}`
          this.props.generateRaport(url, raportName)
        }
        break
    }
  }

  renderTree = position => {
    const { activeTopTabIndex } = this.state
    const { params, outlineData, isTesterRole, projectType, roles } = this.props
    const dropDownAttrName = isTesterRole ? 'testerItems' : 'architectItems'
    if (position === 'top') {
      return <div className='wrapperTree__top'>
        <div className='wrapperTree__dropdown'>
          <Dropdown
            id={testIds.wrapperTreeComponent.projectExplorerDropDown}
            text={labels.wrapperTreeComponentDropdownMenuOne[0].name}>
            <Dropdown.Menu className='wrapperTree__dropdown-menu'>
              {labels.wrapperTreeComponentDropdownMenuOne[1][dropDownAttrName].map(el =>
              ((el.id !== 109 && el.id !== 110) ||
              (el.id === 109 && projectType === 'EGSCC_MCM') ||
              (el.id === 110 && roles.includes('ROLE_ARCHITECT'))) &&
                <Dropdown.Item
                  key={el.id}
                  text={el.name}
                  id={el.id}
                  icon={el.icon}
                  onClick={() => this.handleDropdownItemClick(el, false)} />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='wrapperTree__panes'>
          <Tab
            activeIndex={activeTopTabIndex}
            onTabChange={this.changeActiveTab}
            menu={{ secondary: true, pointing: true }}
            panes={this.renderPanes()} />
        </div>
      </div>
    }
    return <div className='wrapperTree__bottom'>
      <div className='wrapperTree__dropdown'>
        {!isEmpty(outlineData)
          ? <Dropdown
              id={testIds.wrapperTreeComponent.outlineDropDown}
              text={labels.wrapperTreeComponentDropdownMenuTwo[0].name}>
            <Dropdown.Menu className='wrapperTree__dropdown-menu'>
              {labels.wrapperTreeComponentDropdownMenuTwo[1][dropDownAttrName].map(el => {
                return <Dropdown.Item
                  onClick={() => this.handleDropdownItemClick(el, true)}
                  key={el.id}
                  text={el.name}
                  icon={el.icon} />
              })}
            </Dropdown.Menu>
          </Dropdown>
            : <span>{labels.wrapperTreeComponentDropdownMenuTwo[0].name}</span>}
      </div>
      <div className='wrapperTree__container'>
        <VirtualizedTreeComponent
          params={params}
          isTesterRole={isTesterRole}
          menuContextActionHandler={this.menuContextActionHandler}
          position={'bottom'}
          treeData={outlineData} />
      </div>
    </div>
  }

  renderPanes = () => {
    const { params, isTesterRole } = this.props
    const { selectedTopTreeIds, activeTopTabIndex } = this.state
    const panes = topTreePanes.map(item => ({
      menuItem: item.menuItemName,
      render: () => <Tab.Pane attached={false} className='wrapperTree__container'>
        <VirtualizedTreeComponent
          params={params}
          position={'top'}
          isTesterRole={isTesterRole}
          setSelectedIdTopTree={this.setSelectedIdTopTree}
          selectedTabId={selectedTopTreeIds[activeTopTabIndex]}
          activeTopTabIndex={activeTopTabIndex}
          treeData={this.props[this.setCorrectDataName()]}
          menuContextActionHandler={this.menuContextActionHandler}
          getPackageName={name => this.setState({ package: name })} />
      </Tab.Pane>
      }))
    return panes
  }

  setCorrectDataName = () => {
    const { activeTopTabIndex } = this.state
    switch (activeTopTabIndex) {
      case 0:
        return 'projectTabData'
      case 1:
        return 'devicesDataTab'
      case 2:
        return 'executionsTabData'
    }
  }

  setSelectedIdTopTree = id => {
    const { activeTopTabIndex } = this.state
    if (activeTopTabIndex !== 1) {
      const selectedTopTreeIds = [...this.state.selectedTopTreeIds]
      selectedTopTreeIds[activeTopTabIndex] = id
      this.setState({ selectedTopTreeIds })
    }
  }

  changeActiveTab = (event, { activeIndex }) =>
  this.setState({ activeTopTabIndex: activeIndex }, this.props.setActiveTopTabIndex(activeIndex))

  render () {
    const {
      isModalComponentOpen,
      fileType,
      modalType,
      modalTitle,
      modalText,
      modalMode
    } = this.state
    const { importOtxFile, importOtxFileFromEgscc } = this.props
    return (
      <div className='max-size wrapperTree'>
        { isModalComponentOpen && (fileType === 'otx' || fileType === 'ssm' || fileType === 'exportActivity') &&
        <ModalComponent
          isOpen={isModalComponentOpen}
          fileType={fileType}
          addNewFile={importOtxFile}
          importOtxFileFromEgscc={importOtxFileFromEgscc}
          closeModal={this.closeModal}
          modalType={modalType}
          modalTitle={modalTitle}
          params={this.props.params}
        />}
        { isModalComponentOpen && (fileType === 'postOtxFile') && <OtxDocumentModal
          closeModal={this.closeModal}
          isModalOpened={isModalComponentOpen}
          dataHandler={this.dataHandler}
          option={isEmpty(this.state.editItem) ? 'add' : 'edit'}
          package={this.state.package}
          editItem={this.state.editItem}
        />}
        { isModalComponentOpen && (modalType === 'switchProject' || modalType === 'shareProject') &&
        <ModalComponent
          closeModal={this.closeModal}
          isOpen={isModalComponentOpen}
          modalType={modalType}
          modalTitle={modalTitle}
          modalText={modalText}
          params={this.props.params}
        />}
        { ((isModalComponentOpen && (this.checkFileType(fileType))) || this.props.isOpenAddVar) &&
          <AddNewFileModal
            editItem={this.state.editItem}
            fileType={this.props.isOpenAddVar ? 'VariableDeclaration' : fileType}
            closeModal={this.closeModal}
            isModalOpened={isModalComponentOpen || this.props.isOpenAddVar}
            saveModal={this.saveModal}
            modalMode={modalMode}
            params={this.props.params}
          />}
        {this.renderTree('top')}
        {this.renderTree('bottom')}
      </div>
    )
  }
}
export default WrapperTreeComponent
