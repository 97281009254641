import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './OtxDocumentModal.scss'
import { Form, Button, Icon, Modal, Popup } from 'semantic-ui-react'
import labels from '../../../../public/labels.json'
import testIds from '../../../../public/testIds.json'
import isEqual from 'lodash/isEqual'

const packageRegex = RegExp('^_*[a-zA-Z][a-zA-Z0-9]*(\\.[a-zA-Z][a-zA-Z0-9]*)*$')

class OtxDocumentModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      specification: '',
      version: '',
      isMainProcedureEnabled: true,
      package: props.package || '',
      nameError: false,
      packageError: false,
      versionError: false
    }
  }

  static propTypes = {
    // props from parent
    closeModal: PropTypes.func,
    isModalOpened: PropTypes.bool,
    dataHandler: PropTypes.func,
    option: PropTypes.string,
    package: PropTypes.string,
    editItem: PropTypes.object
  }

  componentDidUpdate (prevProps) {
    const { editItem } = this.props
    if (!isEqual(editItem, prevProps.editItem)) {
      this.setState({
        name: editItem.name ? editItem.name.replace('.otx', '') : '',
        specification: editItem.specification || '',
        version: editItem.version || '',
        package: editItem.package || this.props.package || ''
       })
    }
  }

  saveData = () => {
    if (this.isFormValid()) {
      const { option, editItem } = this.props
      if (option === 'add') {
        this.props.dataHandler(option, {
          otx_name: this.state.name,
          otx_specification: this.state.specification,
          otx_version: this.state.version,
          otx_package: this.state.package,
          isMainProcedureEnabled: this.state.isMainProcedureEnabled
        })
      } else {
        this.props.dataHandler(
          option,
          {
            name: this.state.name,
            specification: this.state.specification,
            package: this.state.package,
            version: this.state.version,
            type: 'OTX'
          },
          editItem.id
        )
      }
    }
  }

  isFormValid = () =>
    !this.validateBlur('name', 'formValidation') &&
    !this.validateBlur('version', 'formValidation') &&
    !this.validateBlur('package', 'formValidation')

  changeHandler (stateName, value) {
    const change = { ...this.state }
    change[stateName] = value
    this.setState(stateName === 'isMainProcedureEnabled'
    ? { isMainProcedureEnabled: !this.state.isMainProcedureEnabled }
    : change, () => this.validateBlur(stateName))
  }

  validateBlur = (stateName, validationName) => {
    const error = stateName === 'package'
    ? !packageRegex.test(this.state[stateName])
    : this.state[stateName] === '' || this.state[stateName] === null
    this.setState({ [`${stateName}Error`]: error })

    if (validationName === 'formValidation') return error
  }

  render () {
    const { otxDocumentModal, buttons, hints } = labels
    const { inputs, checkboxes } = labels.modalPattern
    const isEdit = this.props.option === 'edit'
    return (
      <Modal
        open={this.props.isModalOpened}
        onClose={this.props.closeModal}
        className='wizard__container'
        centered>
        <div className='wizard__header'>
          <div className='wizard__header-content'>
            <div className='wizard__header-logo'>
              <Icon name='upload' />
            </div>
            <div className='wizard__header-text'>
              <h2>{otxDocumentModal.otxFile}</h2>
              <p>{otxDocumentModal.defineOtxFile}</p>
            </div>
          </div>
        </div>
        <div className='wizard__form-container'>
          <Form className='wizard__form'>
            <Form.Group>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Input
                  id={testIds.otxDocumentModal.nameInput}
                  error={this.state.nameError}
                  label={inputs.name.label}
                  placeholder={inputs.name.placeholder}
                  type='text'
                  value={this.state.name}
                  onChange={(event, { value }) => this.changeHandler('name', value)}
                  onBlur={() => this.validateBlur('name')}
                  className='wizard__form-input'>
                </Form.Input>
              </div>
            </Form.Group>
            <Form.Group>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.TextArea
                  id={testIds.otxDocumentModal.specificationTextArea}
                  label={inputs.specification.label}
                  value={this.state.specification}
                  onChange={(event, { value }) => this.changeHandler('specification', value)}
                  placeholder={inputs.specification.placeholder}
                  className='wizard__form-input'/>
              </div>
            </Form.Group>
            <Form.Group>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Input
                  id={testIds.otxDocumentModal.versionInput}
                  error={this.state.versionError}
                  onBlur={() => this.validateBlur('version')}
                  label={inputs.version.label}
                  value={this.state.version}
                  onChange={(event, { value }) => this.changeHandler('version', value)}
                  placeholder={inputs.version.placeholder}
                  className='wizard__form-input'/>
              </div>
            </Form.Group>
            <Form.Group>
              <div className='wizard__form-info'>
                <Popup
                  position='top center'
                  content={hints.projectExplorer.package}
                  trigger={<Icon name='info' className='info' />} />
                <Form.Input
                  error={this.state.packageError}
                  onBlur={() => this.validateBlur('package')}
                  label={inputs.package.label}
                  id={testIds.otxDocumentModal.packageInput}
                  value={this.state.package}
                  onChange={(event, { value }) => this.changeHandler('package', value)}
                  placeholder={inputs.package.placeholder}
                  className='wizard__form-input'/>
              </div>
            </Form.Group>
            <Form.Group>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Checkbox
                  disabled={isEdit}
                  id={testIds.otxDocumentModal.checkboxAddEmpty}
                  label={checkboxes.addEmptyMainProcedure}
                  checked={this.state.isMainProcedureEnabled}
                  onChange={(event, { value }) => this.changeHandler('isMainProcedureEnabled', value)}
                  className='wizard__form-input'/>
              </div>
            </Form.Group>
          </Form>
        </div>
        <div className='wizard__footer'>
          <div>
            <Button
              id={testIds.otxDocumentModal.cancelButton}
              onClick={this.props.closeModal}
              content={buttons.cancel} />
          </div>
          <div>
            <Button
              id={testIds.otxDocumentModal.saveButton}
              onClick={this.saveData}
              content={buttons.save}
              positive />
          </div>
        </div>
      </Modal>
    )
  }
}

export default OtxDocumentModal
