
import React, { useState } from 'react'
import { Image } from 'react-konva'
import { string, func } from 'prop-types'
import useImage from 'use-image'

export const ButtonImage = ({ url, handleClick, id, ...props }) => {
  const [isButtonHover, setIsButtonHover] = useState(false)

  const [image] = useImage(url)
  return (
    <Image
      width={isButtonHover ? 16 : 15}
      height={isButtonHover ? 16 : 15}
      onMouseEnter={() => setIsButtonHover(true)}
      onMouseLeave={() => setIsButtonHover(false)}
      opacity={isButtonHover ? 0.5 : 1}
      image={image}
      {...props}
      onClick={e => {
      e.cancelBubble = true
      handleClick(id)
    }}/>)
}

ButtonImage.propTypes = {
  url: string,
  id: string,
  handleClick: func
}
