export const isCircleShape = type => circleTypes.includes(type)

export const circleTypes = [
  'START',
  'END'
]

export const nodeSize = {
  width: 150,
  height: 80,
  offset: 10
}

export const icons = {
  // eslint-disable-next-line max-len
  edit: `data:image/svg+xml;base64, ${window.btoa('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path stroke="white" stroke-width="2" d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon stroke="white" stroke-width="2" points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>')}`,
  // eslint-disable-next-line max-len
  delete: `data:image/svg+xml;base64, ${window.btoa('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" ><circle fill="white" cx="36" cy="36" r="36"/><path fill="red" d="M36.22,0C16.212,0,0,16.216,0,36.227c0,19.999,16.212,36.214,36.22,36.214c20.011,0,36.214-16.215,36.214-36.214C72.434,16.215,56.231,0,36.22,0z M52.058,46.82c1.379,1.424,0.953,4.078-0.959,5.932c-1.911,1.854-4.577,2.209-5.959,0.785l-9.027-9.295l-9.298,9.027c-1.421,1.379-4.075,0.947-5.929-0.961s-2.206-4.574-0.785-5.956l9.298-9.027l-9.027-9.298c-1.379-1.421-0.946-4.078,0.962-5.932c1.905-1.851,4.577-2.204,5.953-0.785l9.027,9.297l9.301-9.023c1.424-1.382,4.078-0.95,5.929,0.958c1.857,1.908,2.206,4.577,0.785,5.959l-9.295,9.024L52.058,46.82z"/></svg>')}`
}
