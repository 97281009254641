import isEmpty from 'lodash/isEmpty'

export const createMapModifier = (type, result, keys) => {
    result.validity = keys.validFor
    result.map = {
        type: 'MapVariable',
        children: [],
        name: keys.modifiedMap
    }
    if (type === 'MapUnion') {
        const otherMap = []
        !isEmpty(keys.editorTermArray) &&
        keys.editorTermArray.forEach(el => otherMap.push(el.term))
        result.otherMap = otherMap
    } else if (type === 'MapRemove') {
        const keysArr = []
        !isEmpty(keys.editorTermArray) &&
        keys.editorTermArray.forEach(el => keysArr.push(el.term))
        result.keys = keysArr
    } else if (type === 'MapPut') {
        const items = []
        !isEmpty(keys.editorTermArray) &&
        keys.editorTermArray.forEach(el => items.push({
          type: 'MapItem',
          children: [],
          key: el.key.term,
          value: el.value.term
        }))
        result.item = items
    }
    return result
}
