import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as labels from '../../../../../public/labels.json'
import { fontColorSetter } from '../../../../constants/common'

class ProcedureRunConsoleComponent extends Component {
  constructor () {
    super()
    this.consoleRef = React.createRef()
  }

  static propTypes = {
    isProcedureRunning: PropTypes.bool,
    ssmId: PropTypes.number,
    driver: PropTypes.string,
    logItems: PropTypes.array,
    params: PropTypes.object
  }

  componentDidMount () {
    this.props.isProcedureRunning && this.scrollToBottom()
  }

  componentDidUpdate () {
    this.props.isProcedureRunning && this.scrollToBottom()
  }

  componentWillUnmount () {
    this.scrollToTop()
  }

  scrollToBottom = () => {
      this.consoleRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  scrollToTop = () => {
    this.consoleRef.current.scrollIntoView(true)
  }

  render () {
    const { params } = this.props
    return (
      <div ref={this.consoleRef}>
        {typeof params.projectId !== 'undefined' &&
        typeof params.otxFileId !== 'undefined' &&
        typeof params.procedureId !== 'undefined' &&
        this.props.driver !== '' &&
        this.props.ssmId !== -1
        ? this.props.logItems.length ? this.props.logItems.map((message, index) => {
            return <p
              key={`${index}`}
              style={{ marginBottom: 3 }}
              className={fontColorSetter(message)}>
              {message}
            </p>
          }) : this.props.isProcedureRunning ? <div>Procedure is running...</div> : <div>Run procedure</div>
        : <div>
          {typeof params.projectId === 'undefined' && <div>{labels.error_list.not_defined.project_id}</div>}
          {typeof params.otxFileId === 'undefined' && <div>{labels.error_list.not_defined.otx_file_id}</div>}
          {typeof params.procedureId === 'undefined' && <div>{labels.error_list.not_defined.procedure_id}</div>}
          {this.props.ssmId === -1 && <div>{labels.error_list.not_defined.ssm_id}</div>}
          {this.props.driver === '' && <div>{labels.error_list.not_defined.driver}</div>}
          {labels.error_list.run_procedure}
        </div>}
      </div>
    )
  }
}

export default ProcedureRunConsoleComponent
