import React, { useState, useEffect } from 'react'
import { string, array, func, bool } from 'prop-types'
import { Dropdown } from 'semantic-ui-react'
import { List } from 'react-virtualized'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import labels from '../../../public/labels.json'
import './VirtualizedDropdownComponent.scss'

const { placeholder, noResultsFound } = labels.modalPattern.inputs.virtualizedDropdown

const VirtualizedDropdownComponent = ({
  value,
  propsOptions,
  handleChange,
  label,
  propsPlaceholder,
  error = false,
  id = 'virtualizedDropdownComponent_dropDown'
}) => {
  const [options, setOptions] = useState([])
  const [isOpen, openDropdown] = useState(false)
  const [option, setOption] = useState({})
  const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
      if (!isEmpty(propsOptions) && isEmpty(options)) {
        if (!isEqual(propsOptions, options)) {
          setOptions(propsOptions)
        }
        if (value !== '' && isEmpty(option)) {
          const searchingOption = propsOptions.find(el => el.value === value)
          searchingOption ? setOption(searchingOption) : handleChange(option)
        }
      }
    }, [propsOptions, value])

    useEffect(() => {
      if (searchQuery !== '') {
        const fillteredOptions = propsOptions.filter(option =>
          option.text.toUpperCase().includes(searchQuery.toUpperCase()))
        if (!isEqual(fillteredOptions, options)) {
          setOptions(fillteredOptions)
        }
      } else if (!isEqual(options, propsOptions)) {
        setOptions(propsOptions)
      }
    }, [searchQuery])

    const onClickHandler = (e, option) => {
      e.stopPropagation()
      setOption(option)
      if (searchQuery !== '') {
        setSearchQuery('')
      }
      handleChange(option)
      openDropdown(false)
    }

    const onBlurHandler = () => {
      if (searchQuery !== '') {
        setSearchQuery('')
      }
      openDropdown(false)
    }

  return (
    <div className={`field virtualized-dropdown${error ? ' error' : ''}`}>
      {label && <label>{label}</label>}
      <Dropdown
        id={id}
        placeholder={propsPlaceholder || placeholder}
        fluid
        search
        onSearchChange={(e, { searchQuery }) => setSearchQuery(searchQuery)}
        value={option.value || ''}
        text={option.text || option.value || ''}
        searchQuery={searchQuery}
        open={isOpen}
        onClick={() => openDropdown(true)}
        onBlur={() => onBlurHandler()}
        error={error}
        selection>
        <Dropdown.Menu>
          {!isEmpty(options)
          ? <List
            style={{ outline: 'none' }}
            width={255}
            height={options.length >= 6 ? 222 : options.length * 37}
            rowCount={options.length}
            rowHeight={37}
            rowRenderer={({ key, index, style }) => {
            const item = options[index]
            return (
              <Dropdown.Item
                className={`virtualized-dropdown__item${isEqual(option, item) ? ' active selected' : ''}`}
                style={style}
                key={key}
                onClick={(e) => onClickHandler(e, item)}
                value={item.value}
                text={item.text || item.value} />)
              }} />
          : <div className='message'>{noResultsFound}</div>}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

VirtualizedDropdownComponent.propTypes = {
  value: string,
  propsOptions: array,
  handleChange: func,
  label: string,
  propsPlaceholder: string,
  error: bool,
  id: string
}

export default VirtualizedDropdownComponent
