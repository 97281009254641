import { connect } from 'react-redux'
import DiagramComponent from './DiagramComponent.js'
import {
    updateDiagramList,
    createDiagramElement,
    updateDiagramElement,
    openRemoveModal,
    openEditModal,
    closeEditModal
} from '../../store/thunk/reducerList/diagramElement'
import { setBreakPoint, removeBreakPoint, updateScroll } from '../../store/thunk/reducerList/procedure'
import { loaderControl } from '../../store/thunk/reducerList/loaderControl'

const mapDispatchToProps = {
    updateDiagramList,
    createDiagramElement,
    updateDiagramElement,
    openRemoveModal,
    openEditModal,
    closeEditModal,
    loaderControl,
    setBreakPoint,
    removeBreakPoint,
    updateScroll
}

const mapStateToProps = state => ({
    diagramList: state.diagramElement.diagramList,
    headerData: state.diagramElement.headerData,
    modalElement: state.diagramElement.modalElement,
    isOpenEditModal: state.diagramElement.isOpenEditModal,
    isActiveLoader: state.loaderControl.isActiveLoader,
    isProjectContainOtx: state.project.isProjectContainOtx
})

export default connect(mapStateToProps, mapDispatchToProps)(DiagramComponent)
