import React from 'react'
import PropTypes from 'prop-types'

const DiagramElementDescriptionComponent = ({ colors, description }) => {
  return (
    <div
      className='add-padding'
      style={{
        width: '100%',
        backgroundColor: colors.textOnDark,
        color: colors.textOnLight
      }}>
      {description}
    </div>
  )
}

DiagramElementDescriptionComponent.propTypes = {
  colors: PropTypes.object,
  description: PropTypes.string
}

export default DiagramElementDescriptionComponent
