import React from 'react'
import { Button, Icon, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import './DiagramToolBar.scss'
import { hints } from '../../../public/labels.json'

const style = {
  buttonStyle: {
    width: 28,
    height: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
    borderRadius: 3
  }
}

export const DiagramToolBar = props => {
  const validityIcon =
  props.item &&
  ((props.item.validity && props.item.validity.trim() !== '') ||
   (props.item.validFor && props.item.validFor.trim() !== ''))
  ? 'check square outline' : 'square outline'
  let itemType = ''
  if (props.item && props.item.type) {
    itemType = props.item.type
  }
  return (
    <div className='flex-container'>
      {props.isHeader
        ? <React.Fragment>
          <Button
            onClick={props.onToggleCollapse}
            icon
            style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark })}>
            <Icon
              name={`chevron ${props.isCollapsedHeader ? 'up' : 'down'}`}
              style={{ color: props.colors.headerColor }} />
          </Button>
          <Button icon style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark })}>
            <Icon name='world' style={{ color: props.colors.headerColor }} />
          </Button>
          <Button icon style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark })}>
            <Icon name='uncheck square outline' style={{ color: props.colors.headerColor }} />
          </Button>
          <Button icon style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark })}>
            <Icon name='check square outline' style={{ color: props.colors.headerColor }} />
          </Button>
          <Button icon style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark })}>
            <Icon name='arrow up' style={{ color: props.colors.headerColor }} />
          </Button>
        </React.Fragment>
      : <React.Fragment>
        {props.isEdit && <Button
          disabled={props.isTesterRole}
          onClick={e => props.toolBarHandler('edit', e)}
          icon
          style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark })}>
          <Popup
            content={hints.diagramToolbar.edit}
            position='top center'
            trigger={<Icon name='edit' style={{ color: props.colors.headerColor }} />} />
        </Button>}
        {props.isBreakPoint &&
        <Button
          disabled={props.isTesterRole}
          onClick={e => props.toolBarHandler('isBreakPoint', e)}
          icon
          style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark })}>
          <Popup
            content={hints.diagramToolbar.breakpoint}
            position='top center'
            trigger={<span
              className={props.item.isBreakpointSet ? 'icon-breakpoint-on' : 'icon-breakpoint-off'}
              style={{ color: props.colors.headerColor }} />} />
        </Button>}
        {(props.item && typeof props.item.disabled !== 'undefined') && <Button
              disabled={props.isTesterRole}
              onClick={e => {
                e.stopPropagation()
                props.loaderControl(true)
                props.setElementActiveState(
                  props.projectId,
                  props.otxFileId,
                  props.procedureId,
                  props.item.otx_id,
                  Object.assign(props.item, { disabled: !props.item.disabled }))
                  .then(() => props.reColor())
                }
              }
        icon
        style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark })}>
          <Popup
            content={hints.diagramToolbar.switch}
            position='top center'
            trigger={<Icon
              name={props.item.disabled ? 'toggle on' : 'toggle off' }
              style={{ color: props.colors.headerColor }} />} />
        </Button>}
        {props.isValidFor &&
        <Button
          disabled={props.isTesterRole}
          icon
          style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark })}>
          <Icon name={validityIcon} style={{ color: props.colors.headerColor }} />
        </Button>}
        {props.isLoop &&
        <Button
          id={`search_${props.item.type}-${props.item.position}`}
          onClick={e => props.toolBarHandler('search', e)}
          icon
          style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark })}>
          <Popup
            content={hints.diagramToolbar.search}
            position='top center'
            trigger={<Icon name='search' style={{ color: props.colors.headerColor }} />}
          />
        </Button>}
        {(props.isDelete || itemType === 'If' || itemType === 'Try') &&
        <Button
          onClick={e => props.toolBarHandler('remove', e)}
          disabled={!props.isDelete || props.isTesterRole}
          icon
          style={Object.assign({}, style.buttonStyle, { backgroundColor: props.colors.textOnDark }) }>
          <Popup
            content={hints.diagramToolbar.delete}
            position='top center'
            trigger={<Icon
              name='trash alternate'
              style={{ color: props.colors.headerColor }}
              id='delete' />} />
        </Button>}
      </React.Fragment>}
    </div>
  )
}

DiagramToolBar.propTypes = {
  colors: PropTypes.object,
  isBreakPoint: PropTypes.bool,
  isValidFor: PropTypes.bool,
  isEdit: PropTypes.bool,
  isLoop: PropTypes.bool,
  isHeader: PropTypes.bool,
  toolBarHandler: PropTypes.func,
  isDelete: PropTypes.bool,
  activeConsoleIndex: PropTypes.number,
  setActiveConsoleIndex: PropTypes.func,
  item: PropTypes.object,
  reColor: PropTypes.func,
  projectId: PropTypes.number,
  otxFileId: PropTypes.number,
  procedureId: PropTypes.string,
  onToggleCollapse: PropTypes.func,
  isCollapsedHeader: PropTypes.bool,
  setElementActiveState: PropTypes.func,
  loaderControl: PropTypes.func,
  isTesterRole: PropTypes.bool
}

export default DiagramToolBar
