import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './BodyDiagramComponent.scss'
import { DragSource, DropTarget } from 'react-dnd'
import { dndTypes } from '../../../../../../constants/ItemTypes'
import flow from 'lodash/flow'
import DiagramToolBar from '../../../../../DiagramToolBar'
import {
  colorsAndIconsSetter,
  diagramElementModifer,
  couldDrop,
  isActionRealisation,
  isContainItself,
  isSupportedElement,
  isPosibbleToRemove,
  inheritsFromFlow,
  isBreakPoint
} from '../../../../../../constants/common'
import DiagramElementDescriptionComponent from
'../../../DiagramElementDescriptionComponent/DiagramElementDescriptionComponent'
import { Icon, Popup } from 'semantic-ui-react'

const diagramElementTarget = {
    drop (props, monitor) {
      if (monitor.isOver({ shallow: true })) {
        const tempItem = monitor.getItem().item
        const placeToAdd = props.item
        const tempKind = 'diagram_list'
        if (tempItem.kind === tempKind) {
          tempItem.id !== placeToAdd.id && props.addToEmptyElement(tempItem, placeToAdd)
        } else {
         tempItem.kind = tempKind
         isSupportedElement(tempItem.type) &&
         props.openModal(diagramElementModifer(tempItem, placeToAdd, props), placeToAdd, null)
        }
      }
    },
    canDrop (props, monitor) {
      if (monitor.isOver({ shallow: true })) {
        const item = monitor.getItem().item
        const parent = props.item
        if (parent.id !== item.id &&
            props.hasChild === null &&
            isContainItself(item.children, props.item)) {
              if (isPosibbleToRemove(item)) {
                  return couldDrop(item, parent, props)
              }
              return false
        }
        return false
      }
    }
  }

const diagramElementSource = {
    beginDrag (props) {
      return {
        item: props.item
      }
    }
}

class BodyDiagramComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      colorsAndIcons: {}
    }
    this.summaryContainer = React.createRef()
  }

  static propTypes = {
      item: PropTypes.object,
      connectDropTarget: PropTypes.func,
      connectDragSource: PropTypes.func,
      canDrop: PropTypes.bool,
      isDragging: PropTypes.bool,
      isOver: PropTypes.bool,
      hasChild: PropTypes.number,
      children: PropTypes.any,
      addElement: PropTypes.func,
      selectItemHandler: PropTypes.func,
      toolBarHandler: PropTypes.func,
      proceduresWithParametersList: PropTypes.array,
      params: PropTypes.object,
      reachedBreakPoint: PropTypes.string,
      isTesterRole: PropTypes.bool,
      blockWidth: PropTypes.number,
      onContextMenuHandler: PropTypes.func,
      elementsToBeCopied: PropTypes.array,
      cutElements: PropTypes.array
  }

  componentDidMount () {
    const { item } = this.props
    this.setState({ colorsAndIcons:  colorsAndIconsSetter(item) })
  }

  handleValidFor = itemType => {
    const actionRealization = isActionRealisation(itemType)
    return actionRealization || itemType === 'GroupElement'
  }

  handleEdit = itemType => {
    return (itemType !== 'Else' && itemType !== 'Try' && itemType !== 'Finally' &&
    itemType !== 'Lane')
  }

  shouldShowPopupHandler = (index, blockWidth) => {
    const { current } = this.summaryContainer
    let isDisabledPopup = true
    if (current && current.childNodes && current.childNodes[index] && current.childNodes[index].childNodes) {
      isDisabledPopup =
      blockWidth > current.childNodes[index].childNodes[0].scrollWidth
    }
    return isDisabledPopup
  }

  renderNodeHandler = () => {
    const {
      connectDropTarget,
      isDragging,
      item,
      children,
      isOver,
      canDrop,
      toolBarHandler,
      reachedBreakPoint,
      onContextMenuHandler,
      selectItemHandler,
      params,
      elementsToBeCopied,
      cutElements
    } = this.props
    const opacity = isDragging || cutElements.includes(item.otx_id) ? 0.4 : 1
    const isSelectedElement = item.otx_id === params.selectedElementId || elementsToBeCopied.includes(item.otx_id)
    const isBreakPointStyle = reachedBreakPoint === item.otx_id && isSelectedElement
    const colorsAndIcons = !isBreakPointStyle
    ? this.state.colorsAndIcons
    : {
      textOnDark: '#FEFAEA',
      textOnLight: '#091032',
      headerColor: '#313131',
      background: '#ffd23f',
      icon: this.state.colorsAndIcons.icon
    }
    const isRealization = isActionRealisation(item.type)
    let blockWidth = this.props.blockWidth
    if (isRealization) {
      blockWidth -= 160
    } else if (item.type === 'MutexGroup') {
      blockWidth -= 225
    } else {
      blockWidth -= 190
    }
    return (
      <div
        key={item.key}
        id={`bodyDiagramComponent${item.deepness}`}
        onClick={e => selectItemHandler(item.otx_id, e, item)}
        className='diagram-container-styles'
        style={{
          boxShadow: isSelectedElement && '0px 0px 16px 5px #313131',
          border: `1px solid ${colorsAndIcons.headerColor}`,
          opacity
        }}>
        <div
          style={{
            backgroundColor: !isBreakPointStyle
            ? colorsAndIcons.headerColor : colorsAndIcons.background
          }}
          className='diagram-header-styles'>
          <div className='flex-container' style={{ width: blockWidth }}>
            <span
              className={`${colorsAndIcons.icon} diagram-icon-circle`}
              style={{ color: !isBreakPointStyle ? colorsAndIcons.textOnDark : colorsAndIcons.textOnLight }}/>
            <div
              className='diagram-text-container'
              style={{
                color: !isBreakPointStyle
                ? colorsAndIcons.textOnDark : colorsAndIcons.textOnLight
              }}>
              {item.type !== 'actionRealization' && <div className='diagram-subtitle flex-container'>
                <span>{item.type}</span>
                {item.dangerous && <span className='icon-warning'/>}
              </div>}
              <div
                ref={this.summaryContainer}
                key={item.key}
                style={{ width: blockWidth }}
                className='diagram-title'>
                {Array.isArray(item.summaryLine) &&
                  item.summaryLine.map((singleLine, index) => {
                    return <div
                      key={index}
                      style={{ lineHeight: 0, width: blockWidth, height: 15 }}>
                      <Popup
                        hideOnScroll
                        style={{ maxWidth: window.innerWidth * 0.7 }}
                        disabled={this.shouldShowPopupHandler(index, blockWidth)}
                        content={singleLine}
                        trigger={<p
                          style={{
                            fontSize: 12,
                            maxWidth: blockWidth,
                            overflow: 'hidden',
                            display: 'inline-block',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            lineHeight: 'normal',
                            height: 15
                          }}>
                          {singleLine}
                        </p>}
                        position='bottom center'/>
                    </div>
                  })
                }
              </div>
            </div>
          </div>
          <DiagramToolBar
            colors={colorsAndIcons}
            reColor={() => this.setState({ colorsAndIcons: colorsAndIconsSetter(item) })}
            isLoop={inheritsFromFlow(item.type)}
            isBreakPoint={isBreakPoint(item.type)}
            isArrow={true}
            isDelete={isPosibbleToRemove(item)}
            isEdit={this.handleEdit(item.type)}
            isValidFor={this.handleValidFor(item.type)}
            toolBarHandler={(buttonType, e) => toolBarHandler(item, buttonType, e)}
            item={item}
            projectId={params.projectId}
            otxFileId={params.otxFileId}
            procedureId={params.procedureId} />
        </div>
        {item.level < 2 && <React.Fragment>
          {(item.otx_specification) &&
            <div style={{ backgroundColor: colorsAndIcons.textOnDark }}>
              <DiagramElementDescriptionComponent colors={colorsAndIcons} description={item.otx_specification} />
            </div>
          }
          {!isRealization && connectDropTarget(
            <div className='diagram-drop-target'
              onContextMenu={e => onContextMenuHandler(e, item, 'copycutpaste', item)}
              key={item.key}
              style={{
                backgroundColor: (isOver && canDrop) ? '#f1ffef' : colorsAndIcons.textOnDark,
                minHeight: 40,
                border: (isOver && canDrop) && `1px dotted ${colorsAndIcons.headerColor}`
              }}>
              {children || ((isOver && canDrop) && <Icon
                style={{ color: '#9396a2', margin: 0 }}
                size='large'
                name='plus' />)}
            </div>)}
        </React.Fragment>}
      </div>
    )
  }

  render () {
    const {
      connectDragSource,
      isTesterRole
    } = this.props
    return isTesterRole
      ? this.renderNodeHandler()
      : connectDragSource(this.renderNodeHandler())
  }
}

export default flow(
  DragSource(dndTypes.DIAGRAM, diagramElementSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })),
  DropTarget(dndTypes.DIAGRAM, diagramElementTarget, (connect, monitor) => ({
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }))
)(BodyDiagramComponent)
