import React from 'react'
import { browserHistory, Router } from 'react-router'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import 'react-contexify/dist/ReactContexify.min.css'
import 'antd/dist/antd.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-tree/lib/main.css'
import 'material-icons/css/material-icons.css'
class App extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    routes: PropTypes.object.isRequired
  }

  shouldComponentUpdate () {
    return false
  }

  render () {
    return (
      <Provider store={this.props.store}>
        <div className='max-size'>
          <Router history={browserHistory}>
            {this.props.routes}
          </Router>
        </div>
      </Provider>
    )
  }
}

export default App
