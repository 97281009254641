import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import {
  Modal,
  Form,
  Icon,
  Button
} from 'semantic-ui-react'
import './ModalComponent.scss'
import labels from '../../../public/labels.json'
import testIds from '../../../public/testIds.json'

// eslint-disable-next-line max-len
const emailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

export default class ModalComponent extends Component {
  state = {
    fileInputValue: {},
    isButtonDisabled: true,
    session: '',
    email: '',
    emailError: false
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    modalTitle: PropTypes.string,
    modalText: PropTypes.string,
    addNewFile: PropTypes.func,
    closeModal: PropTypes.func,
    fileType: PropTypes.string,
    modalType: PropTypes.string,
    deleteElement: PropTypes.func,
    validateOtxFile: PropTypes.func,
    projectType: PropTypes.string,
    importOtxFileFromEgscc: PropTypes.func,
    params: PropTypes.object,
    // props form redux
    clearDiagramData: PropTypes.func,
    getOutlineTreeAction: PropTypes.func,
    setProjectTabDataAction: PropTypes.func,
    resetSsmList: PropTypes.func,
    setReportAttributes: PropTypes.func,
    downloadFile: PropTypes.func,
    setDriver: PropTypes.func,
    setIsProjectContainOtxAction: PropTypes.func,
    shareProject: PropTypes.func
  }

  onFileInput = e => {
    this.setState({
      fileInputValue: e.target.files[0],
      isButtonDisabled: false
    })
  }

  handleAddNewFile = () => {
    if (this.props.projectType === 'EGSCC_MCM' && this.props.fileType === 'ssm') {
      this.props.importOtxFileFromEgscc(this.state.session, this.props.params.projectId)
    } else {
      this.props.addNewFile(
        this.props.params.projectId,
        this.state.fileInputValue,
        this.props.fileType)
    }
  }

  componentWillUnmount () {
    this.setState({
      fileInputValue: {},
      isButtonDisabled: true
    })
  }

  handleChangeInput = (stateName, value) => {
    const change = { ...this.state, isButtonDisabled: false }
    change[stateName] = value
    this.setState(change)
  }

  handleDisabled = () => {
    this.setState({
      isButtonDisabled: true
    })
  }

  handleModalIcon = modalType => {
    if (modalType === 'delete') {
      return 'delete'
    } else if (modalType === 'validate') {
      return 'question circle outline'
    } else if (modalType === 'switchProject') {
      return 'exchange'
    } else if (modalType === 'export-file') {
      return 'download'
    } else {
      return 'folder'
    }
  }

  handleSwitchProject = () => {
    browserHistory.push('/dashboard')
    this.props.clearDiagramData()
    this.props.getOutlineTreeAction([])
    this.props.resetSsmList()
    this.props.setProjectTabDataAction([])
    this.props.setReportAttributes()
    this.props.setDriver()
    this.props.setIsProjectContainOtxAction(false)
    localStorage.removeItem('projectTabDataCollapsedElements')
    localStorage.removeItem('outlineDataCollapsedElements')
    localStorage.removeItem('devicesDataTabCollapsedElements')
    localStorage.removeItem('executionsDataTabCollapsedElements')
  }

  exportFileHandler = () => {
    const { downloadFile, params, modalTitle, closeModal } = this.props
    const { session } = this.state
    const name = `${modalTitle.replace('Export ', '').replace(' ', '_')}.json`
    const url = `egscc/tree/packet-activity-mapping?projectId=${params.projectId}&sessionId=${session}`
    this.exportFileStateHandler(true)
    downloadFile(url, name)
      .then(() => closeModal())
      .catch(() => this.exportFileStateHandler(false))
  }

  exportFileStateHandler = value => this.setState({ isButtonDisabled: value })

  validateEmail = (value, isFormValidation) => {
    const isError = !emailRegex.test(value)
    this.setState({ emailError: isError })

    if (isFormValidation === 'formValidation') return isError
  }

  emailHandler (email) {
    this.setState({ email }, () => this.validateEmail(email))
  }

  handleShareProject = () => {
    const { emailError, email } = this.state
    const { closeModal, shareProject, params } = this.props
    if (!emailError) {
      const body = { email }
      shareProject(params.projectId, body)
      .then(() => closeModal())
    }
  }

  render () {
    const {
      isOpen,
      closeModal,
      modalType,
      modalTitle,
      modalText,
      projectType,
      fileType
    } = this.props
    const {
      isButtonDisabled,
      email,
      emailError
    } = this.state
    const { buttons, modalPattern } = labels
    return (
      <Modal
        open={isOpen}
        className='modalcomponent__container'>
        <div className='modalcomponent__header'>
          <div className='modalcomponent__header-content'>
            <div className='modalcomponent__header-logo'>
              <Icon
                name={this.handleModalIcon(modalType)}
                className='modalcomponent__header-logo-icon' />
            </div>
            <div className='modalcomponent__header-text'>
              <h2>{modalTitle}</h2>
            </div>
          </div>
        </div>
        {(modalType === 'delete' || modalType === 'validate') &&
          <div className='modalcomponent__body-text'>
            {modalText}
          </div>}
        { (modalType === 'add-file' || modalType === 'export-file') && <Form className='modalcomponent__form'>
          { projectType === 'EGSCC_MCM' && (fileType === 'ssm' || fileType === 'exportActivity')
          ? <Form.Group>
            <Form.Select
              id={testIds.modalComponent.sessionDropDown}
              label={modalPattern.inputs.session.label}
              onChange={(event, { value }) => this.handleChangeInput('session', value)}
              value={this.state.session}
              placeholder={modalPattern.inputs.session.placeholder}
              options={modalPattern.inputs.session.options} />
          </Form.Group>
          : <Form.Group className='modalcomponent__form-field'>
            <Form.Input
              type='file'
              id={testIds.modalComponent.fileInput}
              onChange={e => this.onFileInput(e)} />
          </Form.Group>}
        </Form> }
        {modalType === 'switchProject' &&
          <div className='modalcomponent__body-text'>
            {modalText}
          </div>}
        {modalType === 'shareProject' &&
          <Form style={{ margin: 16, width: '40%' }}>
            <Form.Input
              id={testIds.actionWizard.nameInput}
              fluid
              value={email}
              error={emailError}
              onChange={(e, { value }) => this.emailHandler(value)}
              label={modalPattern.inputs.email.label}
              placeholder={modalPattern.inputs.email.placeholder}
              type='text' />
          </Form>}
        <div className='modalcomponent__footer'>
          <div>
            <Button
              id={testIds.modalComponent.cancelNoButton}
              content={modalType !== 'switchProject' ? buttons.cancel
              : buttons.no}
              onClick={closeModal} />
          </div>
          <div>
            { modalType === 'add-file' &&
            <Button
              id={testIds.modalComponent.addButton}
              positive
              disabled={isButtonDisabled}
              content={buttons.add}
              onClick={ () => {
              this.handleDisabled()
              this.handleAddNewFile()
            } }
            />}
            { modalType === 'delete' &&
              <Button
                id={testIds.modalComponent.deleteButton}
                negative
                content={buttons.delete}
                onClick={this.props.deleteElement} />}
            { modalType === 'validate' &&
              <Button
                id={testIds.modalComponent.validateButton}
                onClick={this.props.validateOtxFile}
                positive
                content={buttons.validate} />}
            { modalType === 'switchProject' &&
              <Button
                id={testIds.modalComponent.validateButton}
                onClick={this.handleSwitchProject}
                positive
                content={buttons.yes} />}
            { modalType === 'shareProject' &&
              <Button
                positive
                disabled={emailError || email === ''}
                onClick={this.handleShareProject}
                content={buttons.share} />}
            { modalType === 'export-file' && <Button
              id={testIds.modalComponent.exportButton}
              positive
              disabled={isButtonDisabled}
              content={buttons.export}
              onClick={this.exportFileHandler} />}
          </div>
        </div>
      </Modal>
    )
  }
}
