import React, { useState, useEffect } from 'react'
import { Arrow, Group, Text } from 'react-konva'
import { object, func, number } from 'prop-types'
import { nodeSize } from '../../Utilities/common'
import isEqual from 'lodash/isEqual'

export const ArrowComponent = ({ fromNode, toNode, connection, editArrowHandler, index }) => {
  const textRef = React.useRef(null)

  const [isArrowHover, setIsArrowHover] = useState(false)
  const [textWidth, setTextWidth] = useState(0)

  useEffect(() => {
    if (textRef.current && textRef.current.textWidth && !isEqual(textWidth, textRef.current.textWidth)) {
      setTextWidth(textRef.current.textWidth)
    }
  }, [textRef.current])

  const dx = fromNode.x - toNode.x
  const dy = fromNode.y - toNode.y
  const angle = Math.atan2(-dy, dx)
  const degrees = angle / Math.PI * 180 + 180

  const radius = 15

  const calcCircleStateXFrom = x => x + -radius * Math.cos(angle)

  const calcCircleStateYFrom = y => y + radius * Math.sin(angle)

  const calcCircleStateXTo = x => x + -radius * Math.cos(angle + Math.PI)

  const calcCircleStateYTo = y => y + radius * Math.sin(angle + Math.PI)

  const isRightSideFrom = () => degrees >= 315 || degrees < 45
  const isUpSideFrom = () => degrees >= 45 && degrees < 135
  const isLeftSideFrom = () => degrees >= 135 && degrees < 225

  const calcRecStateXFrom = x => {
    if (isRightSideFrom()) {
      return x + nodeSize.width
    } else if (isUpSideFrom()) {
      return x + nodeSize.width / 2 + nodeSize.offset
    } else if (isLeftSideFrom()) {
      return x
    } else {
      return x + nodeSize.width / 2 - nodeSize.offset
    }
  }
  const calcRecStateYFrom = y => {
    if (isRightSideFrom()) {
      return y + nodeSize.height / 2 + nodeSize.offset
    } else if (isUpSideFrom()) {
      return y
    } else if (isLeftSideFrom()) {
      return y + nodeSize.height / 2 - nodeSize.offset
    } else {
      return y + nodeSize.height
    }
  }
  const calcRecStateXTo = x => {
    if (isRightSideFrom()) {
      return x
    } else if (isUpSideFrom()) {
      return x + nodeSize.width / 2 + nodeSize.offset
    } else if (isLeftSideFrom()) {
      return x + nodeSize.width
    } else {
      return x + nodeSize.width / 2 - nodeSize.offset
    }
  }
  const calcRecStateYTo = y => {
    if (isRightSideFrom()) {
      return y + nodeSize.height / 2 + nodeSize.offset
    } else if (isUpSideFrom()) {
      return y + nodeSize.height
    } else if (isLeftSideFrom()) {
      return y + nodeSize.height / 2 - nodeSize.offset
    } else {
      return y
    }
  }

  const arrowStart = {
    x: fromNode.type === 'STATE' ? calcRecStateXFrom(fromNode.x) : calcCircleStateXFrom(fromNode.x),
    y: fromNode.type === 'STATE' ? calcRecStateYFrom(fromNode.y) : calcCircleStateYFrom(fromNode.y)
  }

  const arrowEnd = {
    x: toNode.type === 'STATE' ? calcRecStateXTo(toNode.x) : calcCircleStateXTo(toNode.x),
    y: toNode.type === 'STATE' ? calcRecStateYTo(toNode.y) : calcCircleStateYTo(toNode.y)
  }

  return (
    <Group
      onMouseEnter={() => setIsArrowHover(true)}
      onMouseLeave={() => setIsArrowHover(false)}
      opacity={isArrowHover ? 0.5 : 1}
      onClick={() => editArrowHandler(connection)}>
      <Arrow
        tension={0.2}
        points={[
          arrowStart.x,
          arrowStart.y,
          arrowEnd.x,
          arrowEnd.y
        ]}
        stroke='#BFC3C6'
        fill='#BFC3C6'
        strokeWidth={isArrowHover ? 4 : 3}
        pointerWidth={5} />
      {typeof connection.summaryLine !== 'undefined' && connection.summaryLine !== '' &&
      <Text
        ref={textRef}
        key={index}
        text={connection.summaryLine}
        fill='black'
        x={((arrowStart.x + arrowEnd.x) / 2) - (textWidth / 2)}
        y={(arrowStart.y + arrowEnd.y) / 2}
        fontSize={12} />}
    </Group>
  )
}

ArrowComponent.propTypes = {
  fromNode: object,
  toNode: object,
  connection: object,
  editArrowHandler: func,
  index: number
}
