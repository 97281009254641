import React, { useEffect } from 'react'
import { string, func, object } from 'prop-types'
import { Form } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'

const { settings } = labels.inputs

const SettingsComponent = ({
  description,
  getPseudoCodeAttributes,
  attributesObject,
  handleChangeSettings
}) => {
  useEffect(() => {
    getPseudoCodeAttributes()
  }, [])

  return (
    <div>
      {description && <div
        style={{ paddingBottom: 10 }}
        className='universalModalComponent__content-description'>
        {description}
      </div>}
      <div className='universalModalComponent__content-settings'>
        <Form>
          {Object.keys(attributesObject).map((key, i) => (
            <div key={i}>
              <Form.Input
                id={`settingsComponent-${key}`}
                placeholder={settings.placeholder}
                error={attributesObject[key] === ''}
                value={attributesObject[key]}
                onChange={(e, { value }) => handleChangeSettings(value, key)}
                type='text' />
              <div className='settings-label'>{`${settings.label} ${key}`}</div>
            </div>))}
        </Form>
      </div>
    </div>
  )
}

SettingsComponent.propTypes = {
  description: string,
  attributesObject: object,
  getPseudoCodeAttributes: func,
  handleChangeSettings: func
}

export default SettingsComponent
