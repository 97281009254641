import React from 'react'
import { Form, Icon, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import * as labels from '../../../public/labels.json'

const VariableEditor = props => {
  let isError = false
  if (props.isSaveClicked) {
    isError = !props.element.isValid
  }
  return (
    <Form.Group className='wizard__form-col5'>
      <div className='wizard__form-info'>
        <Icon name='info' className='info-hidden' />
        <Form.Input
          readOnly
          fluid
          value={props.element.name}
          label={labels.modalPattern.inputs.nameSecondary.label}
          placeholder={labels.modalPattern.inputs.nameSecondary.placeholder} />
      </div>
      <div className='wizard__form-info'>
        {props.hintText &&
          <Popup
            position='top center'
            content={props.hintText}
            trigger={<Icon name='info' className='info' />} />}
        <Form.Select
          fluid
          error={isError}
          options={props.variableList}
          onChange={(event, { value }) => props.changeHandler('valueOfVariable', value, props.index)}
          value={props.element.valueOfVariable}
          label={labels.modalPattern.inputs.mappedOtxVariable.label}
          placeholder={labels.modalPattern.inputs.mappedOtxVariable.placeholder} />
      </div>
    </Form.Group>
  )
}

VariableEditor.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  element: PropTypes.object.isRequired,
  index: PropTypes.number,
  variableList: PropTypes.array.isRequired,
  isSaveClicked: PropTypes.bool,
  hintText: PropTypes.string
}

export default VariableEditor
