import { connect } from 'react-redux'
import UniversalModalComponent from './UniversalModalComponent.js'
import { clearUniversalModalObject } from '../../store/thunk/reducerList/universalModal'
import { pasteDiagramElemets } from '../../store/thunk/reducerList/diagramElement'
import { setPseudoCodeAttributes } from '../../store/thunk/reducerList/pseudoCode'

const mapStateToProps = state => ({
  beforePasteObject: state.diagramElement.beforePasteObject,
  pseudoCodeAttributes: state.pseudoCode.pseudoCodeAttributes
})

const mapDispatchToProps = {
  clearUniversalModalObject,
  pasteDiagramElemets,
  setPseudoCodeAttributes
}

export default connect(mapStateToProps, mapDispatchToProps)(UniversalModalComponent)
