export const elementsList = [
    {
        name: 'Action',
        type: 'Action',
        children: [
            { name: 'Assignment', type: 'Assignment' },
            { name: 'Procedure Call', type: 'ProcedureCall' },
            { name: 'Write Log', type: 'WriteLog' },
            {
                name: 'List Modifier',
                type: 'ListModifier',
                children: [
                    { name: 'Remove Items', type: 'ListRemove' },
                    { name: 'Clear', type: 'ListClear' },
                    { name: 'Append Items', type: 'ListAppend' },
                    { name: 'Insert Items', type: 'ListInsert' },
                    { name: 'Concatenate', type: 'ListConcatenate' }
                ]
            },
            {
                name: 'Map Modifiers',
                type: 'MapModifiers',
                children: [
                    { name: 'Union', type: 'MapUnion' },
                    { name: 'Remove Items', type: 'MapRemove' },
                    { name: 'Clear', type: 'MapClear' },
                    { name: 'Put Items', type: 'MapPut' }
                ]
            },
            {
                name: 'Bit Field Modifiers',
                type: 'BitFieldModifiers',
                children: [
                    { name: 'Set Bit', type: 'BitSet' },
                    { name: 'Shift Left', type: 'BitShiftLeft' },
                    { name: 'Shift Right', type: 'BitShiftRight' },
                    { name: 'Append Byte', type: 'BitAppend' },
                    { name: 'Replace Sub Byte', type: 'BitReplace' }
                ]
            },
            {
                name: 'SSM extensions',
                type: 'SsmExtensions',
                children: [
                    { name: 'Add Data Listener', type: 'AddDataListener' },
                    { name: 'Add Package Listener', type: 'AddPackageListener' },
                    { name: 'Invoke Activity', type: 'InvokeActivity' },
                    { name: 'Invoke Activity&Wait', type: 'InvokeActivityAndWait' },
                    { name: 'Terminate Activity', type: 'TerminateActivity' },
                    { name: 'Get Activity State', type: 'GetActivityState' },
                    { name: 'Connect', type: 'Connect' },
                    { name: 'Disconnect', type: 'Disconnect' },
                    { name: 'Wait', type: 'Wait' },
                    { name: 'Add Event Listener', type: 'AddEventListener' },
                    { name: 'Server Command', type: 'ServerCommand' }
                ]
            },
            {
              name: 'HMI Extensions',
              type: 'HmiExtensions',
              children: [
                { name: 'Choice Dialog', type: 'ChoiceDialog' },
                { name: 'Input Dialog', type: 'InputDialog' },
                { name: 'Confirm Dialog', type: 'ConfirmDialog' }
              ]
            }
        ]
    },
    {
        name: 'Group',
        type: 'Group',
        children: [
            { name: 'Group Element', type: 'GroupElement' }
        ]
    },
    {
        name: 'Parallel',
        type: 'Parallel',
        children: [
            { name: 'Lane', type: 'Lane' }
        ]
    },
    {
        name: 'Handler',
        type: 'Handler',
        children: [
            { name: 'Try', type: 'Try' },
            { name: 'Catching Flow', type: 'CatchingFlow' },
            { name: 'Finally', type: 'Finally' }
        ]
    },
    {
        name: 'Loop',
        type: 'Loop',
        children: [
            { name: 'For Loop', type: 'ForLoop' },
            { name: 'For Each Loop', type: 'ForEachLoop' },
            { name: 'While Loop', type: 'WhileLoop' }
        ]
    },
    {
        name: 'Mutex Group',
        type: 'MutexGroup',
        children: []
    },
    {
        name: 'Branch',
        type: 'Branch',
        children: [
            { name: 'If', type: 'If' },
            { name: 'Else If', type: 'ElseIf' },
            { name: 'Else', type: 'Else' }
        ]
    },
    {
        name: 'End',
        type: 'End',
        children: [
            { name: 'Break', type: 'Break' },
            { name: 'Continue', type: 'Continue' },
            { name: 'Return', type: 'Return' },
            { name: 'Terminate Lanes', type: 'TerminateLanes' },
            { name: 'Throw', type: 'Throw' }
        ]
    }
]

export const onlyWrapperElementsList = [
  'ListModifier',
  'MapModifiers',
  'BitFieldModifiers',
  'End',
  'SsmExtensions',
  'HmiExtensions'
]
