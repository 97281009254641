// *** This file contains all action types to prevent multiplications in project

// ** AUTH
export const SET_USER_ROLE = 'SET_USER_ROLE'
export const SET_USER_ROLES = 'SET_USER_ROLES'
export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'
export const RESET_STORE = 'RESET_STORE'

//  ** PROJECT
export const GET_PROJECT_LIST = 'GET_PROJECT_LIST'
export const GET_PROJECT = 'GET_PROJECT'
export const ADD_PROJECT = 'ADD_PROJECT'
export const UPDATE_PROJECT_LIST = 'UPDATE_PROJECT_LIST'
export const GET_PROJECT_TYPES = 'GET_PROJECT_TYPES'
export const SET_PROJECT_TYPE = 'SET_PROJECT_TYPE'
export const SET_OTX_FILE_LIST = 'SET_OTX_FILE_LIST'
export const CLEAR_PROJECT_LIST_TIME = 'CLEAR_PROJECT_LIST_TIME'
export const SET_PROJECT_TAB_DATA = 'SET_PROJECT_TAB_DATA'
export const SET_EXECUTIONS_TAB_DATA = 'SET_EXECUTIONS_TAB_DATA'
export const SET_IS_PROJECT_CONTAIN_OTX = 'SET_IS_PROJECT_CONTAIN_OTX'

//  ** OTX_FILE
export const GET_OUTLINE_TREE = 'GET_OUTLINE_TREE'
export const UPDATE_OTX_FILE_LIST = 'UPDATE_OTX_FILE_LIST'
export const GET_OTX_MODAL_TIME = 'GET_OTX_MODAL_TIME'
export const ADD_OTX_FILE = 'ADD_OTX_FILE'
export const UPDATE_OTX_FILE = 'UPDATE_OTX_FILE'
export const DELETE_OTX_FILE = 'DELETE_OTX_FILE'
export const SET_SSM_ELEMENT_LIST = 'SET_SSM_ELEMENT_LIST'
export const SET_VALIDITY_OPTION_LIST = 'SET_VALIDITY_OPTION_LIST'
export const SET_DEVICES_TAB_DATA = 'SET_DEVICES_TAB_DATA'

//  ** PROCEDURE
export const GET_PROCEDURE_LIST = 'GET_PROCEDURE_LIST'
export const GET_PROCEDURE = 'GET_PROCEDURE'
export const ADD_PROCEDURE = 'ADD_PROCEDURE'
export const UPDATE_PROCEDURE = 'UPDATE_PROCEDURE'
export const DELETE_PROCEDURE = 'DELETE_PROCEDURE'

//  ** PROCEDURE_ELEMENT
export const GET_PROCEDURE_ELEMENT_LIST = 'GET_PROCEDURE_ELEMENT_LIST'
export const GET_PROCEDURE_ELEMENT = 'GET_PROCEDURE_ELEMENT'
export const ADD_PROCEDURE_ELEMENT = 'ADD_PROCEDURE_ELEMENT'
export const UPDATE_PROCEDURE_ELEMENT = 'UPDATE_PROCEDURE_ELEMENT'
export const DELETE_PROCEDURE_ELEMENT = 'DELETE_PROCEDURE_ELEMENT'

// ** SELECT ELEMENT
export const SET_ACTIVE_CONSOLE_INDEX = 'SET_ACTIVE_CONSOLE_INDEX'
export const SET_SSM_ID = 'SET_SSM_ID'
export const SET_SELECTED_PSEUDOCODE_ID = 'SET_SELECTED_PSEUDOCODE_ID'
export const SET_NODES_TO_COPY = 'SET_NODES_TO_COPY'
export const SET_COPIED_NODES = 'SET_COPIED_NODES'
// ** DIAGRAM ATTRIBUTES

export const GET_ATTRIBUTES = 'GET_ATTRIBUTES'
export const GET_XML_PREVIEW = 'GET_XML_PREVIEW'
export const GET_DIAGRAM_LIST = 'GET_DIAGRAM_LIST'
export const GET_HEADER_DATA = 'GET_HEADER_DATA'
export const CREATE_ELEMENT = 'CREATE_ELEMENT'
export const READ_ELEMENT = 'READ_ELEMENT'
export const UPDATE_OLD = 'UPDATE_OLD'
export const UPDATE_ELEMENT = 'UPDATE_ELEMENT'
export const UPDATE_BREAKPOINT = 'UPDATE_BREAKPOINT'
export const DELETE_ELEMENT = 'DELETE_ELEMENT'
export const CHANGE_DIAGRAM_COMPONENT_STATE = 'CHANGE_DIAGRAM_COMPONENT_STATE'
export const SET_REPORT_ATTRIBUTES = 'SET_REPORT_ATTRIBUTES'
export const SET_ACTIVE_TOP_TAB_INDEX = 'SET_ACTIVE_TOP_TAB_INDEX'
export const SET_BEFORE_PASTE_OBJECT = 'SET_BEFORE_PASTE_OBJECT'

// ** PARAMETERS

export const GET_SIGNATURE = 'GET_SIGNATURE'
export const GET_VALIDITIES = 'GET_VALIDITIES'
export const GET_PARENT_LOOPS = 'GET_PARENT_LOOPS'

// ** ACTIONS ON ELEMENT

export const OPEN_EDIT_MODAL = 'OPEN_EDIT_MODAL'
export const OPEN_REMOVE_MODAL = 'OPEN_REMOVE_MODAL'
export const MODAL_ELEMENT = 'MODAL_ELEMENT'

// ** DIAGRAM ELEMENT

export const GET_VARIABLE_LIST = 'GET_VARIABLE_LIST'
export const GET_VARIABLE_LIST_WITH_TYPES = 'GET_VARIABLE_LIST_WITH_TYPES'
export const GET_PROCEDURES_PARAMETERS_LIST = 'GET_PROCEDURES_PARAMETERS_LIST'
export const OPEN_ADD_VARIABLE = 'OPEN_ADD_VARIABLE'

// ** PROCEDURE

export const SET_IS_PROCEDURE_RUNNING = 'SET_IS_PROCEDURE_RUNNING'
export const SET_REACHED_BREAKPOINT = 'SET_REACHED_BREAKPOINT'
export const SHOULD_UPDATE_SCROLL = 'SHOULD_UPDATE_SCROLL'
export const SET_REACHED_NODE_ID = 'SET_REACHED_NODE_ID'

// ** ACTIVE LOADER

export const ACTIVE_LOADER = 'ACTIVE_LOADER'

// ** DRIVER

export const GET_DRIVER_LIST = 'GET_DRIVER_LIST'
export const SET_DRIVER = 'SET_DRIVER'
export const SET_LOG_ITEM = 'SET_LOG_ITEM'
export const SET_VARIABLE_WATCH_ITEM = 'SET_VARIABLE_WATCH_ITEM'
export const RESET_ALL_LOGS = 'RESET_ALL_LOGS'
export const SET_SUT_STATUS_ITEM = 'SET_SUT_STATUS_ITEM'
export const ACTIVATE_CLEAN_BUTTON = 'ACTIVATE_CLEAN_BUTTON'

// ** VARIABLE

export const SET_ADDED_VARIABLE = 'SET_ADDED_VARIABLE'

// ** PSEUDOCODE

export const GET_PSEUDO_CODE_LIST = 'GET_PSEUDO_CODE_LIST'
export const UPDATE_PSEUDO_CODE_LIST = 'UPDATE_PSEUDO_CODE_LIST'
export const GET_PSEUDO_CODE_ATTRIBUTES = 'GET_PSEUDO_CODE_ATTRIBUTES'

// ** UNIVERSALMODAL

export const SET_UNIVERSAL_MODAL_OBJECT = 'SET_UNIVERSAL_MODAL_OBJECT'

// ** STATEDIAGRAM

export const GET_STATE_DIAGRAM = 'GET_STATE_DIAGRAM'
