import { isOptionalFieldHandler, validateValueByType, checkIsValidListHandler } from '../validation/validationHandlers'
import { isValidString } from '../validation/strings'

export const termCreatorHandler = (term, type = getTermType(term)) => {
    const initTermObj = { ...returnInitTermHandler(type, true) }
    const editionType = term.value !== undefined
      ? 'Literal'
      : typeof term.valueOf === 'string' ? 'ValueOf' : 'AdvancedEdit'
  return {
    ...initTermObj,
    value: editionType === 'Literal' ? term : initTermObj.value,
    valueOf: editionType === 'ValueOf' ? term : initTermObj.valueOf,
    editionType,
    term
  }
}

export const termEditorHandler = (state, stateName, value, propertyName) => {
  const isEditionStarted = propertyName !== 'editionType'
  let newTerm = {}
  let term = {}
  let isValid = state.isValid
  let valueObj = {}
  switch (propertyName) {
    case 'editionType':
      if (value === 'ValueOf') {
        term = { ...state.valueOf }
        isValid = isTermValidHandler(value, state.valueOf, stateName, isValid)
      } else if (value === 'Literal') {
        term = { ...state.value }
        if (state.value.items) {
          isValid = checkIsValidListHandler(state.value.items)
        } else {
          isValid = isTermValidHandler(value, state.value, stateName, isValid)
        }
      } else {
        term = { ...state.term }
      }

      newTerm = {
        ...state,
        [propertyName]: value,
        isEditionStarted,
        isValid,
        term
      }
      break
    case 'value':
    case 'valueOf':
      valueObj = { ...state[propertyName], [propertyName]: value }
      term = valueObj
      isValid = isTermValidHandler(state.editionType, valueObj, stateName, isValid)
      newTerm = {
        ...state,
        [propertyName]: valueObj,
        isEditionStarted,
        isValid,
        term
      }
      break
    case 'term':
      term = value
      if (value.value !== undefined) {
        isValid = isTermValidHandler('Literal', value, stateName, isValid)
        newTerm = {
          ...state,
          value,
          isEditionStarted,
          isValid,
          term
        }
      } else if (value.valueOf !== undefined) {
        isValid = isTermValidHandler('ValueOf', value, stateName, isValid)
        newTerm = {
          ...state,
          valueOf: value,
          isEditionStarted,
          isValid,
          term
        }
      } else {
        isValid = true
        newTerm = {
          ...state,
          isEditionStarted,
          isValid,
          term
        }
      }
    break
    default:
      return state
  }
  return newTerm
}

const isTermValidHandler = (type, term, stateName, isValidProp) => {
  let isValid
  switch (type) {
    case 'Literal':
      isValid = validateValueByType(term.value, term.type, stateName)
      break
    case 'ValueOf':
      isValid = isOptionalFieldHandler(stateName) || isValidString(term.valueOf)
      break
    default:
      isValid = isValidProp
      break
  }
  return isValid
}

export const returnInitTermHandler = (type, isOptional = false) => {
  const editionType = isSupportingLiteral(type) ? 'Literal' : 'ValueOf'
  const value = {
    value: '',
    children: [],
    type: `${type}Literal`
  }
  const valueOf = {
    valueOf: '',
    children: [],
    type: `${type}Value`
  }
  const term = editionType === 'Literal' ? value : valueOf
  return {
    editionType,
    isValid: isOptional,
    isEditionStarted: false,
    type,
    value,
    valueOf,
    term
  }
}

export const returnInitMapTermHandler = (type, keyType, valueType, isValid = false) => {
  const mapTermObject = {
    key: returnInitTermHandler(keyType),
    value: returnInitTermHandler(valueType),
    type: 'MapItem',
    children: []
  }

  const initMapTerm = returnInitTermHandler('Map', isValid)

  let mapTerm = {}

  switch (type) {
    case 'MapPut':
      mapTerm = mapTermObject
      break
    case 'MapUnion':
      mapTerm = {
        ...initMapTerm,
        value: {
          items: [mapTermObject],
          children: [],
          type: 'MapLiteral',
          keyType: { type: keyType, children: [], value: null },
          valueType: { type: valueType, children: [], value: null }
        }
      }
      break
    case 'MapUnionItem':
      mapTerm = mapTermObject
      break
    default:
      mapTerm = initMapTerm
  }
  return mapTerm
}

export const termsWithObligatoryChildren = terms => {
  const array = []
  function getTermsContainingType (children) {
    children.map(term => {
      if (term.name && term.children.length) {
        getTermsContainingType(term.children)
      } else if (term.type && term.children && term.children.length) {
        array.push(term.type)
      }
    })
  }
  getTermsContainingType(terms)
  return array
}

export const isSupportingLiteral = type => !typesWithoutSupportingLiteral.includes(type)

export const getTermType = term => term.type.replace('Literal', '').replace('Value', '')

const typesWithoutSupportingLiteral = [
  'Map',
  'List',
  'ArithmeticException',
  'ByteField',
  'ConcurrentModificationException',
  'Connection',
  'Exception',
  'InvalidReferenceException',
  'OutOfBoundsException',
  'SsmEventData',
  'SsmReportingData',
  'TypeMismatchException',
  'UnknownTargetException',
  'UserException',
  'AmbiguousCallException',
  'ConnectionException',
  'MapContainsValue',
  'MapContainsKey',
  'ListContainsValue'
]
