import { connect } from 'react-redux'
import PickProjectComponent from './PickProjectComponent.js'
import {
  getProjectList,
  addProject,
  updateProject,
  deleteProject,
  clearProjectListTime
} from '../../../../store/thunk/reducerList/project'
import { loaderControl } from '../../../../store/thunk/reducerList/loaderControl'

const mapDispatchToProps = {
    getProjectList,
    addProject,
    updateProject,
    deleteProject,
    clearProjectListTime,
    loaderControl
}

const mapStateToProps = state => ({
  projects: state.project.projectList,
  project: state.project.project,
  responseTime: state.project.updateProjectListTime,
  isTesterRole: state.auth.isTesterRole
})

export default connect(mapStateToProps, mapDispatchToProps)(PickProjectComponent)
