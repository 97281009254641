import { connect } from 'react-redux'
import VirtualizedTreeComponent from './VirtualizedTreeComponent'
import {
  getAttributes,
  getXmlPreview,
  getXmlPreviewAction,
  getReportInfo,
  setReportAttributes
} from '../../../../store/thunk/reducerList/diagramAttributes'
import {
  getOutlineTreeAction,
  getSsmList,
  resetSsmList
} from '../../../../store/thunk/reducerList/otxFile'
import { clearDiagramData } from '../../../../store/thunk/reducerList/diagramElement'
import { loaderControl } from '../../../../store/thunk/reducerList/loaderControl'
import {
    setActiveConsoleIndex,
    updateTreeData,
    setSelectedContextViewId,
    copyElementHandler
} from '../../../../store/thunk/reducerList/selectElement'
import { updateScroll } from '../../../../store/thunk/reducerList/procedure'

const mapDispatchToProps = {
    getOutlineTreeAction,
    getAttributes,
    getXmlPreview,
    getSsmList,
    resetSsmList,
    loaderControl,
    clearDiagramData,
    getXmlPreviewAction,
    getReportInfo,
    setActiveConsoleIndex,
    updateScroll,
    setReportAttributes,
    updateTreeData,
    setSelectedContextViewId,
    copyElementHandler
}
const mapStateToProps = state => ({
    headerData: state.diagramElement.headerData,
    reachedBreakPoint: state.procedure.reachedBreakPoint,
    activeConsoleIndex: state.selectElement.activeConsoleIndex,
    selectedContextViewId: state.selectElement.selectedContextViewId
})

export default connect(mapStateToProps, mapDispatchToProps)(VirtualizedTreeComponent)
