import axios from 'axios'
import { origin, protocol, getHeaders, getHeadersDocuments, errorHandle, loop }
from '../../../store/addons'
import {
  GET_OUTLINE_TREE,
  ADD_OTX_FILE,
  UPDATE_OTX_FILE_LIST,
  GET_OTX_MODAL_TIME,
  SET_SSM_ELEMENT_LIST,
  SET_VALIDITY_OPTION_LIST,
  SET_DEVICES_TAB_DATA
} from '../actionTypeList'
import { setActiveConsoleIndex } from './selectElement'
import { getProject } from './project'
import { openRemoveModalAction } from './diagramElement'
import { loaderControl } from './loaderControl'
import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
import { setProcedureIfExist } from '../../../constants/common'
import { updatePseudoCodeList } from './pseudoCode'
import isEmpty from 'lodash/isEmpty'

export const getOutlineTreeAction = data => {
  return {
    type: GET_OUTLINE_TREE,
    data
  }
}

export const setDevicesTabData = data => {
  return {
    type: SET_DEVICES_TAB_DATA,
    data
  }
}

export const addOtxFileAction = data => {
  return {
    type: ADD_OTX_FILE,
    data
  }
}

export const updateOtxFileListAction = data => {
  return {
    type: UPDATE_OTX_FILE_LIST,
    data
  }
}

export const getOtxFileModalTimeAction = data => {
  return {
    type: GET_OTX_MODAL_TIME,
    data
  }
}

export const setSsmElementListByTypeAction = data => {
  return {
    type: SET_SSM_ELEMENT_LIST,
    data
  }
}

export const getOtxVariableListAction = data => {
  return {
    type: SET_VALIDITY_OPTION_LIST,
    data
  }
}

export const getOtx = (projectId, id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
     axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${id}`, getHeaders())
       .then(response => {
        const { name, specification, version } = response.data
        const otxData = {
          package: response.data.package,
          name,
          specification,
          version
        }
        dispatch(loaderControl())
        resolve(otxData)
       })
       .catch(error => {
        dispatch(loaderControl())
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
        reject(error)
       })
    })
  }
}

export const getOutlineTree = (projectId, id, isProcedureRunning = false, shouldSetDefaultProcedure = true) => {
  const collapsedElementsArray = JSON.parse(localStorage.getItem('outlineDataCollapsedElements')) || []
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${id}`, getHeaders())
        .then(response => {
          let i
          loop(response.data.imports, '0-0', '0-0', false, false, false, '', false, true)
          loop(response.data.validities, '0-1', '0-1', false, false, true)
          loop(response.data.declarations
            .filter(element => element.type === 'GlobalConstantDeclaration')
            , '0-2-0', '0-2-0', false, true)
          loop(response.data.declarations
            .filter(element => element.type === 'ContextVariableDeclaration')
            , '0-2-1', '0-2-1', false, true)
          loop(response.data.declarations
            .filter(element => element.type === 'DocumentVariableDeclaration')
            , '0-2-2', '0-2-2', false, true)
          const result = [
            {
              children: [
                {
                  children: response.data.imports,
                  key: '0-0',
                  name: 'Imports',
                  type: 'node',
                  id: '0-0',
                  treeId: '0-0',
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === '0-0')
                  }
                },
                {
                  children: response.data.validities,
                  key: '0-1',
                  name: 'Validities',
                  type: 'node',
                  id: '0-1',
                  treeId: '0-1',
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === '0-1')
                  }
                },
                {
                  children: [
                    {
                      children: response.data.declarations
                        .filter(element => element.type === 'GlobalConstantDeclaration'),
                      key: '0-2-0',
                      name: 'CONSTANTS',
                      type: 'node',
                      id: '0-2-0',
                      treeId: '0-2-0',
                      state: {
                        expanded: !collapsedElementsArray.some(el => el === '0-2-0')
                      }
                    },
                    {
                      children: response.data.declarations
                      .filter(element => element.type === 'ContextVariableDeclaration'),
                      key: '0-2-1',
                      name: 'CONTEXTS',
                      type: 'node',
                      id: '0-2-1',
                      treeId: '0-2-1',
                      state: {
                        expanded: !collapsedElementsArray.some(el => el === '0-2-1')
                      }
                    },
                    {
                      children: response.data.declarations
                      .filter(element => element.type === 'DocumentVariableDeclaration'),
                      key: '0-2-2',
                      name: 'VARIABLES',
                      type: 'node',
                      id: '0-2-2',
                      treeId: '0-2-2',
                      state: {
                        expanded: !collapsedElementsArray.some(el => el === '0-2-2')
                      }
                    }
                  ],
                  key: '0-2',
                  name: 'Global Declarations',
                  type: 'node',
                  id: '0-2',
                  treeId: '0-2',
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === '0-2')
                  }
                },
                {
                  children: [],
                  key: '0-3',
                  name: 'Procedures',
                  type: 'node',
                  id: '0-3',
                  treeId: '0-3',
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === '0-3')
                  }
                },
                {
                  children: [],
                  key: '0-4',
                  name: 'Signatures',
                  type: 'node',
                  id: '0-4',
                  treeId: '0-4',
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === '0-4')
                  }
                }
            ],
            key: '0',
            name: response.data.name,
            isOtxTree: true,
            type: 'node',
            id: response.data.name,
            treeId: response.data.name,
            state: {
              expanded: !collapsedElementsArray.some(el => el === response.data.name)
            }
          }]
          for (i = 0; i < response.data.procedures.length; i++) {
            loop(response.data.procedures[i].declarations
              .filter(element => element.type === 'ConstantDeclaration'),
                 `0-3-${i}-0`,
                 `0-3-${i}-0`,
                 false,
                 true,
                 false,
                 response.data.procedures[i].otx_id)
            loop(response.data.procedures[i].declarations
              .filter(element => element.type === 'VariableDeclaration'),
                `0-3-${i}-0`,
                `0-3-${i}-0`,
                false,
                true,
                false,
                response.data.procedures[i].otx_id)
            loop(response.data.procedures[i].parameters.filter(element => element.type === 'InParameter'),
                 `0-3-${i}-1-0`,
                 `0-3-${i}-1-0`,
                 false,
                 true,
                 false,
                 response.data.procedures[i].otx_id)
            loop(response.data.procedures[i].parameters.filter(element => element.type === 'InOutParameter'),
                `0-3-${i}-1-1`,
                `0-3-${i}-1-1`,
                false,
                true,
                false,
                response.data.procedures[i].otx_id)
            loop(response.data.procedures[i].parameters.filter(element => element.type === 'OutParameter'),
                `0-3-${i}-1-2`,
                `0-3-${i}-1-2`,
                false,
                true,
                false,
                response.data.procedures[i].otx_id)
            loop(response.data.procedures[i].throws,
                 `0-3-${i}-2`,
                 `0-3-${i}-2`,
                 false,
                 false,
                 false,
                 response.data.procedures[i].otx_id,
                 false,
                 false,
                 true)
            loop(response.data.procedures[i].children,
                 `0-3-${i}-3`,
                 `0-3-${i}-3`,
                 true,
                 false,
                 false,
                 response.data.procedures[i].otx_id,
                 false,
                 false,
                 false,
                 false,
                 null,
                 [
                  {
                    parentFlowElement: null,
                    id: response.data.id,
                    name: response.data.name,
                    type: 'Otx'
                  },
                  {
                    parentFlowElement: null,
                    id: response.data.procedures[i].otx_id,
                    name: response.data.procedures[i].otx_name,
                    type: response.data.procedures[i].type
                  }
                 ],
                 2,
                 collapsedElementsArray)
            result[0].children[3].children.push({
              children: [
                {
                  children: [
                  {
                    children: response.data.procedures[i].declarations
                      .filter(element => element.type === 'ConstantDeclaration'),
                    key: `0-3-${i}-0-0`,
                    name: 'CONSTANTS',
                    type: 'node',
                    procedureId: response.data.procedures[i].otx_id,
                    id: `0-3-${i}-0-0`,
                    treeId: `0-3-${i}-0-0`,
                    state: {
                      expanded: !collapsedElementsArray.some(el => el === `0-3-${i}-0-0`)
                    }
                  },
                  {
                    children: response.data.procedures[i].declarations
                      .filter(element => element.type === 'VariableDeclaration'),
                    key: `0-3-${i}-0-1`,
                    name: 'VARIABLES',
                    type: 'node',
                    procedureId: response.data.procedures[i].otx_id,
                    id: `0-3-${i}-0-1`,
                    treeId: `0-3-${i}-0-1`,
                    state: {
                      expanded: !collapsedElementsArray.some(el => el === `0-3-${i}-0-1`)
                    }
                  }
                  ],
                  key: `0-3-${i}-0`,
                  name: 'Local Declarations',
                  type: 'node',
                  procedureId: response.data.procedures[i].otx_id,
                  id: `0-3-${i}-0`,
                  treeId: `0-3-${i}-0`,
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === `0-3-${i}-0`)
                  }
                },
                {
                  children: [
                    {
                      children: response.data.procedures[i].parameters
                        .filter(element => element.type === 'InParameter'),
                      key: `0-3-${i}-1-0`,
                      name: 'IN',
                      type: 'node',
                      procedureId: response.data.procedures[i].otx_id,
                      id: `0-3-${i}-1-0`,
                      treeId: `0-3-${i}-1-0`,
                      state: {
                        expanded: !collapsedElementsArray.some(el => el === `0-3-${i}-1-0`)
                      }
                    },
                    {
                      children: response.data.procedures[i].parameters
                        .filter(element => element.type === 'InOutParameter'),
                      key: `0-3-${i}-1-1`,
                      name: 'INOUT',
                      type: 'node',
                      procedureId: response.data.procedures[i].otx_id,
                      id: `0-3-${i}-1-1`,
                      treeId: `0-3-${i}-1-1`,
                      state: {
                        expanded: !collapsedElementsArray.some(el => el === `0-3-${i}-1-1`)
                      }
                    },
                    {
                      children: response.data.procedures[i].parameters
                        .filter(element => element.type === 'OutParameter'),
                      key: `0-3-${i}-1-2`,
                      name: 'OUT',
                      type: 'node',
                      procedureId: response.data.procedures[i].otx_id,
                      id: `0-3-${i}-1-2`,
                      treeId: `0-3-${i}-1-2`,
                      state: {
                        expanded: !collapsedElementsArray.some(el => el === `0-3-${i}-1-2`)
                      }
                    }
                  ],
                  key: `0-3-${i}-1`,
                  name: 'Parameters',
                  type: 'node',
                  procedureId: response.data.procedures[i].otx_id,
                  id: `0-3-${i}-1`,
                  treeId: `0-3-${i}-1`,
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === `0-3-${i}-1`)
                  }
                },
                {
                  children: response.data.procedures[i].throws,
                  key: `0-3-${i}-2`,
                  name: 'Throws',
                  type: 'node',
                  procedureId: response.data.procedures[i].otx_id,
                  id: `0-3-${i}-2`,
                  treeId: `0-3-${i}-2`,
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === `0-3-${i}-2`)
                  }
                },
                {
                  children: response.data.procedures[i].children,
                  key: `0-3-${i}-3`,
                  name: 'Flows',
                  type: 'node',
                  procedureId: response.data.procedures[i].otx_id,
                  id: `0-3-${i}-3`,
                  treeId: `0-3-${i}-3`,
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === `0-3-${i}-3`)
                  }
                }
              ],
              key: `0-3-${i}`,
              name: response.data.procedures[i].otx_name,
              localisation: [
                {
                  parentFlowElement: null,
                  id: response.data.id,
                  type: 'Otx',
                  name: response.data.name
                },
                {
                  parentFlowElement: null,
                  id: response.data.procedures[i].otx_id,
                  type: response.data.procedures[i].type,
                  name: response.data.procedures[i].otx_name
                }
              ],
              specification: response.data.procedures[i].otx_specification,
              type: response.data.procedures[i].type,
              otx_id: response.data.procedures[i].otx_id,
              visibility: response.data.procedures[i].visibility,
              validFor: response.data.procedures[i].validFor,
              signature: response.data.procedures[i].signature,
              id: response.data.procedures[i].otx_id,
              treeId: response.data.procedures[i].otx_id,
              state: {
                expanded: !collapsedElementsArray.some(el => el === response.data.procedures[i].otx_id)
              }
            })
          }
          for (let j = 0; j < response.data.signatures.length; j++) {
              loop(response.data.signatures[j].parameters.filter(element => element.type === 'InParameter'),
                `0-4-${i}-0-0`,
                `0-4-${i}-0-0`,
                false,
                true,
                false,
                response.data.signatures[j].otx_id,
                false,
                false,
                false,
                true)
              loop(response.data.signatures[j].parameters.filter(element => element.type === 'InOutParameter'),
                `0-4-${i}-0-1`,
                `0-4-${i}-0-1`,
                false,
                true,
                false,
                response.data.signatures[j].otx_id,
                false,
                false,
                false,
                true)
              loop(response.data.signatures[j].parameters.filter(element => element.type === 'OutParameter'),
                `0-4-${i}-0-2`,
                `0-4-${i}-0-2`,
                false,
                true,
                false,
                response.data.signatures[j].otx_id,
                false,
                false,
                false,
                true)
            loop(response.data.signatures[j].throws,
              `0-4-${i}-1`,
              `0-4-${i}-1`,
              false,
              false,
              false,
              response.data.signatures[j].otx_id,
              false,
              false,
              true,
              true)
            result[0].children[4].children.push({
              children: [
                {
                  children: [
                    {
                      children: response.data.signatures[j].parameters
                        .filter(element => element.type === 'InParameter'),
                      key: `0-4-${j}-0-0`,
                      name: 'IN',
                      type: 'node',
                      signatureId: response.data.signatures[j].otx_id,
                      id: `0-4-${j}-0-0`,
                      treeId: `0-4-${j}-0-0`,
                      state: {
                        expanded: !collapsedElementsArray.some(el => el === `0-4-${j}-0-0`)
                      }
                    },
                    {
                      children: response.data.signatures[j].parameters
                        .filter(element => element.type === 'InOutParameter'),
                      key: `0-4-${j}-0-1`,
                      name: 'INOUT',
                      type: 'node',
                      signatureId: response.data.signatures[j].otx_id,
                      id: `0-4-${j}-0-1`,
                      treeId: `0-4-${j}-0-1`,
                      state: {
                        expanded: !collapsedElementsArray.some(el => el === `0-4-${j}-0-1`)
                      }
                    },
                    {
                      children: response.data.signatures[j].parameters
                        .filter(element => element.type === 'OutParameter'),
                      key: `0-4-${j}-0-2`,
                      name: 'OUT',
                      type: 'node',
                      signatureId: response.data.signatures[j].otx_id,
                      id: `0-4-${j}-0-2`,
                      treeId: `0-4-${j}-0-2`,
                      state: {
                        expanded: !collapsedElementsArray.some(el => el === `0-4-${j}-0-2`)
                      }
                    }
                  ],
                  key: `0-4-${j}-0`,
                  name: 'Parameters',
                  type: 'node',
                  signatureId: response.data.signatures[j].otx_id,
                  id: `0-4-${j}-0`,
                  treeId: `0-4-${j}-0`,
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === `0-4-${j}-0`)
                  }
                },
                {
                  children: response.data.signatures[j].throws,
                  key: `0-4-${j}-1`,
                  name: 'Throws',
                  type: 'node',
                  id: `0-4-${j}-1`,
                  signatureId: response.data.signatures[j].otx_id,
                  treeId: `0-4-${j}-1`,
                  state: {
                    expanded: !collapsedElementsArray.some(el => el === `0-4-${j}-1`)
                  }
                }
              ],
              key: `0-4-${j}`,
              name: response.data.signatures[j].otx_name,
              type: response.data.signatures[j].type,
              otx_id: response.data.signatures[j].otx_id,
              visibility: response.data.signatures[j].visibility,
              specification: response.data.signatures[j].otx_specification,
              id: response.data.signatures[j].otx_id,
              treeId: `0-4-${j}`,
              state: {
                expanded: !collapsedElementsArray.some(el => el === response.data.signatures[j].otx_id)
              }
            })
          }
          !isProcedureRunning && dispatch(setActiveConsoleIndex(4, 0))
          dispatch(getOutlineTreeAction(result))
          shouldSetDefaultProcedure && setProcedureIfExist(response.data.procedures, projectId, id)
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          dispatch(loaderControl())
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const setSsmElementListByType = (projectId = -1, ssmId = -1, type) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/devices/${ssmId}`, getHeaders())
      .then(response => {
        const result = []
        const loop = data => {
          data.forEach(item => {
            item.type === type && result.push({
                key: result.length,
                text: item.rdType
                ? `${item.name} : ${item.rdType}`
                : item.name,
                value: item.name,
                activity: item
              })
            if (item.spaceSystemObjects && item.spaceSystemObjects.length) {
              return loop(item.spaceSystemObjects)
            }
          })
        }
        loop(response.data.spaceSystemObjects)
        dispatch(setSsmElementListByTypeAction(result))
        dispatch(loaderControl())
        resolve(result)
      })
      .catch(error => {
        dispatch(loaderControl())
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
        reject(error)
      })
    })
  }
}

export const getSsmList = (projectId, ssmId) => {
  const collapsedElementsArray = JSON.parse(localStorage.getItem('devicesDataTabCollapsedElements')) || []
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/devices/${ssmId}`, getHeaders())
        .then(response => {
          const result = [
            {
              key: '0',
              name: response.data.objectName,
              isOtxTree: false,
              isSsmTree : true,
              type: 'node',
              children: response.data.spaceSystemObjects,
              id: ssmId,
              treeId: '0',
              state: {
                expanded: !collapsedElementsArray.some(el => el === ssmId)
              }
            }
          ]
          const loop = (data, preKey = '0', prePath = '') => {
            data.forEach((item, index) => {
              const key = `${preKey}-${index}`
              let path = `${prePath}`
              path += `[${index}]${item.children && '.children'}`
              item.key = key
              item.children = []
              if (item.id) {
                item.treeId = item.id
              } else if (item.otx_id) {
                item.id = item.otx_id
                item.treeId = item.otx_id
              } else {
                item.id = key
                item.treeId = key
              }
              item.state = {
                expanded: isEmpty(collapsedElementsArray) || !collapsedElementsArray.some(el => el === item.id)
              }
              if (item.type === 'activity' || item.type === 'event' || item.type === 'reporting data') {
                item.name = `${item.name} : ${item.type}`
              }
              if (item.type === 'activity' && item.activityArgs.length) {
                const tempArr = []
                item.activityArgs.forEach((el, index) => {
                  const tempObj = Object.assign(el, {
                    id: `${el.ssm_id}${index}`,
                    children: [],
                    name: `${el.name} : ${el.actArgDataType}`
                  })
                  tempArr.push(tempObj)
                })
                item.children = tempArr
              }
              if (item.spaceSystemObjects && item.spaceSystemObjects.length) {
                item.children = item.spaceSystemObjects
                delete item.spaceSystemObjects
                return loop(item.children, key, path)
              }
            })
          }
          loop(response.data.spaceSystemObjects, '0', '0')
          // dispatch(getOutlineTreeAction(result))
          dispatch(setDevicesTabData(result))
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          dispatch(loaderControl())
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const resetSsmList = () => {
  localStorage.removeItem('devicesDataTabCollapsedElements')
  return dispatch => dispatch(setDevicesTabData([]))
}

export const resetSsmElementListByType = () => dispatch => dispatch(setSsmElementListByTypeAction([]))

export const getOtxVariableList = (projectId = -1, otxFileId = -1) => {
  return dispatch => {
    dispatch(loaderControl(true))
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/declarations/all`, getHeaders())
       .then(response => {
         dispatch(getOtxVariableListAction(response.data.map((element, index) => {
           return {
             key: `${element.otx_id}_${index}`,
             value: element.otx_name,
             text: element.otx_name,
             obj: element
           }
         })))
         dispatch(loaderControl())
         resolve()
       })
       .catch(error => {
         const errorMessage = parseError(error)
         dispatch(loaderControl())
         dispatch(Notifications.error(errorMessage))
         errorHandle(error)
         reject(error)
       })
    })
  }
}

export const addOtxFile = (projectId, data) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/items/new`, data, getHeaders())
       .then(response => {
         dispatch(addOtxFileAction(response.data))
         dispatch(getProject(projectId))
         dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
         dispatch(loaderControl())
         resolve()
       })
       .catch(error => {
         dispatch(loaderControl())
         const errorMessage = parseError(error)
         dispatch(Notifications.error(errorMessage))
         errorHandle(error)
         reject(error)
       })
    })
  }
}

export const removeOtxOrPackage = (projectId, otxFileId, parentId, shouldClearData = false) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.delete(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}?parentItemId=${parentId}`,
      getHeaders())
       .then(() => {
         dispatch(getProject(projectId, shouldClearData))
         shouldClearData && dispatch(getOutlineTreeAction([]))
         dispatch(openRemoveModalAction(false))
         dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
         dispatch(loaderControl())
         resolve()
       })
       .catch(error => {
        dispatch(loaderControl())
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
        reject(error)
       })
    })
  }
}

export const importOtxFile = (projectId, file, fileType) => {
  return dispatch => {
    dispatch(loaderControl(true))
    return new Promise((resolve, reject) => {
      const data = new FormData()
     data.append(fileType, file)
      axios.post(`${protocol}//${origin}/api/projects/${projectId}/items/import`, data, getHeadersDocuments())
       .then(response => {
        dispatch(resetSsmList())
        dispatch(getProject(projectId))
        dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
        dispatch(updateOtxFileListAction((new Date()).getTime()))
        dispatch(loaderControl())
        resolve()
       })
       .catch(error => {
        const errorMessage = parseError(error)
        dispatch(Notifications.error(errorMessage))
        errorHandle(error)
        dispatch(loaderControl())
        reject(error)
       })
    })
  }
}

export const importOtxFileFromEgscc = (sessionId, projectId) => {
  return dispatch => {
    dispatch(loaderControl(true))
    return new Promise((resolve, reject) => {
      axios.post(`${protocol}//${origin}/api/egscc/tree/importssm?sessionId=${sessionId}&projectId=${projectId}`, {},
      getHeaders())
      .then(() => {
        dispatch(resetSsmList())
        dispatch(getProject(projectId))
        dispatch(getOtxFileModalTimeAction((new Date()).getTime()))
        dispatch(updateOtxFileListAction((new Date()).getTime()))
        dispatch(loaderControl())
        resolve()
      })
       .catch(error => {
         const errorMessage = parseError(error)
         dispatch(Notifications.error(errorMessage))
         errorHandle(error)
         dispatch(loaderControl())
         reject(error)
       })
    })
  }
}

export const updateOtxFile = (projectId, otxId, data, shouldUpdate = false) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${projectId}/otx/${otxId}`,
      data, getHeaders())
        .then(() => {
          dispatch(getProject(projectId))
          if (shouldUpdate) {
            dispatch(updatePseudoCodeList())
            dispatch(getOutlineTree(projectId, otxId, false, false))
          }
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          dispatch(loaderControl())
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const deleteOtxFile = id => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.put(`${protocol}//${origin}/api/projects/${id}`, getHeaders())
        .then(() => {
          dispatch(updateOtxFileListAction((new Date()).getTime()))
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const actions = {
  getOutlineTree,
  addOtxFile,
  updateOtxFile,
  deleteOtxFile,
  importOtxFileFromEgscc,
  removeOtxOrPackage,
  getOtxVariableList,
  resetSsmList,
  resetSsmElementListByType,
  getOtx
}

const ACTION_HANDLERS = {
  [GET_OUTLINE_TREE]: (state, action) => {
    return {
      ...state,
      outlineTree: action.data
    }
  },
  [SET_DEVICES_TAB_DATA]: (state, action) => {
    return {
      ...state,
      devicesDataTab: action.data
    }
  },
  [ADD_OTX_FILE]: (state, action) => {
    return {
      ...state,
      project: action.data
    }
  },
  [SET_VALIDITY_OPTION_LIST]: (state, action) => {
    return {
      ...state,
      otxVariableList: action.data
    }
  },
  [UPDATE_OTX_FILE_LIST]: (state, action) => {
    return {
      ...state,
      updateOtxFileListTime: action.data
    }
  },
  [SET_SSM_ELEMENT_LIST]: (state, action) => {
    return {
      ...state,
      ssmElementList: action.data
    }
  },
  [GET_OTX_MODAL_TIME]: (state, action) => {
    return {
      ...state,
      updateOtxFileModalTime: action.data
    }
  }
}

const initialState = {
  outlineTree: [],
  devicesDataTab: [],
  otxFile: null,
  updateotxFileListTime: 0,
  updateOtxFileModalTime: 0,
  ssmElementList: [],
  otxVariableList: []
}

export default function otxFileReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
