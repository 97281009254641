import SingleLineComponent from './SingleLineComponent.js'
import { connect } from 'react-redux'
import { setBreakPoint, removeBreakPoint, updateScroll } from '../../../../store/thunk/reducerList/procedure'
import { loaderControl } from '../../../../store/thunk/reducerList/loaderControl'
import { setSelectedContextViewId } from '../../../../store/thunk/reducerList/selectElement'

const mapDispatchToProps = {
  updateScroll,
  setBreakPoint,
  removeBreakPoint,
  loaderControl,
  setSelectedContextViewId
}

const mapStateToProps = state => ({
  reachedBreakPoint: state.procedure.reachedBreakPoint,
  isProcedureRunning: state.procedure.isProcedureRunning
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleLineComponent)
