import React from 'react'
import { Circle } from 'react-konva'
import { string, bool } from 'prop-types'

export const CircleNode = ({ type, isSelected }) => <>
  <Circle
    y={0}
    x={0}
    radius={15}
    fill={type === 'END' ? 'white' : '#666668'}
    stroke={isSelected ? 'green' : '#666668'}
    strokeWidth={1}
    shadowColor={isSelected ? 'green' : '#666668'}
    shadowBlur={isSelected ? 15 : 10}
    shadowOpacity={isSelected ? 1 : 0.3}
  />
  {type === 'END' &&
  <Circle
    y={0}
    x={0}
    radius={12}
    fill='#666668'
    stroke='#666668'
    strokeWidth={1}/>}
</>

CircleNode.propTypes = {
  type: string,
  isSelected: bool
}
