import { connect } from 'react-redux'
import BarComponent from './BarComponent.js'
import {
  setIsProcedureRunning,
  stopProcedure,
  setReachedBreakPoint,
  nextStep,
  setReachedNodeId
} from '../../../../store/thunk/reducerList/procedure'
import {
    setActiveConsoleIndex
} from '../../../../store/thunk/reducerList/selectElement'
import { validateDiagramOtxFile } from '../../../../store/thunk/reducerList/diagramValidation'
import {
  setDriver,
  setLogItems,
  setVariableWatch,
  resetAllLogs,
  activateCleanButton,
  setSutStatus
} from '../../../../store/thunk/reducerList/driver'
import { loaderControl } from '../../../../store/thunk/reducerList/loaderControl'
import { getProject } from '../../../../store/thunk/reducerList/project'

const mapDispatchToProps = {
    validateDiagramOtxFile,
    setIsProcedureRunning,
    setActiveConsoleIndex,
    setDriver,
    setLogItems,
    setVariableWatch,
    stopProcedure,
    nextStep,
    resetAllLogs,
    setSutStatus,
    loaderControl,
    getProject,
    setReachedBreakPoint,
    activateCleanButton,
    setReachedNodeId
}
const mapStateToProps = state => ({
    ssmId: state.selectElement.ssmId,
    isProcedureRunning: state.procedure.isProcedureRunning,
    activeConsoleIndex: state.selectElement.activeConsoleIndex,
    driverList: state.driver.driverList,
    driver: state.driver.driver,
    sutStatusItem: state.driver.sutStatusItem,
    variableWatchItem: state.driver.variableWatchItem,
    logItems: state.driver.logItems,
    isDisabledButtonClean: state.driver.isDisabledButtonClean
})

export default connect(mapStateToProps, mapDispatchToProps)(BarComponent)
