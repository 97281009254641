import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OverlayComponent from './components/OverlayComponent'
import BodyDiagramComponent from './components/BodyDiagramComponent'
class SingleDiagramElementComponent extends Component {
  constructor () {
    super()
    this.state = {}
  }

  static propTypes = {
    item: PropTypes.object,
    key: PropTypes.string,
    hasChild: PropTypes.number,
    children: PropTypes.any,
    addElement: PropTypes.func,
    addToEmptyElement: PropTypes.func,
    parent: PropTypes.any,
    openModal: PropTypes.func,
    selectItemHandler: PropTypes.func,
    toolBarHandler: PropTypes.func,
    params: PropTypes.object,
    elementWidth: PropTypes.number,
    onContextMenuHandler: PropTypes.func
  }

  handleRowClick (item, id) {
    this.setState({
      itemId: item.id,
      item
    })
    this.toggleClass()
  }

  toggleClass () {
    const currentState = this.state.active
    this.setState({ active: !currentState })
  }

  render () {
    const {
      item,
      children,
      hasChild,
      parent,
      key,
      addElement,
      addToEmptyElement,
      params,
      elementWidth,
      onContextMenuHandler
    } = this.props
    let blockWidth = 0
    switch (item.level) {
      case 0:
         blockWidth = elementWidth * 0.7
         break
      case 1:
        blockWidth = elementWidth * 0.65
        break
      case 2:
        blockWidth = elementWidth * 0.6
        break
    }
    return (
      <div
        onContextMenu={e => onContextMenuHandler(e, item, 'copycut')}
        style={{
          width: blockWidth,
          display: item.level <= 3 ? 'block' : 'none'
        }}>
        <OverlayComponent
          params={params}
          item={item}
          boxPosition='top'
          parent={parent}
          onContextMenuHandler={onContextMenuHandler}
          addElement={addElement}
          openModal={this.props.openModal} />
        <BodyDiagramComponent
          params={params}
          hasChild={hasChild}
          selectItemHandler={this.props.selectItemHandler}
          parent={parent}
          key={key}
          addToEmptyElement={addToEmptyElement}
          onContextMenuHandler={onContextMenuHandler}
          addElement={addElement}
          openModal={this.props.openModal}
          item={item}
          blockWidth={blockWidth}
          toolBarHandler={this.props.toolBarHandler} >
          {children}
        </BodyDiagramComponent>
        {item.position === item.elementLength - 1 && <OverlayComponent
          boxPosition='bottom'
          params={params}
          parent={parent}
          onContextMenuHandler={this.props.onContextMenuHandler}
          item={item}
          addElement={addElement}
          openModal={this.props.openModal} />}
      </div>
    )
  }
}

export default SingleDiagramElementComponent
