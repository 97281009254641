import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Item, animation } from 'react-contexify'
import { copyPasteList } from '../../../../../../constants/contextMenuLists'
import isEmpty from 'lodash/isEmpty'
import { idChanger, couldDrop } from '../../../../../../constants/common'
import { typesWithAbilityToAddMultipleElements } from '../../../../../../constants/ItemTypes'

const copyPasteMenu = ({ id, node, copyPasteActionHandler, option, copiedNodes, parent, boxPosition }) => {
  const disableItemHandler = (action) => {
    let isDisabled = true
    if (action === 'paste' && !isEmpty(copiedNodes)) {
      if (copiedNodes.length > 1) {
        isDisabled = parent !== null &&
        !typesWithAbilityToAddMultipleElements.includes(parent.type)
      } else {
        isDisabled = !couldDrop(copiedNodes[0], parent, { boxPosition, item: node })
      }
    } else if (action === 'copy') {
      isDisabled = false
    } else if (action === 'cut') {
      isDisabled = (node.type === 'If' || node.type === 'Try') && node.hasSiblings
    }
    return isDisabled
  }
  return (
    <Menu id={id} animation={animation.fade}>
      {copyPasteList.map(item => option.includes(item.action) &&
      <Item
        key={idChanger()}
        disabled={disableItemHandler(item.action)}
        id={`item${item.text}`}
        onClick={() => copyPasteActionHandler(node, item.action, boxPosition, true)}>
        {item.text}
      </Item>)}
    </Menu>
  )
}

copyPasteMenu.propTypes = {
  id: PropTypes.string,
  node: PropTypes.objec,
  copiedNodes: PropTypes.array,
  copyPasteActionHandler: PropTypes.func,
  option: PropTypes.string,
  parent: PropTypes.object,
  boxPosition: PropTypes.string
}

export const MemoizedCopyPasteMenu = React.memo(copyPasteMenu)
