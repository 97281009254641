export const topTreePanes = [
  {
    menuItemName: 'Project',
    propsName: 'project'
  },
  {
    menuItemName: 'Devices',
    propsName: 'devices'
  },
  {
    menuItemName: 'Executions',
    propsName: 'executions'
  }
]
