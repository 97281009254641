import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Popup } from 'semantic-ui-react'
import labels from '../../../../../public/labels.json'
import testIds from '../../../../../public/testIds.json'
import { nameRegex } from '../../../../store/addons'
import ClearSelect from '../../../Utilities/ClearSelect'

class NewProcedureComponent extends Component {
  constructor (props) {
    super()
    this.state = {
      name: (props.editItem && typeof props.editItem.name !== 'undefined' &&
       props.editItem.name !== null) ? props.editItem.name : '',
      specification: props.editItem && props.editItem.specification && props.editItem.specification !== null
      ? props.editItem.specification : '',
      visibility: (props.editItem && props.editItem.visibility && props.editItem.visibility !== null)
      ? props.editItem.visibility : 'PUBLIC',
      signature: (props.editItem && props.editItem.signature && props.editItem.signature !== null)
      ? props.editItem.signature : null,
      validFor: (props.editItem && props.editItem.validFor && props.editItem.validFor !== null &&
        typeof props.editItem.validFor !== 'undefined')
      ? props.editItem.validFor : null,
      nameError: false,
      canEditVisibility: true
    }
  }

  static propTypes = {
    // props from reducer
    getSignatures: PropTypes.func,
    signatures: PropTypes.array,
    getValidities: PropTypes.func,
    validities: PropTypes.array,
    // props from parent
    saveModal: PropTypes.func,
    closeModal: PropTypes.func,
    fileType: PropTypes.string,
    editItem: PropTypes.object,
    params: PropTypes.object
  }

  componentDidMount () {
    const { params, getSignatures, getValidities } = this.props
    getSignatures(params.projectId, params.otxFileId)
    getValidities(params.projectId, params.otxFileId)
    if (this.props.editItem &&
        this.props.editItem.name &&
        this.props.editItem.name.toLowerCase() === 'main') {
          this.setState({ canEditVisibility: false })
    }
  }

  handleChangeInput = (stateName, value) => {
    const change = { ...this.state }
    if (stateName === 'name' && value.toLowerCase() === 'main') {
      change.canEditVisibility = false
      change.visibility = 'PUBLIC'
    } else if (stateName === 'name' && value.toLowerCase() !== 'main') {
      change.canEditVisibility = true
    }
    change[stateName] = value
    if (stateName === 'name') {
      change.nameError = this.validateBlur('name', null, change.name)
    }
    this.setState(change)
  }

  validateBlur = (stateName, validationName, val) => {
    let value = val
    if (validationName === 'formValidation') {
      value = this.state[stateName]
    }
    const change = { ...this.state }
    const error = `${stateName}Error`
    change[error] = !nameRegex.test(value)
    if (validationName === 'formValidation') {
      this.setState(change)
      return change[error]
    } else {
      return change[error]
    }
  }

  saveData = () => {
    if (this.isFormValid()) {
      this.props.saveModal(
        this.props.fileType,
        {
          otx_name: this.state.name,
          otx_id: this.props.editItem.otx_id,
          otx_specification: this.state.specification,
          visibility: this.state.visibility,
          validFor: this.state.validFor,
          signature: this.state.signature,
          type: 'Procedure'
      })
    }
  }

  isFormValid = () => !this.validateBlur('name', 'formValidation')

  render () {
    const { inputs } = labels.modalPattern
    const {
      nameError,
      validFor
    } = this.state
    return (
      <div className='wizard__form-container'>
        <Form className='wizard__form'>
          <Form.Group>
            <div className='wizard__form-info'>
              <Popup
                position='top center'
                content={labels.hints.procedure.name}
                trigger={<Icon name='info' />} />
              <Form.Input
                id={testIds.newProcedureComponent.nameInput}
                label={inputs.name.label}
                placeholder={inputs.name.placeholder}
                value={this.state.name}
                onChange={(event, { value }) => this.handleChangeInput('name', value)}
                error={nameError}
                onBlur={() => this.validateBlur('name', 'formValidation')}
                className='wizard__form-input'>
              </Form.Input>
            </div>
          </Form.Group>
          <Form.Group>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.TextArea
                id={testIds.newProcedureComponent.descriptionTextArea}
                label={inputs.specification.label}
                value={this.state.specification}
                onChange={(event, { value }) => this.handleChangeInput('specification', value)}
                placeholder={inputs.specification.placeholder}
                className='wizard__form-input'/>
            </div>
          </Form.Group>
          <Form.Group>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                id={testIds.newProcedureComponent.visibilityDropDown}
                fluid
                disabled={!this.state.canEditVisibility}
                label={inputs.visibility.label}
                options={inputs.visibility.options}
                onChange={(event, { value }) => this.handleChangeInput('visibility', value)}
                placeholder={inputs.modifiedVariable.placeholder}
                value={this.state.visibility} />
            </div>
          </Form.Group>
          <Form.Group>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={testIds.newProcedureComponent.implementedSignatureDropDown}
                label={inputs.implementedSignature.label}
                options={this.props.signatures}
                onChange={(event, { value }) => this.handleChangeInput('signature', value)}
                placeholder={inputs.implementedSignature.placeholder}
                value={this.state.signature} />
            </div>
          </Form.Group>
          <Form.Group>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={testIds.newProcedureComponent.validForDropDown}
                label={inputs.validFor.label}
                options={this.props.validities}
                onChange={(event, { value }) => this.handleChangeInput('validFor', value)}
                placeholder={inputs.validFor.placeholder}
                value={validFor} />
              {validFor !== null && <ClearSelect handleClick={() => this.handleChangeInput('validFor', null)} />}
            </div>
          </Form.Group>
        </Form>
        <div className='wizard__footer'>
          <div>
            <Button
              id={testIds.newProcedureComponent.cancelButton}
              onClick={this.props.closeModal}
              content={labels.buttons.cancel} />
          </div>
          <div>
            <Button
              id={testIds.newProcedureComponent.saveButton}
              onClick={this.saveData}
              content={labels.buttons.save}
              positive />
          </div>
        </div>
      </div>
    )
  }
}

export default NewProcedureComponent
