import React, { useState, useEffect } from 'react'
import './UserDropDownComponent.scss'
import { func, bool } from 'prop-types'
import { Dropdown, Icon } from 'semantic-ui-react'
import isEmpty from 'lodash/isEmpty'
import labels from '../../../../../public/labels.json'

const { options } = labels.modalPattern.inputs.userDropDown

const UserDropDownComponent = ({ isLoggedIn, logOut, setUniversalModalObject }) => {
  const [user, setUser] = useState({})

  useEffect(() => {
    if (isLoggedIn) {
      const user = JSON.parse(localStorage.getItem('user'))
      user && setUser(user)
    }
  }, [isLoggedIn])

  const handleClick = type => {
    switch (type) {
      case 'settings':
        setUniversalModalObject(type)
      break
      case 'logout':
        logOut()
      break
    }
  }

  return (
    <div className='userDropDownComponent'>
      {isLoggedIn && !isEmpty(user) &&
      <Dropdown
        id='userDropDownComponent_dropDown'
        trigger={
          <span style={{ fontWeight: 900 }}>
            <Icon name='user circle' />
            {`${user.firstName} ${user.lastName}`}
          </span>}>
        <Dropdown.Menu>
          {options.map(option =>
            <Dropdown.Item
              key={option.key}
              text={option.text}
              icon={option.icon}
              onClick={() => handleClick(option.type)} />)}
        </Dropdown.Menu>
      </Dropdown>}
    </div>
)
}

UserDropDownComponent.propTypes = {
  logOut: func,
  setUniversalModalObject: func,
  isLoggedIn: bool
}

export default UserDropDownComponent
