import PageLayout from '../layouts/PageLayout/PageLayout'
import DiagramRoute from './Diagram'
import LoginRoute from './Login'
import NotFoundRoute from './NotFound'
import DashboardRoute from './Dashboard'

export const createRoutes = () => ({
  path        : '/',
  component   : PageLayout,
  indexRoute  :  { component: DashboardRoute },
  childRoutes : [
    {
      path: '/project(/:projectId)(/otx/:otxFileId)(/procedure/:procedureId)' +
      '(/element/:procedureElementId)(/selectedElement/:selectedElementId)',
      component : DiagramRoute
    },
    {
      path: '/dashboard',
      component : DashboardRoute
    },
    {
      path: '/login',
      component : LoginRoute
    },
    {
      path: '*',
      component: NotFoundRoute
    }
  ]
})

export default createRoutes
