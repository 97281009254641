import React, { Component } from 'react'
import { Form, Button, Icon, Segment } from 'semantic-ui-react'
import ClearSelect from '../../../../../Utilities/ClearSelect'
import PropTypes from 'prop-types'
import labels from '../../../../../../../public/labels.json'
import TermEditorComponent from '../../../../../TermEditor/TermEditorComponent'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import './MapModifiers.scss'
import {
  returnInitTermHandler,
  termEditorHandler,
  termCreatorHandler,
  returnInitMapTermHandler
} from '../../../../../../utilites/terms/terms'
import testIds from '../../../../../../../public/testIds.json'
import { checkIsValidListHandler } from '../../../../../../utilites/validation/validationHandlers'

class MapModifiers extends Component {
  state = {
    unionMapPattern: returnInitMapTermHandler('MapUnion', 'String', 'String'),
    putMapPattern: returnInitMapTermHandler('MapPut', 'String', 'String'),
    selectedMap: {},
    modifiedMap: '',
    editorTermArray: [],
    keyType: 'String',
    valueType: 'String',
    mapRemovePattern: returnInitTermHandler('String')
  }

  static propTypes = {
      optionsVariableList: PropTypes.array,
      validities: PropTypes.array,
      openAddVariable: PropTypes.func,
      onSelect: PropTypes.func,
      onInput: PropTypes.func,
      onValidate: PropTypes.func,
      validFor: PropTypes.string,
      modifiedMap: PropTypes.string,
      modifiedMapError: PropTypes.bool,
      changeHandler: PropTypes.func,
      item: PropTypes.object,
      variableList: PropTypes.array,
      editorTermArrayCreator: PropTypes.func,
      params: PropTypes.object,
      isSaveClicked: PropTypes.bool
  }

  componentDidMount () {
    if (!isEmpty(this.state.selectedMap)) {
      this.createTermEditorArray()
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.modifiedMap !== '' && nextProps.modifiedMap !== prevState.modifiedMap &&
        nextProps.optionsVariableList && nextProps.optionsVariableList.length &&
        nextProps.optionsVariableList.find(obj => obj.value === nextProps.modifiedMap)) {
          const selectedMap = nextProps.optionsVariableList.find(obj => obj.value === nextProps.modifiedMap)
          const keyType = selectedMap.object.obj.dataType.keyType.type
          const valueType = selectedMap.object.obj.dataType.valueType.type
      return {
        selectedMap,
        keyType,
        valueType,
        modifiedMap: nextProps.modifiedMap
      }
    }
    return null
  }

  componentDidUpdate (nextProps, prevState) {
    if (!isEqual(prevState.selectedMap, this.state.selectedMap)) {
      isEmpty(prevState.selectedMap)
        ? this.createTermEditorArray()
        : this.setState({ editorTermArray: [] })
    }
  }

  createTermEditorArray () {
    const { item } = this.props
    const { keyType, valueType } = this.state
    let editorTermArray = []
    let newTerm = {}
    switch (item.type) {
      case 'MapRemove':
        if (item.keys && item.keys.length) {
          cloneDeep(item.keys).forEach(el => {
            newTerm = termCreatorHandler(el, keyType)
            editorTermArray = [...editorTermArray, newTerm]
          })
        }
      break
      case 'MapUnion':
        if (item.otherMap && item.otherMap.length) {
          cloneDeep(item.otherMap).forEach(el => {
            newTerm = returnInitMapTermHandler(item.type, keyType, valueType, true)
            if (el.type === 'MapLiteral') {
              const items = el.items.map(term => ({
                ...term,
                key: termCreatorHandler(term.key, el.keyType.type),
                value: termCreatorHandler(term.value, el.valueType.type)
              }))
              newTerm = {
                ...newTerm,
                editionType: 'Literal',
                value: { ...el, items },
                term: el
              }
            } else if (el.type === 'MapValue') {
              newTerm = {
                ...newTerm,
                editionType: 'ValueOf',
                valueOf: el,
                term: el
              }
            } else {
              newTerm = {
                ...newTerm,
                editionType: 'AdvancedEdit',
                term: el
              }
            }
            editorTermArray = [...editorTermArray, newTerm]
          })
        }
      break
      case 'MapPut':
        if (item.item && item.item.length) {
          cloneDeep(item.item).forEach(el => {
            newTerm = {
              key: termCreatorHandler(el.key, keyType),
              value: termCreatorHandler(el.value, valueType)
            }
            editorTermArray = [...editorTermArray, newTerm]
          })
        }
      break
    }
    this.setState({ editorTermArray }, this.props.editorTermArrayCreator(editorTermArray))
  }

  termEditorFieldHandler = (stateName, value, propertyName, index, attrName) => {
    const tempArray = cloneDeep(this.state[stateName])
    if (this.props.item.type === 'MapUnion') {
      const isEditionType = propertyName === 'editionType'
      if (Array.isArray(index)) {
        const shouldChangeName = ['editionType', 'valueOf', 'term'].includes(propertyName)
        let newTerm = {}
        let state = ''
        let nestedName = ''

        if (shouldChangeName) {
          nestedName = 'value'
        } else {
          nestedName = propertyName
        }

        state = this.state[stateName][index[0]][nestedName].items[index[1]][attrName]

        newTerm = termEditorHandler(
          state,
          stateName,
          value,
          propertyName)

        tempArray[index[0]][nestedName].items[index[1]][attrName] = newTerm

        const isValid = checkIsValidListHandler(tempArray[index[0]][nestedName].items)
        tempArray[index[0]].isValid = isValid

        const newTermItems = this.createNewTermItems(tempArray[index[0]].value.items)
        tempArray[index[0]].term.items = newTermItems
      } else {
        const newTerm = termEditorHandler(this.state[stateName][index], stateName, value, propertyName)
        if (isEditionType && value === 'Literal') {
          const newTermItems = this.createNewTermItems(newTerm.value.items)
          newTerm.term.items = newTermItems
        }
        tempArray[index] = newTerm
      }
    } else if (this.props.item.type === 'MapPut') {
      const newTerm = termEditorHandler(this.state[stateName][index][attrName], stateName, value, propertyName)
      tempArray[index][attrName] = newTerm
    } else if (this.props.item.type === 'MapRemove') {
      const newTerm = termEditorHandler(this.state[stateName][index], stateName, value, propertyName)
      tempArray[index] = newTerm
    }
    this.setState({ [stateName]: tempArray },
      this.props.editorTermArrayCreator(tempArray))
  }

  createNewTermItems = items => items.map(item => ({ ...item, key: item.key.term, value: item.value.term }))

  addNewMap = () => {
    const editorTermArray = cloneDeep(this.state.editorTermArray)
    const { keyType, valueType } = this.state
    const { type } = this.props.item
    let newTerm = {}
    switch (type) {
      case 'MapRemove':
        newTerm = returnInitTermHandler(keyType)
      break
      case 'MapUnion':
      case 'MapPut':
        newTerm = returnInitMapTermHandler(type, keyType, valueType)
      break
    }
    editorTermArray.push(newTerm)
    this.setState({ editorTermArray }, this.props.editorTermArrayCreator(editorTermArray))
  }

  addNewMapItem = index => {
    const { keyType, valueType } = this.state

    const editorTermArray = cloneDeep(this.state.editorTermArray)
    const newItem = returnInitMapTermHandler('MapUnionItem', keyType, valueType)
    const newItems = [...this.state.editorTermArray[index].value.items, newItem]

    editorTermArray[index].value.items = newItems
    editorTermArray[index].isValid = false

    this.setState({ editorTermArray },
      this.props.editorTermArrayCreator(editorTermArray))
  }

  removeElement = index => {
    const editorTermArray = cloneDeep(this.state.editorTermArray)
    if (Array.isArray(index)) {
      editorTermArray[index[0]].value.items.splice(index[1], 1)
      editorTermArray[index[0]].term.items = this.createNewTermItems(editorTermArray[index[0]].value.items)
      const isValid = checkIsValidListHandler(editorTermArray[index[0]].value.items)
      editorTermArray[index[0]].isValid = isValid
    } else {
      editorTermArray.splice(index, 1)
    }
    this.setState({ editorTermArray },
      this.props.editorTermArrayCreator(editorTermArray))
  }

  render () {
    const {
      optionsVariableList,
      openAddVariable,
      validities,
      onValidate,
      validFor,
      onSelect,
      modifiedMap,
      modifiedMapError,
      item,
      isSaveClicked,
      variableList
    } = this.props
    const { inputs, term } = labels.modalPattern
    const { buttons } = labels
    const { selectedMap, editorTermArray } = this.state
    return (
      <React.Fragment>
        <Form.Group>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
                fluid
                id={testIds.actionWizard.validForDropDown}
                label={inputs.validFor.label}
                options={validities}
                onChange={(e, { value }) => onSelect('validFor', value)}
                placeholder={inputs.validFor.placeholder}
                value={validFor} />
            {validFor !== '' && <ClearSelect handleClick={() => onSelect('validFor', '')} />}
          </div>
        </Form.Group>
        <Form.Group className='wizard__form-col2'>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
                id={testIds.actionWizard.modifiedMapDropDown}
                error={modifiedMapError}
                onBlur={() => onValidate('modifiedMap')}
                fluid
                label={inputs.modifiedMap.label}
                placeholder={inputs.modifiedMap.placeholder}
                options={optionsVariableList}
                onChange={(e, { value }) => onSelect('modifiedMap', value)}
                value={modifiedMap} />
          </div>
          <Button
            id={testIds.actionWizard.addVariableButton}
            content={buttons.addVariable}
            onClick={() => openAddVariable(true)}/>
        </Form.Group>
        {!isEmpty(selectedMap) && !isEmpty(editorTermArray) &&
        editorTermArray.map((el, index) =>
          <Segment
            key={index}
            className='flex-container'
            style={{
              background: item.type === 'MapUnion' && el.editionType === 'Literal' ? 'white' : '#f9fafb',
              alignItems: 'flex-end',
              marginRight: 16
            }}>
            <TermEditorComponent
              changeHandler={this.termEditorFieldHandler}
              element={el}
              stateName='editorTermArray'
              item={item}
              labelsProp={term[item.type]}
              index={index}
              isSaveClicked={isSaveClicked}
              variableList={item.type !== 'MapRemove'
              ? variableList
              : variableList.filter(variable =>
                variable.obj.dataType ? variable.obj.dataType.type === this.state.keyType : false)}
              selectedMap={selectedMap}
              removeElement={this.removeElement}
              addNewMapItem={this.addNewMapItem}
            />
          </Segment>)}
        <div className='add-button'>
          {!isEmpty(selectedMap) && item.type !== 'MapClear' &&
          <Button
            content={term[item.type].button}
            positive
            onClick={this.addNewMap} />}
        </div>
      </React.Fragment>
    )
  }
}

export default MapModifiers
