import _ from 'lodash'

export const createListModifier = (type, result, keys) => {
    result.validity = keys.validFor !== '' ? keys.validFor : null
    result.list = {
            type: 'ListVariable',
            name: keys.modifiedList
        }
    if (type === 'ListRemove') {
        result.index = keys.indexTerm.term
        result.count = keys.countTerm.term
    } else if (type === 'ListAppend') {
        const item = []
        !_.isEmpty(keys.editorTermArray) &&
        keys.editorTermArray.forEach(el => item.push(el.term))
        result.item = item
    } else if (type === 'ListInsert') {
        result.index = keys.indexTerm.term
        const item = []
        !_.isEmpty(keys.editorTermArray) &&
        keys.editorTermArray.forEach(el => item.push(el.term))
        result.item = item
    } else if (type === 'ListConcatenate') {
        const item = []
        !_.isEmpty(keys.editorTermArray) &&
        keys.editorTermArray.forEach(el => item.push(el.term))
        result.otherList = item
    }
    return result
}
