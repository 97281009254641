import React, { useState, useEffect } from 'react'
import { object, func } from 'prop-types'
import { Modal, Button, Icon } from 'semantic-ui-react'
import { iconSetter } from '../../constants/common'
import BeforePasteComponent from './components/BeforePasteComponent'
import SettingsComponent from './components/SettingsComponent'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

const UniversalModalComponent = ({
  universalModalObject = {},
  beforePasteObject,
  clearUniversalModalObject,
  pasteDiagramElemets,
  pseudoCodeAttributes,
  setPseudoCodeAttributes
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [attributesObject, setAttributesObject] = useState({})

  useEffect(() => {
    if (!isEmpty(pseudoCodeAttributes) && !isEqual(pseudoCodeAttributes, attributesObject)) {
      setAttributesObject(pseudoCodeAttributes)
    }
  }, [pseudoCodeAttributes])

  const clickHandler = name => {
    switch (name) {
      case 'Cancel':
        clearUniversalModalObject()
      break
      case 'Paste':
        if (beforePasteObject.url && beforePasteObject.data && beforePasteObject.params) {
          const { url, data, params } = beforePasteObject
          setIsButtonDisabled(true)
          pasteDiagramElemets(url, data, params)
          .then(() => {
            setIsButtonDisabled(false)
            clearUniversalModalObject()
          })
        }
      break
      case 'Save':
        setIsButtonDisabled(true)
        setPseudoCodeAttributes(attributesObject).then(() => {
          setIsButtonDisabled(false)
          clearUniversalModalObject()
        })
      break
      default:
        clearUniversalModalObject()
    }
  }

  const handleChangeSettings = (value, key) => {
    const tempObj = cloneDeep(attributesObject)
    tempObj[key] = value
    if (value === '' && isButtonDisabled === false) {
      setIsButtonDisabled(true)
    } else if (isButtonDisabled && value !== '') {
      setIsButtonDisabled(false)
    }
    setAttributesObject(tempObj)
  }

  return (
    <Modal
      size='small'
      open={!isEmpty(universalModalObject)}>
      <div className='universalModalComponent__header'>
        {universalModalObject.type !== 'settings'
        ? <span className={`${iconSetter(universalModalObject.type || '')}`} />
        : <Icon circular inverted size='large' name={universalModalObject.type} />}
        <div className='universalModalComponent__header-text'>
          {universalModalObject.title && <div>{universalModalObject.title}</div>}
          {universalModalObject.subtitle && <div>{universalModalObject.subtitle}</div>}
        </div>
      </div>
      <div className='universalModalComponent__content' style={{ maxHeight: window.innerHeight * 0.5 }}>
        {universalModalObject.type === 'pasteValidation' &&
          <BeforePasteComponent
            logs={beforePasteObject.logs || []}
            descriptions={universalModalObject.descriptions || []} />}
        {universalModalObject.type === 'settings' &&
          <SettingsComponent
            attributesObject={attributesObject}
            handleChangeSettings={handleChangeSettings}
            description={universalModalObject.description || ''} />}
      </div>
      <div className='universalModalComponent__footer'>
        {universalModalObject.buttons && universalModalObject.buttons.map(name =>
          <Button
            key={name}
            id={`universalModal__button-${name}`}
            positive={name !== 'Cancel'}
            disabled={name !== 'Cancel' && isButtonDisabled}
            content={name}
            onClick={() => clickHandler(name)}
          />)}
      </div>
    </Modal>
  )
}

UniversalModalComponent.propTypes = {
  universalModalObject: object,
  beforePasteObject: object,
  pseudoCodeAttributes: object,
  clearUniversalModalObject: func,
  pasteDiagramElemets: func,
  setPseudoCodeAttributes: func
}

export default UniversalModalComponent
