import './semantic/semantic.less'
import React from 'react'
import ReactDOM from 'react-dom'
import createStore from './store/createStore'
import './styles/main.scss'
require('event-source-polyfill/src/eventsource')

// Store Initialization
// ------------------------------------
const store = createStore(window.__INITIAL_STATE__)
window.store = store
// Render Setup
// ------------------------------------
const MOUNT_NODE = document.getElementById('root')

const App = require('./components/App').default
const routes = require('./routes/index').default(store)

ReactDOM.render(
  <App store={store} routes={routes} />,
  MOUNT_NODE
)
