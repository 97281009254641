import { connect } from 'react-redux'
import LoginComponent from './components/LoginComponent.js'
import {
    logIn,
    logOut
} from '../../store/thunk/reducerList/auth'

const mapDispatchToProps = {
    logIn,
    logOut
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)
