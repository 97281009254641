import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Menu, Tab } from 'semantic-ui-react'
import {
  colorsAndIconsSetter,
  setSpecificationTitle,
  isActionRealisation,
  isCondition,
  isLoop,
  isDiagramEnded
} from '../../constants/common'
import ActionWizard from './components/ActionWizard'
import ConditionalWizard from './components/ConditionalWizard'
import LoopWizard from './components/LoopWizard'
import GroupParallelMutexGroupWizard from './components/GroupParallelMutexGroupWizard'
import EndWizard from './components/EndWizard'
import HandlerWizard from './components/HandlerWizard'
import labels from '../../../public/labels.json'

export default class WizardPattern extends Component {
  constructor () {
    super()
    this.state = {
      modalOpen: false,
      tabBasic: true,
      tabTechnical: false,
      colorsAndIcons: {}
    }
  }

  static propTypes = {
    closeModal: PropTypes.func,
    isOpen: PropTypes.bool,
    openModal: PropTypes.func,
    modalItemToAdd: PropTypes.object,
    saveModal: PropTypes.func,
    params: PropTypes.object
  }

  componentDidMount () {
    const { modalItemToAdd } = this.props
    this.setState({ colorsAndIcons:  colorsAndIconsSetter(modalItemToAdd) })
  }

  handleWizardFormClassName = itemType => {
    if (
      itemType !== 'Else' &&
      itemType !== 'Lane' &&
      itemType !== 'Try' &&
      itemType !== 'Finally'
      ) {
          return 'form'
    } return 'modal'
  }

  renderBasicFields = item => {
      const {
        modalItemToAdd,
        saveModal,
        closeModal
      } = this.props
    if (item.type === 'Action' || isActionRealisation(item.type)) {
      return (
        <Form className='wizard__form'>
          <ActionWizard
            isFirstTabActive={this.state.tabBasic}
            onTabChange={this.onTabChange}
            item={item}
            modalItemToAdd={modalItemToAdd}
            saveModal={saveModal}
            params={this.props.params}
            closeModal={closeModal} />
        </Form>
      )
    } else if (item.type === 'Branch' || isCondition(item.type)) {
      return (
        <Form
          className={`wizard__${this.handleWizardFormClassName(item.type)}`}>
          <ConditionalWizard
            isFirstTabActive={this.state.tabBasic}
            onTabChange={this.onTabChange}
            item={item}
            params={this.props.params}
            modalItemToAdd={modalItemToAdd}
            saveModal={saveModal}
            closeModal={closeModal} />
        </Form>
      )
    } else if (item.type === 'Loop' || isLoop(item.type)) {
      return (
        <Form className='wizard__form'>
          <LoopWizard
            isFirstTabActive={this.state.tabBasic}
            onTabChange={this.onTabChange}
            item={item}
            params={this.props.params}
            modalItemToAdd={modalItemToAdd}
            saveModal={saveModal}
            closeModal={closeModal} />
        </Form>
      )
    } else if (
      item.type === 'Group' ||
      item.type === 'Parallel' ||
      item.type === 'MutexGroup' ||
      item.type === 'GroupElement' ||
      item.type === 'Lane'
      ) {
      return (
        <Form
          className={`wizard__${this.handleWizardFormClassName(item.type)}`}>
          <GroupParallelMutexGroupWizard
            isFirstTabActive={this.state.tabBasic}
            onTabChange={this.onTabChange}
            item={item}
            modalItemToAdd={modalItemToAdd}
            saveModal={saveModal}
            params={this.props.params}
            closeModal={closeModal} />
        </Form>
      )
    } else if (
      item.type === 'Handler' ||
      item.type === 'CatchingFlow' ||
      item.type === 'Try' ||
      item.type === 'Finally'
      ) {
      return (
        <Form
          className={`wizard__${this.handleWizardFormClassName(item.type)}`}>
          <HandlerWizard
            isFirstTabActive={this.state.tabBasic}
            onTabChange={this.onTabChange}
            item={item}
            params={this.props.params}
            modalItemToAdd={modalItemToAdd}
            saveModal={saveModal}
            closeModal={closeModal} />
        </Form>
      )
    } else if (isDiagramEnded(item.type)) {
      return (
        <Form className='wizard__form'>
          <EndWizard
            item={item}
            params={this.props.params}
            modalItemToAdd={modalItemToAdd}
            saveModal={saveModal}
            closeModal={closeModal} />
        </Form>
      )
    }
  }

  renderTechnicalFields = children => {
    let item
    children.length !== 2 ? item = children[0] : item = children[1]
    const {
      modalItemToAdd,
      saveModal,
      closeModal
    } = this.props
        if (isActionRealisation(item.type)) {
        return (
          <Form className='wizard__form'>
            <ActionWizard
              isFirstTabActive={this.state.tabBasic}
              onTabChange={this.onTabChange}
              item={item}
              modalItemToAdd={modalItemToAdd}
              saveModal={saveModal}
              params={this.props.params}
              closeModal={closeModal} />
          </Form>
        )
      } else if (item.type === 'If') {
          return (
            <Form className='wizard__form'>
              <ConditionalWizard
                isFirstTabActive={this.state.tabBasic}
                onTabChange={this.onTabChange}
                item={item}
                params={this.props.params}
                modalItemToAdd={modalItemToAdd}
                saveModal={saveModal}
                closeModal={closeModal} />
            </Form>
          )
        } else if (
          item.type === 'ForLoop' ||
          item.type === 'ForEachLoop' ||
          item.type === 'WhileLoop'
          ) {
          return (
            <Form className='wizard__form'>
              <LoopWizard
                isFirstTabActive={this.state.tabBasic}
                onTabChange={this.onTabChange}
                item={item}
                params={this.props.params}
                modalItemToAdd={modalItemToAdd}
                saveModal={saveModal}
                closeModal={closeModal} />
            </Form>
          )
        } else if (
          item.type === 'GroupElement' ||
          item.type === 'Lane'
          ) {
          return (
            <Form
              className={`wizard__${this.handleWizardFormClassName(item.type)}`}>
              <GroupParallelMutexGroupWizard
                isFirstTabActive={this.state.tabBasic}
                onTabChange={this.onTabChange}
                item={item}
                params={this.props.params}
                modalItemToAdd={modalItemToAdd}
                saveModal={saveModal}
                closeModal={closeModal} />
            </Form>
          )
        } else if (
          item.type === 'Handler' ||
          item.type === 'Try' ||
          item.type === 'Finally' ||
          item.type === 'CatchingFlow'
          ) {
          return (
            <Form
              className={`wizard__${this.handleWizardFormClassName(item.type)}`}>
              <HandlerWizard
                isFirstTabActive={this.state.tabBasic}
                onTabChange={this.onTabChange}
                item={item}
                params={this.props.params}
                modalItemToAdd={modalItemToAdd}
                saveModal={saveModal}
                closeModal={closeModal} />
            </Form>
          )
        }
  }

   renderPanes = () => [
    {
      menuItem: (
        <Menu.Item
          key={1}
          // onClick={() => this.onTabChange(true, false)}
          active={this.state.tabBasic}
          className='menu__item'
          style={{ cursor: 'default' }}
        >
          <div key={2} className='menu__item-number'>
            1
          </div>
          <div key={3} className='menu__item-heading'>
            {labels.modalPattern.panes.defineANew} {this.props.modalItemToAdd.name}
          </div>
        </Menu.Item>
      )
    },
    {
      menuItem: (
        <Menu.Item
          key={5}
          // onClick={() => this.onTabChange(false, true)}
          active={this.state.tabTechnical}
          style={{ cursor: 'default' }}
          className='menu__item'
        >
          <div key={6} className='menu__item-number'>
            2
          </div>
          <div className='menu__item-heading'>
            {setSpecificationTitle(this.props.modalItemToAdd)}
          </div>
        </Menu.Item>
      )
    }
  ]

onTabChange = (tabBasic, tabTechnical) => this.setState({ tabBasic, tabTechnical })

shouldRenderNoFields = item => {
 if (item.type === 'Lane') {
    return true
  }
  return false
}

splitCamelCaseString (string) {
  return string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
}

handleWizardTitle (item) {
 if (item.children.length === 0) {
   const name = this.splitCamelCaseString(item.type)
   return (<React.Fragment>
     <h1>{name}</h1>
     <p>{setSpecificationTitle(this.props.modalItemToAdd)}</p>
   </React.Fragment>)
 } else if (item.children.length === 2) {
   return (<h1>{item.name} &gt;
     {item.children[0].name} &gt;
     {item.children[1].name}</h1>)
 } else if (item.children.length === 1) {
  return <h1>{item.name} &gt; {item.children[0].name}</h1>
 }
}

render () {
    const { closeModal, modalItemToAdd } = this.props
    const { colorsAndIcons, tabBasic } = this.state
    return (
      <Modal
        open={true}
        closeOnDimmerClick={false}
        onClose={closeModal}
        className='wizard__container'
        centered={true}>
        <div className='wizard__header'>
          <div className='wizard__header-content'>
            <div className='wizard__header-logo'>
              <span className={colorsAndIcons.icon} />
            </div>
            <div className='wizard__header-text'>
              {this.handleWizardTitle(modalItemToAdd)}
            </div>
          </div>
        </div>
        { !this.shouldRenderNoFields(modalItemToAdd) && <React.Fragment>
          {modalItemToAdd.children[0] && <Tab menu={{ pointing: true }} className='menu' panes={this.renderPanes()} />}
          {tabBasic ? this.renderBasicFields(modalItemToAdd) : this.renderTechnicalFields(modalItemToAdd.children)}
        </React.Fragment>}
        { this.shouldRenderNoFields(modalItemToAdd) && <React.Fragment>
          {this.renderBasicFields(modalItemToAdd)}
        </React.Fragment>}
      </Modal>
    )
  }
}
