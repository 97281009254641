import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, Popup } from 'semantic-ui-react'
import { conditionalStrucureCreator } from '../../../../constants/itemStructureCreator'
import labels from '../../../../../public/labels.json'
import { nameRegex } from '../../../../store/addons'
import TermEditorComponent from '../../../TermEditor/TermEditorComponent'
import testIds from '../../../../../public/testIds.json'
import {
  returnInitTermHandler,
  termEditorHandler,
  termCreatorHandler
} from '../../../../utilites/terms/terms'

export default class ConditionalWizard extends Component {
  constructor (props) {
    super()
    this.state = {
      basicName: (props.modalItemToAdd && props.modalItemToAdd.otx_name &&
      props.modalItemToAdd.type === 'Branch') ? props.modalItemToAdd.otx_name : '',
      basicSpecification: (props.modalItemToAdd && props.modalItemToAdd.otx_specification &&
      props.modalItemToAdd.type === 'Branch')
      ? props.modalItemToAdd.otx_specification : '',
      technicalName: (props.modalItemToAdd && props.modalItemToAdd.otx_name &&
      props.modalItemToAdd.type !== 'Branch') ? props.modalItemToAdd.otx_name : '',
      technicalSpecification: (props.modalItemToAdd && props.modalItemToAdd.otx_specification &&
      props.modalItemToAdd.type !== 'Branch')
      ? props.modalItemToAdd.otx_specification : '',
      basicNameError: false,
      technicalNameError: false,
      parameter: returnInitTermHandler('Boolean'),
      isSaveClicked: false
    }
  }

  static propTypes = {
    modalItemToAdd: PropTypes.object.isRequired,
    saveModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    variableList: PropTypes.array.isRequired,
    getVariableList: PropTypes.func.isRequired,
    onTabChange: PropTypes.func,
    isFirstTabActive: PropTypes.bool,
    params: PropTypes.object
  }

  componentDidMount = () => {
    // getting all variables and parameters to valueOf
    const { params, getVariableList, item } = this.props
    if (typeof params.projectId !== 'undefined' &&
        typeof params.otxFileId !== 'undefined' &&
        typeof params.procedureId !== 'undefined') {
      getVariableList(params.projectId, params.otxFileId, params.procedureId)
    }
    if ((item.type === 'If' || item.type === 'ElseIf') && item.condition) {
      const parameter = termCreatorHandler(item.condition, 'Boolean')
      this.setState({
        parameter
      })
    }
  }

  handleChangeInput = (stateName, value) => {
    this.setState({
      [stateName]: value
    }, () => {
      if (stateName === 'basicName' || stateName === 'technicalName') {
        this.validateBlur(stateName)
      }
    })
  }

  validateBlur = (stateName, validationName) => {
    const error = stateName === 'basicName' || stateName === 'technicalName'
    ? !nameRegex.test(this.state[stateName])
    : !this.state[stateName]

    this.setState({ [`${stateName}Error`]: error })

    if (validationName === 'formValidation') return error
  }

  isFormValid = itemType => {
    if (itemType === 'Else') {
        return true
    } else if (itemType === 'Branch') {
        return !this.validateBlur('basicName', 'formValidation')
    } else if (itemType === 'If' || itemType === 'ElseIf') {
        return (!this.validateBlur('technicalName', 'formValidation')) &&
          this.state.parameter.isValid
    }
  }

  saveModal = () => {
    const { saveModal, modalItemToAdd } = this.props
    this.setState({ isSaveClicked: true }, () => {
      if (this.isFormValid(modalItemToAdd.type) && (modalItemToAdd.children[0]
      ? this.isFormValid(modalItemToAdd.children[0].type) : true)) {
          const modifiedModalItemToAdd = conditionalStrucureCreator(modalItemToAdd, this.state)
          saveModal(modifiedModalItemToAdd)
      }
    })
  }

  termEditorFieldHandler = (stateName, value, propertyName) => {
    const newTerm = termEditorHandler(this.state[stateName], stateName, value, propertyName)
    this.setState({ [stateName]: newTerm })
  }

  renderButtons = () => {
    const { buttons } = labels
    if (this.props.modalItemToAdd.isEdit || !this.props.modalItemToAdd.children.length) {
      return (
        <div>
          <Button
            id={testIds.conditionalWizard.finishButton}
            onClick={() => this.saveModal()}
            content={buttons.finish}
            positive />
        </div>
      )
    } else {
      return (
        <div>
          {this.props.isFirstTabActive
          ? <Button
              id={testIds.conditionalWizard.nextButton}
              disabled={this.state.basicNameError}
              onClick={() => !this.validateBlur('basicName', 'formValidation') && this.props.onTabChange(false, true)}
              content={buttons.next} />
          : <Button
              id={testIds.conditionalWizard.previousButton}
              onClick={() => this.props.onTabChange(true, false)}
              content={buttons.previous} />}
          {(!this.props.isFirstTabActive || !this.props.modalItemToAdd.children.length) &&
          <Button
            id={testIds.conditionalWizard.finishButton}
            onClick={() => this.saveModal()}
            content={buttons.finish}
            positive />}
        </div>)
    }
  }

  render () {
    const {
      item,
      closeModal
    } = this.props
    const {
      basicName,
      technicalName,
      basicSpecification,
      technicalSpecification,
      basicNameError,
      technicalNameError
    } = this.state
    const { inputs } = labels.modalPattern
    const { buttons, modalPattern, hints } = labels
    return (
      <React.Fragment>
        <div className='wizard__form-container'>
          <Form.Group className='wizard__form'>
            {item.type === 'Branch' && <React.Fragment>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Popup
                    position='top center'
                    content={hints.diagramModal.basicName}
                    trigger={<Icon name='info' />} />
                  <Form.Input
                    id={testIds.conditionalWizard.nameInput}
                    error={basicNameError}
                    onBlur={() => this.validateBlur('basicName')}
                    label={inputs.name.label}
                    placeholder={inputs.name.placeholder}
                    value={basicName}
                    type='text'
                    onChange={(event, { value }) => this.handleChangeInput('basicName', value)}
                    className='wizard__form-input' />
                </div>
              </Form.Group>
              <Form.Group>
                <div className='wizard__form-info'>
                  <Icon name='info' className='info-hidden' />
                  <Form.TextArea
                    id={testIds.conditionalWizard.descriptionTextArea}
                    label={inputs.specification.label}
                    onChange={(event, { value }) => this.handleChangeInput('basicSpecification', value)}
                    placeholder={inputs.specification.placeholder}
                    value={basicSpecification}
                    type='text'
                    className='wizard__form-input' />
                </div>
              </Form.Group>
            </React.Fragment>}
            {(item.type === 'If' ||
              item.type === 'ElseIf') && <React.Fragment>
                <Form.Group>
                  <div className='wizard__form-info'>
                    <Icon name='info' className='info-hidden' />
                    <Form.Input
                      id={testIds.conditionalWizard.technicalNameInput}
                      error={technicalNameError}
                      onBlur={() => this.validateBlur('technicalName')}
                      label={inputs.name.label}
                      placeholder={inputs.name.placeholder}
                      value={technicalName}
                      type='text'
                      onChange={(event, { value }) => this.handleChangeInput('technicalName', value)}
                      className='wizard__form-input' />
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className='wizard__form-info'>
                    <Icon name='info' className='info-hidden' />
                    <Form.TextArea
                      id={testIds.conditionalWizard.technicalDescriptionTextArea}
                      label={inputs.specification.label}
                      onChange={(event, { value }) => this.handleChangeInput('technicalSpecification', value)}
                      placeholder={inputs.specification.placeholder}
                      value={technicalSpecification}
                      className='wizard__form-input' />
                  </div>
                </Form.Group>
                <TermEditorComponent
                  changeHandler={this.termEditorFieldHandler}
                  isSaveClicked={this.state.isSaveClicked}
                  element={this.state.parameter}
                  stateName='parameter'
                  item={item}
                  index={null}
                  variableList={this.props.variableList.filter(variable =>
                    variable.obj.dataType ? variable.obj.dataType.type === 'Boolean' : false)}
                />
              </React.Fragment>}
            {item.type === 'Else' && <React.Fragment>
              <p className='modalcomponent__body-warning'>
                {modalPattern.branch.modalWarning}
              </p></React.Fragment>}
            <div className='wizard__footer'>
              <div>
                <Button
                  id={testIds.conditionalWizard.cancelButton}
                  onClick={closeModal}
                  content={buttons.cancel} />
              </div>
              {this.renderButtons()}
            </div>
          </Form.Group>
        </div>
      </React.Fragment>
    )
  }
}
