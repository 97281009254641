import { connect } from 'react-redux'
import UserDropDownComponent from './UserDropDownComponent.js'
import { logOut } from '../../../../store/thunk/reducerList/auth'
import { setUniversalModalObject } from '../../../../store/thunk/reducerList/universalModal'

const mapDispatchToProps = { logOut, setUniversalModalObject }

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn
})

export default connect(mapStateToProps, mapDispatchToProps)(UserDropDownComponent)
