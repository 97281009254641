import React, { useState, useEffect } from 'react'
import { element, bool, array, object } from 'prop-types'
import './PageLayout.scss'
import { Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import HeaderComponent from '../../components/HeaderComponent'
import LoginComponent from '../../components/LoginComponent'
import WrapperTreeComponent from '../../components/WrapperTreeComponent'
import EditorConsoleComponent from '../../components/EditorConsoleComponent'
import DashboardComponent from '../../components/DashboardComponent'
import NotFound from '../../routes/NotFound'
import UniversalModalComponent from '../../components/UniversalModalComponent'
import Notifications from 'react-notification-system-redux'
import isEmpty from 'lodash/isEmpty'
import PrivacyPolicyPopup from '../../components/PrivacyPolicyPopup'
import { hotjar } from 'react-hotjar'

const { hostname } = window.location
const isRecordingAllowProp = localStorage.getItem('isRecordingAllow')

const PageLayout = ({
  children,
  params,
  isLoggedIn,
  notifications,
  isActiveLoader,
  universalModalObject
}) => {
  const [isRecordingAllow, setIsRecordingAllow] = useState(isRecordingAllowProp === 'true')
  const [isOpenPrivacyPolicyPopup, setIsOpenPrivacyPolicyPopup] = useState(
    isRecordingAllowProp === null &&
    (hostname === 'dev.itti.com.pl' || hostname === 'atena.itti.com.pl' || hostname === 'localhost')
  )

  useEffect(() => {
    if (isRecordingAllow) {
      if (hostname === 'dev.itti.com.pl' || hostname === 'localhost') {
        hotjar.initialize(2375600, 6)
      } else if (hostname === 'atena.itti.com.pl') {
        hotjar.initialize(2373547, 6)
      }
    }
  }, [isRecordingAllow])

  const privacyPolicyHandler = isAllow => {
    setIsRecordingAllow(isAllow)
    localStorage.setItem('isRecordingAllow', isAllow)
    setIsOpenPrivacyPolicyPopup(false)
  }

  const renderContentHandler = () => {
    if (window.location.pathname.includes('project') &&
        !isEmpty(params) &&
        typeof params.projectId !== 'undefined') {
      return (
        <React.Fragment>
          <HeaderComponent params={params} />
          <div className='main-container'>
            <div className='left-container'>
              <WrapperTreeComponent params={params}/>
            </div>
            <div className='right-container'>
              <div className='route-container'>
                {children}
              </div>
              <div className='bottom-panel-container'>
                <div className='console-attributes-container'>
                  <EditorConsoleComponent
                    params={params}
                    position='left' />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>)
    } else if (window.location.pathname === '/dashboard') {
      return (
        <React.Fragment>
          <HeaderComponent params={params} />
          <div className='main-container'>
            <DashboardComponent />
          </div>
        </React.Fragment>)
    } else {
      return <NotFound isLoggedIn={isLoggedIn} />
    }
  }

  return (
    <div className='max-size'>
      {isLoggedIn
        ? renderContentHandler()
        : window.location.pathname === '/'
          ? <LoginComponent />
          : <NotFound isLoggedIn={isLoggedIn} />}
      <Loader
        style={{ zIndex: 9999 }}
        size='huge'
        active={isActiveLoader} />
      <Notifications
        notifications={notifications} />
      {(!isEmpty(universalModalObject)) &&
        <UniversalModalComponent
          universalModalObject={universalModalObject}/>}
      {isOpenPrivacyPolicyPopup &&
        <PrivacyPolicyPopup
          privacyPolicyHandler={privacyPolicyHandler}
          isOpenProp={isOpenPrivacyPolicyPopup} />}
    </div>
  )
}

PageLayout.propTypes = {
  children: element.isRequired,
  isLoggedIn: bool,
  notifications: array,
  isActiveLoader: bool,
  params: object,
  universalModalObject: object
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  notifications: state.notifications,
  isActiveLoader: state.loaderControl.isActiveLoader,
  universalModalObject: state.universalModal.universalModalObject
})

export default connect(mapStateToProps)(PageLayout)
