import React from 'react'
import { Form, Icon, Popup, Button } from 'semantic-ui-react'
import ClearSelect from '../../../../../Utilities/ClearSelect'
import PropTypes from 'prop-types'
import labels from '../../../../../../../public/labels.json'
import TermEditorComponent from '../../../../../TermEditor/TermEditorComponent'
import './LogElements.scss'
import testIds from '../../../../../../../public/testIds.json'
import { messageTypeOptions } from '../../../../../../constants/optionsLists'

const LogElements = ({
  validities,
  onSelect,
  validFor,
  severityLevelOptions,
  severityLevel,
  isSaveClicked,
  item,
  termEditorFieldHandler,
  messageTerm,
  messageOptionalTerm,
  variableList,
  optionsTerm,
  defaultTerm,
  resultError,
  validateBlur,
  initialValueTerm,
  instructionTerm,
  openAddVariable,
  titleTerm,
  restrictionTerm,
  messageType,
  result
}) => {
  const { inputs } = labels.modalPattern
  const { buttons } = labels
  const lowerCaseType = `${item.type[0].toLowerCase()}${item.type.slice(1)}`
  const popupResultText = labels.hints.diagramModal[lowerCaseType]
    ? labels.hints.diagramModal[lowerCaseType].result || null
    : null
  return (
    <React.Fragment>
      <Form.Group>
        <div className='wizard__form-info'>
          <Icon name='info' className='info-hidden' />
          <Form.Group>
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                label={inputs.validFor.label}
                id={testIds.actionWizard.validForDropDown}
                options={validities}
                onChange={(e, { value }) => onSelect('validFor', value)}
                placeholder={inputs.validFor.placeholder}
                value={validFor} />
              {validFor !== '' && <ClearSelect handleClick={() => onSelect('validFor', '')} />}
            </div>
          </Form.Group>
          {item.type === 'WriteLog' &&
          <React.Fragment>
            <Form.Group>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Select
                  fluid
                  label={inputs.severityLevel.label}
                  id={testIds.actionWizard.severityLevelDropDown}
                  options={severityLevelOptions}
                  onChange={(e, { value }) => onSelect('severityLevel', value)}
                  placeholder={inputs.severityLevel.placeholder}
                  value={severityLevel} />
              </div>
            </Form.Group>
            <div className='term-wrapper'>
              <div>
                <TermEditorComponent
                  changeHandler={termEditorFieldHandler}
                  isSaveClicked={isSaveClicked}
                  element={messageTerm}
                  stateName='messageTerm'
                  item={item}
                  index={null}
                  labelsProp={inputs.message}
                  variableList={variableList.filter(variable =>
                    variable.obj.dataType
                    ? variable.obj.dataType.type === messageTerm.type
                    : false)} />
              </div>
              <Button
                id={testIds.actionWizard.addVariableButton}
                content={buttons.addVariable}
                onClick={() => openAddVariable(true)} />
            </div>
            <Form.Group>
              <div className='wizard__form-info'>
                <Popup
                  trigger={<Icon name='info' className='info' />}
                  position='bottom right'
                  content={inputs.target.info} />
                <Form.Input
                  disabled
                  id={testIds.actionWizard.targetInput}
                  label={inputs.target.label}
                  placeholder={inputs.target.placeholder}
                  type='text'
                  className='wizard__form-input' />
              </div>
            </Form.Group>
          </React.Fragment>}
          {item.type.includes('Dialog') &&
          <React.Fragment>
            <TermEditorComponent
              changeHandler={termEditorFieldHandler}
              isSaveClicked={isSaveClicked}
              element={titleTerm}
              stateName='titleTerm'
              item={item}
              index={null}
              labelsProp={inputs.title}
              variableList={variableList.filter(variable =>
                variable.obj.dataType
                ? variable.obj.dataType.type === titleTerm.type
                : false)} />
            <TermEditorComponent
              changeHandler={termEditorFieldHandler}
              isSaveClicked={isSaveClicked}
              element={messageOptionalTerm}
              stateName='messageOptionalTerm'
              item={item}
              index={null}
              labelsProp={inputs.messageOptional}
              variableList={variableList.filter(variable =>
                variable.obj.dataType
                ? variable.obj.dataType.type === messageTerm.type
              : false)} />
          </React.Fragment>}
          {item.type === 'ChoiceDialog' &&
          <React.Fragment>
            <TermEditorComponent
              changeHandler={termEditorFieldHandler}
              isSaveClicked={isSaveClicked}
              element={optionsTerm}
              stateName='optionsTerm'
              item={item}
              index={null}
              labelsProp={inputs.options}
              variableList={variableList.filter(variable =>
                variable.obj.dataType
                ? variable.obj.dataType.type === optionsTerm.type && variable.obj.dataType.itemType.type === 'String'
                : false)} />
            <TermEditorComponent
              changeHandler={termEditorFieldHandler}
              isSaveClicked={isSaveClicked}
              element={defaultTerm}
              stateName='defaultTerm'
              item={item}
              index={null}
              labelsProp={inputs.default}
              variableList={variableList.filter(variable =>
                variable.obj.dataType
                ? variable.obj.dataType.type === defaultTerm.type
                : false)} />
            <Form.Group className='wizard__form-col2'>
              <div className='wizard__form-info'>
                {popupResultText &&
                  <Popup
                    position='top center'
                    content={popupResultText}
                    trigger={<Icon name='info' className='info' />} />}
                <Form.Select
                  fluid
                  id={testIds.actionWizard.resultDropDown}
                  error={resultError}
                  onBlur={() => validateBlur('result')}
                  label={inputs.result.label[1]}
                  options={variableList.filter(variable =>
                    variable.obj.dataType
                    ? variable.obj.dataType.type === 'Integer'
                    : false)}
                  onChange={(e, { value }) => onSelect('result', value)}
                  placeholder={inputs.result.placeholder[3]}
                  value={result} />
              </div>
              <Button
                id={testIds.actionWizard.addVariableButton}
                content={buttons.addVariable}
                onClick={() => openAddVariable(true)} />
            </Form.Group>
          </React.Fragment>}
          {item.type === 'InputDialog' &&
          <React.Fragment>
            <TermEditorComponent
              changeHandler={termEditorFieldHandler}
              isSaveClicked={isSaveClicked}
              element={initialValueTerm}
              stateName='initialValueTerm'
              item={item}
              index={null}
              labelsProp={inputs.initialValue}
              variableList={variableList.filter(variable =>
                variable.obj.dataType
                ? variable.obj.dataType.type === initialValueTerm.type
                : false)} />
            <TermEditorComponent
              changeHandler={termEditorFieldHandler}
              isSaveClicked={isSaveClicked}
              element={instructionTerm}
              stateName='instructionTerm'
              item={item}
              index={null}
              labelsProp={inputs.instruction}
              variableList={variableList.filter(variable =>
                variable.obj.dataType
                ? variable.obj.dataType.type === instructionTerm.type
                : false)} />
            <TermEditorComponent
              changeHandler={termEditorFieldHandler}
              isSaveClicked={isSaveClicked}
              element={restrictionTerm}
              stateName='restrictionTerm'
              item={item}
              index={null}
              labelsProp={inputs.restriction}
              variableList={variableList.filter(variable =>
                variable.obj.dataType
                ? variable.obj.dataType.type === restrictionTerm.type
                : false)} />
            <Form.Group className='wizard__form-col2'>
              <div className='wizard__form-info'>
                {popupResultText &&
                  <Popup
                  position='top center'
                  content={popupResultText}
                  trigger={<Icon name='info' className='info' />} />}
                <Form.Select
                  fluid
                  error={resultError}
                  id={testIds.actionWizard.resultDropDown}
                  onBlur={() => validateBlur('result')}
                  label={inputs.result.label[1]}
                  options={variableList.filter(variable =>
                    variable.obj.dataType
                    ? variable.obj.dataType.type === 'String'
                    : false)}
                  onChange={(e, { value }) => onSelect('result', value)}
                  placeholder={inputs.result.placeholder[3]}
                  value={result} />
              </div>
              <Button
                id={testIds.actionWizard.addVariableButton}
                content={buttons.addVariable}
                onClick={() => openAddVariable(true)} />
            </Form.Group>
          </React.Fragment>}
          {item.type === 'ConfirmDialog' &&
          <React.Fragment>
            <Form.Group>
              <div className='wizard__form-info'>
                <Icon name='info' className='info-hidden' />
                <Form.Select
                  fluid
                  id={testIds.actionWizard.messageTypeDropDown}
                  label={inputs.messageType.label}
                  options={messageTypeOptions}
                  onChange={(e, { value }) => onSelect('messageType', value)}
                  placeholder={inputs.messageType.placeholder}
                  value={messageType} />
              </div>
            </Form.Group>
            <Form.Group className='wizard__form-col2'>
              <div className='wizard__form-info'>
                {popupResultText &&
                  <Popup
                  position='top center'
                  content={popupResultText}
                  trigger={<Icon name='info' className='info' />} />}
                <Form.Select
                  fluid
                  id={testIds.actionWizard.resultDropDown}
                  label={inputs.result.label[0]}
                  options={variableList.filter(variable =>
                    variable.obj.dataType
                    ? variable.obj.dataType.type === 'ConfirmationType'
                    : false)}
                  onChange={(e, { value }) => onSelect('result', value)}
                  placeholder={inputs.result.placeholder[3]}
                  value={result} />
              </div>
              <Button
                id={testIds.actionWizard.addVariableButton}
                content={buttons.addVariable}
                onClick={() => openAddVariable(true)} />
            </Form.Group>
          </React.Fragment>}
        </div>
      </Form.Group>
    </React.Fragment>
  )
}

LogElements.propTypes = {
  // props from parent
  item: PropTypes.object,
  validFor: PropTypes.string,
  validities: PropTypes.array,
  onSelect: PropTypes.func,
  severityLevelOptions: PropTypes.array,
  severityLevel: PropTypes.string,
  variableList: PropTypes.array,
  termEditorFieldHandler: PropTypes.func,
  isSaveClicked: PropTypes.bool,
  messageTerm: PropTypes.object,
  messageOptionalTerm: PropTypes.object,
  optionsTerm: PropTypes.object,
  defaultTerm: PropTypes.object,
  resultError: PropTypes.bool,
  result: PropTypes.string,
  validateBlur: PropTypes.func,
  initialValueTerm: PropTypes.object,
  instructionTerm: PropTypes.object,
  openAddVariable: PropTypes.func,
  titleTerm: PropTypes.object,
  restrictionTerm: PropTypes.object,
  messageType: PropTypes.string
}

export default LogElements
