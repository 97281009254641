import { connect } from 'react-redux'
import SutStatusComponent from './SutStatusComponent'

const mapDispatchToProps = {}

const mapStateToProps = state => ({
  ssmId: state.selectElement.ssmId,
  driver: state.driver.driver,
  isProcedureRunning: state.procedure.isProcedureRunning,
  sutStatusItem: state.driver.sutStatusItem
})

export default connect(mapStateToProps, mapDispatchToProps)(SutStatusComponent)
