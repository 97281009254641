import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
// ------------------------------------
// Constants
// ------------------------------------
import axios from 'axios'
import {
  GET_ATTRIBUTES,
  GET_XML_PREVIEW,
  SET_REPORT_ATTRIBUTES,
  SET_ACTIVE_TOP_TAB_INDEX
} from '../actionTypeList'
import { origin, protocol, getHeaders, errorHandle } from '../../addons'
import { loaderControl } from './loaderControl'
// ------------------------------------
// Actions
// ------------------------------------
const getAttributesAction = (data = {}) => {
  return {
    type: GET_ATTRIBUTES,
    data
  }
}

const setReportAttributesAction = (data = {}) => {
  return {
    type: SET_REPORT_ATTRIBUTES,
    data
  }
}

const setActiveTopTabIndexAction = (index = 0) => {
  return {
    type: SET_ACTIVE_TOP_TAB_INDEX,
    data: index
  }
}

export const getXmlPreviewAction = data => {
  return {
    type: GET_XML_PREVIEW,
    data
  }
}

export const getAttributes = data => {
  return dispatch => {
    dispatch(getAttributesAction(data))
  }
}

export const setActiveTopTabIndex = (index = 0) => {
  return dispatch => {
    dispatch(setActiveTopTabIndexAction(index))
  }
}

export const setReportAttributes = (data = {}) => {
  return dispatch => {
    dispatch(setReportAttributesAction(data))
  }
}

export const getXmlPreview = (projectId = '', otxFileId = -1, id = '') => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/items/${otxFileId}/${id}/xml`,
      getHeaders())
        .then(response => {
          dispatch(getXmlPreviewAction(response.data))
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          dispatch(getXmlPreviewAction(''))
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const getReportInfo = (projectId = '', reportId = '') => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/projects/${projectId}/reports/${reportId}`,
      getHeaders())
        .then(response => {
          dispatch(setReportAttributes(response.data))
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          dispatch(loaderControl())
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const actions = {
  getAttributes,
  getXmlPreview,
  getReportInfo,
  setActiveTopTabIndex,
  setReportAttributes
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GET_ATTRIBUTES]: (state, action) => {
    return {
      ...state,
      attribute: action.data
    }
  },
  [GET_XML_PREVIEW]: (state, action) => {
    return {
      ...state,
      xmlPreview: action.data
    }
  },
  [SET_REPORT_ATTRIBUTES]: (state, action) => {
    return {
      ...state,
      reportAttributes: action.data
    }
  },
  [SET_ACTIVE_TOP_TAB_INDEX]: (state, action) => {
    return {
      ...state,
      activeTopTabIndex: action.data
    }
  }
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  attribute: {},
  xmlPreview: '',
  reportAttributes: {},
  activeTopTabIndex: 0
}

export default function diagramAttributesReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
