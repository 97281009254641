import Notifications from 'react-notification-system-redux'
import { parseError } from '../../../constants/errorParser'
// ------------------------------------
// Constants
// ------------------------------------
import axios from 'axios'

import { origin, protocol, getHeadersFileDownload, errorHandle } from '../../addons'
import fileDownload from 'js-file-download'
import { loaderControl } from './loaderControl'

// ------------------------------------
// Actions
// ------------------------------------
export const downloadFile = (url, name) => {
  return dispatch => {
    dispatch(loaderControl(true))
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/api/${url}`,
        getHeadersFileDownload())
        .then(response => {
          fileDownload(response.data, `${name}`)
          dispatch(loaderControl())
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          dispatch(loaderControl())
          reject(error)
        })
    })
  }
}

export const generateRaport = (url, name) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get(`${protocol}//${origin}/${url}`,
        getHeadersFileDownload())
        .then(response => {
          fileDownload(response.data, name)
          resolve()
        })
        .catch(error => {
          const errorMessage = parseError(error)
          dispatch(Notifications.error(errorMessage))
          errorHandle(error)
          reject(error)
        })
    })
  }
}

export const actions = {
  downloadFile,
  generateRaport
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default function downloadFileReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
