import React from 'react'
import { object } from 'prop-types'
import UserDropDownComponent from './components/UserDropDownComponent'
import BarComponent from './components/BarComponent'
import './HeaderComponent.scss'
import isEmpty from 'lodash/isEmpty'

const HeaderComponent = ({ params }) => {
  return (
    <div className='headerComponent'>
      <div className='headerComponent-logo'>
        <img src='/atena-logo.svg' />
      </div>
      <div className='headerComponent-toolbar'>
        {!isEmpty(params) && <BarComponent params={params} />}
      </div>
      <UserDropDownComponent />
    </div>
  )
}

HeaderComponent.propTypes = {
  params: object
}

export default HeaderComponent
