import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as labels from '../../../../../public/labels.json'
import { Form, Icon, Button } from 'semantic-ui-react'
import testIds from '../../../../../public/testIds.json'

export default class Throw extends Component {
  constructor (props) {
    super()
    this.state = typeof props.editItem.qualifierParam === 'undefined'
    ? {
      type: '',
      typeError: false,
      qualifierParam: '',
      qualifierParamError: false,
      textParam: '',
      textParamError: false
    } : {
      type: 'UserException',
      typeError: false,
      qualifierParam: props.editItem.qualifierParam,
      qualifierParamError: false,
      textParam: props.editItem.textParam,
      textParamError: false
    }
  }

  static propTypes = {
    saveModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    editItem: PropTypes.object.isRequired
  }

  handleChangeInput = (stateName, value) => {
    this.setState({
      [stateName]: value
    }, () => this.validateBlur(stateName))
  }

  validateBlur = stateName => {
    this.setState({
      [`${stateName}Error`]: this.state[stateName] === ''
    })
  }

  saveData = () => {
    this.validateBlur('type')
    if (this.state.type === 'UserException') {
      this.validateBlur('qualifierParam')
      this.validateBlur('textParam')

      if (this.state.qualifierParam !== '' && this.state.textParam !== '') {
        this.props.saveModal(
          'Throw',
          {
            type: this.state.type,
            children: [],
            qualifierParam: this.state.qualifierParam,
            textParam: this.state.textParam
          },
          this.props.editItem.type)
      }
    } else if (this.state.type !== '') {
      this.props.saveModal(
        'Throw',
        {
          type: this.state.type,
          children: []
        },
        this.props.editItem.type)
    }
  }

  render () {
    return (
      <section className='wizard__form-container'>
        <Form className='wizard__form'>
          <Form.Group>
            <section className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                search
                id={testIds.throw.typeDropDown}
                disabled={typeof this.props.editItem.type === 'string'}
                label={labels.modalPattern.inputs.type.label}
                placeholder={labels.modalPattern.inputs.type.placeholder}
                options={labels.throw_type_list}
                value={this.state.type}
                error={this.state.typeError}
                onChange={(event, { value }) => this.handleChangeInput('type', value)}
                onBlur={this.validateBlur.bind(this, 'type')} />
            </section>
          </Form.Group>
          {this.state.type === 'UserException' &&
          <Form.Group>
            <section className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Input
                id={testIds.throw.qualifierParamInput}
                label={labels.modalPattern.inputs.qualifier_param.label}
                placeholder={labels.modalPattern.inputs.qualifier_param.placeholder}
                type='text'
                value={this.state.qualifierParam}
                onChange={event => this.handleChangeInput('qualifierParam', event.target.value)}
                onBlur={this.validateBlur.bind(this, 'qualifierParam')}
                error={this.state.qualifierParamError}
                className='wizard__form-input' >
              </Form.Input>
            </section>
            <section className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Input
                id={testIds.throw.textParamInput}
                label={labels.modalPattern.inputs.text_param.label}
                placeholder={labels.modalPattern.inputs.text_param.placeholder}
                type='text'
                value={this.state.textParam}
                onChange={event => this.handleChangeInput('textParam', event.target.value)}
                onBlur={this.validateBlur.bind(this, 'textParam')}
                error={this.state.textParamError}
                className='wizard__form-input' >
              </Form.Input>
            </section>
          </Form.Group>}
        </Form>
        <div className='wizard__footer'>
          <div>
            <Button
              id={testIds.throw.cancelButton}
              onClick={this.props.closeModal}
              content={labels.buttons.cancel} />
          </div>
          <div>
            <Button
              id={testIds.throw.saveButton}
              onClick={this.saveData}
              content={labels.buttons.save}
              positive />
          </div>
        </div>
      </section>
    )
  }
}
