import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ConfirmModalComponent.scss'
import {
  Button,
  Modal,
  Form
} from 'semantic-ui-react'
import { iconSetter } from '../../constants/common'
import labels from '../../../public/labels.json'
import testIds from '../../../public/testIds.json'

class ConfirmModalComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      inputValue: ''
    }
  }

  static propTypes = {
    // props from parent
    confirmModalObject: PropTypes.object,
    closeModal: PropTypes.func,
    params: PropTypes.object,
    // props from redux
    confirmModalController: PropTypes.func
  }

  componentDidMount () {
    const { confirmModalObject } = this.props
    switch (confirmModalObject.type) {
      case 'ChoiceDialog':
        typeof confirmModalObject.defaultOption === 'number' &&
        this.setState({ inputValue: confirmModalObject.defaultOption })
        break
      case 'InputDialog':
        this.setState({ inputValue: confirmModalObject.initialValue || '' })
        break
    }
  }

  handleChangeInput = (stateName, value) => {
    const change = {}
    change[stateName] = value
    this.setState(change)
  }

  saveModal = buttonText => {
    const { confirmModalController, params, closeModal } = this.props
    const { inputValue } = this.state
    const { type } = this.props.confirmModalObject
    const body = { input: '' }
    let url = `/api/executions/projects/${params.projectId}/items/${params.otxFileId}/dialog/`
    switch (type) {
      case 'ChoiceDialog':
        url += 'choice-dialog'
        body.input = inputValue
      break
      case 'InputDialog':
        url += 'input-dialog'
        body.input = inputValue
      break
      case 'ConfirmDialog':
        url += 'confirm-dialog'
        body.input = buttonText
      break
    }
    closeModal(false)
    confirmModalController(url, body).catch(() => {
      closeModal(true)
    })
  }

  renderButtonHandler = () => {
    const { confirmModalObject } = this.props
    const { buttons } = labels
    const { inputValue } = this.state
    const isConfirmDialog = confirmModalObject.type === 'ConfirmDialog'
    const buttonList = []
    if (isConfirmDialog && confirmModalObject.buttons) {
      confirmModalObject.buttons.forEach(button => {
        buttonList.push(button)
      })
    } else {
      buttonList.push(buttons.save)
    }
    return buttonList.map((buttonText, index) => <Button
      key={index}
      id={`${testIds.confirmModalComponent.button}_${buttonText.toLocaleLowerCase()}`}
      disabled={inputValue === '' && !isConfirmDialog}
      onClick={() => this.saveModal(buttonText)}
      content={buttonText}
      positive />)
  }

  render () {
    const { confirmModalComponent } = labels
    const { confirmModalObject } = this.props
    const { inputValue } = this.state
    return (
      <Modal
        closeOnDimmerClick={false}
        open
        size='tiny'
        className='confirmComponent__container'
        centered>
        <div className='confirmComponent__header'>
          <span className={iconSetter(confirmModalObject.type || '')} />
          <span>{confirmModalObject.title || confirmModalComponent.headerTitles[confirmModalObject.type]}</span>
        </div>
        <div className='confirmComponent__content'>
          {confirmModalObject.message &&
          <div className='confirmComponent__content-description'>{confirmModalObject.message}</div>}
          <Form>
            {confirmModalObject.type === 'ChoiceDialog' &&
            <Form.Group>
              <Form.Select
                fluid
                id={testIds.confirmModalComponent.userChoiceDropDown}
                label={confirmModalComponent.userChoice.label}
                options={confirmModalObject.options}
                onChange={(e, { value }) => this.handleChangeInput('inputValue', value)}
                placeholder={confirmModalComponent.userChoice.placeholder}
                value={inputValue}
                />
            </Form.Group>}
            {confirmModalObject.type === 'InputDialog' &&
            <React.Fragment>
              <Form.Group>
                <Form.Input
                  label={confirmModalComponent.userInput.label}
                  placeholder={confirmModalComponent.userInput.placeholder}
                  id={testIds.confirmModalComponent.userInputInput}
                  type='text'
                  value={inputValue}
                  onChange={(event, { value }) => this.handleChangeInput('inputValue', value)}/>
              </Form.Group>
              {confirmModalObject.instruction &&
              <div className='confirmComponent__content-inputDescription'>{confirmModalObject.instruction}</div>}
            </React.Fragment>
        }
          </Form>
        </div>
        <div className='confirmComponent__footer'>
          {this.renderButtonHandler()}
        </div>
      </Modal>
    )
  }
}

export default ConfirmModalComponent
