import cloneDeep from 'lodash/cloneDeep'

export const returnInitValueHandler = name => cloneDeep(initValuesObject[name])

const initValuesObject = {
  copyObjectInit: {
    nodesToBeCopied: [],
    elementsToBeCopied: [],
    source: {},
    isContextOptionClicked: false,
    action: ''
  },
  sdvNodeInit: {
    type: 'STATE',
    title: ''
  },
  initNodesDataState: {
    nodes: {},
    connections: []
  }
}
