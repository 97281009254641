import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Icon, Button, Segment, Popup } from 'semantic-ui-react'
import { func, string, object, bool, number, array } from 'prop-types'
import TermWizard from './components/TermWizard'
import * as labels from '../../../public/labels.json'
import { isSupportingLiteral } from '../../utilites/terms/terms'
import testIds from '../../../public/testIds.json'

class TermEditorComponent extends Component {
  state = {
    isTermEditionModalOpen: false,
    subElementType: '',
    termObj: { term: this.props.element.term, propertyName: 'term' }
  }

  static propTypes = {
    allVariableList: array,
    changeHandler: func,
    element: object,
    variableList: array,
    item: object,
    stateName: string,
    labelsProp: object,
    index: number,
    isSaveClicked: bool,
    fieldType: string,
    // all props below will be used only in modifier elements (maps, lists)
    selectedMap: object,
    removeElement: func,
    addNewMapItem: func,
    elementsAmount: number
  }

  saveChangedTermValue = newTerm => {
    const { item, changeHandler, stateName, index } = this.props
    if (item.type === 'MapPut' || item.type === 'MapUnion') {
      const { termObj } = this.state
      changeHandler(
        stateName,
        newTerm,
        termObj.propertyName,
        termObj.index ? termObj.index : index,
        termObj.attrName)
    } else {
      changeHandler(stateName, newTerm, 'term', index)
    }

    this.setState({ isTermEditionModalOpen: false })
  }

  renderLiteralInputs () {
    const {
      labelsProp,
      selectedMap,
      removeElement,
      changeHandler,
      isSaveClicked,
      element,
      stateName,
      variableList,
      addNewMapItem
    } = this.props
    return <Segment style={{ background: '#f9fafb' }}>
      {element.value.items.map((el, index) => {
        const isKeyNotValid = !el.key.isValid && (isSaveClicked || el.key.isEditionStarted)
        const isValueNotValid = !el.value.isValid && (isSaveClicked || el.value.isEditionStarted)
        return <div key={index}>
          <div style={{ display: 'flex', width: 584, justifyContent: 'space-between', marginTop: 20 }}>
            <div style={{ alignSelf: 'center', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 'bold' }}>
              {`${labelsProp.header} ${index + 1}`}
            </div>
            {element.value.items.length > 1 &&
            <Button
              id={`${testIds.termEditorComponent.removeButton}_${this.props.index}_${index}`}
              content='Remove'
              negative
              onClick={() => removeElement([this.props.index, index])} />}
          </div>
          <div className='flex-container' style={{ alignItems: 'flex-start' }}>
            <div className='wizard__form-info' style={{ marginRight: 16 }}>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={`${testIds.termEditorComponent.editionType}_key_${this.props.index}_${index}`}
                options={labels.variable_edition_type_list_term}
                onChange={(event, { value }) =>
                  changeHandler(
                    stateName,
                    value,
                    'editionType',
                    [this.props.index, index],
                    'key')}
                value={el.key.editionType}
                label={labelsProp && labelsProp.key
                  ? labelsProp.key : labels.modalPattern.inputs.variable_edit_type_choose.label}
                placeholder={labels.modalPattern.inputs.variable_edit_type_choose.placeholder} />
            </div>
            {el.key.editionType === 'Literal' &&
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Input
                fluid
                id={`${testIds.termEditorComponent.value}_key_${this.props.index}_${index}`}
                onChange={(event, { value }) =>
                  changeHandler(
                    stateName,
                    value,
                    'value',
                    [this.props.index, index],
                    'key')}
                value={el.key.value.value}
                error={isKeyNotValid}
                label={labels.modalPattern.inputs.value.label[0]}
                placeholder={labels.modalPattern.inputs.value.placeholder[2]} />
            </div>}
            {el.key.editionType === 'ValueOf' &&
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={`${testIds.termEditorComponent.assignedValue}_key_${this.props.index}_${index}`}
                options={variableList.filter(variable =>
                  variable.obj.dataType ? (
                    variable.obj.dataType.type === selectedMap.object.obj.dataType.keyType.type
                  ) : false)}
                onChange={(event, { value }) =>
                  changeHandler(
                    stateName,
                    value,
                    'valueOf',
                    [this.props.index, index],
                    'key')}
                value={el.key.valueOf.valueOf}
                error={isKeyNotValid}
                label={labels.modalPattern.inputs.assignedValue.label}
                placeholder={labels.modalPattern.inputs.assignedValue.placeholder} />
            </div>}
            {el.key.editionType === 'AdvancedEdit' &&
              <div style={{ marginTop: 31 }}>
                <Button
                  id={`${testIds.termEditorComponent.advancedEdit}_key_${this.props.index}_${index}`}
                  style={{ margin: 0 }}
                  color={isKeyNotValid
                    ? 'google plus' : null}
                  onClick={() => this.setState({
                    isTermEditionModalOpen: true,
                    subElementType: element.term.keyType.type,
                    termObj: {
                      term: el.key.term,
                      propertyName: 'term',
                      index: [this.props.index, index],
                      attrName: 'key'
                    }
                  })}
                  content={labels.buttons.advancedEdit} />
              </div>}
          </div>
          <div className='flex-container' style={{ alignItems: 'flex-start' }}>
            <div className='wizard__form-info' style={{ marginRight: 16 }}>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={`${testIds.termEditorComponent.editionType}_value_${this.props.index}_${index}`}
                options={isSupportingLiteral(selectedMap.object.obj.dataType.valueType.type)
                  ? labels.variable_edition_type_list_term
                  : labels.variable_edition_type_list_term.filter(el => el.text !== 'Literal')}
                onChange={(event, { value }) =>
                  changeHandler(
                    stateName,
                    value,
                    'editionType',
                    [this.props.index, index],
                    'value')}
                value={el.value.editionType}
                label={labelsProp && labelsProp.value
                  ? labelsProp.value : labels.modalPattern.inputs.variable_edit_type_choose.label}
                placeholder={labels.modalPattern.inputs.variable_edit_type_choose.placeholder} />
            </div>
            {el.value.editionType === 'Literal' &&
            (selectedMap.object.obj.dataType.valueType.type === 'Boolean'
            ? <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                error={isValueNotValid}
                id={`${testIds.termEditorComponent.booleanValue}_value_${this.props.index}_${index}`}
                label={labels.modalPattern.inputs.booleanValue.label}
                options={labels.modalPattern.inputs.booleanValue.options}
                value={String(el.value.value.value)}
                onChange={(event, { value }) =>
                changeHandler(
                  stateName,
                  value,
                  'value',
                  [this.props.index, index],
                  'value')}
                placeholder={labels.modalPattern.inputs.booleanValue.placeholder}/>
            </div>
            : <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Input
                fluid
                id={`${testIds.termEditorComponent.value}_value_${this.props.index}_${index}`}
                onChange={(event, { value }) =>
                changeHandler(
                  stateName,
                  value,
                  'value',
                  [this.props.index, index],
                  'value')}
                value={el.value.value.value}
                error={isValueNotValid}
                label={labels.modalPattern.inputs.value.label[0]}
                placeholder={labels.modalPattern.inputs.value.placeholder[2]} />
            </div>)}
            {el.value.editionType === 'ValueOf' &&
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={`${testIds.termEditorComponent.assignedValue}_value_${this.props.index}_${index}`}
                options={variableList.filter(variable =>
                  variable.obj.dataType ? (
                    variable.obj.dataType.type === selectedMap.object.obj.dataType.valueType.type
                  ) : false)}
                  onChange={(event, { value }) =>
                  changeHandler(
                    stateName,
                    value,
                    'valueOf',
                    [this.props.index, index],
                    'value')}
                value={el.value.valueOf.valueOf}
                error={isValueNotValid}
                label={labels.modalPattern.inputs.assignedValue.label}
                placeholder={labels.modalPattern.inputs.assignedValue.placeholder} />
            </div>}
            {el.value.editionType === 'AdvancedEdit' &&
              <div style={{ marginTop: 31 }}>
                <Button
                  id={`${testIds.termEditorComponent.advancedEdit}_value_${this.props.index}_${index}`}
                  style={{ margin: 0 }}
                  color={isValueNotValid
                    ? 'google plus' : null}
                  onClick={() => this.setState({
                    isTermEditionModalOpen: true,
                    subElementType: element.term.valueType.type,
                    termObj: {
                      term: el.value.term,
                      propertyName: 'term',
                      index: [this.props.index, index],
                      attrName: 'value'
                    }
                  })}
                  content={labels.buttons.advancedEdit} />
              </div>}
          </div>
        </div>
      })}
      <div className='add-button'>
        <Button
          id={`${testIds.termEditorComponent.addButton}_${this.props.index}`}
          content='Add a New Map Item'
          positive
          onClick={() => addNewMapItem(this.props.index)} />
      </div>
    </Segment>
  }

  renderInputs (index, element) {
    const {
      stateName,
      fieldType,
      labelsProp,
      changeHandler,
      variableList,
      item
    } = this.props

    const isListElement = typeof index !== 'undefined' && index !== null
    const isNotValid = !element.isValid && (this.props.isSaveClicked || element.isEditionStarted)
    const lowerCaseType = `${item.type[0].toLowerCase()}${item.type.slice(1)}`
    const popupText = labels.hints.diagramModal[lowerCaseType]
      ? labels.hints.diagramModal[lowerCaseType][stateName] || null
      : null

    let idString = ''
    switch (stateName) {
      case 'countTerm':
      case 'indexTerm':
      case 'titleTerm':
      case 'messageTerm':
      case 'messageOptionalTerm':
      case 'defaultTerm':
      case 'instructionTerm':
      case 'initialValueTerm':
      case 'restrictionTerm':
        idString = `_${stateName}`
        break
    }

    return (
      <Form.Group
        className='wizard__form-col5'
        style={{ alignItems: 'flex-start' }}>
        {typeof element.name !== 'undefined' &&
        <div className='wizard__form-info'>
          <Icon name='info' className='info-hidden' />
          <Form.Input
            readOnly
            fluid
            value={element.name}
            label={labels.modalPattern.inputs.nameSecondary.label}
            placeholder={labels.modalPattern.inputs.nameSecondary.placeholder} />
        </div>}
        <div className='wizard__form-info'>
          {popupText &&
          <Popup
            position='top center'
            content={popupText}
            trigger={<Icon name='info' className='info' />} />}
          <Form.Select
            fluid
            id={`${testIds.termEditorComponent.editionType}${isListElement ? `_${index}` : idString}`}
            options={element.type && isSupportingLiteral(element.type)
              ? labels.variable_edition_type_list_term
              : labels.variable_edition_type_list_term.filter(el => el.text !== 'Literal')}
            onChange={(event, { value }) => changeHandler(stateName, value, 'editionType', index)}
            value={element.editionType}
            label={labelsProp && labelsProp.label
              ? labelsProp.label : labels.modalPattern.inputs.variable_edit_type_choose.label}
            placeholder={labelsProp && labelsProp.placeholder
            ? labelsProp.placeholder : labels.modalPattern.inputs.variable_edit_type_choose.placeholder} />
        </div>
        {element.editionType === 'Literal' &&
          (element.value && element.value.type && element.value.type.includes('Boolean')
          ? <div className='wizard__form-info'>
            <Form.Select
              fluid
              id={`${testIds.termEditorComponent.booleanValue}${isListElement ? `_${index}` : idString}`}
              label={labels.modalPattern.inputs.booleanValue.label}
              options={labels.modalPattern.inputs.booleanValue.options}
              value={String(element.value.value)}
              error={isNotValid}
              onChange={(event, { value }) =>
              changeHandler(stateName, value, 'value', index)}
              placeholder={labels.modalPattern.inputs.booleanValue.placeholder}/>
          </div>
          : element.value.type.includes('ActivityState')
            ? <div className='wizard__form-info'>
              <Form.Select
                fluid
                error={isNotValid}
                id={`${testIds.termEditorComponent.booleanValue}${isListElement ? `_${index}` : idString}`}
                label={labels.modalPattern.inputs.activityState.label}
                options={labels.modalPattern.inputs.activityState.options}
                value={element.value.value}
                onChange={(event, { value }) =>
                  changeHandler(stateName, value, 'value', index)}
                placeholder={labels.modalPattern.inputs.activityState.placeholder}/>
            </div>
            : <div className='wizard__form-info'>
              {fieldType && fieldType === 'textArea'
               ? <Form.TextArea
                id={`${testIds.termEditorComponent.value}${isListElement ? `_${index}` : idString}`}
                error={isNotValid}
                label={labels.modalPattern.inputs.value.label[0]}
                placeholder={labels.modalPattern.inputs.value.placeholder[2]}
                onChange={(event, { value }) =>
                changeHandler(stateName, value, 'value', index)}
                value={element.value.value}
                style={{ minHeight: 150 }} />
               : <Form.Input
                fluid
                id={`${testIds.termEditorComponent.value}${isListElement ? `_${index}` : idString}`}
                error={isNotValid}
                onChange={(event, { value }) =>
                changeHandler(stateName, value, 'value', index)}
                value={element.value.value}
                label={labels.modalPattern.inputs.value.label[0]}
                placeholder={labels.modalPattern.inputs.value.placeholder[2]} />}
            </div>)}
        {element.editionType === 'ValueOf' &&
          <div className='wizard__form-info'>
            <Form.Select
              id={`${testIds.termEditorComponent.assignedValue}${isListElement ? `_${index}` : idString}`}
              error={isNotValid}
              fluid
              options={variableList}
              onChange={(event, { value }) =>
              changeHandler(stateName, value, 'valueOf', index)}
              value={element.valueOf.valueOf}
              label={labels.modalPattern.inputs.assignedValue.label}
              placeholder={labels.modalPattern.inputs.assignedValue.placeholder} />
          </div>}
        {element.editionType === 'AdvancedEdit' &&
          <div style={{ marginTop: 31 }}>
            <Button
              id={`${testIds.termEditorComponent.advancedEdit}${isListElement ? `_${index}` : idString}`}
              color={isNotValid
                    ? 'google plus' : null}
              onClick={() => this.setState({
                isTermEditionModalOpen: true,
                termObj: { term: element.term, propertyName: 'term' }
              })}
              content={labels.buttons.advancedEdit} />
            </div>}
        {isListElement &&
        typeof this.props.removeElement !== 'undefined' &&
        <div style={{ marginTop: 31 }}>
          <Button
          id={`${testIds.termEditorComponent.removeButton}_${index}`}
          content='Remove'
          disabled={this.props.elementsAmount <= 1}
          negative
          onClick={() => this.props.removeElement(index)}/>
        </div>}
      </Form.Group>)
  }

  renderMapUnion (index, element) {
    const { labelsProp, changeHandler, stateName, selectedMap } = this.props
    const isNotValid = !element.isValid && (this.props.isSaveClicked || element.isEditionStarted)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Form.Group
          style={{ alignItems: element.editionType === 'AdvancedEdit' ? 'flex-end' : 'flex-start' }}
          className={`wizard__form-col${element.editionType === 'Literal' ? '6' : '5'}`} >
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
              fluid
              id={`${testIds.termEditorComponent.editionType}_${index}`}
              options={labels.variable_edition_type_list_term}
              onChange={(event, { value }) =>
                changeHandler(stateName, value, 'editionType', index)}
              value={element.editionType}
              label={labelsProp && labelsProp.label
                  ? labelsProp.label : labels.modalPattern.inputs.variable_edit_type_choose.label}
              placeholder={labels.modalPattern.inputs.variable_edit_type_choose.placeholder} />
          </div>
          {element.editionType === 'Literal' &&
            this.renderLiteralInputs()
          }
          {element.editionType === 'ValueOf' &&
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={`${testIds.termEditorComponent.assignedValue}_${index}`}
                options={this.props.variableList.filter(variable =>
                  variable.obj.dataType ? (
                    variable.obj.dataType.type === 'Map' &&
                    variable.obj.dataType.keyType.type === selectedMap.object.obj.dataType.keyType.type &&
                    variable.obj.dataType.valueType.type === selectedMap.object.obj.dataType.valueType.type
                  ) : false)}
                onChange={(event, { value }) =>
                  changeHandler(stateName, value, 'valueOf', index)}
                value={element.valueOf.valueOf}
                error={isNotValid}
                label={labels.modalPattern.inputs.assignedValue.label}
                placeholder={labels.modalPattern.inputs.assignedValue.placeholder} />
            </div>}
          {element.editionType === 'AdvancedEdit' &&
            <Button
              id={`${testIds.termEditorComponent.advancedEdit}_${index}`}
              color={isNotValid
                ? 'google plus' : null}
              onClick={() => this.setState({
                isTermEditionModalOpen: true,
                termObj: { term: element.term, propertyName: 'term' }
              })}
              content={labels.buttons.advancedEdit} />}
        </Form.Group>
        <Button
          id={`${testIds.termEditorComponent.removeButton}_${index}`}
          content='Remove'
          style={{ position: 'relative', top: 31 }}
          negative
          onClick={() => this.props.removeElement(index)} />
      </div>)
  }

  renderMapPut (index, element) {
    const { labelsProp, isSaveClicked } = this.props
    const isKeyNotValid = !element.key.isValid && (isSaveClicked || element.key.isEditionStarted)
    const isValueNotValid = !element.value.isValid && (isSaveClicked || element.value.isEditionStarted)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
        <Form.Group
          className='wizard__form-col5'
          style={{ alignItems: element.key.editionType === 'AdvancedEdit' ? 'flex-end' : 'flex-start' }}>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
              fluid
              id={`${testIds.termEditorComponent.editionType}_key_${index}`}
              options={labels.variable_edition_type_list_term}
              onChange={(event, { value }) =>
              this.props.changeHandler(this.props.stateName, value, 'editionType', index, 'key')}
              value={element.key.editionType}
              label={labelsProp && labelsProp.key
                ? labelsProp.key : labels.modalPattern.inputs.variable_edit_type_choose.label}
              placeholder={labels.modalPattern.inputs.variable_edit_type_choose.placeholder} />
          </div>
          {element.key.editionType === 'Literal' &&
            (element.key.value.type.includes('Boolean')
            ? <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={`${testIds.termEditorComponent.booleanValue}_key_${index}`}
                error={isKeyNotValid}
                label={labels.modalPattern.inputs.booleanValue.label}
                options={labels.modalPattern.inputs.booleanValue.options}
                value={String(element.key.value.value)}
                onChange={(event, { value }) =>
                this.props.changeHandler(this.props.stateName, value, 'value', index, 'key')}
                placeholder={labels.modalPattern.inputs.booleanValue.placeholder}/>
            </div>
            : <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Input
                fluid
                id={`${testIds.termEditorComponent.value}_key_${index}`}
                onChange={(event, { value }) =>
                this.props.changeHandler(this.props.stateName, value, 'value', index, 'key')}
                error={isKeyNotValid}
                value={element.key.value.value}
                label={labels.modalPattern.inputs.value.label[0]}
                placeholder={labels.modalPattern.inputs.value.placeholder[2]} />
            </div>)}
          {element.key.editionType === 'ValueOf' &&
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={`${testIds.termEditorComponent.assignedValue}_key_${index}`}
                options={this.props.variableList.filter(variable =>
                  variable.obj.dataType ? (
                    variable.obj.dataType.type === this.props.selectedMap.object.obj.dataType.keyType.type
                  ) : false)}
                onChange={(event, { value }) =>
                this.props.changeHandler(this.props.stateName, value, 'valueOf', index, 'key')}
                value={element.key.valueOf.valueOf}
                error={isKeyNotValid}
                label={labels.modalPattern.inputs.assignedValue.label}
                placeholder={labels.modalPattern.inputs.assignedValue.placeholder} />
            </div>}
          {element.key.editionType === 'AdvancedEdit' &&
            <Button
              color={isKeyNotValid
                ? 'google plus' : null}
              style={{ marginTop: 31 }}
              id={`${testIds.termEditorComponent.advancedEdit}_key_${index}`}
              onClick={() => this.setState({
                isTermEditionModalOpen: true,
                termObj: { term: element.key.term, propertyName: 'term', attrName: 'key' }
              })}
              content={labels.buttons.advancedEdit} />}
          <Button
            content='Remove'
            style={{ marginTop: 31 }}
            negative
            id={`${testIds.termEditorComponent.removeButton}_${index}`}
            onClick={() => this.props.removeElement(index)}/>
        </Form.Group>
        <Form.Group
          className='wizard__form-col5'
          style={{ alignItems: element.value.editionType === 'AdvancedEdit' ? 'flex-end' : 'flex-start' }}>
          <div className='wizard__form-info'>
            <Icon name='info' className='info-hidden' />
            <Form.Select
              fluid
              id={`${testIds.termEditorComponent.editionType}_value_${index}`}
              options={isSupportingLiteral(this.props.selectedMap.object.obj.dataType.valueType.type)
                ? labels.variable_edition_type_list_term
                : labels.variable_edition_type_list_term.filter(el => el.text !== 'Literal')}
              onChange={(event, { value }) =>
              this.props.changeHandler(this.props.stateName, value, 'editionType', index, 'value')}
              value={element.value.editionType}
              label={labelsProp && labelsProp.value
                ? labelsProp.value : labels.modalPattern.inputs.variable_edit_type_choose.label}
              placeholder={labels.modalPattern.inputs.variable_edit_type_choose.placeholder} />
          </div>
          {element.value.editionType === 'Literal' &&
            (element.value.value.type.includes('Boolean')
            ? <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={`${testIds.termEditorComponent.booleanValue}_value_${index}`}
                error={isValueNotValid}
                label={labels.modalPattern.inputs.booleanValue.label}
                options={labels.modalPattern.inputs.booleanValue.options}
                value={String(element.value.value.value)}
                onChange={(event, { value }) =>
                this.props.changeHandler(this.props.stateName, value, 'value', index, 'value')}
                placeholder={labels.modalPattern.inputs.booleanValue.placeholder}/>
            </div>
            : <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Input
                fluid
                id={`${testIds.termEditorComponent.value}_value_${index}`}
                onChange={(event, { value }) =>
                this.props.changeHandler(this.props.stateName, value, 'value', index, 'value')}
                error={isValueNotValid}
                value={element.value.value.value}
                label={labels.modalPattern.inputs.value.label[0]}
                placeholder={labels.modalPattern.inputs.value.placeholder[2]} />
            </div>)}
          {element.value.editionType === 'ValueOf' &&
            <div className='wizard__form-info'>
              <Icon name='info' className='info-hidden' />
              <Form.Select
                fluid
                id={`${testIds.termEditorComponent.assignedValue}_value_${index}`}
                options={this.props.variableList.filter(variable =>
                  variable.obj.dataType ? (
                    variable.obj.dataType.type === this.props.selectedMap.object.obj.dataType.valueType.type
                  ) : false)}
                onChange={(event, { value }) =>
                this.props.changeHandler(this.props.stateName, value, 'valueOf', index, 'value')}
                error={isValueNotValid}
                value={element.value.valueOf.valueOf}
                label={labels.modalPattern.inputs.assignedValue.label}
                placeholder={labels.modalPattern.inputs.assignedValue.placeholder} />
            </div>}
          {element.value.editionType === 'AdvancedEdit' &&
            <Button
              id={`${testIds.termEditorComponent.advancedEdit}_value_${index}`}
              color={isValueNotValid
                ? 'google plus' : null}
              onClick={() => this.setState({
                isTermEditionModalOpen: true,
                termObj: { term: element.value.term, propertyName: 'term', attrName: 'value' }
              })}
            content={labels.buttons.advancedEdit} />}
        </Form.Group>
      </div>)
  }

  inputTypeHandler () {
    const { element } = this.props
    switch (element.type) {
      case 'Map':
      case 'List':
      case 'Integer':
      case 'Float':
        return element.type
      case 'String':
      case 'Boolean':
      case 'ActivityState':
      case 'ByteField':
      case 'Connection':
      case 'SsmEventData':
      case 'SsmReportingData':
        return `${element.type}Term`
      case 'Validity':
        return 'BooleanTerm'
      default:
        if (element.type !== undefined) {
          const type = element.type.includes('Exception') ? 'ExceptionTerm' : element.type
          return type
        } else {
          return null
        }
    }
  }

  renderByType = () => {
    const { index, element, item } = this.props
    switch (item.type) {
      case 'MapUnion':
        return this.renderMapUnion(index, element)
      case 'MapPut':
        return this.renderMapPut(index, element)
      default:
        return this.renderInputs(index, element)
    }
  }

  render () {
    const { isTermEditionModalOpen, termObj, subElementType } = this.state
    return (
      <div style={{ width: '100%' }}>
        {this.renderByType()}
        {isTermEditionModalOpen && <TermWizard
          isModalOpen={isTermEditionModalOpen}
          onSaveChangedTermValue={this.saveChangedTermValue}
          onCloseModal={() => this.setState({ isTermEditionModalOpen: false })}
          inputType={subElementType || this.inputTypeHandler()}
          variableList={this.props.allVariableList}
          assignedVal={termObj.term} />
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  allVariableList: state.diagramElement.variableList
})

export default connect(mapStateToProps, null)(TermEditorComponent)
