export const globalDeclarationList = [
    { text: 'Add a new Global Constant', action: 'GlobalConstantDeclaration' },
    { text: 'Add a new Document Variable', action: 'DocumentVariableDeclaration' },
    { text: 'Add a new Context Variable', action: 'ContextVariableDeclaration' }
]

export const validitiesList = [
    { text: 'Add a new Validity', action: 'addNewValidity' }
]

export const signaturesList = [
    { text: 'Add a new Signature', action: 'addNewSignature' }
]

export const proceduresList = [
    { text: 'Add a new Procedure', action: 'addNewProcedure' }
]

export const parametersList = [
    { text: 'Add a new In Parameter', action: 'InParameter' },
    { text: 'Add a new In&Out Parameter', action: 'InOutParameter' },
    { text: 'Add a new Out Parameter', action: 'OutParameter' }
]

export const localDeclarationList = [
    { text: 'Add a new Variable', action: 'VariableDeclaration' },
    { text: 'Add a new Constant', action: 'ConstantDeclaration' }
]

export const ImportList = [
    { text: 'Add a new Import', action: 'AddImport' }
]

export const ThrowList = [
    { text: 'Add a new Throw', action: 'AddThrow' }
]

export const copyPasteList = [
  { text: 'Copy', action: 'copy' },
  { text: 'Cut', action: 'cut' },
  { text: 'Paste', action: 'paste' }
]

export const throwTypes = [
    'AmbiguousCallException',
    'ArithmeticException',
    'ConcurrentModificationException',
    'ConnectionException',
    'Exception',
    'InvalidReferenceException',
    'OutOfBoundsException',
    'SsmException',
    'TypeMismatchException',
    'UnknownTargetException',
    'UserException'
]

export const contextMenusupportedNames = [
  'IN',
  'INOUT',
  'OUT',
  'CONSTANTS',
  'CONTEXTS',
  'VARIABLES',
  'Global Declarations',
  'Imports',
  'Throws',
  'Validities',
  'Signatures',
  'Parameters',
  'Local Declarations'
]

export const contextMenusupportedTypes = [
  'Procedure',
  'VariableDeclaration',
  'ConstantDeclaration',
  'Signature',
  'Import',
  'Validity'
]
