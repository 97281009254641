import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './PseudocodeComponent.scss'
import InfoComponent from '../InfoComponent'
import { isEmpty, isEqual } from 'lodash'
import labels from '../../../public/labels.json'
import SingleLineComponent from './components/SingleLineComponent'
import ToggleButtonsComponent from './components/ToggleButtonsComponent'
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache
} from 'react-virtualized'

class PseudocodeComponent extends Component {
  constructor (props) {
    super(props)
    this.cache = new CellMeasurerCache({ defaultHeight: 21, fixedWidth: true })
      this.state = {
        scrollToIndex: undefined,
        scrollToAlignment: 'center'
      }
  }

  static propTypes = {
    // props from redux
    loaderControl: PropTypes.func,
    updateDiagramListTime: PropTypes.number,
    isActiveLoader: PropTypes.bool,
    pseucoCodeList: PropTypes.array,
    shouldUpdateScroll: PropTypes.bool,
    selectedContextViewId: PropTypes.any,
    isTesterRole: PropTypes.bool,
    getPseudoCodeList: PropTypes.func,
    // props from parent
    params: PropTypes.object
  }

  componentDidMount () {
    const { pseucoCodeList, selectedContextViewId } = this.props
    this.props.isActiveLoader && this.props.loaderControl(false)
      if (!isEmpty(pseucoCodeList)) {
        this.scrollToIndexHandler(pseucoCodeList, selectedContextViewId)
      }
  }

  componentDidUpdate (prevProps) {
    const { pseucoCodeList, shouldUpdateScroll } = this.props
    if (!isEmpty(pseucoCodeList) && shouldUpdateScroll) {
        if (!isEqual(this.props.selectedContextViewId, prevProps.selectedContextViewId)) {
          this.scrollToIndexHandler(pseucoCodeList, this.props.selectedContextViewId)
        }
    }
  }

  scrollToIndexHandler (data, searchingId) {
    const scrollToIndex = data.findIndex(el => el.id && el.id === searchingId)
    this.setState({ scrollToIndex: scrollToIndex !== -1 ? scrollToIndex : undefined })
  }

  renderPseudoCode = data => data.map(item => {
      const { params, isTesterRole } = this.props
      return <SingleLineComponent
        isTesterRole={isTesterRole}
        selectedContextViewId={this.props.selectedContextViewId}
        key={item.index}
        params={params}
        singleLine={item} />
  })

  changePseudocodeTypeHandler = () => {
    const { loaderControl, getPseudoCodeList, params } = this.props
    loaderControl(true)
    getPseudoCodeList(params.projectId, params.otxFileId)
  }

  render () {
    const { pseucoCodeList, isActiveLoader, params } = this.props
    const { scrollToIndex, scrollToAlignment } = this.state
    return (
      <div className='max-size pseudocode'>
        <div className='pseudocode__container'>
          {!isEmpty(pseucoCodeList) && typeof params.otxFileId !== 'undefined'
          ? <div className='max-size'>
            <ToggleButtonsComponent
              changePseudocodeTypeHandler={this.changePseudocodeTypeHandler} />
            <AutoSizer>
              {({ width, height }) => (
                <List
                  style={{ outline: 'none' }}
                  width={width}
                  height={height}
                  rowHeight={this.cache.rowHeight}
                  deferredMeasurementCache={this.cache}
                  rowCount={pseucoCodeList.length}
                  scrollToIndex={scrollToIndex}
                  scrollToAlignment={scrollToAlignment}
                  rowRenderer={({ key, index, style, parent }) => {
                  const item = pseucoCodeList[index]
                  return (
                    <CellMeasurer
                      key={key}
                      cache={this.cache}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}>
                      {({ measure, registerChild }) => (
                        <div ref={registerChild}
                          style={style}>
                          <div onLoad={measure}>
                            {this.renderPseudoCode([item], index)}
                          </div>
                        </div>
                      )}
                    </CellMeasurer>)
                  }}
                />
              )}
            </AutoSizer>
          </div>
          : !isActiveLoader &&
            <InfoComponent
              title={labels.infoComponent.title[0]} />}
        </div>
      </div>
    )
  }
}

export default PseudocodeComponent
