import ConditionalWizard from './ConditionalWizard'
import { connect } from 'react-redux'
import { getVariableList } from '../../../../store/thunk/reducerList/diagramElement'

const mapDispatchToProps = {
  getVariableList
}

const mapStateToProps = state => ({
  variableList: state.diagramElement.variableList
})

export default connect(mapStateToProps, mapDispatchToProps)(ConditionalWizard)
