import {
  SET_ACTIVE_CONSOLE_INDEX,
  SET_SSM_ID,
  SET_SELECTED_PSEUDOCODE_ID,
  SET_NODES_TO_COPY,
  SET_COPIED_NODES
} from '../actionTypeList'
import { validateDiagramOtxFileAction } from './diagramValidation'
import { setProjectTabDataAction, setExecutionsTabDataAction } from './project'
import { setDevicesTabData, getOutlineTreeAction } from './otxFile'
import { storeCollapsedElements } from '../../../constants/common'
import Notifications from 'react-notification-system-redux'
import labels from '../../../../public/labels.json'
import { returnInitValueHandler } from '../../../constants/initValues'
import isEqual from 'lodash/isEqual'

export const setActiveConsoleIndexAction = (index = 0) => {
  return {
    type: SET_ACTIVE_CONSOLE_INDEX,
    data: index
  }
}

export const setSelectedContextViewIdAction = id => {
  return {
    type: SET_SELECTED_PSEUDOCODE_ID,
    data: id
  }
}

export const setSsmIdAction = (id = -1) => {
  return {
    type: SET_SSM_ID,
    data: id
  }
}

const setNodesToCopyAction = (data = {}) => {
  return {
    type: SET_NODES_TO_COPY,
    data
  }
}

const setCopiedNodesAction = (data = {}) => {
  return {
    type: SET_COPIED_NODES,
    data
  }
}

export const setSsmId = (id = -1) =>
  dispatch => dispatch(setSsmIdAction(id))

export const setSelectedContextViewId = (id = null) =>
  dispatch => dispatch(setSelectedContextViewIdAction(id))

export const setActiveConsoleIndex = (oldIndex = 0, newIndex = 0) => {
  return dispatch => {
    dispatch(setActiveConsoleIndexAction(newIndex))
    if (oldIndex === 4 && newIndex !== 4) { dispatch(validateDiagramOtxFileAction([])) }
  }
}

export const updateTreeData = (nodes = [], position = '', activeTopTabIndex = null) => {
  return dispatch => {
    let localStorageName = ''
    if (position === 'top') {
      switch (activeTopTabIndex) {
        case 0:
          localStorageName = 'projectTabDataCollapsedElements'
          dispatch(setProjectTabDataAction(nodes))
        break
        case 1:
          localStorageName = 'devicesDataTabCollapsedElements'
          dispatch(setDevicesTabData(nodes))
        break
        case 2:
          localStorageName = 'executionsDataTabCollapsedElements'
          dispatch(setExecutionsTabDataAction(nodes))
        break
      }
    } else {
      localStorageName = 'outlineDataCollapsedElements'
      dispatch(getOutlineTreeAction(nodes))
    }
    storeCollapsedElements(nodes, localStorageName)
  }
}

export const copyElementHandler = newNodesToCopyObject => {
  return (dispatch, getState) => {
    if (newNodesToCopyObject.isContextOptionClicked) {
      const { success, info, options } = labels.notifications
      const amountOfElements = newNodesToCopyObject.elementsToBeCopied.length
      const isEqualtoPrevObject = isEqual(getState().selectElement.copiedNodes, newNodesToCopyObject)
      const option = options[newNodesToCopyObject.action]
      let type = ''
      const messageObj = {
        position: 'tr',
        autoDismiss: 2,
        title: '',
        message: ''
      }
      if (isEqualtoPrevObject) {
        type = 'info'
        messageObj.title = `${info.title} ${option}`
        messageObj.message = `${info.message[amountOfElements === 1 ? 0 : 1]} ${option}`
        dispatch(setNodesToCopyAction(newNodesToCopyObject))
      } else {
        type = 'success'
        messageObj.title = `${option} ${success.title}`
        messageObj.message = `${amountOfElements} ${success.message[amountOfElements === 1 ? 0 : 1]} ${option}`
        dispatch(setCopiedNodesAction(newNodesToCopyObject))
      }
      dispatch(Notifications[type](messageObj))
    } else {
      dispatch(setNodesToCopyAction(newNodesToCopyObject))
    }
  }
}

export const copyElementAfterDiagramChangedHandler = elementId => (dispatch, getState) => {
  const copiedNodes = getState().selectElement.copiedNodes
  const nodesToCopyObject = getState().selectElement.nodesToCopyObject
  if (copiedNodes.elementsToBeCopied.includes(elementId)) {
    const newCopiedNodes = returnInitValueHandler('copyObjectInit')
    Object.assign(newCopiedNodes, {
      nodesToBeCopied: copiedNodes.nodesToBeCopied.filter(el => el.otx_id !== elementId),
      elementsToBeCopied: copiedNodes.elementsToBeCopied.filter(id => id !== elementId),
      source: copiedNodes.source,
      isContextOptionClicked: copiedNodes.isContextOptionClicked,
      action: copiedNodes.action
    })

    dispatch(setCopiedNodesAction(newCopiedNodes))
  }
  if (nodesToCopyObject.elementsToBeCopied.includes(elementId)) {
    const newNodesToCopyObject = returnInitValueHandler('copyObjectInit')

    Object.assign(newNodesToCopyObject, {
      nodesToBeCopied: nodesToCopyObject.nodesToBeCopied.filter(el => el.otx_id !== elementId),
      elementsToBeCopied: nodesToCopyObject.elementsToBeCopied.filter(id => id !== elementId),
      source: nodesToCopyObject.source,
      isContextOptionClicked: nodesToCopyObject.isContextOptionClicked,
      action: nodesToCopyObject.action
    })

    dispatch(setNodesToCopyAction(newNodesToCopyObject))
  }
}

export const clearAfterPasteHandler = () =>
  dispatch => dispatch(setCopiedNodesAction(returnInitValueHandler('copyObjectInit')))

export const actions = {
  setActiveConsoleIndex,
  setSsmId,
  setSelectedContextViewId,
  copyElementHandler,
  copyElementAfterDiagramChangedHandler,
  clearAfterPasteHandler
}

const ACTION_HANDLERS = {
  [SET_SSM_ID]: (state, action) => {
    return {
      ...state,
      ssmId: action.data
    }
  },
  [SET_ACTIVE_CONSOLE_INDEX]: (state, action) => {
    return {
      ...state,
      activeConsoleIndex: action.data
    }
  },
  [SET_SELECTED_PSEUDOCODE_ID]: (state, action) => {
    return {
      ...state,
      selectedContextViewId: action.data
    }
  },
  [SET_NODES_TO_COPY]: (state, action) => {
    return {
      ...state,
      nodesToCopyObject: action.data
    }
  },
  [SET_COPIED_NODES]: (state, action) => {
    return {
      ...state,
      copiedNodes: action.data,
      nodesToCopyObject: returnInitValueHandler('copyObjectInit')
    }
  }
}

const initialState = {
  activeConsoleIndex: 0,
  ssmId: -1,
  selectedContextViewId: null,
  nodesToCopyObject: returnInitValueHandler('copyObjectInit'),
  copiedNodes: returnInitValueHandler('copyObjectInit')
}

export default function selectElementReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
