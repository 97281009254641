import React, { Component } from 'react'
import './XmlPreviewTabComponent.scss'
import PropTypes from 'prop-types'
import XMLViewer from 'react-xml-viewer'
import labels from '../../../../../public/labels.json'

class XmlPreviewTabComponent extends Component {
    static propTypes = {
        xmlPreview: PropTypes.string
      }

  render () {
    return (
      <div>
        {this.props.xmlPreview !== ''
          ? <XMLViewer xml={this.props.xmlPreview} /> : labels.XmlPreviewTabComponent.errorResponse}
      </div>
    )
  }
}

export default XmlPreviewTabComponent
