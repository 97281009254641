import React, { useState } from 'react'
import './DashboardComponent.scss'
import PickProjectComponent from './components/PickProjectComponent'
import ReportsComponent from './components/ReportsComponent'
import { Menu } from 'semantic-ui-react'
import labels from '../../../public/labels.json'

const { menuItems } = labels.dashboardComponent

const DashboardComponent = () => {
  const [activeItem, setActiveItem] = useState(menuItems[0])

  return (
    <div className='dashBoard'>
      <Menu pointing secondary>
        {menuItems.map(item => (
          <Menu.Item
            key={item}
            name={item}
            active={activeItem === item}
            onClick={() => setActiveItem(item)} />))}
      </Menu>
      <div className='dashBoard-card'>
        {activeItem === menuItems[0] && <PickProjectComponent />}
        {activeItem === menuItems[1] && <ReportsComponent />}
      </div>
    </div>
  )
}

export default DashboardComponent
