import { connect } from 'react-redux'
import NewLocalDeclarationComponent from './NewLocalDeclarationComponent'
import { openAddVariable } from '../../../../store/thunk/reducerList/diagramElement'

const mapDispatchToProps = { openAddVariable }

const mapStateToProps = state => ({
    isOpenAddVar: state.diagramElement.isOpenAddVar
})

export default connect(mapStateToProps, mapDispatchToProps)(NewLocalDeclarationComponent)
